import {
  Autocomplete,
  Button as Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import { getDevices } from "../../../../store/device/deviceAction";
import ComboBox from "../../../../components/select/ComboBox";
import palette from "../../../../theme/palette";
import {
  getAutoReplies2,
  updateDeviceAutoReply,
} from "../../../../store/marketing/marketingAction";

export default function UpdateDeviceModalFacebook({ onCancle }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { autoReplies } = useSelector((state) => state.marketing);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_device_key: "",
      new_devices_key: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const new_devices_key = [];

      value.new_devices_key.map((item) => {
        new_devices_key.push(item.device_key);
      });

      const data = {
        old_device_key: value.old_device_key,
        new_devices_key: new_devices_key,
        use_all_devices: value.new_devices_key.find(
          (e) => e.device_key === "all"
        )
          ? true
          : false,
      };

      await dispatch(updateDeviceAutoReply(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      onCancle();
      handleGetAutoReplies2();
      resetForm();
      setIsLoading(false);
    },
  });

  const handleGetAutoReplies2 = async () => {
    await dispatch(getAutoReplies2());
  };

  const handleGetDevices = async () => {
    await dispatch(getDevices());
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  return (
    <FormContainer title="Update Device">
      <Box mb={2}>
        <Box sx={{ position: "relative", height: "68px" }}>
          <Box
            sx={{
              position: "fixed",
              width: "calc(100% - 40px)",
              zIndex: "1500",
            }}
          >
            <ComboBox
              fullWidth
              disableMargin
              title="Old Device"
              placeholder="Please select channel"
              options={
                autoReplies
                  ? [
                      ...new Map(
                        autoReplies?.data?.map((item) => [
                          item.device_key,
                          item,
                        ])
                      ).values(),
                    ]
                  : []
              }
              getOptionLabel={(option) => option?.device_key}
              sx={{ zIndex: "1300" }}
              onChange={(e, value) => {
                formik.setFieldValue("old_device_key", value.device_key);
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Box>
          <Box>
            <Typography fontWeight="700" mb={0.5}>
              New Device
            </Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={[{ name: "All", device_key: "all" }].concat(devices)}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              ChipProps={{
                style: {
                  backgroundColor: palette.primary.main,
                  color: palette.secondary.main,
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Plase select device"
                  size="small"
                />
              )}
              onChange={(e, value) =>
                formik.setFieldValue("new_devices_key", value)
              }
            />
          </Box>
        </Box>
      </Box>
      <Box mb={3} />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Paper onClick={onCancle}>Cancel</Paper>
        <BasicButton
          disabled={isLoading}
          title="Submit"
          onClick={formik.handleSubmit}
        />
      </Stack>
    </FormContainer>
  );
}
