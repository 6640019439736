import { Autocomplete, Avatar, Box, Button, Grid, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import toast from 'react-hot-toast';
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import Container from "../../../../components/containers/Container";
import Multilines from "../../../../components/input/Multiline";
import ComboBox from "../../../../components/select/ComboBox";
import palette from "../../../../theme/palette";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import { useFormik } from "formik";
import BasicButton from "../../../../components/button/BasicButton";
import { useDispatch } from "react-redux";
import { getContacts, updateContact } from "../../../../store/contact/contactAction";

export default function DetailContact({ onClose, personToEdit, search, page }) {
  const [loadingSubmit, setLoadingSubmit] = React.useState(false)
  const dispatch = useDispatch()
  // const list = [
  //   {
  //     id: 1,
  //     key: "Name",
  //     value: "Byorka",
  //   },
  //   {
  //     id: 2,
  //     key: "Phone",
  //     value: "60182298957",
  //   },
  //   {
  //     id: 3,
  //     key: "Email",
  //     value: "byorka@gmail.com",
  //   },
  //   {
  //     id: 4,
  //     key: "Facebook Link",
  //     value: "_",
  //   },
  //   {
  //     id: 5,
  //     key: "Instagram Link",
  //     value: "_",
  //   },
  //   {
  //     id: 6,
  //     key: "Message sent",
  //     value: "_",
  //   },
  //   {
  //     id: 7,
  //     key: "Message received",
  //     value: "_",
  //   },
  // ];
  // const test = [
  //   { label: "Payment in process", year: 1994 },
  //   { label: "Payment in process", year: 1994 },
  //   { label: "Payment in process", year: 1994 },
  // ];

  const tags = ["Shopee", "Facebook", "Instagram", "Web"]

  function preFetchContact(){
    const data = {
      params: {
        search: search,
        page: page,
      },
    };
    dispatch(getContacts(data))
  }

  const formik = useFormik({
    initialValues: {
      id: personToEdit?.id,
      name: personToEdit?.name,
      phone: personToEdit?.phone,
      email: personToEdit?.email,
      keyword: personToEdit?.keyword ? personToEdit.keyword : [],
      facebook: personToEdit?.facebook,
      instagram: personToEdit?.instagram
    },
    onSubmit: async (value) => {
      setLoadingSubmit(true)

      toast.loading("Please wait...")

      const dataUpdate = {
        id: value.id,
        name: value.name,
        phone: value.phone,
        email: value.email,
        keyword: value.keyword,
        facebook: value.facebook,
        instagram: value.instagram
      }

      const result = await dispatch(updateContact(dataUpdate))
      if (result?.type == 'contact/update/rejected') {
        toast.dismiss();
        toast.error(result?.error?.message);
        setLoadingSubmit(false)
      } else if (result?.type == 'contact/update/fulfilled') {
        toast.dismiss();
        toast.success(result?.payload?.data?.message);
        setLoadingSubmit(false)
        preFetchContact()
        onClose()
      }
    }
  })

  const desktopSize = useMediaQuery('(min-width:1680px)')

  return (
    // <Container iconClose onClose={onClose}>
    //   <Grid container spacing={10}>
    //     <Grid item xs={6}>
    //       <Box sx={{ display: "grid", justifyContent: "center" }}>
    //         <Avatar
    //           alt="Remy Sharp"
    //           src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQr94KjFti_6ImzW1XF5jpEBPlmcRVTZall7w&usqp=CAU"
    //           sx={{
    //             width: { xs: 100, sm: 140, md: 220 },
    //             height: { xs: 100, sm: 140, md: 220 },
    //           }}
    //         />
    //       </Box>
    //       <Stack mt={3} spacing={0.5} mb={4}>
    //         {list.map((item, index) => {
    //           return (
    //             <Box key={index} display="flex" justifyContent="space-between">
    //               <Typography color="#7B7B7B" fontSize="16px" fontWeight="400">
    //                 {item.key}
    //               </Typography>
    //               <Typography
    //                 color={item.id === 2 ? palette.primary.main : "#7B7B7B"}
    //                 fontSize="16px"
    //                 fontWeight="400"
    //               >
    //                 {item.value}
    //               </Typography>
    //             </Box>
    //           );
    //         })}
    //       </Stack>
    //       <Box sx={{ display: "grid", justifyContent: "center" }}>
    //         <ButtonWithIcon icon="akar-icons:whatsapp-fill">
    //           <Typography ml={1} fontSize={20}>
    //             Start Conversation
    //           </Typography>
    //         </ButtonWithIcon>
    //       </Box>
    //     </Grid>
    //     <Grid item xs={6}>
    //       <ComboBox multiple options={test} fullWidth title="Tag" />
    //       <Multilines
    //         title="Order"
    //         rows={3}
    //         placeholder="This customer has no registered order."
    //       />
    //       <Multilines title="Notes" rows={3} placeholder="No notes." />
    //     </Grid>
    //   </Grid>
    // </Container>
    <Box style={{
      height: desktopSize ? 'auto' : '90vh',
    }}>
      <FormContainer title="Update Contact">
        <BasicInput title="Name" {...formik.getFieldProps('name')} />
        <BasicInput title="Phone" {...formik.getFieldProps("phone")} />
        <BasicInput title="Email" {...formik.getFieldProps("email")} />
        <Typography fontWeight="700" mb={0.5}>
          Tags
        </Typography>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={tags}
          getOptionLabel={(option) => option}
          defaultValue={personToEdit?.keyword ? personToEdit.keyword : []}
          filterSelectedOptions
          ChipProps={{
            style: {
              backgroundColor: palette.primary.main,
              color: palette.secondary.main,
            },
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
          onChange={(e, value) =>
            formik.setFieldValue("keyword", value.toString())
          }
        />
        <Box mb={3} />
        <BasicInput title="Facebook Link" {...formik.getFieldProps("facebook")} />
        <BasicInput
          title="Instagram Link"
          {...formik.getFieldProps("instagram")}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button
            onClick={onClose}
          >Cancel</Button>
          <BasicButton
            title="Save"
            type="submit"
            disabled={loadingSubmit}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </FormContainer>
    </Box>
  );
}
