import { Box, Button } from "@mui/material";
import Iconify from "../../../../../../../../../components/Iconify";

export default function BodyTools({
    clickB,
    clickI,
    addVariable
}) {
    // const miniMobile = useMediaQuery('(max-width:360px)')

    return <Box
    // sx={{
    //     display: 'flex',
    //     width: miniMobile ? '100%' : 'fit-content',
    //     justifyContent: miniMobile ? 'flex-end' : 'flex-start'
    // }}
    >
        <Button
            sx={{
                minWidth: 30
            }}
            onClick={clickB}
        >
            <Iconify icon="octicon:bold-16" />
        </Button>
        <Button
            sx={{
                minWidth: 30
            }}
            onClick={clickI}
        >
            <Iconify icon="tabler:italic" />
        </Button>
        <Button
            variant="text"
            sx={{
                fontSize: {
                    xs: 12,
                    sm: 14,
                    md: 14
                },
                fontWeight: 500
            }}
            onClick={addVariable}
        >
            <Iconify icon="ic:round-plus" sx={{
                marginRight: 0.5
            }} /> Add variable
        </Button>
    </Box>
}