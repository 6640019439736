import { Checkbox, Chip, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FormContainer from "../../../../components/containers/FormContainer";
import Iconify from "../../../../components/Iconify";
import InputSearch from "../../../../components/input/InputSearch";

const generateColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, "0");
  return `#${randomColor}`;
};

export default function Tags({ onCancle }) {
  const [value, setValue] = React.useState(null);
  const [tags, setTags] = React.useState([
    {
      title: "New Customer",
      color: "#31C7F8",
    },
    {
      title: "New Customer",
      color: "#00CFB3",
    },
    {
      title: "Payment in process",
      color: "#FD8C7D",
    },
  ]);

  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const _onChange = (event) => {
    setValue(event.target.value);
  };

  const _onSubmit = (event) => {
    setValue(null);
    event.preventDefault();
    tags.push({
      title: value,
      color: generateColor(),
    });
    console.log(tags);
  };

  return (
    <FormContainer title="Tags">
      <form onSubmit={_onSubmit}>
        <InputSearch id="tag" name="tag" value={value} onChange={_onChange} />
        <Box mt={2} />
        {tags.map((item, index) => {
          return (
            <div key={index}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box gap={2} display="flex" alignItems="center">
                  <Checkbox size="small" />
                  <Chip
                    size="small"
                    label={item.title}
                    color="info"
                    onDelete={handleDelete}
                    sx={{ backgroundColor: item.color }}
                  />
                </Box>
                <IconButton>
                  <Iconify
                    icon={"fluent:delete-24-regular"}
                    color="red"
                    width={20}
                    height={20}
                  />
                </IconButton>
              </Box>
              <Divider />
            </div>
          );
        })}
        <Box
          my={1}
          display={value === null ? "none" : "flex"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontSize="14px">
            Create tag{" "}
            <span style={{ fontWeight: "600", fontSize: "15px" }}>{value}</span>
          </Typography>
          <IconButton type="submit">
            <Iconify
              icon={"carbon:add-alt"}
              color="#1A776F"
              width={22}
              height={22}
            />
          </IconButton>
        </Box>
        <Divider />
      </form>
      <Box mb={4} />
    </FormContainer>
  );
}
