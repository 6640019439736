import { createSlice } from "@reduxjs/toolkit";
import { getDailyMessageCount, getUserActivity } from "./statisticAction";

const initialState = {
  userActivity: null,
  dailyMessageCount: null,
  loading: false,
};

const statisticSlice = createSlice({
  name: "statistic",
  initialState: initialState,
  extraReducers: {
    [getUserActivity.fulfilled]: (state, { payload }) => {
      state.userActivity = payload;
    },
    [getUserActivity.rejected]: (state) => {
      state.userActivity = null;
    },
    [getDailyMessageCount.fulfilled]: (state, { payload }) => {
      state.dailyMessageCount = payload;
    },
    [getDailyMessageCount.rejected]: (state) => {
      state.dailyMessageCount = null;
    },
  },
});

export const { resetStatistic } = statisticSlice.actions;

export default statisticSlice.reducer;
