import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../../components/Iconify";
import TransparentContainer from "../../../../components/containers/TransparentContainer";
import {
  deleteCatalogAddress,
  getCatalogAddresses,
} from "../../../../store/catalog/catalogAction";
import AddAdress from "./AddAdress";
import EasyParcel from "./EasyParcel";
import Profile from "./Profile";
import ShippingMethod from "./ShippingMethod";
import ShippingRate from "./ShippingRate";

export default function ShippingSetting() {
  const dispatch = useDispatch();

  const { catalogAddresses } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);
  const [isAddAddress, setIsAddAddress] = useState(false);

  const [detail, setDetail] = useState(false);

  const handleGetCatalogOrdersMessage = async () => {
    setIsLoading(true);

    await dispatch(getCatalogAddresses());

    setIsLoading(false);
  };

  const handleDeleteCatalogAddress = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteCatalogAddress(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);
        handleGetCatalogOrdersMessage();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    handleGetCatalogOrdersMessage();
  }, []);

  return (
    <TransparentContainer
      title="Shipping Setting"
      withButton
      buttonTitle="New Address"
      buttonIcon="carbon:add-alt"
      onClick={() => setIsAddAddress(!isAddAddress)}
    >
      <Typography variant="h5">Store Address</Typography>

      {!isAddAddress ? (
        <Box mt={1.5} alignItems="center" display="flex">
          <Iconify
            icon="bi:info-circle-fill"
            sx={{ width: 14, height: 14, color: "#7B7B7B", mr: 2 }}
          />
          <Typography maxWidth="280px" fontSize="12px">
            Enter the address first, so that the courier won't be confused about
            picking up the order!
          </Typography>
        </Box>
      ) : (
        <Box mt={1.5} />
      )}

      {!isAddAddress ? (
        <Grid container mt={1.5} spacing={2}>
          {catalogAddresses?.data?.map((item, index) => {
            return (
              <Grid item xs={12} sm={12} key={index}>
                <Profile
                  detail={item}
                  onDelete={(e) => handleDeleteCatalogAddress(e)}
                  onEdit={(e) => {
                    setDetail(e);
                    setIsAddAddress(true);
                  }}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6}>
            <Box display>
              <ShippingMethod />
              <Box mb={2} />
              <Box display={{
                xs: 'block',
                sm: 'none'
              }}>
                <ShippingRate />
              </Box>
              {/* <Instant /> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <EasyParcel />
          </Grid>
          <Grid
            display={{
              xs: 'none',
              sm: 'block'
            }}
            item
            sm={12}
            md={6}
          >
            <ShippingRate />
          </Grid>
        </Grid>
      ) : (
        <AddAdress
          detail={detail}
          setIsAddAddress={(e) => {
            setDetail(null);
            setIsAddAddress(e);
          }}
        />
      )}
    </TransparentContainer>
  );
}
