import {
  Badge,
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyBox from "../../../../components/EmptyBox";
import Scrollbar from "../../../../components/Scrollbar";
import { getReportBulkEmail } from "../../../../store/report/reportAction";
import palette from "../../../../theme/palette";

const tableCellList = ["Bulk ID", "Date", "Total", "Total Success", "Cost", "Remaining Credits", "Status"];

export default function BulkEmail() {
  const dispatch = useDispatch();
  const { bulkEmailAll } = useSelector((state) => state.report);

  const [isLoading, setIsLoading] = useState(false);

  const handeGetReportBulkEmail = async (data) => {
    setIsLoading(true);

    await dispatch(getReportBulkEmail(data));

    setIsLoading(false);
  };

  useEffect(() => {
    handeGetReportBulkEmail();
  }, []);

  return (
    <Card
      style={{
        backgroundColor: "#FDFDFD",
        padding: "16px",
        borderRadius: "10px",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        mb={1}
        pl={2}
        pr={1}
        alignItems="center"
      >
        <Box>
          <Typography
            fontSize={{ xs: 20, sm: 24 }}
            fontWeight="700"
            color="#131313"
          >
            Email Broadcast
          </Typography>
        </Box>
      </Box>
      {bulkEmailAll ? (
        <Scrollbar>
          <TableContainer
            component={Paper}
            style={{ backgroundColor: "#FDFDFD" }}
          >
            <Table aria-label="simple table">
              <TableHead
                style={{
                  background: palette.gradients.custom,
                }}
              >
                <TableRow>
                  {tableCellList.map((item, index) => {
                    return (
                      <TableCell align="left" key={index}>
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent={"space-between"}
                        >
                          <Typography color="#FDFDFD">{item}</Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Box
                        component="img"
                        src="/images/loading.svg"
                        alt="loading"
                        height="48px"
                        sx={{ mx: "auto" }}
                      />
                    </TableCell>
                  </TableRow>
                ) : bulkEmailAll?.length ? (
                  bulkEmailAll?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{item?.bulk_id}</TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {item?.created_at}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {item?.total}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {item?.total_success}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {item?.cost}
                      </TableCell>
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        RM{item?.remain}
                      </TableCell>
                      <TableCell align="left">
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          {item?.status === "stopped" ? (
                            <Badge color="error" badgeContent={item?.status}>
                              <></>
                            </Badge>
                          ) : item?.status === "success" ? (
                            <Badge color="primary" badgeContent={item?.status}>
                              <></>
                            </Badge>
                          ) : (
                            <Badge color="warning" badgeContent={item?.status}>
                              <></>
                            </Badge>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      Data Not Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      ) : (
        <EmptyBox title1="No Email Broadcast Database" />
      )}
    </Card>
  );
}
