import axios from "axios";

export const api = axios.create({
  baseURL: "https://new-api.realm.chat",
});

export const clientApi = axios.create({
  baseURL: "https://new-client.realm.chat/api",
});

export const connectApi = axios.create({
  baseURL: "https://connect.realm.chat/api",
});
