import { IconButton, Stack, Tooltip } from "@mui/material";
import Iconify from "../../../../../components/Iconify";

export default function MenuCloudAPI({
    handleAddCatalogWaba
}) {
    return <Stack direction="row">
        <Tooltip title="Add Catalog">
            <IconButton
                onClick={handleAddCatalogWaba}
            >
                <Iconify
                    icon={"solar:cart-line-duotone"}
                    color="#64d88b"
                    width={24}
                    height={24}
                />
            </IconButton>
        </Tooltip>
    </Stack>
}