import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardContainer from "../../../../components/containers/CardContainer";
import EmptyBox from "../../../../components/EmptyBox";
import Scrollbar from "../../../../components/Scrollbar";
import { getShippingGateways } from "../../../../store/catalog/catalogAction";
import ShippingMethodForm from "./ShippingMethodForm";
import ShippingMethodList from "./ShippingMethodList";

export default function ShippingMethod() {
  const dispatch = useDispatch();

  const { shippingGateways } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetShippingGateways = async () => {
    setIsLoading(true);

    await dispatch(getShippingGateways());

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetShippingGateways();
  }, []);

  return (
    <CardContainer title="Choose Store Shipping Method">
      <Scrollbar>
        {isLoading ? (
          <Box sx={{ py: 2 }}>
            <Box
              component="img"
              src="/images/loading.svg"
              alt="loading"
              height="48px"
              sx={{ mx: "auto" }}
            />
          </Box>
        ) : shippingGateways?.data?.length ? (
          shippingGateways?.data?.map((item, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary sx={{ px: 0 }}>
                  <ShippingMethodList key={index} item={item} />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    pt: 2,
                    backgroundColor: "#F5F5F5",
                    borderRadius: "0px 0px 16px 16px",
                  }}
                >
                  <ShippingMethodForm forms={item.form} name={item.name} />
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Box mt={6}>
            <EmptyBox leaf title1="Data Not Found" />
          </Box>
        )}
      </Scrollbar>
    </CardContainer>
  );
}
