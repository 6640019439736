import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import BasicInput from "../../../../components/input/BasicInput";
import { updatePaymentGateway } from "../../../../store/catalog/catalogAction";

const ItemForm = ({ item, value, onChange, index }) => {
  return (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <Box display="flex" gap={1} height="40px">
        <BasicInput
          type={item?.type}
          label={item?.label}
          value={value[item?.name]}
          onChange={(e) => {
            onChange(item?.name, e.target.value);
          }}
        />
      </Box>
    </Grid>
  );
};

export default function Form({ forms, name }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState([]);

  const handleUpdatePaymentGateway = async () => {
    setIsLoading(true);

    toast.loading("Please wait...");

    const data = {
      name,
    };

    formValues.map(async (item) => {
      const key = Object.keys(item)[0];
      data[key] = item[key];
    });

    await dispatch(updatePaymentGateway(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
          toast.success(res.payload.message)
        } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });

    setIsLoading(false);
  };

  const handleChangeForm = async (name, value, index) => {
    const _formValues = formValues.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: value,
        };
      } else {
        return item;
      }
    });

    setFormValues(_formValues);
  };

  useEffect(() => {
    if (forms) {
      const _formValues = forms.map((item) => {
        return {
          [item.name]: item.value ?? "",
        };
      });

      setFormValues(_formValues);
    }
  }, [forms]);

  return (
    <>
      <Grid container spacing={2}>
        {formValues.length
          ? forms?.map((item, index) => {
              return (
                <ItemForm
                  item={item}
                  value={formValues[index]}
                  onChange={(name, value) =>
                    handleChangeForm(name, value, index)
                  }
                  key={index}
                />
              );
            })
          : null}
      </Grid>
      <Box mb={3} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <BasicButton
          type="submit"
          title="Save"
          disabled={isLoading}
          onClick={() => handleUpdatePaymentGateway()}
        />
      </Box>
    </>
  );
}
