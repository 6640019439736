import { IconButton, Link, Stack } from "@mui/material";
import Iconify from "../../../../components/Iconify";

export function createData(no, name, phoneNumber, action) {
  return { no, name, phoneNumber, action };
}
export const IconList = ({ onEdit, onDelete }) => {
  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={onEdit}>
        <Iconify
          icon={"akar-icons:edit"}
          color="#1A776F"
          width={24}
          height={24}
        />
      </IconButton>
      <IconButton onClick={onDelete}>
        <Iconify
          icon={"fluent:delete-24-regular"}
          color="#F15A28"
          width={24}
          height={24}
        />
      </IconButton>
    </Stack>
  );
};

export const tableCell = [
  "Device",
  "WhatsApp Schedule",
  "Date & Time",
  "Action",
];

export const IconListDetailResponsive = ({ onClickExportIcon }) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      display={{ xs: "flex", sm: "none", md: "none" }}
    >
      <Link onClick={onClickExportIcon}>
        <Iconify
          icon={"mdi:file-export-outline"}
          color="#00BCD4"
          width={24}
          height={24}
        />
      </Link>
    </Stack>
  );
};
export const addOption = [
  {
    id: 1,
    title: "Add Manual",
  },
  {
    id: 2,
    title: "Import From Excel File",
  },
  {
    id: 3,
    title: "Import From Device",
  },
  {
    id: 4,
    title: "Import From Whatsapp Groups",
  },
];
