import { createSlice } from "@reduxjs/toolkit";
import { getPlans } from "./planAction";

const initialState = {
  plans: null,
};

const planSlice = createSlice({
  name: "plan",
  initialState: initialState,
  extraReducers: {
    [getPlans.fulfilled]: (state, { payload }) => {
      state.plans = payload.data;
    },
    [getPlans.rejected]: (state) => {
      state.plans = null;
    },
  },
});

export const {} = planSlice.actions;

export default planSlice.reducer;
