import {
  Box,
  CardMedia,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import { useSelector } from "react-redux";
import formatWppMarkdown from "whatsapp-markdown";
import palette from "../../theme/palette";
import Iconify from "../Iconify";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import ButtonTooltip from "../button/ButtonTooltip";

export const DeviceFrame = ({
  single,
  multiples,
  children,
  display,
  setFooter,
}) => {
  const ref = React.useRef();

  const [value, setValue] = React.useState("Powered by realm.chat");

  var today = new Date();
  var time = today.getHours() + ":" + today.getMinutes();

  const { plan } = useSelector((state) => state.user);

  const [isListModalOpen, setIsListModalOpen] = React.useState(false);

  const [listModal, setListModal] = React.useState(null);

  useEffect(() => {
    if (setFooter) {
      setFooter(value);
    }
  }, [value]);

  useEffect(() => {
    formatWppMarkdown(".chat-message");
  }, [multiples]);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box>
        <DeviceFrameset device="iPhone X" color="black" zoom="0.75">
          <Box>
            <Box
              sx={{
                backgroundColor: "#008069",
                height: "72px",
                position: "absolute",
                zIndex: 100,
                width: "100%",
              }}
            >
              <Box
                component="img"
                src="/images/statusbar.svg"
                sx={{ pt: "2px", width: "100%" }}
              />
              <Box
                component="img"
                src="/images/nav-wa.svg"
                sx={{ mt: "0px", width: "100%" }}
              />
            </Box>
            <Box
              sx={{
                backgroundImage: `url(${"/images/bg-wa.svg"})`,
                height: "860px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {!multiples?.formMultiples?.length ? (
                // single
                <>
                  <Box
                    sx={{
                      bottom: 32,
                      right: 12,
                      position: "absolute",
                      width: "calc(100% - 100px)",
                    }}
                  >
                    {/* single - audio or voice */}
                    {single?.fileSelected?.file_type === "audio" ? (
                      <Box
                        sx={{
                          borderRadius: "6px",
                          display: display,
                          backgroundColor: "rgba(231, 255, 219, 1)",
                          px: 2,
                          pt: 1,
                          pb: 0.5,
                          fontSize: 16,
                        }}
                      >
                        <Box>
                          <Box sx={{ mb: 1 }}>
                            <Box
                              sx={{
                                py: 1,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                borderRadius: "4px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Iconify
                                  icon="material-symbols:play-arrow-rounded"
                                  height="28px"
                                  width="48px"
                                  color="#94a3b8"
                                />
                                <Box
                                  sx={{
                                    ml: 1,
                                    mr: 2,
                                    height: "4px",
                                    width: "100%",
                                    background: "#94a3b8",
                                  }}
                                ></Box>
                              </Box>
                              {single?.isVoice ? (
                                <Box sx={{ position: "relative" }}>
                                  <CardMedia
                                    component="img"
                                    image={`https://realm.chat/images/logo-1.svg`}
                                    loading="lazy"
                                    sx={{
                                      height: "32px",
                                      width: "32px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Iconify
                                    icon="material-symbols:mic-rounded"
                                    height="16px"
                                    width="16px"
                                    color="#38bdf8"
                                    sx={{
                                      position: "absolute",
                                      bottom: "0",
                                      left: "0",
                                    }}
                                  />
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    p: 1,
                                    height: "32px",
                                    width: "32px",
                                    background: "#fbbf24",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <Iconify
                                    icon="ri:headphone-fill"
                                    height="16px"
                                    width="16px"
                                    color="#FFFFFF"
                                  />
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box
                            mb={1}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Typography
                                fontSize="14px"
                                color="rgba(55, 55, 55, 0.7)"
                              >
                                {value}
                              </Typography>
                            </Box>
                            <Box display="flex">
                              <Typography
                                fontSize="8px"
                                mr={1}
                                color="rgba(55, 55, 55, 0.7)"
                              >
                                {time}
                              </Typography>
                              <img src="/static/icons/double_check.svg" />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ) : null}
                    {/* single - message or reaction type */}
                    <Box
                      sx={{
                        borderRadius: "6px",
                        display: display,
                        backgroundColor: "rgba(231, 255, 219, 1)",
                        px: 2,
                        pt: 1,
                        mt: 1,
                        pb: 0.5,
                        fontSize: 16,
                      }}
                    >
                      {single?.formListsOther?.title ? (
                        <Box mb={0.25}>
                          <Typography
                            fontSize="18px"
                            color="#3b4a54"
                            fontWeight={700}
                          >
                            {single?.formListsOther?.title}
                          </Typography>
                        </Box>
                      ) : null}
                      {single?.fileSelected?.file_type === "image" ? (
                        <Box sx={{ mb: 1 }}>
                          <CardMedia
                            component="img"
                            height="140px"
                            width="100%"
                            image={`https://new-client.realm.chat/cloud_storage/${single?.fileSelected?.file_url}`}
                            loading="lazy"
                            sx={{ objectFit: "cover", borderRadius: "8px" }}
                          />
                        </Box>
                      ) : single?.fileSelected?.file_type === "video" ? (
                        <Box sx={{ mb: 1 }}>
                          <CardMedia
                            component="img"
                            height="140px"
                            width="100%"
                            image={`/cloud/video.png`}
                            loading="lazy"
                            sx={{ objectFit: "cover", borderRadius: "8px" }}
                          />
                        </Box>
                      ) : single?.fileSelected?.file_type === "document" ? (
                        <Box sx={{ mb: 1 }}>
                          <Box
                            sx={{
                              p: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#cdf0bb",
                              width: "100%",
                              borderRadius: "4px",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Iconify
                                icon="bxs:file-pdf"
                                height="32px"
                                width="32px"
                                color="#FFFF"
                              />
                              <Typography
                                fontSize="14px"
                                color="rgba(55, 55, 55, 0.7)"
                                sx={{ ml: 1 }}
                              >
                                {single?.fileSelected?.file_name.length > 16
                                  ? single?.fileSelected?.file_name.substring(
                                    0,
                                    16
                                  ) + "..."
                                  : single?.fileSelected?.file_name.substring(
                                    0,
                                    16
                                  )}
                              </Typography>
                            </Box>
                            <Iconify
                              icon="el:download"
                              height="28px"
                              width="28px"
                              color="#3b82f6"
                            />
                          </Box>
                        </Box>
                      ) : null}
                      {single?.typeMessage?.key !== "reaction" ? (
                        children
                      ) : (
                        <Box
                          p={0.5}
                          sx={{
                            right: "16px",
                            bottom: "-20px",
                            position: "absolute",
                            background: "#fff",
                            borderRadius: "50%",
                          }}
                        >
                          {children}
                        </Box>
                      )}

                      <Box>
                        <Box
                          mb={1}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Typography
                              fontSize="14px"
                              color="rgba(55, 55, 55, 0.7)"
                            >
                              {value}
                            </Typography>
                          </Box>
                          <Box display="flex">
                            <Typography
                              fontSize="8px"
                              mr={1}
                              color="rgba(55, 55, 55, 0.7)"
                            >
                              {time}
                            </Typography>
                            <img src="/static/icons/double_check.svg" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {/* single - buttons type */}
                    {single?.formButtons?.length === 1 ? (
                      <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
                        <Box
                          sx={{
                            borderRadius: "6px",
                            display: "block",
                            backgroundColor: "rgba(231, 255, 219, 1)",
                            px: 2,
                            pt: 1,
                            pb: 0.5,
                            mx: 0.5,
                            fontSize: 16,
                            width: "100%",
                            textAlign: "center",
                            color: "#1FA2FF",
                            minHeight: "36px",
                          }}
                        >
                          {single?.formButtons[0].button_title}
                        </Box>
                      </Box>
                    ) : single?.formButtons?.length === 2 ? (
                      <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
                        <Box
                          sx={{
                            borderRadius: "6px",
                            display: "block",
                            backgroundColor: "rgba(231, 255, 219, 1)",
                            px: 2,
                            pt: 1,
                            pb: 0.5,
                            mx: 0.5,
                            fontSize: 16,
                            width: "50%",
                            textAlign: "center",
                            color: "#1FA2FF",
                            minHeight: "36px",
                          }}
                        >
                          {single?.formButtons[0].button_title}
                        </Box>
                        <Box
                          sx={{
                            borderRadius: "6px",
                            display: "block",
                            backgroundColor: "rgba(231, 255, 219, 1)",
                            px: 2,
                            pt: 1,
                            pb: 0.5,
                            mx: 0.5,
                            fontSize: 16,
                            width: "50%",
                            textAlign: "center",
                            color: "#1FA2FF",
                            minHeight: "36px",
                          }}
                        >
                          {single?.formButtons[1].button_title}
                        </Box>
                      </Box>
                    ) : single?.formButtons?.length === 3 ? (
                      <>
                        <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
                          <Box
                            sx={{
                              borderRadius: "6px",
                              display: "block",
                              backgroundColor: "rgba(231, 255, 219, 1)",
                              px: 2,
                              pt: 1,
                              pb: 0.5,
                              mx: 0.5,
                              fontSize: 16,
                              width: "50%",
                              textAlign: "center",
                              color: "#1FA2FF",
                              minHeight: "36px",
                            }}
                          >
                            {single?.formButtons[0].button_title}
                          </Box>
                          <Box
                            sx={{
                              borderRadius: "6px",
                              display: "block",
                              backgroundColor: "rgba(231, 255, 219, 1)",
                              px: 2,
                              pt: 1,
                              pb: 0.5,
                              mx: 0.5,
                              fontSize: 16,
                              width: "50%",
                              textAlign: "center",
                              color: "#1FA2FF",
                              minHeight: "36px",
                            }}
                          >
                            {single?.formButtons[1].button_title}
                          </Box>
                        </Box>
                        <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
                          <Box
                            sx={{
                              borderRadius: "6px",
                              display: "block",
                              backgroundColor: "rgba(231, 255, 219, 1)",
                              px: 2,
                              pt: 1,
                              pb: 0.5,
                              mx: 0.5,
                              fontSize: 16,
                              width: "100%",
                              textAlign: "center",
                              color: "#1FA2FF",
                              minHeight: "36px",
                            }}
                          >
                            {single?.formButtons[2].button_title}
                          </Box>
                        </Box>
                      </>
                    ) : null}
                    {/* single - lists type */}
                    {single?.formLists?.length ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            mx: -0.5,
                            cursor: "pointer",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "6px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(231, 255, 219, 1)",
                              px: 2,
                              pt: 1,
                              pb: 0.5,
                              mx: 0.5,
                              fontSize: 16,
                              width: "100%",
                              textAlign: "center",
                              color: "#1FA2FF",
                              minHeight: "36px",
                            }}
                            onClick={() => {
                              setListModal({
                                formLists: single?.formLists,
                                formListsButton: single?.formListsButton,
                                formListsOther: single?.formListsOther,
                              });
                              setIsListModalOpen(!isListModalOpen);
                            }}
                          >
                            <Iconify
                              icon="ant-design:unordered-list-outlined"
                              sx={{ mr: 1 }}
                            />
                            {single?.formListsOther?.buttonText ?? "Choose"}
                          </Box>
                        </Box>
                      </>
                    ) : null}
                    {/* single - templates type */}
                    {single?.formTemplates?.map((item, index) => {
                      return (
                        <Box
                          sx={{ mt: 1, display: "flex", mx: -0.5 }}
                          key={index}
                        >
                          {item.type === "call" ? (
                            <Box
                              sx={{
                                borderRadius: "6px",
                                backgroundColor: "rgba(231, 255, 219, 1)",
                                px: 2,
                                pt: 1,
                                pb: 0.5,
                                mx: 0.5,
                                fontSize: 16,
                                width: "100%",
                                color: "#1FA2FF",
                                minHeight: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Iconify
                                icon="fluent:call-24-filled"
                                color="#1FA2FF"
                                sx={{ mr: 0.5 }}
                              />
                              {item.displayText}
                            </Box>
                          ) : item.type === "url" ? (
                            <Box
                              sx={{
                                borderRadius: "6px",
                                backgroundColor: "rgba(231, 255, 219, 1)",
                                px: 2,
                                pt: 1,
                                pb: 0.5,
                                mx: 0.5,
                                fontSize: 16,
                                width: "100%",
                                color: "#1FA2FF",
                                minHeight: "36px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Iconify
                                icon="fluent:share-20-filled"
                                color="#1FA2FF"
                                sx={{ mr: 0.5 }}
                              />
                              {item.displayText}
                            </Box>
                          ) : item.type === "quick-reply" ? (
                            <Box
                              sx={{
                                borderRadius: "6px",
                                display: "block",
                                backgroundColor: "rgba(231, 255, 219, 1)",
                                px: 2,
                                pt: 1,
                                pb: 0.5,
                                mx: 0.5,
                                fontSize: 16,
                                width: "100%",
                                textAlign: "center",
                                color: "#1FA2FF",
                                minHeight: "36px",
                              }}
                            >
                              {item.displayText}
                            </Box>
                          ) : null}
                        </Box>
                      );
                    })}
                  </Box>
                </>
              ) : (
                // multiples
                <>
                  <Box
                    sx={{
                      bottom: 32,
                      right: 12,
                      position: "absolute",
                      width: "calc(100% - 100px)",
                    }}
                  >
                    {multiples?.formMultiples?.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          {/* multiples - audio or voice */}
                          {item?.fileSelected?.file_type === "audio" ? (
                            <Box
                              sx={{
                                borderRadius: "6px",
                                display: display,
                                backgroundColor: "rgba(231, 255, 219, 1)",
                                px: 2,
                                pt: 1,
                                pb: 0.5,
                                fontSize: 16,
                              }}
                            >
                              <Box>
                                <Box sx={{ mb: 1 }}>
                                  <Box
                                    sx={{
                                      py: 1,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Iconify
                                        icon="material-symbols:play-arrow-rounded"
                                        height="28px"
                                        width="48px"
                                        color="#94a3b8"
                                      />
                                      <Box
                                        sx={{
                                          ml: 1,
                                          mr: 2,
                                          height: "4px",
                                          width: "100%",
                                          background: "#94a3b8",
                                        }}
                                      ></Box>
                                    </Box>
                                    {item?.isVoice ? (
                                      <Box sx={{ position: "relative" }}>
                                        <CardMedia
                                          component="img"
                                          image={`https://realm.chat/images/logo-1.svg`}
                                          loading="lazy"
                                          sx={{
                                            height: "32px",
                                            width: "32px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                        />
                                        <Iconify
                                          icon="material-symbols:mic-rounded"
                                          height="16px"
                                          width="16px"
                                          color="#38bdf8"
                                          sx={{
                                            position: "absolute",
                                            bottom: "0",
                                            left: "0",
                                          }}
                                        />
                                      </Box>
                                    ) : (
                                      <Box
                                        sx={{
                                          p: 1,
                                          height: "32px",
                                          width: "32px",
                                          background: "#fbbf24",
                                          borderRadius: "50%",
                                        }}
                                      >
                                        <Iconify
                                          icon="ri:headphone-fill"
                                          height="16px"
                                          width="16px"
                                          color="#FFFFFF"
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                <Box
                                  mb={1}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Box>
                                    <Typography
                                      fontSize="14px"
                                      color="rgba(55, 55, 55, 0.7)"
                                    >
                                      {value}
                                    </Typography>
                                  </Box>
                                  <Box display="flex">
                                    <Typography
                                      fontSize="8px"
                                      mr={1}
                                      color="rgba(55, 55, 55, 0.7)"
                                    >
                                      {time}
                                    </Typography>
                                    <img src="/static/icons/double_check.svg" />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          ) : null}
                          {/* multiple - message type */}
                          <Box
                            sx={{
                              borderRadius: "6px",
                              backgroundColor: "rgba(231, 255, 219, 1)",
                              px: 2,
                              pt: 1,
                              mt: 1,
                              pb: 0.5,
                              fontSize: 16,
                            }}
                          >
                            {item?.formListsOther?.title ? (
                              <Box mb={0.25}>
                                <Typography
                                  fontSize="18px"
                                  color="#3b4a54"
                                  fontWeight={700}
                                >
                                  {item?.formListsOther?.title}
                                </Typography>
                              </Box>
                            ) : null}
                            {item?.fileSelected?.file_type === "image" ? (
                              <Box sx={{ mb: 1 }}>
                                <CardMedia
                                  component="img"
                                  height="140px"
                                  width="100%"
                                  image={`https://new-client.realm.chat/cloud_storage/${item?.fileSelected?.file_url}`}
                                  loading="lazy"
                                  sx={{
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                              </Box>
                            ) : item?.fileSelected?.file_type === "video" ? (
                              <Box sx={{ mb: 1 }}>
                                <CardMedia
                                  component="img"
                                  height="140px"
                                  width="100%"
                                  image={`/cloud/video.png`}
                                  loading="lazy"
                                  sx={{
                                    objectFit: "cover",
                                    borderRadius: "8px",
                                  }}
                                />
                              </Box>
                            ) : item?.fileSelected?.file_type === "document" ? (
                              <Box sx={{ mb: 1 }}>
                                <Box
                                  sx={{
                                    p: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    background: "#cdf0bb",
                                    width: "100%",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Iconify
                                      icon="bxs:file-pdf"
                                      height="32px"
                                      width="32px"
                                      color="#FFFF"
                                    />
                                    <Typography
                                      fontSize="14px"
                                      color="rgba(55, 55, 55, 0.7)"
                                      sx={{ ml: 1 }}
                                    >
                                      {item?.fileSelected?.file_name.length > 16
                                        ? item?.fileSelected?.file_name.substring(
                                          0,
                                          16
                                        ) + "..."
                                        : item?.fileSelected?.file_name.substring(
                                          0,
                                          16
                                        )}
                                    </Typography>
                                  </Box>
                                  <Iconify
                                    icon="el:download"
                                    height="28px"
                                    width="28px"
                                    color="#3b82f6"
                                  />
                                </Box>
                              </Box>
                            ) : null}
                            {item?.reply_type === "reaction" ? (
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  p={0.5}
                                  sx={{
                                    right: "16px",
                                    bottom: "-48px",
                                    position: "absolute",
                                    background: "#fff",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <Emoji
                                    unified={item?.reply}
                                    emojiStyle={EmojiStyle.APPLE}
                                    size={24}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              <p
                                className="chat-message"
                                style={{ whiteSpace: "pre-line" }}
                              >
                                {item?.message
                                  ? item?.message
                                  : item?.reply
                                    ? item?.reply
                                    : null}
                              </p>
                            )}
                            <Box>
                              <Box
                                mb={1}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Box>
                                  <Typography
                                    fontSize="14px"
                                    color="rgba(55, 55, 55, 0.7)"
                                  >
                                    {value}
                                  </Typography>
                                </Box>
                                <Box display="flex">
                                  <Typography
                                    fontSize="8px"
                                    mr={1}
                                    color="rgba(55, 55, 55, 0.7)"
                                  >
                                    {time}
                                  </Typography>
                                  <img src="/static/icons/double_check.svg" />
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          {/* multiple - buttons type */}
                          {multiples?.formMultiplesButtons
                            .filter((e) => e.multipleIndex === item.index)
                            .map((x, i) => {
                              return (
                                <Box
                                  sx={{ mt: 1, display: "flex", mx: -0.5 }}
                                  key={i}
                                >
                                  <Box
                                    sx={{
                                      borderRadius: "6px",
                                      display: "block",
                                      backgroundColor: "rgba(231, 255, 219, 1)",
                                      px: 2,
                                      pt: 1,
                                      pb: 0.5,
                                      mx: 0.5,
                                      fontSize: 16,
                                      width: "100%",
                                      textAlign: "center",
                                      color: "#1FA2FF",
                                      minHeight: "36px",
                                    }}
                                  >
                                    {x.button_title}
                                  </Box>
                                </Box>
                              );
                            })}
                          {/* single - templates type */}
                          {multiples?.formMultiplesTemplates
                            .filter((e) => e.multipleIndex === item.index)
                            .map((x, i) => {
                              return (
                                <Box
                                  sx={{ mt: 1, display: "flex", mx: -0.5 }}
                                  key={i}
                                >
                                  {x.type === "call" ? (
                                    <Box
                                      sx={{
                                        borderRadius: "6px",
                                        backgroundColor:
                                          "rgba(231, 255, 219, 1)",
                                        px: 2,
                                        pt: 1,
                                        pb: 0.5,
                                        mx: 0.5,
                                        fontSize: 16,
                                        width: "100%",
                                        color: "#1FA2FF",
                                        minHeight: "36px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Iconify
                                        icon="fluent:call-24-filled"
                                        color="#1FA2FF"
                                        sx={{ mr: 0.5 }}
                                      />
                                      {x.displayText}
                                    </Box>
                                  ) : x.type === "url" ? (
                                    <Box
                                      sx={{
                                        borderRadius: "6px",
                                        backgroundColor:
                                          "rgba(231, 255, 219, 1)",
                                        px: 2,
                                        pt: 1,
                                        pb: 0.5,
                                        mx: 0.5,
                                        fontSize: 16,
                                        width: "100%",
                                        color: "#1FA2FF",
                                        minHeight: "36px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Iconify
                                        icon="fluent:share-20-filled"
                                        color="#1FA2FF"
                                        sx={{ mr: 0.5 }}
                                      />
                                      {x.displayText}
                                    </Box>
                                  ) : x.type === "quick-reply" ? (
                                    <Box
                                      sx={{
                                        borderRadius: "6px",
                                        display: "block",
                                        backgroundColor:
                                          "rgba(231, 255, 219, 1)",
                                        px: 2,
                                        pt: 1,
                                        pb: 0.5,
                                        mx: 0.5,
                                        fontSize: 16,
                                        width: "100%",
                                        textAlign: "center",
                                        color: "#1FA2FF",
                                        minHeight: "36px",
                                      }}
                                    >
                                      {x.displayText}
                                    </Box>
                                  ) : null}
                                </Box>
                              );
                            })}
                          {/* single - list type */}
                          {multiples?.formMultiplesLists
                            .filter((e) => e.multipleIndex === item.index)
                            .map((x, i) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      mx: -0.5,
                                      cursor: "pointer",
                                    }}
                                    key={i}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: "6px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor:
                                          "rgba(231, 255, 219, 1)",
                                        px: 2,
                                        pt: 1,
                                        pb: 0.5,
                                        mx: 0.5,
                                        fontSize: 16,
                                        width: "100%",
                                        textAlign: "center",
                                        color: "#1FA2FF",
                                        minHeight: "36px",
                                      }}
                                      onClick={() => {
                                        setListModal({
                                          formLists:
                                            multiples?.formMultiplesLists.filter(
                                              (e) =>
                                                e.multipleIndex === item.index
                                            ),
                                          formListsButton:
                                            multiples?.formMultiplesListsButton.filter(
                                              (e) =>
                                                e.multipleListIndex === x.index
                                            ),
                                          formListsOther: {
                                            title: item?.title,
                                            buttonText: item?.buttonText,
                                          },
                                        });
                                        setIsListModalOpen(!isListModalOpen);
                                      }}
                                    >
                                      <Iconify
                                        icon="ant-design:unordered-list-outlined"
                                        sx={{ mr: 1 }}
                                      />
                                      {item?.buttonText ?? "Choose"}
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                        </Fragment>
                      );
                    })}
                  </Box>
                </>
              )}
              {/*  list modal */}
              <Box
                className={isListModalOpen ? "list-show" : null}
                sx={{
                  position: "absolute",
                  bottom: "-1000px",
                  left: 0,
                  zIndex: 100,
                  width: "100%",
                  pb: 4,
                  backgroundColor: "rgba(231, 255, 219, 1)",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "rgba(231, 255, 219, 1)",
                    px: 2,
                    pt: 1,
                    pb: 0.5,
                    mx: 0.5,
                    fontSize: 16,
                    width: "100%",
                    textAlign: "center",
                    color: "#1FA2FF",
                  }}
                >
                  <Box sx={{ width: "12px" }}></Box>
                  <Box>{listModal?.formListsOther?.buttonText ?? "Choose"}</Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setListModal(null);
                      setIsListModalOpen(!isListModalOpen);
                    }}
                  >
                    <Iconify icon="eva:close-outline" sx={{ mt: 1 }} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "rgba(231, 255, 219, 1)",
                    px: 2,
                    pt: 1,
                    pb: 0.5,
                    mx: 0.5,
                    width: "100%",
                    textAlign: "center",
                    color: "#1FA2FF",
                  }}
                >
                  {listModal?.formLists?.map((item, index) => {
                    return (
                      <Box sx={{ pb: 1 }} key={index}>
                        <Box sx={{ pb: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Typography fontSize={14}>{item.list}</Typography>
                          </Box>
                          <Box
                            sx={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                            }}
                          ></Box>
                        </Box>
                        {!multiples?.formMultiples?.length ? (
                          <>
                            {/* single */}
                            {listModal?.formListsButton
                              .filter((e) => e.listIndex === item.index)
                              .map((item, i) => {
                                return (
                                  <Box
                                    sx={{ pb: 1, cursor: "pointer" }}
                                    key={i}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        pb: 1,
                                      }}
                                    >
                                      <Typography
                                        fontSize={18}
                                        sx={{ mb: -0.5 }}
                                      >
                                        {item.title}
                                      </Typography>
                                      <Typography fontSize={14} color="#60b8f7">
                                        {item.description}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.2)",
                                      }}
                                    ></Box>
                                  </Box>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            {/* multiple */}
                            {listModal?.formListsButton
                              .filter((e) => e.multipleListIndex === item.index)
                              .map((item, i) => {
                                return (
                                  <Box
                                    sx={{ pb: 1, cursor: "pointer" }}
                                    key={i}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        pb: 1,
                                      }}
                                    >
                                      <Typography
                                        fontSize={18}
                                        sx={{ mb: -0.5 }}
                                      >
                                        {item.title}
                                      </Typography>
                                      <Typography fontSize={14} color="#60b8f7">
                                        {item.description}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.2)",
                                      }}
                                    ></Box>
                                  </Box>
                                );
                              })}
                          </>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </DeviceFrameset>

        <Box width="100%" textAlign="center" alignItems="center">
          <Typography color="#131313" fontWeight="600">
            Footer Copyright
          </Typography>
          {plan?.is_copyright === "1" ? (
            <TextField
              sx={{ maxWidth: "280px", mt: 1 }}
              inputRef={ref}
              value={value}
              onChange={(v) => setValue(v.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <ButtonTooltip
                      title="Edit"
                      handleClick={() => ref.current.focus()}
                      icon="jam:write"
                      color={palette.primary.main}
                      height={18}
                      width={18}
                    />
                    {/* <IconButton onClick={() => ref.current.focus()}>
                      <Iconify
                        color={palette.primary.main}
                        icon="jam:write"
                        sx={{ height: 18, ml: 1 }}
                      />
                    </IconButton> */}
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: "#F6F7FF",
                  height: "40px",
                  borderRadius: "12px",
                  fontSize: "14px",
                  border: 0,
                },
              }}
              variant="outlined"
            />
          ) : (
            <TextField
              sx={{ maxWidth: "280px", mt: 1 }}
              inputRef={ref}
              value={value}
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: "pointer" }}
                  ></InputAdornment>
                ),
                style: {
                  backgroundColor: "#F6F7FF",
                  height: "40px",
                  borderRadius: "12px",
                  fontSize: "14px",
                  border: 0,
                },
              }}
              variant="outlined"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
