import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getMetaData,
  updateMetaData,
} from "../../../../store/catalog/catalogAction";
import { getDevices } from "../../../../store/device/deviceAction";


const messageTypes = [
  { name: "Facebook Pixel", key: "pixel_id" },
];

const messageTypesTwo = [
  { name: "Google Analytics", key: "gtag_id" },
];


export default function Form({
  onCancel,
  setDevices
}) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const [device, setDevice] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      device: null,
      message: "",
      message2: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        device_key: value.device?.device_key,
        pixel_id: value.message,
        gtag_id: value.message2,
      };

      await dispatch(updateMetaData(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message)
        } else {
          toast.dismiss();
          toast.error(res.payload.message)
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
      handleGetDevices();
    },
  });


  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba,telegram,instagram",
      },
    };
    const result = await dispatch(getDevices(body))
    if (result.type == 'get-devices/fulfilled' && result?.payload?.data?.length > 0) {
      //   setDevices(result.payload.data)
      const filteredDevices = result.payload.data.filter(device => device.meta_data.gtag_id && device.meta_data.gtag_id !== '-' && device.meta_data.pixel_id && device.meta_data.pixel_id !== '-');
      setDevices(filteredDevices)
      setDevice(filteredDevices);
    } else {
      toast.dismiss()
      toast.error(result?.payload?.message ?? 'Device not found')
      setDevice([])
      setDevices([])
    }
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (devices?.length) {
      setDevice(devices[0]);
    }
  }, [devices]);


  return (
    <FormContainer title="Add Analytics">
      <ComboBox
        fullWidth
        disableMargin
        title="Channel"
        placeholder="Please select channel"
        options={devices}
        getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
        value={formik.values.device}
        onChange={(e, value) => {
          formik.setFieldValue("device", value);
        }}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Facebook Pixel"
        placeholder="Facebook Pixel"
        options={messageTypes}
        getOptionLabel={(option) => option?.name}
        value={formik.values.type || messageTypes[0]}
        onChange={(e, value) => {
          formik.setFieldValue("type", value);
        }}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        id="message-input"
        placeholder="XXXXXXXXXXXXX"
        {...formik.getFieldProps("message")}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Google Analytics"
        placeholder="Google Analytics"
        options={messageTypesTwo}
        getOptionLabel={(option) => option?.name}
        value={formik.values.type || messageTypesTwo[0]}
        onChange={(e, value) => {
          formik.setFieldValue("type", value);
        }}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        id="message-input"
        placeholder="GA-XXXXXXX"
        {...formik.getFieldProps("message2")}
      />
      <Box mb={2} />
      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          title="Save"
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
      <Box mb={2} />
    </FormContainer>
  );
}
