import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const createFarming = createAsyncThunk(
  "farming/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("farming/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteFarming = createAsyncThunk(
  "farming/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("farming/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
