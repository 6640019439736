import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const authLogin = createAsyncThunk(
  "user/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("login", data);

      if (response.data.result) {
        const authToken = response.data.data.token;

        localStorage.setItem("auth-token", authToken);

        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authRegister = createAsyncThunk(
  "user/register",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("register", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const authLogout = createAsyncThunk(
  "user/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("logout", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const generateQr = createAsyncThunk(
  "user/register",
  async (_arg, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("generate-qr");

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyRegister = createAsyncThunk(
  "user/verify",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("verify", data);

      if (response.data.result) {
        const authToken = response.data.data.token;

        localStorage.setItem("auth-token", authToken);

        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUser = createAsyncThunk(
  "account/info",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      };

      const response = await clientApi.get("account/info", config);

      if (response.data.result) {
        clientApi.defaults.headers.common["Authorization"] =
          "Bearer " + user.authToken;

        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserQuestions = createAsyncThunk(
  "user/questions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `user/questions/${data.user_id}`,
        data.body
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "user/reset-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("reset-password", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/account/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("account/update", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/change-password",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("change-password", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDashboard = createAsyncThunk(
  "summary-sales",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("summary-sales/?type=monthly", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


export const getIncomeStatistic = createAsyncThunk(
  "summary-sales-weekly",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("summary-sales/?type=weekly", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
