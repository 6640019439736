import { Box, Button, Grid, styled, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import Scrollbar from "../../../../components/Scrollbar";
import ListMessage from "./ListMessage";
import AddNewKeyword from "./add-new-keyword";
import UpdateDeviceModal from "./UpdateDeviceModal";
import CustomModal from "../../../../components/modal/CustomModal";
import Container from "../../../../components/containers/Container";
import { useDispatch, useSelector } from "react-redux";
import palette from "../../../../theme/palette";
import EditNewKeyword from "./edit-new-keyword";
import { getDevices } from "../../../../store/device/deviceAction";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import ComboBox from "../../../../components/select/ComboBox";

export default function AutoReplyInstagram() {
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const { plan } = useSelector((state) => state.user);

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "instagram",
      },
    };
    const result = await dispatch(getDevices(body));
    if (result.type === 'get-devices/fulfilled' && result?.payload?.data?.length > 0) {
      setDevices(result.payload.data);
    } else {
      toast.dismiss();
      toast.error(result?.payload?.message ?? 'Device not found');
      setDevices([]);
    }
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  const [addNewKeyword, setAddNewKeyword] = React.useState(false);
  const [editNewKeyword, setEditNewKeyword] = React.useState(false);

  const [openModal, setOpenModal] = React.useState(false);
  const [modalItems, setModalItems] = React.useState(null);

  const showUpdateDeviceModal = () => {
    setModalItems(<UpdateDeviceModal onCancle={() => setOpenModal(false)} />);
    setOpenModal(!openModal);
  };

  if (plan?.is_autoreply === "1") {
    return (
      <div>
        {addNewKeyword ? (
          <AddNewKeyword
            setAddNewKeyword={() => setAddNewKeyword(!addNewKeyword)}
          />
        ) : editNewKeyword ? (
          <EditNewKeyword
            device={device}
            setEditNewKeyword={() => setEditNewKeyword(!editNewKeyword)}
          />
        ) : (
          <ContainerWithSomeButton
            title="Auto Reply Instagram"
            button1
            button1Title="Add Keyword"
            button1OnClick={() => setAddNewKeyword(true)}
            button2
            button2Title="Update Device"
            button2OnClick={() => showUpdateDeviceModal()}
          >
            <Box sx={{ width: "100%" }}>
              <ComboBox
                fullWidth
                disableMargin
                placeholder="Please select channel"
                options={devices}
                getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
                value={device}
                onChange={(e, value) => {
                  setDevice(value);
                  setPage(1)
                }}
              />
            </Box>
            <Box mb={3} />
            <Scrollbar>
              <ListMessage
                page={page}
                setPage={setPage}
                device={device}
                setEditNewKeyword={() => setEditNewKeyword(!editNewKeyword)}
              />
            </Scrollbar>
          </ContainerWithSomeButton>
        )}
        <CustomModal open={openModal}>{modalItems}</CustomModal>
      </div>
    );
  } else {
    return (
      <Container title="Auto Reply Instagram">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <Box sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

