import { Box, Typography } from "@mui/material"

export default function RenderHeaderText({
    detail
}) {
    let header_text = detail?.header_text
    if (header_text && header_text?.variable && header_text?.text) {
        header_text.text = header_text?.text?.replace('{{1}}', header_text?.variable)
    }
    return (
        <>
            <Box sx={{ mb: 1, px: 2, }}>
                <Typography
                    style={{
                        fontWeight: '700'
                    }}
                >
                    {header_text?.text}
                </Typography>
            </Box>
        </>
    )
}