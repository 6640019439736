import { Link, Stack } from "@mui/material";
import Iconify from "../../../../components/Iconify";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

export function createData(no, name, phoneNumber, action) {
  return { no, name, phoneNumber, action };
}
export const IconList = ({ onEdit, onDelete }) => {
  return (
    <Stack direction="row" spacing={1}>
      <ButtonTooltip
        title="Edit"
        handleClick={onEdit}
        icon="akar-icons:edit"
        color="#0d0d0d"
      />
      <ButtonTooltip
        title="Delete"
        handleClick={onDelete}
        icon="fluent:delete-24-regular"
        color="#F15A28"
      />
    </Stack>
  );
};

// export const tableCell = ["No", "Profile Picture", "Name", "Labels", "Phone Number", "Email", "Company Name", "Job Title", "Lead Stage", "Country", "Subscriber", "Last Channel", "Last Contact From You", "Last Contact From Customer", "Created At", "Updated At", "Action"];
export const tableCell = [
  "No",
  "Name",
  "Labels",
  "Phone Number",
  "Email",
  "Action",
];

export const tableCellMobile = ["Detail", "Action"];

export const IconListResponsive = ({
  onClickAddIcon,
  onClickDeleteIcon,
  onClickExportIcon,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      display={{ xs: "flex", sm: "none", md: "none" }}
    >
      {/* <Iconify
        onClick={onClickTagIcon}
        icon={"akar-icons:tag"}
        color={palette.primary.accent}
        width={24}
        height={24}
      /> */}
      <Link onClick={onClickExportIcon}>
        <Iconify
          icon={"ant-design:export-outlined"}
          color="blue"
          width={24}
          height={24}
        />
      </Link>
      <Link onClick={onClickDeleteIcon}>
        <Iconify
          icon={"fluent:delete-24-regular"}
          color="#F15A28"
          width={24}
          height={24}
        />
      </Link>

      <Link onClick={onClickAddIcon}>
        <Iconify
          icon={"carbon:add-alt"}
          color="#1A776F"
          width={24}
          height={24}
        />
      </Link>
    </Stack>
  );
};

export const addOption = [
  {
    id: 1,
    title: "Add Manual",
  },
  {
    id: 2,
    title: "Import From Excel File",
  },
  {
    id: 3,
    title: "Import From Device",
  },
  {
    id: 4,
    title: "Import From Whatsapp Groups",
  },
];
