import { useCallback, useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { Typography, useMediaQuery } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getCountryCodes } from "../../../../../../../../store/country/countryAction"
import Header from "./header"
import Body from "./body"
import Footer from "./footer"
import Buttons from "./buttons"

export default function Custom({
    contentAddButton,
    setContentAddButton,
    headerValue,
    setHeaderValue,
    textHeader,
    setTextHeader,
    totalVariableHeader,
    setTotalVariableHeader,
    headerMedia,
    setHeaderMedia,
    bodyValue,
    setBodyValue,
    totalVariables,
    setTotalVariables,
    footerValue,
    setFooterValue,
    detail,
    setDetail,
    bodyVariableRegex,
    stringRegex
}) {
    // button
    const [anchorEl, setAnchorEl] = useState(null)
    const [btnOpenMenu, setBtnOpenMenu] = useState(false)

    const { countryCodes } = useSelector((state) => state.country)

    const dispatch = useDispatch()

    const phoneCode = useMemo(() => {
        if (countryCodes?.length === 0) {
            return []
        }
        const createPhoneCode = countryCodes.map(item => ({
            name: `${item?.name} (+${item?.phone_code})`,
            value: `${item?.name} (+${item?.phone_code})`,
            originName: item.name
        }))
        return createPhoneCode
    }, [countryCodes])

    function headerTextComponent() {
        const getVariable = totalVariableHeader[0]?.value
        delete detail?.header_media
        setDetail((prev) => ({
            ...prev,
            header_text: {
                type: 'text',
                text: textHeader?.value,
                variable: getVariable ?? ''
            }
        }))
    }
    function headerMediaComponent() {
        delete detail?.header_text
        setDetail((prev) => ({
            ...prev,
            header_media: headerMedia
        }))
    }
    function samplesBodyComponent() {
        const currentVariable = totalVariables?.map((item) => item?.value)
        setDetail((prev) => ({
            ...prev,
            body: {
                text: bodyValue,
                examples: currentVariable
            }
        }))
    }
    function footerComponent() {
        setDetail((prev) => ({
            ...prev,
            footer: {
                text: footerValue
            }
        }))
    }
    function buttonComponent() {
        if (!detail?.buttons) {
            setDetail((prev) => ({
                ...prev,
                buttons: []
            }))
        }
    }
    function pushButtonDetail(
        contentType,
        data
    ) {
        const currentDetail = { ...detail }
        const buttonsIndex = currentDetail.buttons.findIndex(item => item?.contentType == contentType)
        // example format
        // {
        //     contentType: 'call',
        //     actions: [
        //         {
        //             type: 'web_url',
        //             text: '',
        //             url: '',
        //             icon: 'akar-icons:link-out',
        //             examples: []
        //         }
        //     ]
        // }
        if (buttonsIndex !== -1) {
            detail.buttons[buttonsIndex].actions.push(data)
        } else {
            detail.buttons.push({
                contentType: contentType,
                actions: [data]
            })
        }

        setDetail(currentDetail)
    }
    function updateTxtButtonDetail(
        contentType,
        actionIndex,
        name,
        value
    ) {
        const currentDetail = { ...detail }
        const buttonsIndex = currentDetail.buttons.findIndex(item => item?.contentType == contentType)
        currentDetail.buttons[buttonsIndex].actions[actionIndex] = {
            ...currentDetail.buttons[buttonsIndex].actions[actionIndex],
            [name]: value
        }
        setDetail(currentDetail)
    }
    function updateButtonTypeDetail(
        contentType,
        actionIndex,
        data
    ) {
        const currentDetail = { ...detail }
        const buttonsIndex = currentDetail.buttons.findIndex(item => item?.contentType == contentType)
        currentDetail.buttons[buttonsIndex].actions[actionIndex] = data
        setDetail(currentDetail)
    }
    function removeButtonDetail(
        contentType,
        actionIndex
    ) {
        const currentDetail = { ...detail }
        const buttonsIndex = currentDetail.buttons.findIndex(item => item?.contentType == contentType)
        const isRemoveContent = currentDetail.buttons[buttonsIndex].actions.length < 2 ? true : false
        if (isRemoveContent) {
            const removed = detail.buttons.filter(item => item.contentType !== contentType)
            setDetail((prev) => ({
                ...prev,
                buttons: removed
            }))
            return
        }
        const removeBtn = detail.buttons[buttonsIndex].actions.filter((item, i) => i !== actionIndex)
        detail.buttons[buttonsIndex].actions = removeBtn
        setDetail(currentDetail)
    }

    useEffect(() => {
        if (headerValue?.value == 'text') {
            headerTextComponent()
        } else if (headerValue?.value == 'media') {
            headerMediaComponent()
        } else {
            delete detail?.header_text
            delete detail?.header_media
        }
    }, [
        headerValue,
        textHeader,
        totalVariableHeader,
        headerMedia,
    ])
    useEffect(() => {
        samplesBodyComponent()
    }, [
        totalVariables,
        bodyValue
    ])
    useEffect(() => {
        footerComponent()
    }, [footerValue])
    useEffect(() => {
        if (contentAddButton) {
            buttonComponent()
        }
    }, [contentAddButton])

    async function loadGetCountryCodes() {
        await dispatch(getCountryCodes())
    }

    useEffect(() => {
        loadGetCountryCodes()
    }, [])

    const headers = [
        { name: `There isn't any`, value: 'no', },
        { name: 'Text', value: 'text' },
        { name: 'Media', value: 'media' },
    ]

    function changeHeader(e, v) {
        const findValue = headers.find(item => item.name == e.target.value)
        setHeaderValue({
            name: e.target.value,
            value: findValue?.value
        })
        if (findValue.value == 'text') {
            setTextHeader({
                example: '',
                value: ''
            })
        } else {
            setTextHeader({})
        }
        setTotalVariableHeader([])
    }

    const addVariable = useCallback(() => {
        const currentVariables = totalVariables
        currentVariables.push({
            number: totalVariables.length + 1,
            value: ''
        })
        setTotalVariables(currentVariables)
        setBodyValue((prev) => `${prev}{{${currentVariables.length}}}`)
    }, [totalVariables])

    function validateBodyVariable(e) {
        var inputString = e.target.value

        var replacementCounter = 1;

        var resultString = inputString.replace(bodyVariableRegex, function (match) {
            var replacement = "{{" + replacementCounter + "}}";
            replacementCounter++;
            return replacement;
        });

        return resultString
    }

    const changeBody = useCallback((e) => {
        if (e.target.value.length <= 1024) {
            let newInput = validateBodyVariable(e)
            const getVar = newInput.match(bodyVariableRegex)
            if (getVar) {
                let currentTotalVar = totalVariables
                const currentVariables = getVar.map((item) => {
                    const getNumber = item.split('{{')[1].split('}}')[0]
                    return {
                        number: Number(getNumber),
                        value: ''
                    }
                })
                if (
                    totalVariables.length === 0 &&
                    totalVariables.length !== currentVariables.length
                ) {
                    setTotalVariables(currentVariables)
                } else if (
                    totalVariables.length > 0 &&
                    currentVariables.length > totalVariables.length
                ) {
                    const findLeftItem = currentVariables.filter(item => item.number > totalVariables.length)
                    findLeftItem.forEach((item) => {
                        currentTotalVar.push(item)
                    })
                    setTotalVariables(currentTotalVar)
                } else if (
                    totalVariables.length > 0 &&
                    currentVariables.length < totalVariables.length
                ) {
                    const findLeftItem = currentTotalVar.filter(item => item.number <= currentVariables.length)
                    setTotalVariables(findLeftItem)
                }
            } else {
                setTotalVariables([])
            }

            setBodyValue(newInput)
        } else {
            toast.dismiss()
            toast.error('Cannot exceed 1024 characters')
        }
    }, [
        totalVariables,
        bodyValue
    ])

    function clickB() {
        setBodyValue((prev) => `${prev} *text bold example*`)
    }
    function clickI() {
        setBodyValue((prev) => `${prev} _text italic example_`)
    }

    const changeFooter = useCallback((e) => {
        if (e.target.value.length <= 60) {
            setFooterValue(e.target.value)
        } else {
            toast.dismiss()
            toast.error('Cannot exceed 60 characters')
        }
    }, [])

    function validateHeaderVariable(e) {
        var inputString = e.target.value;

        var regex = /{{(?!1}})[^{}]+}}/g;

        var count = (inputString.match(/{{1}}/g) || []).length;

        if (count > 1) {
            inputString = inputString.replace(regex, function (match, offset) {
                return offset === inputString.indexOf('{{1}}') ? match : '';
            });
        } else {
            inputString = inputString.replace(regex, '');
        }

        return inputString
    }

    const changeTextHeader = useCallback((e) => {
        const found = e.target.value.match(/{{[?!1]+}}/g)
        let newInput = validateHeaderVariable(e)
        const checked = found?.filter((e) => e == '{{1}}')
        const isAnyOtherVariable = e.target.value.match(bodyVariableRegex)
        if (!checked && isAnyOtherVariable) {
            toast.dismiss()
            toast.error('Maximum variable 1')
        } else if (isAnyOtherVariable?.length > 1) {
            toast.dismiss()
            toast.error('Maximum variable 1')
        }
        if (checked?.length > 1) {
            toast.dismiss()
            toast.error('Maximum variable 1')
            return
        }
        setTextHeader({
            ...textHeader,
            value: newInput
        })
        if (newInput.includes('{{1}}') && totalVariableHeader.length === 0) {
            setTotalVariableHeader([
                {
                    number: 1,
                    value: ''
                }
            ])
        } else if (!newInput.includes('{{1}}') && totalVariableHeader.length > 0) {
            setTotalVariableHeader([])
        }
        // else if(){
        //     console.log(true)
        // }
    }, [textHeader])

    function changeHeaderMedia(e) {
        setHeaderMedia({
            type: 'media',
            extension: e.target.value
        })
    }

    function addVariableHeader() {
        if (totalVariableHeader.length === 1) {
            toast.dismiss()
            toast.error('Maximum variable 1')
            return
        }
        setTextHeader((prev) => ({
            ...prev,
            value: `${textHeader?.value}{{1}}`,
        }))
        setTotalVariableHeader([
            {
                number: 1,
                value: ''
            }
        ])
    }

    function changeHeaderContent(e, item) {
        setTotalVariableHeader([
            {
                number: 1,
                value: e.target.value
            }
        ])
    }

    function changeBodyContent(e, item) {
        const currentVariables = [...totalVariables]
        currentVariables[item.number - 1].value = e.target.value
        setTotalVariables(currentVariables)
    }

    // masing2 action punya tipenya sendiri yang di ambil dr value | name
    // masing2 action formatnya sama
    const menuAddButton = [
        {
            type: 'quick-reply',
            name: 'Quick reply button',
            menu: [
                {
                    name: 'Marketing rejection Recommended',
                    value: 'marketing-rejection',
                    type: 'quick-reply'
                },
                {
                    name: 'Custom',
                    value: 'custom',
                    type: 'quick-reply'
                },
            ]
        },
        {
            type: 'call',
            name: 'Invitation Button',
            menu: [
                // {
                //     name: 'Call on WhatsApp',
                //     value: 'whatsapp',
                //     type: 'call'
                // },
                {
                    name: 'Open a website Maximum 2 buttons',
                    value: 'website',
                    type: 'call'
                },
                {
                    name: 'Call the phone number Maximum 1 button',
                    value: 'call',
                    type: 'call'
                },
                // {
                //     name: 'Complete the form Maximum 1 button',
                //     value: 'complete',
                //     type: 'call'
                // },
                {
                    name: 'Copy the promo code Maximum 1 button',
                    value: 'copy',
                    type: 'call'
                }
            ]
        }
    ]

    // quick reply
    const specialMenu = [
        {
            name: 'Custom',
            value: 'custom'
        },
        {
            name: 'Marketing rejection',
            value: 'marketing-rejection'
        },
    ]

    // disable add buttons quick reply
    const quickReply = contentAddButton.find(item => item?.type == 'quick-reply')
    const isRejection = quickReply?.buttons?.find(act => {
        const checkRejection = act?.actions?.find(item => item.selectionType == 'marketing-rejection')
        return checkRejection
    })
    const marketingRejection = isRejection
    const isMaxCustomButtons = quickReply?.buttons?.filter(act => {
        const checkCustom = act?.actions?.find(item => item.selectionType == 'custom')
        return checkCustom
    })

    const changeSelectOfQuckReply = useCallback((e, iBtn, iItem, iContent) => {
        if (e.target.name == 'action-type') {
            changeTypeQuickReply(e, iBtn, iItem, iContent)
        }
    }, [contentAddButton])

    function changeCustomQuickReply(e, iBtn, iItem, iContent, currentContent) {
        if (isMaxCustomButtons?.length === 15) {
            toast.dismiss()
            toast.error('Maximum custom 15')
            return
        }
        const findCustom = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findCustom?.value
        currentContent[iContent].buttons[iBtn].actions[iItem].grid = {
            xs: 12,
            sm: 12,
            md: 12
        }
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'quick-reply',
                type: 'text',
                title: 'Button Text',
                isLimitText: true,
                limitText: 25,
                name: 'button-text',
                selectionType: 'custom',
                width: {
                    xs: '60vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                value: '',
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            }
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'quick-reply',
            iBtn,
            {
                icon: 'ic:round-reply',
                text: '',
                type: 'custom',
            }
        )
    }
    function changeMarketingRejection(e, iBtn, iItem, iContent, currentContent) {
        if (marketingRejection?.actions) {
            toast.dismiss()
            toast.error('Maximum marketing rejection 1')
            return
        }

        const findRejection = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].grid = {
            xs: 12,
            sm: 5,
            md: 4.5
        }
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findRejection?.value
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'quick-reply',
                type: 'text',
                title: 'Button Text',
                name: 'button-text',
                isLimitText: false,
                selectionType: 'marketing-rejection',
                value: 'Stop Promotion',
                isDisable: true,
                width: {
                    xs: '50vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 5,
                    md: 4.5
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            },
            {
                contentType: 'quick-reply',
                type: 'text',
                title: 'Footer Text',
                isLimitText: false,
                isDisable: true,
                selectionType: 'marketing-rejection',
                name: 'footer-text',
                value: 'Not interested? tap stop promotion',
                width: {
                    xs: '50vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 5,
                    md: 4.5
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Footer Text
                </Typography>
            },
            {
                contentType: 'quick-reply',
                type: 'warning',
                desc: 'I understand that komers.io is responsible for stopping sending marketing messages to customers who object.',
                selectionType: 'marketing-rejection',
                warningDesc: 'This box must be checked to add this button.',
                value: false,
                grid: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
            }
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'quick-reply',
            iBtn,
            {
                icon: 'ic:round-reply',
                text: 'Stop Promotion',
                type: 'reject',
            }
        )
    }

    const changeTypeQuickReply = useCallback((e, iBtn, iItem, iContent) => {
        const currentContent = [...contentAddButton]
        if (e.target.value == 'Custom') {
            changeCustomQuickReply(e, iBtn, iItem, iContent, currentContent)
        } else if (e.target.value == 'Marketing rejection') {
            changeMarketingRejection(e, iBtn, iItem, iContent, currentContent)
        }
    }, [
        contentAddButton,
        marketingRejection,
        isMaxCustomButtons,
        detail
    ])

    const changeTxtBtnSpecialSelect = useCallback((e, iBtn, iItem, iContent, item) => {
        if (e.target.value.length <= item?.limitText) {
            const currentContent = [...contentAddButton]
            currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
            setContentAddButton(currentContent)

            // update button detail to show in mockup
            updateTxtButtonDetail(
                item?.contentType,
                iBtn,
                item?.name == 'button-text' ?
                    'text' :
                    null
                ,
                e.target.value
            )
        } else {
            toast.dismiss()
            toast.error(`Cannot exceed ${item?.limitText} characters`)
        }
    }, [
        contentAddButton,
        detail
    ])
    const addBtnQuickReplyForSpecial = () => {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'quick-reply')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'quick-reply',
                        name: 'Quick Reply',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'quick-reply',
                                        type: 'select',
                                        title: 'Type',
                                        value: 'Custom',
                                        name: 'action-type',
                                        menuSelection: specialMenu,
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12
                                        },
                                        selectionType: 'custom',
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'quick-reply',
                                        type: 'text',
                                        title: 'Button Text',
                                        isLimitText: true,
                                        limitText: 25,
                                        name: 'button-text',
                                        selectionType: 'custom',
                                        width: {
                                            xs: '60vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12
                                        },
                                        value: '',
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    }
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'quick-reply')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'quick-reply',
                        type: 'select',
                        title: 'Type',
                        value: 'Custom',
                        name: 'action-type',
                        menuSelection: specialMenu,
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        grid: {
                            xs: 12,
                            sm: 12,
                            md: 12
                        },
                        selectionType: 'custom',
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Type
                        </Typography>
                    },
                    {
                        contentType: 'quick-reply',
                        type: 'text',
                        title: 'Button Text',
                        isLimitText: true,
                        limitText: 25,
                        name: 'button-text',
                        selectionType: 'custom',
                        width: {
                            xs: '60vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 12,
                            md: 12
                        },
                        value: '',
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    }
                ]
            })
            setContentAddButton(getContent)
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'quick-reply',
            {
                type: 'custom',
                text: '',
                icon: 'ic:round-reply'
            }
        )
    }
    function addBtnQuickReplyRejection() {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'quick-reply')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'quick-reply',
                        name: 'Quick Reply',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'quick-reply',
                                        type: 'select',
                                        title: 'Type',
                                        name: 'action-type',
                                        menuSelection: specialMenu,
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 5,
                                            md: 4.5
                                        },
                                        selectionType: 'marketing-rejection',
                                        value: 'Marketing rejection',
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'quick-reply',
                                        type: 'text',
                                        title: 'Button Text',
                                        name: 'button-text',
                                        isLimitText: false,
                                        selectionType: 'marketing-rejection',
                                        value: 'Stop Promotion',
                                        isDisable: true,
                                        width: {
                                            xs: '50vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 5,
                                            md: 4.5
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    },
                                    {
                                        contentType: 'quick-reply',
                                        type: 'text',
                                        title: 'Footer Text',
                                        isLimitText: false,
                                        isDisable: true,
                                        selectionType: 'marketing-rejection',
                                        name: 'footer-text',
                                        value: 'Not interested? tap stop promotion',
                                        width: {
                                            xs: '50vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 5,
                                            md: 4.5
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Footer Text
                                        </Typography>
                                    },
                                    {
                                        contentType: 'quick-reply',
                                        type: 'warning',
                                        desc: 'I understand that komers.io is responsible for stopping sending marketing messages to customers who object.',
                                        selectionType: 'marketing-rejection',
                                        warningDesc: 'This box must be checked to add this button.',
                                        value: false,
                                        grid: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12
                                        },
                                    }
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'quick-reply')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'quick-reply',
                        type: 'select',
                        title: 'Type',
                        name: 'action-type',
                        menuSelection: specialMenu,
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        grid: {
                            xs: 12,
                            sm: 5,
                            md: 4.5
                        },
                        selectionType: 'marketing-rejection',
                        value: 'Marketing rejection',
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Type
                        </Typography>
                    },
                    {
                        contentType: 'quick-reply',
                        type: 'text',
                        title: 'Button Text',
                        name: 'button-text',
                        isLimitText: false,
                        selectionType: 'marketing-rejection',
                        value: 'Stop Promotion',
                        isDisable: true,
                        width: {
                            xs: '50vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 5,
                            md: 4.5
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    },
                    {
                        contentType: 'quick-reply',
                        type: 'text',
                        title: 'Footer Text',
                        isLimitText: false,
                        isDisable: true,
                        selectionType: 'marketing-rejection',
                        name: 'footer-text',
                        value: 'Not interested? tap stop promotion',
                        width: {
                            xs: '50vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 5,
                            md: 4.5
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Footer Text
                        </Typography>
                    },
                    {
                        contentType: 'quick-reply',
                        type: 'warning',
                        desc: 'I understand that komers.io is responsible for stopping sending marketing messages to customers who object.',
                        selectionType: 'marketing-rejection',
                        warningDesc: 'This box must be checked to add this button.',
                        value: false,
                        grid: {
                            xs: 12,
                            sm: 12,
                            md: 12
                        },
                    }
                ]
            })
            setContentAddButton(getContent)
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'quick-reply',
            {
                type: 'reject',
                text: 'Stop Promotion',
                icon: 'ic:round-reply'
            }
        )
    }
    const removeBtnQuickReply = (content, i, iBtn) => {
        const isRemoveContent = content.buttons.length < 2 ? true : false
        if (isRemoveContent) {
            const removed = contentAddButton.filter(item => item.type !== content.type)
            setContentAddButton(removed)
            removeButtonDetail('quick-reply', iBtn)
            return
        }
        const currentContent = [...contentAddButton]
        const findIdx = currentContent.findIndex(item => item.type == content.type)
        const removeBtn = currentContent[findIdx].buttons.filter((item, i) => i !== iBtn)
        currentContent[findIdx].buttons = removeBtn
        setContentAddButton(currentContent)
        removeButtonDetail('quick-reply', iBtn)
    }
    const changeWarningRejection = useCallback((e, iBtn, iItem, iContent) => {
        const currentContent = [...contentAddButton]
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.checked
        setContentAddButton(currentContent)
    }, [contentAddButton])

    // call to action button
    const callButtonMenu = [
        // {
        //     name: 'Call on WhatsApp',
        //     value: 'whatsapp',
        // },
        {
            name: 'Call the phone number',
            value: 'call'
        },
        {
            name: 'Copy the promo code',
            value: 'copy'
        },
        {
            name: 'Open a website',
            value: 'website'
        },
        // {
        //     name: 'Complete the form',
        //     value: 'complete'
        // },
    ]
    const URLTypeMenu = [
        {
            name: 'Static',
            value: 'static'
        },
        {
            name: 'Dynamic',
            value: 'dynamic'
        }
    ]

    // website
    const invitationButtons = contentAddButton.find(item => item?.type == 'call')
    const isMaxWebsiteButtons = invitationButtons?.buttons?.filter(act => {
        const checkWebsiteButtons = act?.actions?.find(item => item.selectionType == 'website')
        return checkWebsiteButtons
    })
    // wa call
    const isMaxWaCall = invitationButtons?.buttons?.filter(act => {
        const checkWaCall = act?.actions?.find(item => item.selectionType == 'whatsapp')
        return checkWaCall
    })
    // phone number
    const isMaxPhoneNumber = invitationButtons?.buttons?.filter(act => {
        const checkPhone = act?.actions?.find(item => item.selectionType == 'call')
        return checkPhone
    })
    // promo code
    const isMaxPromoCode = invitationButtons?.buttons?.filter(act => {
        const checkPromoCode = act?.actions?.find(item => item.selectionType == 'copy')
        return checkPromoCode
    })

    function addBtnCallWebsite() {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'call')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'call',
                        name: 'Invitation Button',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'call',
                                        type: 'select',
                                        title: 'Action Type',
                                        name: 'action-type',
                                        selectionType: 'website',
                                        value: 'Open a website',
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        menuSelection: callButtonMenu,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Action Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Button Text',
                                        isLimitText: true,
                                        limitText: 25,
                                        selectionType: 'website',
                                        name: 'button-text',
                                        width: {
                                            xs: '60vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        value: '',
                                        isDisable: false,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'select',
                                        title: 'URL Type',
                                        selectionType: 'website',
                                        menuSelection: URLTypeMenu,
                                        name: 'url-type',
                                        value: 'Static',
                                        width: {
                                            xs: '60vw',
                                            sm: 120,
                                            md: 120
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            URL Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Website URL',
                                        name: 'website-url',
                                        isLimitText: true,
                                        limitText: 2000,
                                        selectionType: 'website',
                                        placeholder: 'https://www.example.com',
                                        padding: '8.5px 70px 8.5px 14px',
                                        value: '',
                                        width: {
                                            xs: '60vw',
                                            sm: 300,
                                            md: 300
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Website URL
                                        </Typography>
                                    },
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'call')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'call',
                        type: 'select',
                        title: 'Action Type',
                        name: 'action-type',
                        selectionType: 'website',
                        value: 'Open a website',
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        menuSelection: callButtonMenu,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Action Type
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Button Text',
                        isLimitText: true,
                        limitText: 25,
                        selectionType: 'website',
                        name: 'button-text',
                        width: {
                            xs: '60vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        value: '',
                        isDisable: false,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'select',
                        title: 'URL Type',
                        selectionType: 'website',
                        menuSelection: URLTypeMenu,
                        name: 'url-type',
                        value: 'Static',
                        width: {
                            xs: '60vw',
                            sm: 120,
                            md: 120
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            URL Type
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Website URL',
                        name: 'website-url',
                        isLimitText: true,
                        limitText: 2000,
                        selectionType: 'website',
                        placeholder: 'https://www.example.com',
                        padding: '8.5px 70px 8.5px 14px',
                        value: '',
                        width: {
                            xs: '60vw',
                            sm: 300,
                            md: 300
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Website URL
                        </Typography>
                    },
                ]
            })
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'call',
            {
                type: 'web_url',
                text: '',
                url: '',
                icon: 'akar-icons:link-out',
                examples: []
            }
        )
    }
    function addBtnCallWa() {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'call')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'call',
                        name: 'Invitation Button',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'call',
                                        type: 'select',
                                        title: 'Action Type',
                                        selectionType: 'whatsapp',
                                        name: 'action-type',
                                        menuSelection: callButtonMenu,
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        value: 'Call on WhatsApp',
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Action Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Button Text',
                                        isLimitText: true,
                                        limitText: 25,
                                        selectionType: 'whatsapp',
                                        value: '',
                                        name: 'whatsapp',
                                        isDisable: false,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    },
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'call')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'call',
                        type: 'select',
                        title: 'Action Type',
                        selectionType: 'whatsapp',
                        name: 'action-type',
                        menuSelection: callButtonMenu,
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        value: 'Call on WhatsApp',
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Action Type
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Button Text',
                        isLimitText: true,
                        limitText: 25,
                        selectionType: 'whatsapp',
                        name: 'whatsapp',
                        value: '',
                        isDisable: false,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    },
                ]
            })
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'call',
            {
                type: 'whatsapp',
                text: '',
                icon: 'ic:baseline-phone',
            }
        )
    }
    function addBtnCallPhone() {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'call')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'call',
                        name: 'Invitation Button',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'call',
                                        type: 'select',
                                        title: 'Action Type',
                                        name: 'action-type',
                                        selectionType: 'call',
                                        menuSelection: callButtonMenu,
                                        value: 'Call the phone number',
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Action Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Button Text',
                                        isLimitText: true,
                                        name: 'button-text',
                                        limitText: 25,
                                        selectionType: 'call',
                                        width: {
                                            xs: '60vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        value: '',
                                        isDisable: false,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'search-select',
                                        title: 'Country',
                                        selectionType: 'call',
                                        name: 'select-country',
                                        menuSelection: phoneCode,
                                        placeholder: 'Choose Country',
                                        value: {
                                            name: 'East Malaysia (+60)',
                                            value: 'East Malaysia (+60)',
                                            originName: 'East Malaysia'
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        isDisable: false,
                                        width: 220,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Country
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Phone Number',
                                        selectionType: 'call',
                                        value: '',
                                        name: 'phone-number',
                                        isDisable: false,
                                        isLimitText: true,
                                        limitText: 20,
                                        width: {
                                            xs: '60vw',
                                            sm: 220,
                                            md: 220
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Phone Number
                                        </Typography>
                                    },
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'call')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'call',
                        type: 'select',
                        title: 'Action Type',
                        name: 'action-type',
                        selectionType: 'call',
                        menuSelection: callButtonMenu,
                        value: 'Call the phone number',
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Action Type
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Button Text',
                        isLimitText: true,
                        name: 'button-text',
                        limitText: 25,
                        selectionType: 'call',
                        width: {
                            xs: '60vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        value: '',
                        isDisable: false,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'search-select',
                        title: 'Country',
                        selectionType: 'call',
                        name: 'select-country',
                        menuSelection: phoneCode,
                        placeholder: 'Choose Country',
                        value: {
                            name: 'East Malaysia (+60)',
                            value: 'East Malaysia (+60)',
                            originName: 'East Malaysia'
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        isDisable: false,
                        width: 220,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Country
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Phone Number',
                        selectionType: 'call',
                        value: '',
                        name: 'phone-number',
                        isDisable: false,
                        isLimitText: true,
                        limitText: 20,
                        width: {
                            xs: '60vw',
                            sm: 220,
                            md: 220
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Phone Number
                        </Typography>
                    },
                ]
            })
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'call',
            {
                type: 'call',
                text: '',
                icon: 'ic:baseline-phone',
                phone: '',
            }
        )
    }
    function addBtnCallPromoCode() {
        const currentContent = contentAddButton
        const totalBtn = currentContent.filter(item => item.type == 'call')
        if (totalBtn.length === 0) {
            setContentAddButton((prev) => {
                return [
                    ...prev,
                    {
                        type: 'call',
                        name: 'Invitation Button',
                        buttons: [
                            {
                                actions: [
                                    {
                                        contentType: 'call',
                                        type: 'select',
                                        title: 'Action Type',
                                        name: 'action-type',
                                        selectionType: 'copy',
                                        menuSelection: callButtonMenu,
                                        value: 'Copy the promo code',
                                        width: {
                                            xs: '60vw',
                                            sm: 150,
                                            md: 150
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Action Type
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Button Text',
                                        name: 'button-text',
                                        selectionType: 'copy',
                                        width: {
                                            xs: '60vw',
                                            sm: 250,
                                            md: 250
                                        },
                                        grid: {
                                            xs: 12,
                                            sm: 6,
                                            md: 6
                                        },
                                        value: 'Copy the offer code',
                                        isDisable: true,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Button Text
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        selectionType: 'copy',
                                        grid: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12
                                        },
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Add a promo code sample
                                        </Typography>,
                                        description: <Typography
                                            fontSize={14}
                                            fontWeight={400}
                                            mb={0.5}
                                            width="100%"
                                            color="#7B7B7B"
                                        >
                                            To help us review your message template, add an example for your promo code.
                                        </Typography>
                                    },
                                    {
                                        contentType: 'call',
                                        type: 'text',
                                        title: 'Promo Code',
                                        selectionType: 'copy',
                                        value: '',
                                        grid: {
                                            xs: 12,
                                            sm: 12,
                                            md: 12
                                        },
                                        name: 'promo-code',
                                        placeholder: 'Enter sample',
                                        validationType: 'no-space',
                                        isDisable: false,
                                        isLimitText: true,
                                        limitText: 15,
                                        titleElement: <Typography
                                            fontSize={14}
                                            fontWeight={700}
                                            mb={0.5}
                                        >
                                            Promo Code
                                        </Typography>
                                    },
                                ]
                            }
                        ]
                    }
                ]
            })
        } else {
            const getContent = [...contentAddButton]
            const findIdx = contentAddButton.findIndex(content => content.type == 'call')
            getContent[findIdx].buttons.push({
                actions: [
                    {
                        contentType: 'call',
                        type: 'select',
                        title: 'Action Type',
                        name: 'action-type',
                        selectionType: 'copy',
                        menuSelection: callButtonMenu,
                        value: 'Copy the promo code',
                        width: {
                            xs: '60vw',
                            sm: 150,
                            md: 150
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Action Type
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Button Text',
                        name: 'button-text',
                        selectionType: 'copy',
                        width: {
                            xs: '60vw',
                            sm: 250,
                            md: 250
                        },
                        grid: {
                            xs: 12,
                            sm: 6,
                            md: 6
                        },
                        value: 'Copy the offer code',
                        isDisable: true,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Button Text
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        selectionType: 'copy',
                        grid: {
                            xs: 12,
                            sm: 12,
                            md: 12
                        },
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Add a promo code sample
                        </Typography>,
                        description: <Typography
                            fontSize={14}
                            fontWeight={400}
                            mb={0.5}
                            width="100%"
                            color="#7B7B7B"
                        >
                            To help us review your message template, add an example for your promo code.
                        </Typography>
                    },
                    {
                        contentType: 'call',
                        type: 'text',
                        title: 'Promo Code',
                        selectionType: 'copy',
                        value: '',
                        grid: {
                            xs: 12,
                            sm: 12,
                            md: 12
                        },
                        name: 'promo-code',
                        placeholder: 'Enter sample',
                        validationType: 'no-space',
                        isDisable: false,
                        isLimitText: true,
                        limitText: 15,
                        titleElement: <Typography
                            fontSize={14}
                            fontWeight={700}
                            mb={0.5}
                        >
                            Promo Code
                        </Typography>
                    },
                ]
            })
        }

        // update button detail to views in mockup
        pushButtonDetail(
            'call',
            {
                type: 'copy',
                text: 'Copy the offer code',
                icon: 'bi:files',
                example: '',
            }
        )
    }
    // change type call
    function changeCallOnWA(e, iBtn, iItem, iContent, currentContent) {
        if (isMaxWaCall?.length === 1) {
            toast.dismiss()
            toast.error(`Maximum ${e.target.value} 1`)
            return
        }
        const findWa = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findWa?.value
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'call',
                type: 'text',
                title: 'Button Text',
                isLimitText: true,
                limitText: 25,
                selectionType: 'whatsapp',
                name: 'whatsapp',
                value: '',
                isDisable: false,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            },
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'call',
            iBtn,
            {
                icon: 'ic:baseline-phone',
                text: '',
                type: 'whatsapp'
            }
        )
    }
    function changeOpenWebsite(e, iBtn, iItem, iContent, currentContent) {
        if (isMaxWebsiteButtons?.length === 2) {
            toast.dismiss()
            toast.error(`Maximum ${e.target.value} 2`)
            return
        }
        const findWebsite = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findWebsite?.value
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'call',
                type: 'text',
                title: 'Button Text',
                isLimitText: true,
                limitText: 25,
                selectionType: 'website',
                name: 'button-text',
                width: {
                    xs: '60vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                value: '',
                isDisable: false,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            },
            {
                contentType: 'call',
                type: 'select',
                title: 'URL Type',
                selectionType: 'website',
                menuSelection: URLTypeMenu,
                name: 'url-type',
                value: 'Static',
                width: {
                    xs: '60vw',
                    sm: 120,
                    md: 120
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    URL Type
                </Typography>
            },
            {
                contentType: 'call',
                type: 'text',
                title: 'Website URL',
                name: 'website-url',
                isLimitText: true,
                limitText: 2000,
                selectionType: 'website',
                placeholder: 'https://www.example.com',
                padding: '8.5px 70px 8.5px 14px',
                value: '',
                width: {
                    xs: '60vw',
                    sm: 300,
                    md: 300
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Website URL
                </Typography>
            },
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'call',
            iBtn,
            {
                icon: 'akar-icons:link-out',
                text: '',
                url: '',
                type: 'web_url',
                examples: []
            }
        )
    }
    function changeCallPhoneNumber(e, iBtn, iItem, iContent, currentContent) {
        if (isMaxPhoneNumber?.length > 0) {
            toast.dismiss()
            toast.error(`Maximum ${e.target.value} 1`)
            return
        }
        const findPhone = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findPhone?.value
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'call',
                type: 'text',
                title: 'Button Text',
                isLimitText: true,
                name: 'button-text',
                limitText: 25,
                selectionType: 'call',
                width: {
                    xs: '60vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                value: '',
                isDisable: false,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            },
            {
                contentType: 'call',
                type: 'search-select',
                title: 'Country',
                selectionType: 'call',
                name: 'select-country',
                menuSelection: phoneCode,
                placeholder: 'Choose Country',
                value: {
                    name: 'East Malaysia (+60)',
                    value: 'East Malaysia (+60)',
                    originName: 'East Malaysia'
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                isDisable: false,
                width: 220,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Country
                </Typography>
            },
            {
                contentType: 'call',
                type: 'text',
                title: 'Phone Number',
                selectionType: 'call',
                value: '',
                name: 'phone-number',
                isDisable: false,
                isLimitText: true,
                limitText: 20,
                width: {
                    xs: '60vw',
                    sm: 220,
                    md: 220
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Phone Number
                </Typography>
            },
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'call',
            iBtn,
            {
                icon: 'ic:baseline-phone',
                text: '',
                phone: '',
                type: 'call'
            }
        )
    }
    function changePromoCode(e, iBtn, iItem, iContent, currentContent) {
        if (isMaxPromoCode?.length > 0) {
            toast.dismiss()
            toast.error(`Maximum ${e.target.value} 1`)
            return
        }
        const findPromoCode = callButtonMenu.find(item => item.name == e.target.value)
        currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
        currentContent[iContent].buttons[iBtn].actions[iItem].selectionType = findPromoCode?.value
        const removeObj = currentContent[iContent].buttons[iBtn].actions.filter((item, idx) => idx < 1)
        currentContent[iContent].buttons[iBtn].actions = removeObj
        // create new
        currentContent[iContent].buttons[iBtn].actions.push(
            {
                contentType: 'call',
                type: 'text',
                title: 'Button Text',
                name: 'button-text',
                selectionType: 'copy',
                width: {
                    xs: '60vw',
                    sm: 250,
                    md: 250
                },
                grid: {
                    xs: 12,
                    sm: 6,
                    md: 6
                },
                value: 'Copy the offer code',
                isDisable: true,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Button Text
                </Typography>
            },
            {
                contentType: 'call',
                selectionType: 'copy',
                grid: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Add a promo code sample
                </Typography>,
                description: <Typography
                    fontSize={14}
                    fontWeight={400}
                    mb={0.5}
                    width="100%"
                    color="#7B7B7B"
                >
                    To help us review your message template, add an example for your promo code.
                </Typography>
            },
            {
                contentType: 'call',
                type: 'text',
                title: 'Promo Code',
                selectionType: 'copy',
                value: '',
                grid: {
                    xs: 12,
                    sm: 12,
                    md: 12
                },
                name: 'promo-code',
                placeholder: 'Enter sample',
                validationType: 'no-space',
                isDisable: false,
                isLimitText: true,
                limitText: 15,
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Promo Code
                </Typography>
            },
        )
        setContentAddButton(currentContent)
        // update button detail to view in mockup
        updateButtonTypeDetail(
            'call',
            iBtn,
            {
                type: 'copy',
                text: 'Copy the offer code',
                icon: 'bi:files',
                example: '',
            }
        )
    }
    const changeCallSearchSelect = useCallback((v, iBtn, iItem, iContent) => {
        if (v === null) {
            toast.dismiss()
            toast.error('Please select your country code')
            return
        }
        const currentContent = [...contentAddButton]
        currentContent[iContent].buttons[iBtn].actions[iItem].value = v
        setContentAddButton(currentContent)
    }, [contentAddButton])
    const changeSelectOfCall = useCallback((e, iBtn, iItem, iContent, item) => {
        if (e.target.name == 'action-type') {
            changeTypeCall(e, iBtn, iItem, iContent)
        } else if (e.target.name == 'url-type') {
            changeURLType(e, iBtn, iItem, iContent, item)
        }
    }, [
        contentAddButton,
        isMaxWebsiteButtons,
        isMaxWaCall,
        detail
    ])
    const changeTypeCall = useCallback((e, iBtn, iItem, iContent) => {
        const currentContent = [...contentAddButton]
        if (e.target.value == 'Call on WhatsApp') {
            changeCallOnWA(e, iBtn, iItem, iContent, currentContent)
        } else if (e.target.value == 'Open a website') {
            changeOpenWebsite(e, iBtn, iItem, iContent, currentContent)
        } else if (e.target.value == 'Call the phone number') {
            changeCallPhoneNumber(e, iBtn, iItem, iContent, currentContent)
        } else if (e.target.value == 'Copy the promo code') {
            changePromoCode(e, iBtn, iItem, iContent, currentContent)
        }
    }, [
        contentAddButton,
        isMaxWebsiteButtons,
        isMaxWaCall,
        isMaxPhoneNumber,
        isMaxPromoCode,
        phoneCode,
        detail
    ])
    const changeTxtCall = useCallback((e, iBtn, iItem, iContent, item) => {
        if (e.target.value.length <= item?.limitText) {
            const currentContent = [...contentAddButton]
            let newValue = e.target.value
            if (item?.validationType == 'no-space') {
                newValue = newValue.replace(stringRegex, '')
            }
            currentContent[iContent].buttons[iBtn].actions[iItem].value = newValue
            setContentAddButton(currentContent)
            // update button detail to view in mockup
            if (item?.contentType == 'call') {
                updateTxtButtonDetail(
                    item?.contentType,
                    iBtn,
                    item?.name == 'button-text' ?
                        'text' :
                        item?.name == 'website-url' ?
                            'url' :
                            item?.name == 'phone-number' ?
                                'phone' :
                                item?.name == 'whatsapp' ?
                                    'text' :
                                    item?.name == 'promo-code' ?
                                        'example' :
                                        null
                    ,
                    newValue
                )
            }

            // update placeholder selectionType = website
            // if URL Type is a Dynamic
            if (item?.selectionType == 'website' && item?.showVariable) {
                currentContent[iContent].buttons[iBtn].actions[4].placeholder = `Enter the full URL for ${newValue}/`
            }
        } else {
            toast.dismiss()
            toast.error(`Cannot exceed ${item?.limitText} characters`)
        }
    }, [
        contentAddButton,
        detail
    ])
    const changeTxtVariable = useCallback((e, item, iBtn, iItem, iContent, itemForm) => {
        const currentContent = [...contentAddButton]
        let newValue = e.target.value
        if (itemForm?.validationType == 'no-space') {
            newValue = newValue.replace(stringRegex, '')
        }
        currentContent[iContent].buttons[iBtn].actions[iItem].totalVariables = [
            {
                number: item.number,
                value: newValue
            }
        ]
        setContentAddButton(currentContent)
        if (itemForm?.contentType == 'call') {
            updateTxtButtonDetail(
                itemForm?.contentType,
                iBtn,
                itemForm?.name == 'variable' ?
                    'examples' :
                    null
                ,
                [newValue]
            )
        }
    }, [
        contentAddButton,
        detail
    ])
    const changeURLType = useCallback((e, iBtn, iItem, iContent, item) => {
        const currentContent = [...contentAddButton]
        if (e.target.value == 'Dynamic') {
            currentContent[iContent].buttons[iBtn].actions[3].title = 'Website URL'
            currentContent[iContent].buttons[iBtn].actions[3].showVariable = '{{1}}'
            currentContent[iContent].buttons[iBtn].actions[3].titleElement = <Typography
                fontSize={14}
                fontWeight={700}
                mb={0.5}
            >
                Website URL
            </Typography>
            currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
            currentContent[iContent].buttons[iBtn].actions.push({
                contentType: 'call',
                type: 'variable',
                title: 'Add a sample URL',
                selectionType: 'website',
                name: 'variable',
                value: '',
                validationType: 'no-space',
                placeholder: `Enter the full URL for ${currentContent[iContent].buttons[iBtn].actions[3].value}/`,
                desc: `to help us review your message template, please add an example website URL. don't use real, customer information.`,
                totalVariables: [
                    {
                        number: 1,
                        value: ''
                    }
                ],
                titleElement: <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Add a sample URL
                </Typography>
            })
            setContentAddButton(currentContent)
        } else {
            currentContent[iContent].buttons[iBtn].actions[iItem].value = e.target.value
            const isDynamic = currentContent[iContent].buttons[iBtn].actions.length === 5
            if (isDynamic) {
                currentContent[iContent].buttons[iBtn].actions[3].title = 'Website URL'
                delete currentContent[iContent].buttons[iBtn].actions[3]?.showVariable
                currentContent[iContent].buttons[iBtn].actions[3].titleElement = <Typography
                    fontSize={14}
                    fontWeight={700}
                    mb={0.5}
                >
                    Website URL
                </Typography>
                const removeVariable = currentContent[iContent].buttons[iBtn].actions.filter((item, i) => i < 4)
                currentContent[iContent].buttons[iBtn].actions = removeVariable
                setContentAddButton(currentContent)
            }
        }
        // update detail to views in mockup
        if (item?.contentType == 'call') {
            updateTxtButtonDetail(
                item?.contentType,
                iBtn,
                item?.name == 'url-type' ?
                    'examples' :
                    null
                ,
                []
            )
        }
    }, [
        contentAddButton,
        detail
    ])
    const removeBtnInvitation = (content, i, iBtn) => {
        const isRemoveContent = content.buttons.length < 2 ? true : false
        if (isRemoveContent) {
            const removed = contentAddButton.filter(item => item.type !== content.type)
            setContentAddButton(removed)
            removeButtonDetail('call', iBtn)
            return
        }
        const currentContent = [...contentAddButton]
        const findIdx = currentContent.findIndex(item => item.type == content.type)
        const removeBtn = currentContent[findIdx].buttons.filter((item, i) => i !== iBtn)
        currentContent[findIdx].buttons = removeBtn
        setContentAddButton(currentContent)
        // update button detail to show in mockup
        removeButtonDetail('call', iBtn)
    }

    // contoh format atribute form input / selct
    // name = name attribute input / select
    // value = value attribute input / select
    // noted : untuk search select valuenya {{name, value, originName}}
    const clickMenuAddButton = useCallback(async (item) => {
        setBtnOpenMenu(false)
        setAnchorEl(null)

        if (item?.type == 'quick-reply' && item?.value == 'custom') {
            addBtnQuickReplyForSpecial()
        } else if (item?.type == 'quick-reply' && item?.value == 'marketing-rejection') {
            addBtnQuickReplyRejection()
        } else if (item?.type == 'call' && item?.value == 'website') {
            addBtnCallWebsite()
        } else if (item?.type == 'call' && item?.value == 'whatsapp') {
            addBtnCallWa()
        } else if (item?.type == 'call' && item?.value == 'call') {
            addBtnCallPhone()
        } else if (item?.type == 'call' && item?.value == 'copy') {
            addBtnCallPromoCode()
        }
    }, [
        contentAddButton,
        phoneCode,
        detail
    ])

    // this example format add buttons. (please don't remove for remember it) 
    // const contentAddButton = [
    //     {
    //         type: 'quick-reply',
    //         name: 'Quick reply button',
    //         buttons: [
    //             {
    //                 actions: [
    //                     {
    //                         isElement: false,
    //                         type: 'select',
    //                         title: 'Type',
    //                         value: 'special',
    //                         element: <>
    //                             <Selector
    //                                 menu={specialMenu}
    //                                 value={typeOfSpecialSelect.value}
    //                                 width={150}
    //                                 onChange={(e)=>changeTypeQuickReply(e)}
    //                             />
    //                         </>,
    //                         selectMenu: [
    //                             {
    //                                 name: 'Special',
    //                                 value: 'special'
    //                             },
    //                             {
    //                                 name: 'Marketing rejection',
    //                                 value: 'marketing-rejection'
    //                             },
    //                         ]
    //                     },
    //                     {
    //                         isElement: true,
    //                         type: 'text',
    //                         title: 'Button Text',
    //                         isLimitText: true,
    //                         value: '',
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ]

    function clickDelete(content, i, iBtn) {
        if (content.type == 'quick-reply') {
            removeBtnQuickReply(content, i, iBtn)
        } else if (content.type == 'call') {
            removeBtnInvitation(content, i, iBtn)
        }
    }

    const miniMobile = useMediaQuery('(max-width:360px)')
    const mobileMedia = useMediaQuery('(max-width:420px)')

    return (
        <>
            {/* header */}
            <Header
                headers={headers}
                headerValue={headerValue}
                changeHeader={changeHeader}
                changeTextHeader={changeTextHeader}
                textHeader={textHeader}
                addVariableHeader={addVariableHeader}
                totalVariableHeader={totalVariableHeader}
                changeHeaderContent={changeHeaderContent}
                headerMedia={headerMedia}
                changeHeaderMedia={changeHeaderMedia}
            />

            {/* body */}
            <Body
                bodyValue={bodyValue}
                changeBody={changeBody}
                clickB={clickB}
                clickI={clickI}
                addVariable={addVariable}
                totalVariables={totalVariables}
                changeBodyContent={changeBodyContent}
                miniMobile={miniMobile}
            />

            {/* footer */}
            <Footer
                footerValue={footerValue}
                changeFooter={changeFooter}
            />

            {/* buttons */}
            <Buttons
                setAnchorEl={setAnchorEl}
                setBtnOpenMenu={setBtnOpenMenu}
                btnOpenMenu={btnOpenMenu}
                anchorEl={anchorEl}
                menuAddButton={menuAddButton}
                clickMenuAddButton={clickMenuAddButton}
                marketingRejection={marketingRejection}
                isMaxWebsiteButtons={isMaxWebsiteButtons}
                isMaxPhoneNumber={isMaxPhoneNumber}
                isMaxCustomButtons={isMaxCustomButtons}
                isMaxPromoCode={isMaxPromoCode}
                contentAddButton={contentAddButton}
                clickDelete={clickDelete}
                changeSelectOfQuckReply={changeSelectOfQuckReply}
                changeTxtBtnSpecialSelect={changeTxtBtnSpecialSelect}
                changeWarningRejection={changeWarningRejection}
                changeSelectOfCall={changeSelectOfCall}
                changeTxtCall={changeTxtCall}
                changeCallSearchSelect={changeCallSearchSelect}
                changeTxtVariable={changeTxtVariable}
            />
        </>
    )
}