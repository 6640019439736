import { Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  getPaymentGateways,
  updatePaymentGatewayStatus,
} from "../../../../store/catalog/catalogAction";
import BoxImage from "./BoxImage";

export default function List({ item }) {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(item.status);

  const handleUpdatePaymentGatewayStatus = async ({ status }) => {
    toast.loading("Please wait...");

    const data = {
      name: item.name,
      status: status,
    };

    await dispatch(updatePaymentGatewayStatus(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        handleGetPaymentGateways();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  const handleGetPaymentGateways = async () => {
    await dispatch(getPaymentGateways());
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      boxShadow="0px 2px 20px rgba(0, 0, 0, 0.05)"
    >
      <Box display="flex" alignItems="center">
        <BoxImage src={item.logo} alt={item.title} />
        <Typography color={"#131313"} fontWeight="600">
          {item.title}
        </Typography>
      </Box>
      <Box>
        <Box display="flex" alignItems="center">
          <Switch
            checked={checked}
            onChange={() => {
              setChecked(!checked);
              handleUpdatePaymentGatewayStatus({ status: !checked });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
