import { useEffect, useState } from "react";
import Connect from "../Connect";
import NoAccount from "../NoAccount";
import { useDispatch, useSelector } from "react-redux";
import DeviceChannel from "../device-channel/DeviceChannel";
import { Box, Button, Typography } from "@mui/material";
import CustomModal from "../../../../components/modal/CustomModal";
import Iconify from "../../../../components/Iconify";
import QRCode from "react-qr-code";
import palette from "../../../../theme/palette";
import { getQrDevice } from "../../../../store/device/deviceAction";
import toast from "react-hot-toast";
import socket from "../../../../services/socket";
import { useNavigate } from "react-router-dom";
import { updateStatusDevice } from "../../../../store/device/deviceSlice";

function QRModal({
    qrModal,
    setQrModal,
    qrKey
}) {
    return (
        <CustomModal open={qrModal} handleClose={() => setQrModal(!qrModal)}>
            <Box
                sx={{
                    // ml: { xs: "0", md: "-120px" },
                    ml: { xs: "0", md: "auto" },
                    p: { xs: 3, md: 5 },
                    // width: { xs: "100%", md: "720px" },
                    width: { xs: "100%", md: "auto" },
                    background: "#FFF",
                    borderRadius: 2,
                }}
            >
                <Box
                    mb={2}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <Typography fontSize={24} fontWeight={600} color="#131313">
                        Connect WhatsApp to Komers
                    </Typography>
                    <Button
                        variant="text"
                        sx={{ p: 0, minWidth: "36px", cursor: "pointer" }}
                        onClick={() => setQrModal(!qrModal)}
                    >
                        <Iconify icon={"bi:x"} color="#7B7B7B" width={36} height={36} />
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ mb: { xs: 3, md: 0 } }}>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color="#131313"
                            sx={{ mb: "4px" }}
                        >
                            1. Open WhatsApp on your phone.
                        </Typography>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color="#131313"
                            sx={{ mb: "4px" }}
                        >
                            2. Tap Menu{" "}
                            <Iconify
                                icon={"iwwa:option"}
                                color="#131313"
                                width={16}
                                height={16}
                                sx={{ mb: "-3px", ml: "2px", mr: "2px" }}
                            />
                            or settings{" "}
                            <Iconify
                                icon={"ant-design:setting-outlined"}
                                color="#131313"
                                width={16}
                                height={16}
                                sx={{ mb: "-3px", ml: "6px", mr: "6px" }}
                            />
                            and select Linked devices.
                        </Typography>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color="#131313"
                            sx={{ mb: "4px" }}
                        >
                            3. Tap Link Device.
                        </Typography>
                        <Typography
                            fontSize={16}
                            fontWeight={400}
                            color="#131313"
                            sx={{ mb: 1 }}
                        >
                            4. Point your phone at this screen to scan the code.
                        </Typography>
                        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                            <Box
                                component="img"
                                src="/images/wa-icon.png"
                                alt="logo"
                                height="40px"
                                sx={{ mr: 2 }}
                            />
                            <Box
                                component="img"
                                src="/images/wab-icon.png"
                                alt="logo"
                                height="40px"
                                sx={{ mr: 2 }}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            sx={{
                                mb: "2px",
                                mx: "auto",
                                height: "auto",
                                maxWidth: "160px",
                                width: "100%",
                            }}
                        >
                            {qrKey !== "loading" ? (
                                <>
                                    <QRCode
                                        size={256}
                                        style={{
                                            height: "auto",
                                            maxWidth: "100%",
                                            width: "100%",
                                        }}
                                        value={qrKey}
                                        viewBox={`0 0 256 256`}
                                    />
                                </>
                            ) : (
                                <Box
                                    component="img"
                                    src="/images/loading.svg"
                                    alt="loading"
                                    height="48px"
                                    sx={{ mx: "auto" }}
                                />
                            )}
                        </Box>
                        <Typography
                            fontSize={16}
                            fontWeight={600}
                            sx={{ color: palette.primary.main, textAlign: "center" }}
                        >
                            Log in to Whatsapp by QR Code
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </CustomModal>
    )
}

export default function WhatsAppWeb({
    loading
}) {
    const { devices } = useSelector((state) => state.device)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [qrModal, setQrModal] = useState(false)
    const [qrKey, setQrKey] = useState("loading")
    const [deviceKey, setDeviceKey] = useState(null)

    async function handleGetQrDevice(device_key) {
        setQrKey('loading')
        setDeviceKey(device_key)

        const data = {
            device_key: device_key,
        }

        const result = await dispatch(getQrDevice(data))
        if (result.type == 'device/get-qr/fulfilled') {
            setQrKey(result.payload.qr)
        } else {
            toast.error(result.payload.message)
        }
    }

    useEffect(() => {
        if (!qrModal) {
            setQrKey("loading");
            setDeviceKey(null)
        }
    }, [qrModal])

    useEffect(() => {
        socket.on("qr-update", (res) => {
            if (res.id === deviceKey) {
                setQrKey(res.qr);
            }
        });
    }, [])

    function clickConnect() {
        navigate('/dashboard/channels/connect-whatsapp-web')
    }

    // useEffect(() => {
    //     socket.on("connection-update", (res) => {
    //         if (res.id === deviceKey && res.status === "Connected") {
    //             setQrModal(false);
    //             setQrKey("loading");
    //             setDeviceKey(null);
    //         }
    //         dispatch(
    //             updateStatusDevice({
    //                 accountid: res.id,
    //                 status: res.status,
    //             })
    //         );
    //     });
    // }, [])

    return (
        <>
            {/* modal qrcode */}
            <QRModal
                qrModal={qrModal}
                setQrModal={setQrModal}
                qrKey={qrKey}
            />

            <Connect
                icon="whatsapp.png"
                title="WhatsApp Web"
                textBtn="Connect"
                clickConnect={clickConnect}
            />

            {!loading && devices?.length > 0 ?
                <Box
                    p={2}
                >
                    <Typography
                        fontSize={{ xs: 16, sm: 16, md: 16 }}
                        fontWeight={600}
                        color={"#131313"}
                        mt={3}
                        mb={2}
                    >
                        Accounts
                    </Typography>

                    {devices.map((item, i) => {
                        return (
                            <DeviceChannel
                                key={i}
                                item={item}
                                icon="whatsapp.png"
                                platform="whatsapp"
                                handleGetQrDevice={handleGetQrDevice}
                                setQrModal={() => setQrModal(!qrModal)}
                                setDeviceKey={setDeviceKey}
                                setQrKey={setQrKey}
                                deviceKey={deviceKey}
                            />
                        )
                    })}
                </Box> :
                <>
                    {!loading ?
                        <NoAccount
                            desc={`Click "Connect" to sync your first WhatsApp Web account`}
                        /> :
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 400
                            }}
                        >
                            <Box
                                component="img"
                                width={40}
                                height={40}
                                src="/images/loading.svg"
                            />
                        </Box>
                    }
                </>
            }
        </>
    )
}