import { Box, Typography, Link } from "@mui/material";
import React from "react";
import palette from "../theme/palette";

export default function EmptyBox({ title1, title2, link, onClick, leaf }) {
  return (
    <Box
      width="100%"
      p={5}
      alignItems="center"
      display="grid"
      justifyContent="center"
      textAlign="center"
    >
      {leaf ? (
        <Box mb={2} ml={4} component="img" src="/images/leaf.png" alt="leaf" />
      ) : (
        <Box mb={2} component="img" src="/images/empty_box.png" alt="empty" />
      )}
      <Typography color={palette.secondary.dark}>{title1}</Typography>
      <Typography color={palette.secondary.dark}>{title2}</Typography>
      <Link
        onClick={onClick}
        underline="none"
        sx={{ cursor: "pointer" }}
        color={"#3CC9B7"}
      >
        {link}
      </Link>
    </Box>
  );
}
