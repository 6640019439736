import { Box, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import Iconify from "../../../../../../../../../components/Iconify";

export default function HeaderMedia({
    headerMedia,
    changeHeaderMedia
}) {
    return (
        <FormControl
            fullWidth
            sx={{
                marginTop: 2,
                marginLeft: 2
            }}
        >
            <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={headerMedia?.extension}
                name="radio-buttons-group"
                onChange={changeHeaderMedia}
            >
                <Grid container spacing={1.5}>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormControlLabel
                            sx={{
                                height: 'auto',
                                width: '100%',
                                flexDirection: 'column',
                                border: '2px solid #bbb',
                                borderRadius: 1.5,
                                padding: '8px 0'
                            }}
                            value="image"
                            control={
                                <Radio
                                    color="info"
                                    size="small"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 20
                                            },
                                        },
                                    }}
                                />
                            }
                            label={
                                <Box>
                                    <Iconify
                                        icon="bi:image"
                                        sx={{
                                            fontSize: {
                                                xs: 70,
                                                sm: 70,
                                                md: 70
                                            },
                                            color: '#bbb'
                                        }}
                                    />
                                    <Typography
                                        fontSize={{
                                            xs: 14,
                                            sm: 16,
                                            md: 16
                                        }}
                                        fontWeight={600}
                                        textAlign="center"
                                    >
                                        Image
                                    </Typography>
                                </Box>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <FormControlLabel
                            sx={{
                                height: 'auto',
                                width: '100%',
                                flexDirection: 'column',
                                border: '2px solid #bbb',
                                borderRadius: 1.5,
                                padding: '8px 0'
                            }}
                            value="video"
                            control={
                                <Radio
                                    color="info"
                                    size="small"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 20
                                            },
                                        },
                                    }}
                                />
                            }
                            label={
                                <Box>
                                    <Iconify
                                        icon="lets-icons:video-fill"
                                        sx={{
                                            fontSize: {
                                                xs: 70,
                                                sm: 70,
                                                md: 70
                                            },
                                            color: '#bbb'
                                        }}
                                    />
                                    <Typography
                                        fontSize={{
                                            xs: 14,
                                            sm: 16,
                                            md: 16
                                        }}
                                        fontWeight={600}
                                        textAlign="center"
                                    >
                                        Video
                                    </Typography>
                                </Box>
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <FormControlLabel
                            sx={{
                                height: 'auto',
                                width: '100%',
                                flexDirection: 'column',
                                border: '2px solid #bbb',
                                borderRadius: 1.5,
                                padding: '8px 0'
                            }}
                            value="document"
                            control={
                                <Radio
                                    color="info"
                                    size="small"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 20
                                            },
                                        },
                                    }}
                                />
                            }
                            label={
                                <Box>
                                    <Iconify
                                        icon="solar:document-text-bold"
                                        sx={{
                                            fontSize: {
                                                xs: 70,
                                                sm: 70,
                                                md: 70
                                            },
                                            color: '#bbb'
                                        }}
                                    />
                                    <Typography
                                        fontSize={{
                                            xs: 14,
                                            sm: 16,
                                            md: 16
                                        }}
                                        fontWeight={600}
                                        textAlign="center"
                                    >
                                        Document
                                    </Typography>
                                </Box>
                            }
                        />
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}