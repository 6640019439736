import { MenuItem, Stack, Switch, Typography } from "@mui/material";
import ButtonMenu from "../../../../../components/button/ButtonMenu";

export default function MenuInstagramCatalogSetting({
    status,
    navigate,
    item,
    handleUpdateCatalogSettingInstagram,
    handleDeleteDevice
}) {
    return <Stack direction="row">
        <ButtonMenu
            sx={{
                background: 'transparent'
            }}
        >
            {/* <MenuItem
                sx={{
                    fontSize: 14,
                }}
                onClick={() => navigate('/dashboard/app/device-update/' + item?.device_key)}
            >
                Update Profile
            </MenuItem> */}

                <MenuItem
                    sx={{
                        fontSize: 14,
                        color: '#F15A28'
                    }}
                    onClick={handleDeleteDevice}
                >
                    Delete Account
                </MenuItem>
            
        </ButtonMenu>
    </Stack>
}