import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getDistricts,
  getRegions,
  getStates,
} from "../../../../store/country/countryAction";
import {
  resetDistricts,
  resetStates,
} from "../../../../store/country/countrySlice";
import { getPlans, subscribe } from "../../../../store/plan/planAction";
import Unsubscribe from "./Unsubscribe";

const items = [
  {
    label: "1 Month",
    value: 1,
  },
  {
    label: "3 Month",
    value: 3,
  },
  {
    label: "6 Month",
    value: 6,
  },
  {
    label: "12 Month",
    value: 12,
  },
];

const planFeatures = [
  {
    label: "Total Device",
    value: "total_device",
  },
  {
    label: "Total Customer Service",
    value: "total_customer_service",
  },
  {
    label: "Send Message",
    value: "is_sendmessage",
  },
  {
    label: "WhatsApp Broadcast",
    value: "is_bulkmessage",
  },
  {
    label: "WhatsApp Schedule",
    value: "is_schedulemessage",
  },
  {
    label: "Auto Reply",
    value: "is_autoreply",
  },
  {
    label: "Api Access",
    value: "is_apiaccess",
  },
  {
    label: "Webhook",
    value: "is_webhook",
  },
  {
    label: "Contact Scrapping",
    value: "is_contactscraping",
  },
  {
    label: "Copyright",
    value: "is_copyright",
  },
];

export default function BuyPlan() {
  const dispatch = useDispatch();

  const { plan } = useSelector((state) => state.user);
  const { plans } = useSelector((state) => state.plan);
  const { regions, states, districts } = useSelector((state) => state.country);

  const [planDurationSelected, setPlanDurationSelected] = useState(items[0]);
  const [planSelected, setPlanSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: plans?.billing_address?.name ?? "",
      address: plans?.billing_address?.address ?? "",
      city: null,
      country: null,
      state: null,
      zip: plans?.billing_address?.zip ?? "",
    },
    onSubmit: async (value) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        plan_id: planSelected?.id,
        duration: planDurationSelected?.value,
        name: value.name,
        email: value.email,
        phone: value.phone,
        address: value.address,
        district: value.city?.name,
        state: value.state?.name,
        country: value?.country?.name,
        zip: value.zip,
      };

      await dispatch(subscribe(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          window.location.href = res.payload.data.redirect;
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  const handleGetPlans = async () => {
    await dispatch(getPlans());
  };

  const handleGetDistricts = async (data) => {
    await dispatch(getDistricts(data));
  };

  const handleGetStates = async (data) => {
    await dispatch(getStates(data));
  };

  const handleGetRegions = async () => {
    await dispatch(getRegions());
  };

  useEffect(() => {
    handleGetPlans();
    handleGetRegions();
  }, []);

  useEffect(() => {
    if (plans?.billing_address) {
      formik.setFieldValue("name", plans?.billing_address?.name ?? "");
      formik.setFieldValue("address", plans?.billing_address?.address ?? "");
      formik.setFieldValue("zip", plans?.billing_address?.zip ?? "");
      formik.setFieldValue("email", plans?.billing_address?.email ?? "");
      formik.setFieldValue("phone", plans?.billing_address?.phone ?? "");
    }
  }, [plans?.billing_address]);

  useEffect(() => {
    if (plans?.billing_address && regions) {
      const country = regions?.find(
        (item) => item.name === plans?.billing_address?.country
      );
      formik.setFieldValue("country", country);

      handleGetStates({ region: country?.name });
    }
  }, [plans?.billing_address, regions]);

  useEffect(() => {
    if (plans?.billing_address && regions && states) {
      const state = states?.find(
        (item) => item.name === plans?.billing_address?.state
      );

      if (state) {
        formik.setFieldValue("state", state);

        handleGetDistricts({ state: state?.name });
      }
    }
  }, [plans?.billing_address, regions, states]);

  useEffect(() => {
    if (plans?.billing_address && regions && states && districts) {
      const district = districts?.find(
        (item) => item.name === plans?.billing_address?.city
      );

      if (district) {
        formik.setFieldValue("city", district);
      }
    }
  }, [plans?.billing_address, regions, states, districts]);

  if (plan?.name === "Startup" || plan?.name === "Trial") {
    return (
      <Card
        style={{
          backgroundColor: "#FDFDFD",
          padding: "16px",
          borderRadius: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={1}
          pl={2}
          pr={1}
          alignItems="center"
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Box>
            <Typography
              fontSize={{ xs: 20, sm: 24 }}
              fontWeight="700"
              color="#131313"
              sx={{ mb: { xs: 2, md: 0 } }}
            >
              List Plan Available
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ background: "#F6F7FF", borderRadius: "32px" }}
          >
            {items.map((v, i) => {
              return (
                <Button
                  variant={
                    planDurationSelected.label === v.label
                      ? "contained"
                      : "text"
                  }
                  sx={
                    planDurationSelected?.label === v.label
                      ? {
                          background:
                            "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                          borderRadius: "24px",
                          color: "#fdfdfd",
                        }
                      : { color: "#7b7b7b" }
                  }
                  key={i}
                  onClick={() => setPlanDurationSelected(v)}
                >
                  <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                    {" "}
                    {v.label}
                  </Typography>
                </Button>
              );
            })}
          </Stack>
        </Box>

        <Typography
            fontSize={20}
            fontWeight="700"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Total Amount:{" "}
            <span style={{ margin: "0 4px", color: "#49C759" }}>
              {planSelected?.currency ?? "RM"}
              {planSelected
                ? parseFloat(
                    planSelected?.price_int * (planDurationSelected?.value ?? 1)
                  ).toFixed(2)
                : "0.00"}
            </span>
          </Typography>
        <Box sx={{ maxWidth: "960px", my: 4, mx: "auto" }}>
          <Grid container spacing={2} justifyContent={"center"}>
            {plans?.plans?.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => setPlanSelected(item)}>
                      <Box
                        sx={{
                          border: `4px solid ${
                            planSelected?.id === item?.id
                              ? "#93DF93"
                              : "#CBC8C8"
                          }`,
                          borderRadius: "16px",
                        }}
                      >
                        <Box
                          sx={{
                            px: 2,
                            py: 1,
                            background:
                              "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                            borderRadius: "10px 10px 0 0",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              items: "center",
                            }}
                          >
                            <Typography variant="h5" color="#FFFFFF">
                              {item?.name}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ px: 2, py: 1 }}>
                          <Typography
                            variant="h4"
                            color="#FFFFFF"
                            sx={{
                              my: 2,
                              color: "#49C759",
                              textAlign: "center",
                            }}
                          >
                            {item?.price}
                            <span style={{ margin: "0 4px", fontSize: "24px" }}>
                              /month
                            </span>
                          </Typography>
                          {planFeatures?.map((planFeature, index) => {
                            return (
                              <Box
                                sx={{
                                  mb: 0.5,
                                  display: "flex",
                                  alignItems: "center",
                                  opacity: `${
                                    item[planFeature.value] ? 1 : 0.5
                                  }`,
                                }}
                                key={index}
                              >
                                <Box sx={{ mr: 1 }}>
                                  <Iconify
                                    icon="material-symbols:check"
                                    color="#49C759"
                                    height={24}
                                    width={24}
                                  />
                                </Box>
                                <Typography
                                  color="#7B7B7B"
                                  fontWeight={600}
                                  sx={{ mt: -1 }}
                                >
                                  {planFeature?.value === "total_device"
                                    ? item[planFeature.value]
                                    : ""}
                                  {planFeature?.value ===
                                  "total_customer_service"
                                    ? item[planFeature.value]
                                    : ""}{" "}
                                  {planFeature?.label}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {/* <Typography
            fontSize={20}
            fontWeight="700"
            sx={{ mt: 2, textAlign: "center" }}
          >
            Total Amount:{" "}
            <span style={{ margin: "0 4px", color: "#49C759" }}>
              {planSelected?.currency ?? "RM"}
              {planSelected
                ? parseFloat(
                    planSelected?.price_int * (planDurationSelected?.value ?? 1)
                  ).toFixed(2)
                : "0.00"}
            </span>
          </Typography> */}
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography color="#7B7B7B">Information: </Typography>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Iconify
                  icon="mdi:dot"
                  width={24}
                  height={24}
                  color="#7B7B7B"
                  sx={{ mb: -1 }}
                />
              </Box>
              <Typography color="#7B7B7B">
                If you buy another plan while you current plan is still active,
                Your Current Plan Becomes Inactive.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <Iconify
                  icon="mdi:dot"
                  width={24}
                  height={24}
                  color="#7B7B7B"
                  sx={{ mb: -1 }}
                />
              </Box>
              <Typography color="#7B7B7B">
                If you purchase the same plan while your current plan is still
                active. Duration Will be Extend.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography fontSize={20} fontWeight="700" sx={{ mb: 2 }}>
            Billing Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BasicInput
                disableMargin
                fullWidth
                title="Name"
                placeholder=""
                {...formik.getFieldProps("name")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BasicInput
                disableMargin
                fullWidth
                title="Email"
                placeholder=""
                {...formik.getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BasicInput
                disableMargin
                fullWidth
                title="Phone"
                placeholder=""
                {...formik.getFieldProps("phone")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BasicInput
                disableMargin
                fullWidth
                title="Address"
                placeholder=""
                {...formik.getFieldProps("address")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ComboBox
                fullWidth
                disableMargin
                title="Country"
                placeholder="Please select country"
                options={regions ?? []}
                getOptionLabel={(option) => option?.name}
                value={formik.values.country}
                onChange={(e, value) => {
                  formik.setFieldValue("country", value);

                  dispatch(resetStates());

                  handleGetStates({ region: value.name });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ComboBox
                fullWidth
                disableMargin
                title="State"
                placeholder="Please select state"
                options={states ?? []}
                getOptionLabel={(option) => option?.name}
                value={formik.values.state}
                onChange={(e, value) => {
                  formik.setFieldValue("state", value);

                  dispatch(resetDistricts());

                  handleGetDistricts({ state: value.name });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ComboBox
                fullWidth
                disableMargin
                title="City"
                placeholder="Please select city"
                options={districts ?? []}
                getOptionLabel={(option) => option?.name}
                value={formik.values.city}
                onChange={(e, value) => {
                  formik.setFieldValue("city", value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BasicInput
                disableMargin
                fullWidth
                title="Zip"
                placeholder=""
                {...formik.getFieldProps("zip")}
              />
            </Grid>
          </Grid>
          <Box mt={5} mb={2}>
            <BasicButton
              fullWidth
              type="submit"
              disabled={isLoading}
              onClick={() => formik.handleSubmit()}
            >
              <Typography fontSize={{ xs: 16, sm: 19 }}>Submit</Typography>
            </BasicButton>
          </Box>
        </Box>
      </Card>
    );
  } else {
    return <Unsubscribe />;
  }
}
