import merge from "lodash/merge";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import BaseOptionChart from "../../../components/chart/BaseOptionChart";
import Container from "../../../components/containers/Container";

AppDealByStage.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppDealByStage({
  title,
  subheader,
  chartLabels,
  chartData,
  ...other
}) {
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: "16%" } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Container title="Deal by Stage by Time" {...other}>
      <ReactApexChart
        type="line"
        series={chartData}
        options={chartOptions}
        height={364}
      />
    </Container>
  );
}
