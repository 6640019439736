import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

export default function DeviceGroup({
    children,
    name
}) {
    return (
        <Accordion
            sx={{
                background: '#EEF0FA',
                borderRadius: 1,
                padding: '1rem 0 1rem 0',
                marginBottom: 1,
                "::before":{
                    display: 'none'
                }
            }}
        >
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panelGroup-content"
                id="panelGroup-header"
            >
                <Typography
                    fontWeight={600}
                    fontSize={{ xs: 14, sm: 16, md: 16 }}
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '1',
                        WebkitBoxOrient: 'vertical',
                    }}
                >
                    {name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: '0 2rem 0 2rem'
                }}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}