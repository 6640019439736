import { Box, Menu, MenuItem, Stack, Typography } from "@mui/material"
import WarningContent from "../../../../../../../../../components/WarningContent"
import TemplateSection from "../../../../../TemplateSection"
import ButtonWithIcon from "../../../../../../../../../components/button/ButtonWithIcon"
import Iconify from "../../../../../../../../../components/Iconify"
import palette from "../../../../../../../../../theme/palette"
import MainContentAddButton from "./MainContentAddButton"

export default function Buttons({
    setAnchorEl,
    setBtnOpenMenu,
    btnOpenMenu,
    anchorEl,
    menuAddButton,
    clickMenuAddButton,
    marketingRejection,
    isMaxWebsiteButtons,
    isMaxPhoneNumber,
    isMaxCustomButtons,
    isMaxPromoCode,
    contentAddButton,
    clickDelete,
    changeSelectOfQuckReply,
    changeTxtBtnSpecialSelect,
    changeWarningRejection,
    changeSelectOfCall,
    changeTxtCall,
    changeCallSearchSelect,
    changeTxtVariable
}) {
    return (
        <TemplateSection
            title="Buttons"
            desc="Create buttons that let customers respond to your message or take action."
            isOptional={true}
        >
            <WarningContent />
            <Box
                sx={{ width: 'fit-content' }}
            >
                <Stack>
                    <ButtonWithIcon
                        title="Add button"
                        icon={'ic:baseline-arrow-drop-down'}
                        onClick={(e) => {
                            setAnchorEl(e.currentTarget)
                            setBtnOpenMenu(!btnOpenMenu)
                        }}
                    />
                    <div>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={btnOpenMenu}
                            onClose={() => {
                                setBtnOpenMenu(false)
                                setAnchorEl(null)
                            }}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                            sx={{
                                maxWidth: {
                                    xs: 350,
                                    sm: 350,
                                    md: '100%'
                                },
                            }}
                        >
                            {menuAddButton.map((item, index) => {
                                return (
                                    <Box
                                        key={index}
                                        sx={{
                                            borderBottom: '1.5px solid #ddd'
                                        }}
                                        px={2}
                                        my={1}
                                    >
                                        <Typography
                                            fontWeight={600}
                                            mb={1}
                                        >
                                            {item.name}
                                        </Typography>
                                        {item.menu.map((menu, i) => (
                                            <MenuItem
                                                key={i}
                                                onClick={() => clickMenuAddButton(menu)}
                                                sx={{
                                                    whiteSpace: 'wrap'
                                                }}
                                                disabled={
                                                    menu.type == 'quick-reply' &&
                                                        menu.value == 'marketing-rejection' &&
                                                        marketingRejection?.actions ? true :
                                                        menu.type == 'call' &&
                                                            menu.value == 'website' &&
                                                            isMaxWebsiteButtons?.length > 1 ? true :
                                                            menu.type == 'call' &&
                                                                menu.value == 'whatsapp' ? true :
                                                                menu.type == 'call' &&
                                                                    menu.value == 'call' &&
                                                                    isMaxPhoneNumber?.length > 0 ? true :
                                                                    menu.value == 'complete' ? true :
                                                                        menu.type == 'quick-reply' &&
                                                                            menu.value == 'custom' &&
                                                                            isMaxCustomButtons?.length === 15 ?
                                                                            true :
                                                                            menu.type == 'call' &&
                                                                                menu.value == 'copy' &&
                                                                                isMaxPromoCode?.length > 0 ?
                                                                                true :
                                                                                false
                                                }
                                            >
                                                {menu.name}
                                            </MenuItem>
                                        ))}
                                    </Box>
                                );
                            })}
                        </Menu>
                    </div>
                </Stack>
            </Box>
            <Box
                mt={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Iconify
                    icon="lets-icons:lamp-light"
                    sx={{
                        marginRight: 1,
                        color: palette.secondary.dark,
                        fontSize: 22
                    }}
                />
                <Typography
                    fontSize={14}
                    color={palette.secondary.dark}
                >
                    If you add more than 3 buttons, they will be displayed in the list.
                </Typography>
            </Box>

            {/* content add buttons */}
            <MainContentAddButton
                contentAddButton={contentAddButton}
                clickDelete={clickDelete}
                changeSelectOfQuckReply={changeSelectOfQuckReply}
                changeTxtBtnSpecialSelect={changeTxtBtnSpecialSelect}
                changeWarningRejection={changeWarningRejection}
                changeSelectOfCall={changeSelectOfCall}
                changeTxtCall={changeTxtCall}
                changeCallSearchSelect={changeCallSearchSelect}
                changeTxtVariable={changeTxtVariable}
            />
        </TemplateSection>
    )
}