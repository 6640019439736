import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    redirectPath: ''
}

const channelSlice = createSlice({
    name: 'channel',
    initialState,
    reducers: {
        setRedirectPath: (state, {payload})=>{
            state.redirectPath = payload
        },
        resetRedirectPath: (state)=>{
            state.redirectPath = ''
        }
    }
})

export const {
    setRedirectPath,
    resetRedirectPath
} = channelSlice.actions

export default channelSlice.reducer