import { Box, Card, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import ButtonWithIcon from "../button/ButtonWithIcon";

export default function TransparentContainer({
  children,
  title,
  width,
  withButton,
  buttonTitle,
  onClick,
  buttonIcon,
}) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        alignItems: "center",
        minHeight: "500px",
        width: width,
        backgroundColor: "transparent",
        elevation: 0,
        boxShadow: "none",
        border: "none",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          fontSize={{ xs: 20, sm: 24 }}
          fontWeight="700"
          color="#131313"
        >
          {title}
        </Typography>
        {withButton && (
          <ButtonWithIcon
            title={buttonTitle}
            icon={buttonIcon}
            onClick={onClick}
          />
        )}
      </Box>
      <Box mb={3} />
      {children}
    </Card>
  );
}
