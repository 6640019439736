import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Iconify from "../../components/Iconify";
import BasicButton from "../../components/button/BasicButton";
import BasicInput from "../../components/input/BasicInput";
import InputPassword from "../../components/input/InputPassword";
import CustomModal from "../../components/modal/CustomModal";
import ComboBox from "../../components/select/ComboBox";
import SelectFormatPhoneNumber from "../../components/select/SelectFormatPhoneNumber";
import { getBusiness } from "../../store/business/businessAction";
import { getCountryCodes } from "../../store/country/countryAction";
import { authRegister, verifyRegister } from "../../store/user/userAction";
import palette from "../../theme/palette";

const TERM_OF_SERVICE = "term-of-service";
const PRIVACY_POLICY = "privacy-policy";
const DATA_PROCESSING_ADENDUM = "data-processing-adendum";

export default function SignUpSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const referral = queryParams.get("reff");

  const { countryCodes } = useSelector((state) => state.country);
  const { business } = useSelector((state) => state.business);

  const [isLoading, setIsLoading] = useState(false);

  const [phoneCode, setPhoneCode] = useState(null);
  const [isAgree, setIsAgree] = useState(false);

  const [isOpenQrModal, setIsOpenQrModal] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [currentPopup, setCurrentPopup] = useState("");
  const [qrKey, setQrKey] = useState(null);

  const [searchCountryCode, setSearchCountryCode] = useState("");
  const [resultCountryCodes, setResultCountryCodes] = useState([]);

  const handleGetBusiness = async () => {
    await dispatch(getBusiness());
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      referral: localStorage.getItem("referral") ?? null,
      business: "",
    },
    onSubmit: async (value) => {
      setIsLoading(true);
      toast.loading("Please wait...");

      const body = {
        phone: phoneCode?.phone_code + value?.phone,
        name: value?.name,
        username: value?.username,
        email: value?.email,
        password: value?.password,
        password_confirmation: value?.password_confirmation,
        referral: referral ?? null,
        business: value?.business,
      };

      await dispatch(authRegister({ data: body })).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const isMobile = window.innerWidth <= 768;

          if (isMobile) {
            toast.dismiss();
            toast.success(res.payload.message);

            window.location.href = res.payload?.data?.redirect;
          } else {
            toast.dismiss();
            toast.success(res.payload.message);
            setQrKey(res.payload?.data);
            setIsOpenQrModal(true);
          }
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  const togglePopup = () => setIsOpenPopup((prev) => !prev);

  const handlePopup = (val) => {
    togglePopup();
    setCurrentPopup(val);
  };

  const handleGetCountryCodes = async () => {
    await dispatch(getCountryCodes());
  };

  const handleSearch = (e) => {
    setSearchCountryCode(e.target.value);
  };

  const findCountryOfCode = () => {
    const currentCountry = countryCodes.filter((country) => {
      return (
        country.name?.toLowerCase().includes(searchCountryCode.toLowerCase()) ||
        country.phone_code?.includes(searchCountryCode)
      );
    });
    setResultCountryCodes(currentCountry);
  };

  const resetCountry = () => {
    setSearchCountryCode("");
  };

  useEffect(() => {
    if (searchCountryCode.length > 0) {
      findCountryOfCode();
    } else {
      setResultCountryCodes(countryCodes);
    }
  }, [searchCountryCode]);

  const handleVerifyRegister = async () => {
    const body = {
      token: qrKey?.token,
      type: "register",
    };

    await dispatch(verifyRegister({ data: body })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        navigate("/");
      }
    });
  };

  useEffect(() => {
    handleGetBusiness();
    handleGetCountryCodes();
  }, []);

  useEffect(() => {
    if (countryCodes) {
      setPhoneCode(countryCodes[0]);
      setResultCountryCodes(countryCodes);
    }
  }, [countryCodes]);

  useEffect(() => {
    if (referral) {
      localStorage.setItem("referral", referral);
    }
  }, [referral]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (qrKey) {
        handleVerifyRegister();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [qrKey]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: "16px", md: "24px" },
          width: "100%",
          maxWidth: "480px",
          background: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ margin: "0 0 8px 0" }}>
              <Box
                component="img"
                src="/images/Andromeda_Letter_A_Logo.svg"
                sx={{ height: "48px", width: "150px" }}
              />
            </Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "700", color: "#131313", textAlign: "center" }}
            >
              Start your 3-day free trial
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: "16px",
                fontWeight: "400",
                color: "#7B7B7B",
                textAlign: "center",
              }}
            >
              Unlock Your Business Potential - Sign Up on LUCKY GROUP Today!
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <BasicInput
                  type="text"
                  title="Name"
                  disableMargin
                  {...formik.getFieldProps("name")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <BasicInput
                  type="text"
                  title="Username"
                  disableMargin
                  value={formik.values?.username}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "username",
                      e.target.value?.replace(/\s/g, "")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <BasicInput
                  type="text"
                  title="Email"
                  disableMargin
                  {...formik.getFieldProps("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: "100%" }}>
                  <Box sx={{ mr: 2 }}>
                    <SelectFormatPhoneNumber
                      handleSearch={handleSearch}
                      countries={resultCountryCodes}
                      value={phoneCode}
                      resetCountry={resetCountry}
                      searchValue={searchCountryCode}
                      onClick={(e) => setPhoneCode(e)}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <BasicInput
                      type="number"
                      title="WhatsApp Number"
                      disableMargin
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +{phoneCode?.phone_code}
                          </InputAdornment>
                        ),
                      }}
                      {...formik.getFieldProps("phone")}
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputPassword
                  disableMargin
                  {...formik.getFieldProps("password")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputPassword
                  confirmPassword
                  disableMargin
                  {...formik.getFieldProps("password_confirmation")}
                />
              </Grid>
              <Grid item xs={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  title="Business"
                  options={business ?? []}
                  getOptionLabel={(option) => option?.name}
                  onChange={(e, value) => {
                    formik.setFieldValue("business", value?.id);
                  }}
                />
              </Grid>
              {(referral || formik.values?.referral) && (
                <Grid item xs={12}>
                  <BasicInput
                    type="text"
                    title="Referral"
                    disabled
                    value={referral ?? formik.values?.referral}
                  />
                </Grid>
              )}
            </Grid>

            <Box sx={{ my: "4px" }} />
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Checkbox
                type="checkbox"
                onChange={() => {
                  setIsAgree(!isAgree);
                }}
              />
              <Typography
                variant="body2"
                sx={{ fontWeight: "400", color: "#7B7B7B" }}
              >
                I agree to the{" "}
                <PopupLink variant="body2" sx={{ color: "#121212" }}>
                  terms of service
                </PopupLink>
                , {/* onClick={() => handlePopup(TERM_OF_SERVICE)}  */}
                <PopupLink variant="body2" sx={{ color: "#121212" }}>
                  privacy policy
                </PopupLink>{" "}
                and {/* onClick={() => handlePopup(PRIVACY_POLICY)} */}
                <PopupLink variant="body2" sx={{ color: "#121212" }}>
                  data processing addendum
                </PopupLink>
                . {/* onClick={() => handlePopup(DATA_PROCESSING_ADENDUM)} */}
              </Typography>
            </Box>
            <Box sx={{ my: "4px" }} />
            <BasicButton
              type="submit"
              disabled={isLoading || !isAgree}
              fullWidth
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "#FFFFFF" }}
              >
                Sign up
              </Typography>
            </BasicButton>
            <Box sx={{ my: "32px" }} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "400", color: "#131313", textAlign: "center" }}
            >
              Already have an account?{" "}
              <Link
                to="/auth/signin"
                style={{
                  fontWeight: "700",
                  color: "#121212",
                  textDecoration: "none",
                }}
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>

      <CustomModal open={isOpenQrModal}>
        <Box
          sx={{
            // ml: { xs: "0", md: "-120px" },
            ml: { xs: "0", md: "auto" },
            p: { xs: 3, md: 5 },
            // width: { xs: "100%", md: "720px" },
            width: { xs: "100%", md: "auto" },
            background: "#FFF",
            borderRadius: 2,
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography fontSize={24} fontWeight={600} color="#131313">
              Sign Up to Komers
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                1. Open Camera on your phone.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                2. Scan the QR Code.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                3. Open the link in your browser.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                4. Open your Whatsapp and send the message.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              ></Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: "2px",
                  mx: "auto",
                  height: "auto",
                  maxWidth: "160px",
                  width: "100%",
                }}
              >
                {qrKey ? (
                  <>
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrKey?.redirect}
                      viewBox={`0 0 256 256`}
                    />
                  </>
                ) : (
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                )}
              </Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ color: palette.primary.main, textAlign: "center" }}
              >
                Signup with Qr Code
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal open={isOpenPopup} handleClose={togglePopup}>
        {currentPopup === TERM_OF_SERVICE && <TermsOfService />}
        {currentPopup === PRIVACY_POLICY && <PrivacyPolicy />}
        {currentPopup === DATA_PROCESSING_ADENDUM && <DataProcessingAdendum />}
      </CustomModal>
    </>
  );
}

const TermsOfService = () => {
  return (
    <PopupContainer>
      <h1>Terms Of Service</h1>
      <p>Test</p>
      <br />
    </PopupContainer>
  );
};
const PrivacyPolicy = () => {
  return (
    <PopupContainer>
      <h1>Privacy Policy</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nam
        consectetur architecto adipisci necessitatibus minus quae optio quas
        ipsum ipsam, quaerat nisi beatae ea enim? Harum ab itaque vero obcaecati
        atque cum necessitatibus sequi. Asperiores quaerat deserunt minima
        aperiam fugiat autem, commodi, veritatis, dolorem officiis eum atque
        fugit. Maxime obcaecati dignissimos officia inventore cumque ratione rem
        corrupti, eos non reprehenderit mollitia at vitae corporis soluta sed
        perspiciatis odit assumenda tempora aut atque quas. Ut, maxime earum?
        Voluptatum magnam temporibus aliquid delectus provident facilis, ullam
        exercitationem pariatur non. Delectus, quasi voluptatibus nihil saepe
        excepturi voluptatem obcaecati reiciendis voluptatum autem velit!
        Molestiae, repellat.
      </p>
    </PopupContainer>
  );
};
const DataProcessingAdendum = () => {
  return (
    <PopupContainer>
      <h1>Data Processing Adendum</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nam
        consectetur architecto adipisci necessitatibus minus quae optio quas
        ipsum ipsam, quaerat nisi beatae ea enim? Harum ab itaque vero obcaecati
        atque cum necessitatibus sequi. Asperiores quaerat deserunt minima
        aperiam fugiat autem, commodi, veritatis, dolorem officiis eum atque
        fugit. Maxime obcaecati dignissimos officia inventore cumque ratione rem
        corrupti, eos non reprehenderit mollitia at vitae corporis soluta sed
        perspiciatis odit assumenda tempora aut atque quas. Ut, maxime earum?
        Voluptatum magnam temporibus aliquid delectus provident facilis, ullam
        exercitationem pariatur non. Delectus, quasi voluptatibus nihil saepe
        excepturi voluptatem obcaecati reiciendis voluptatum autem velit!
        Molestiae, repellat.
      </p>
    </PopupContainer>
  );
};
const PopupLink = styled(Typography)({
  display: "inline",
  color: "#49C759",
  cursor: "pointer",
});
const PopupContainer = styled(Box)({
  backgroundColor: "white",
  padding: "1rem",
  borderRadius: "1rem",
});
