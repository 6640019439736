import { Box, Card, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import ButtonWithIcon from "../button/ButtonWithIcon";

export default function CardContainer({
  children,
  title,
  withButton,
  buttonTitle,
  buttonDisabled,
  buttonIcon,
  onClick,
  width,
  minHeight,
}) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: 2.5,
        alignItems: "center",
        minHeight: minHeight,
        width: width,
        backgroundColor: palette.secondary.main,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        {title && (
          <div>
            <Typography variant="h6" fontWeight="700" color="#131313">
              {title}
            </Typography>
          </div>
        )}
        {withButton && (
          <ButtonWithIcon
            title={buttonTitle}
            icon={buttonIcon}
            onClick={onClick}
            disabled={buttonDisabled}
          />
        )}
      </Box>

      {children}
    </Card>
  );
}
