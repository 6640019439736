import { Box, Typography } from "@mui/material";
import palette from "../../theme/palette";
import BasicInput from "./BasicInput";

export default function InputLimitText({
    onChange,
    value,
    length,
    limit,
    disabled,
    width = {
        xs: '50vw',
        sm: 300,
        md: 500
    },
    placeholder,
    disableMargin,
    marginRight = '35px',
    background =  '#fff',
    padding = '8.5px 50px 8.5px 14px',
    ...props
}) {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                width: width,
            }}
        >
            <BasicInput
                fullWidth
                sx={{
                    background: background,
                }}
                inputProps={{
                    style:{
                        padding: padding
                    },
                    maxLength: limit
                }}
                onChange={onChange}
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                disableMargin={disableMargin}
                // style={{
                //     marginRight: '3px'
                // }}
                {...props}
            />
            {limit &&
                <Typography
                    fontSize={12}
                    fontWeight="400"
                    color={palette.secondary.dark}
                    position="absolute"
                    right={10}
                >
                    {length}/{limit}
                </Typography>
            }
        </Box>
    )
}