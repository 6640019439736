import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import BulkEmail from "../sections/dashboard/reports/bulk-email";
import BulkMessage from "../sections/dashboard/reports/bulk-message";
import BulkWaba from "../sections/dashboard/reports/bulk-waba";
import ScheduleMessage from "../sections/dashboard/reports/schedule-message";
import { setIsDetail } from "../store/report/reportSlice";
import PlatformOptions from "../components/PlatformOptions";

const items = [
  {
    name: "WhatsApp Schedule",
    component: <ScheduleMessage />,
  },
  {
    name: "WhatsApp Broadcast",
    component: <BulkMessage />,
  },
];

const wabaItems = [
  {
    name: "Cloud API Broadcast",
    component: <BulkWaba />,
  },
];

const emailItems = [
  {
    name: "Email Broadcast",
    component: <BulkEmail />,
  },
];

const platformOptions = [
  {
    label: "WhatsApp",
    value: "whatsapp",
    image: "whatsapp.png",
  },
  {
    label: "Cloud API",
    value: "waba",
    image: "cloud-api.png",
  },
  // {
  //   label: "Email",
  //   value: "email",
  //   image: "email.png",
  // },
  // {
  //   label: "Telegram",
  //   value: "telegram",
  //   image: "telegram.png",
  // },
];

export default function Report() {
  const dispatch = useDispatch();

  const [reportPages, setReportPages] = useState({
    name: "WhatsApp Schedule",
    component: <ScheduleMessage />,
  });

  const [platformSelected, setPlatformSelected] = useState(platformOptions[0]);

  useEffect(() => {
    if (platformSelected?.value === "whatsapp") {
      setReportPages(items[0]);
    } else if (platformSelected?.value === "waba") {
      setReportPages(wabaItems[0]);
    } else if (platformSelected?.value === "email") {
      setReportPages(emailItems[0]);
    } else {
      setReportPages({
        name: "",
        component: <></>,
      });
    }
  }, [platformSelected]);

  return (
    <Page title="Report">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Report" />
        <PlatformOptions
          platformOptions={platformOptions}
          platformSelected={platformSelected}
          setPlatformSelected={setPlatformSelected}
        />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <Stack direction="row" spacing={1}>
              {(platformSelected?.value === "whatsapp"
                ? items
                : platformSelected?.value === "waba"
                ? wabaItems
                : platformSelected?.value === "email"
                ? emailItems
                : []
              ).map((v, i) => {
                return (
                  <Button
                    variant={reportPages.name === v.name ? "contained" : "text"}
                    key={i}
                    onClick={() => {
                      setReportPages(v);
                      dispatch(setIsDetail(false));
                    }}
                    sx={
                      reportPages?.name === v.name
                        ? {
                            background: "GRADIENTS.custom",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                        : { color: "#7b7b7b" }
                    }
                  >
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                      {" "}
                      {v.name}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
          </Box>
          <Box mt={3}>{reportPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
