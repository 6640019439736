import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import EmojiPicker, { Emoji, EmojiStyle } from "emoji-picker-react";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import { debounce } from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import formatWppMarkdown from "whatsapp-markdown";
import Iconify from "../../../../../components/Iconify";
import BasicButton from "../../../../../components/button/BasicButton";
import { DeviceFrame } from "../../../../../components/containers/DeviceFrame";
import SaveCancelContainer from "../../../../../components/containers/SaveCancelContainer";
import BasicInput from "../../../../../components/input/BasicInput";
import CustomModal from "../../../../../components/modal/CustomModal";
import ComboBox from "../../../../../components/select/ComboBox";
import {
  getDevices,
  getIsBusiness,
} from "../../../../../store/device/deviceAction";
import { updateAutoReply2 } from "../../../../../store/marketing/marketingAction";
import { getUserQuestions } from "../../../../../store/user/userAction";
import palette from "../../../../../theme/palette";
import Upload from "./Upload";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { storagePathName } from "../../../../../utils/storage/storagePathName";
import { getCloudFile } from "../../../../../store/cloudFile/cloudFileAction";

const patternData = [
  { name: "Message Contain", key: 0 },
  { name: "Exact Match", key: 1 },
];

const messageTypeData = [
  { name: "Text", key: "text" },
  // { name: "Media", key: "media" },
  // { name: "Text With Button", key: "button" },
  // { name: "Custom Templates", key: "template" },
  // { name: "Lists", key: "lists" },
  // { name: "Reaction", key: "reaction" },
  { name: "Subsequent", key: "multiple" },
];

export default function NewKeyword(props) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { user, userQuestions } = useSelector((state) => state.user);

  const { autoReply } = useSelector((state) => state.marketing);

  const [typeMessage, setTypeMessage] = useState(null);

  const [isOpenUploadModal, setIsOdpenUploadModal] = useState(false);

  const [isBusiness, setIsBusiness] = useState(false);
  const [isLoadingBusiness, setIsLoadingBusiness] = useState(false);

  const [formButtons, setFormButtons] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formLists, setFormLists] = useState([]);
  const [formListsButton, setFormListsButton] = useState([]);
  const [formListsTitle, setFormListsTitle] = useState(null);
  const [formListsButtonText, setFormListsButtonText] = useState(null);

  const [formMultiples, setFormMultiples] = useState([]);
  const [formMultiplesButtons, setFormMultiplesButtons] = useState([]);
  const [formMultiplesTemplates, setFormMultiplesTemplates] = useState([]);
  const [formMultiplesLists, setFormMultiplesLists] = useState([]);
  const [formMultiplesListsButton, setFormMultiplesListsButton] = useState([]);

  const [patternMatching, setPatternMatching] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);

  const spaceRegex = /\s/g

  // button type
  let handleChangeFormButtons = (index, name, value) => {
    let newFormValues = [...formButtons];
    newFormValues[index][name] = value;
    setFormButtons(newFormValues);
  };

  let addFormButtons = () => {
    setFormButtons([...formButtons, { button_reply: "", button_title: "" }]);
  };

  let removeFormButtons = (index) => {
    let newFormValues = [...formButtons];
    newFormValues.splice(index, 1);
    setFormButtons(newFormValues);
  };

  // form type
  let handleChangeFormLists = (index, name, value) => {
    let newFormValues = [...formLists];
    newFormValues[index][name] = value;
    setFormLists(newFormValues);
  };

  let addFormLists = () => {
    setFormLists([
      ...formLists,
      {
        list: "",
        index: formLists.length ? formLists[formLists.length - 1].index + 1 : 0,
      },
    ]);
  };

  let removeFormLists = (index) => {
    let newFormValues = [...formLists];
    newFormValues.splice(index, 1);
    setFormLists(newFormValues);
  };

  // form type
  let handleChangeFormListsButton = (index, name, value) => {
    let newFormValues = [...formListsButton];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormListsButton(newFormValues);
  };

  let addFormListsButton = (listIndex) => {
    setFormListsButton([
      ...formListsButton,
      {
        title: "",
        description: "",
        row_reply: "",
        listIndex: listIndex,
        index: formListsButton.length
          ? formListsButton[formListsButton.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormListsButton = (index) => {
    let newFormValues = [...formListsButton];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormListsButton(newFormValues);
  };

  // template type
  let handleChangeFormTemplates = (index, name, value) => {
    let newFormValues = [...formTemplates];
    newFormValues[index][name] = value;
    setFormTemplates(newFormValues);
  };

  let addFormTemplates = (data) => {
    setFormTemplates([
      ...formTemplates,
      { type: data, displayText: "", action: "" },
    ]);
  };

  let removeFormTemplates = (index) => {
    let newFormValues = [...formTemplates];
    newFormValues.splice(index, 1);
    setFormTemplates(newFormValues);
  };

  // multiple type
  let handleChangeFormMultiples = (index, name, value) => {
    let newFormValues = [...formMultiples];
    newFormValues[index][name] = value;
    setFormMultiples(newFormValues);
  };

  let addFormMultiples = () => {
    setFormMultiples([
      ...formMultiples,
      {
        message: "",
        fileSelected: "",
        isVoice: false,
        isOpenModal: false,
        index: formMultiples.length
          ? formMultiples[formMultiples.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiples = (index) => {
    let newFormValues = [...formMultiples];
    newFormValues.splice(index, 1);
    setFormMultiples(newFormValues);
  };

  // list type button
  let handleChangeFormMultiplesButtons = (index, name, value) => {
    let newFormValues = [...formMultiplesButtons];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesButtons(newFormValues);
  };

  let addFormMultiplesButtons = (multipleIndex) => {
    setFormMultiplesButtons([
      ...formMultiplesButtons,
      {
        button_title: "",
        button_reply: "",
        multipleIndex: multipleIndex,
        index: formMultiplesButtons.length
          ? formMultiplesButtons[formMultiplesButtons.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesButtons = (index) => {
    let newFormValues = [...formMultiplesButtons];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesButtons(newFormValues);
  };

  // list type button
  let handleChangeFormMultiplesTemplates = (index, name, value) => {
    let newFormValues = [...formMultiplesTemplates];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesTemplates(newFormValues);
  };

  let addFormMultiplesTemplates = (multipleIndex, type) => {
    setFormMultiplesTemplates([
      ...formMultiplesTemplates,
      {
        type: type,
        displayText: "",
        action: "",
        multipleIndex: multipleIndex,
        index: formMultiplesTemplates.length
          ? formMultiplesTemplates[formMultiplesTemplates.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesTemplates = (index) => {
    let newFormValues = [...formMultiplesTemplates];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesTemplates(newFormValues);
  };

  // list type list
  let handleChangeFormMultiplesLists = (index, name, value) => {
    let newFormValues = [...formMultiplesLists];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesLists(newFormValues);
  };

  let addFormMultiplesLists = (multipleIndex) => {
    setFormMultiplesLists([
      ...formMultiplesLists,
      {
        title: "",
        buttonText: "",
        multipleIndex: multipleIndex,
        index: formMultiplesLists.length
          ? formMultiplesLists[formMultiplesLists.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesLists = (index) => {
    let newFormValues = [...formMultiplesLists];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesLists(newFormValues);
  };

  // list type list
  let handleChangeFormMultiplesListsButton = (index, name, value) => {
    let newFormValues = [...formMultiplesListsButton];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesListsButton(newFormValues);
  };

  let addFormMultiplesListsButton = (multipleListIndex) => {
    setFormMultiplesListsButton([
      ...formMultiplesListsButton,
      {
        title: "",
        buttonText: "",
        multipleListIndex: multipleListIndex,
        index: formMultiplesListsButton.length
          ? formMultiplesListsButton[formMultiplesListsButton.length - 1]
            .index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesListsButton = (index) => {
    let newFormValues = [...formMultiplesListsButton];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesListsButton(newFormValues);
  };

  const formik = useFormik({
    initialValues: {
      device: [],
      keyword: [],
      equals: "",
      reply_type: "",
      reply: "",
      fileSelected: "",
      footer: "",
      isVoice: false,
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      let data = null;

      const device = [];

      value.device.map((item) => {
        device.push(item.device_key);
      });

      data = {
        platform: 'telegram',
        name: value?.name,
        device_ids: device,
        keywords: value?.keyword,
        is_equal: value.equals,
        type: "message",
        is_send_message: 0,
        reply_type: value.reply_type,
        message:
          typeMessage?.key !== "reaction"
            ? value.reply
            : String.fromCodePoint(parseInt(value.reply, 16)),
        fileUrl: value.fileSelected
          ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
          : null,
        footer: value.footer,
        isVoice: value.isVoice,
        is_active: autoReply?.is_active ? 1 : 0
      };

      if (typeMessage?.key === "button") {
        data.reply_type = "button";
        data.buttons = formButtons ? formButtons : null;
      } else if (typeMessage?.key === "lists") {
        let lists = [];

        formLists.map((item, index) => {
          let listButtonRaw = [];

          formListsButton.map((item) => {
            if (item.listIndex === index) {
              listButtonRaw.push({
                row_reply: item.row_reply,
                title: item.title,
                description: item.description,
              });
            }
          });

          lists.push({
            title: item.list,
            rows: listButtonRaw,
          });
        });

        data.reply_type = "lists";
        data.listSections = lists ?? null;
        data.title = value.title;
        data.buttonText = value.buttonText;
      } else if (typeMessage?.key === "template") {
        let templates = [];

        formTemplates.map((item, index) => {
          if (item.type === "quick-reply") {
            templates.push({
              index: index + 1,
              quickReplyButton: {
                displayText: item.displayText,
                button_reply: item.action,
              },
            });
          } else if (item.type === "url") {
            templates.push({
              index: index + 1,
              urlButton: {
                displayText: item.displayText,
                url: item.action,
              },
            });
          } else if (item.type === "call") {
            templates.push({
              index: index + 1,
              callButton: {
                displayText: item.displayText,
                phoneNumber: item.action,
              },
            });
          }
        });

        data.reply_type = "template";
        data.templates = templates ?? null;
      } else if (typeMessage?.key === "multiple") {
        let multiples = [];

        formMultiples.map((item) => {
          if (item.reply_type === "text") {
            multiples.push({
              reply_type: "text",
              reply: item.reply,
              fileUrl: item.fileSelected
                ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                : null,
              fileName: null,
              IsVoice: item.isVoice,
            });
          } else if (item.reply_type === "button") {
            const buttons = [];

            formMultiplesButtons.map((i) => {
              buttons.push({
                button_title: i.button_title,
                button_reply: i.button_reply,
              });
            });

            multiples.push({
              reply_type: "button",
              reply: item.reply,
              fileUrl: item.fileSelected
                ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                : null,
              fileName: null,
              IsVoice: item.isVoice,
              buttons: buttons,
            });
          } else if (item.reply_type === "template") {
            let templates = [];

            formMultiplesTemplates.map((x, idx) => {
              if (x.type === "quick-reply") {
                templates.push({
                  index: idx + 1,
                  quickReplyButton: {
                    displayText: x.displayText,
                    button_reply: x.action,
                  },
                });
              } else if (x.type === "url") {
                templates.push({
                  index: idx + 1,
                  urlButton: {
                    displayText: x.displayText,
                    url: x.action,
                  },
                });
              } else if (x.type === "call") {
                templates.push({
                  index: idx + 1,
                  callButton: {
                    displayText: x.displayText,
                    phoneNumber: x.action,
                  },
                });
              }
            });

            multiples.push({
              reply_type: "template",
              reply: item.reply,
              fileUrl: item.fileSelected
                ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                : null,
              fileName: null,
              IsVoice: item.isVoice,
              templates: templates ?? [],
            });
          } else if (item.reply_type === "list") {
            let lists = [];

            formMultiplesLists.map((x, index) => {
              let buttonLists = [];

              formMultiplesListsButton.map((y) => {
                if (y.multipleListIndex === index) {
                  buttonLists.push({
                    row_reply: y.row_reply,
                    title: y.title,
                    description: y.description,
                  });
                }
              });

              lists.push({
                title: x.list,
                rows: buttonLists,
              });
            });

            multiples.push({
              reply_type: "list",
              reply: item.reply,
              fileUrl: item.fileSelected
                ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                : null,
              fileName: null,
              IsVoice: item.isVoice,
              title: item.title,
              buttonText: item.buttonText,
              listSections: lists ?? [],
            });
          }
        });

        data.reply_type = "multiple";
        data.messageContents = multiples ?? [];
      }

      data.user_id = user?.id;
      // data.id = autoReply?.id;
      data.reply_id = autoReply?.id;

      await dispatch(updateAutoReply2(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          setTypeMessage(null);
          setFormButtons([]);
          setFormLists([]);
          setFormListsButton([]);
          setFormTemplates([]);
          setFormListsTitle(null);
          setFormListsButtonText(null);
          setFormMultiples([]);
          setFormMultiplesButtons([]);
          setFormMultiplesTemplates([]);
          setFormMultiplesLists([]);
          setFormMultiplesListsButton([]);

          props.setEditNewKeyword();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    },
  });

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "telegram",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetUserQuestions = useCallback(
    debounce((data) => {
      dispatch(getUserQuestions(data));
    }, 200),
    []
  );

  const handleKeyPress = (event) => {
    let validateKey = null

    if (isMobile || isIOS || isAndroid) {
      validateKey =
        event?.key === "," ||
        event?.code == 'Comma' ||
        event?.keyCode === 188 ||
        event?.which === 188 ||
        event?.which === 44
    } else {
      validateKey =
        event?.key === "Enter" ||
        event?.code == 'Enter' ||
        event?.which === 13 ||
        event?.keyCode === 13 ||
        event?.key === "," ||
        event?.code == 'Comma' ||
        event?.keyCode === 188 ||
        event?.which === 188 ||
        event?.which === 44
    }

    const validateChar = event.target.value.slice(-1) == ','

    if (
      validateKey ||
      validateChar &&
      event.target.value.trim() !== ""
    ) {
      const isAvailableMenu = formik.values.keyword.find(item =>
        item?.toLowerCase()?.replaceAll(spaceRegex, '') ==
        event.target.value.replaceAll(',', '').toLowerCase().replaceAll(spaceRegex, '')
      )
      if (isAvailableMenu || event.target.value.replaceAll(',', '').trim() == "") {
        return
      }

      formik.setFieldValue("keyword", [
        ...(formik.values.keyword ?? []),
        // {
        //   created_at: null,
        //   customer_number: null,
        //   device_id: null,
        //   question: event.target.value.trim(),
        //   user_id: null,
        // },
        event.target.value.replaceAll(',', '')
      ]);
    }

    // if (event.key === "Enter" && event.target.value.trim() !== "") {
    //   formik.setFieldValue("keyword", [
    //     ...(formik.values.keyword ?? []),
    //     {
    //       created_at: null,
    //       customer_number: null,
    //       device_id: null,
    //       question: event.target.value.trim(),
    //       user_id: null,
    //     },
    //   ]);
    // }
  };

  useEffect(() => {
    handleGetDevices();
    handleGetUserQuestions({
      user_id: user?.id,
      body: {
        params: { contains: "" },
      },
    });
  }, []);

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values]);

  const handleGetIsBusiness = async (data) => {
    setIsLoadingBusiness(true);

    await dispatch(getIsBusiness(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if (formik.values.device && isBusiness) {
          setIsBusiness(true);
        } else {
          setIsBusiness(res.payload.isBusiness);
          formik.setFieldValue("reply_type", null);
          setTypeMessage(null);
        }
      }

      setIsLoadingBusiness(false);
    });
  };

  useEffect(() => {
    if (formik.values.device?.length) {
      formik.values.device.map((item) => {
        handleGetIsBusiness(item ? item?.device_key : null);
      });
    } else {
      setIsBusiness(false);
    }
  }, [formik.values.device]);

  async function handleFetchClodFile(type) {
    const data = {
      params: {
        type: type === 'pdf' ? 'document' : type,
        page: "all",
      },
    };
    const result = await dispatch(getCloudFile(data))
    return result?.payload?.data ?? null
  }

  const updateTypeText = async () => {
    const current_file_url = autoReply?.data?.file_url;
    const file_url = current_file_url.split(`/${storagePathName}/`)[1];
    const type = autoReply?.data?.type;
    const result = await handleFetchClodFile(type);
    if (!result?.length) {
      toast.dismiss();
      toast.error(result?.payload?.message ?? "Failed to get files");
      return;
    }
    const find_file_url = result.find(item => item.file_url === file_url)
    formik.setFieldValue('fileSelected', find_file_url)
  };

  async function updateMultiple() {
    const newData = []
    for (let i = 0; i < autoReply.data.length; i++) {
      const item = autoReply.data[i]

      let data = {}
      // TEXT
      if (item.reply_type === 'text') {
        // fetch cloud file if have file
        if (item?.file_url) {
          const result = await handleFetchClodFile(item.file_type)
          const file_url = item.file_url.split(`/${storagePathName}/`)[1];
          const find_file_url = result.find(file => file.file_url === file_url)
          data.fileSelected = find_file_url

          data.buttonText = ''
          data.index = i
          data.isOpenModal = false
          data.isVoice = false
          data.reply = item.reply ?? ""
          data.reply_type = item.reply_type
          data.title = ""
          newData.push(data)
        } else {
          data.fileSelected = ""
          data.buttonText = ''
          data.index = i
          data.isOpenModal = false
          data.isVoice = false
          data.reply = item.reply ?? ""
          data.reply_type = item.reply_type
          data.title = ""
          newData.push(data)
        }
      }
    }
    setFormMultiples(newData)
  }

  // update form
  useEffect(() => {
    if (autoReply && devices) {
      let device = devices?.filter((item) => {
        return props.device.device_key === item?.device_key;
      });

      formik.setFieldValue("device", device);
      formik.setFieldValue("name", autoReply?.name);
      formik.setFieldValue("reply", autoReply?.message);

      let keyword = [];

      autoReply?.keywords?.forEach((item) => {
        keyword.push(item);
      });

      formik.setFieldValue("keyword", keyword);
      formik.setFieldValue("equals", autoReply?.is_equal ? 1 : 0);

      setPatternMatching(
        patternData?.find((e) => e.key === (autoReply?.is_equal ? 1 : 0))
      );

      // if reply by is a text
      if (autoReply?.reply_type === 'text' && autoReply?.data?.file_url) {
        updateTypeText();
      } else if (
        autoReply?.reply_type === 'multiple' &&
        autoReply?.data?.length &&
        autoReply.data.length > 0
      ) {
        // if reply by is a multiple (subsequent)
        updateMultiple()
      }
    }
  }, [autoReply, devices]);

  useEffect(() => {
    if (!isLoadingBusiness && autoReply) {
      setTimeout(() => {
        if (
          autoReply?.reply_type === "text" ||
          autoReply?.reply_type === "multiple"
        ) {
          formik.setFieldValue("reply_type", autoReply?.reply_type);
          formik.setFieldValue("reply", autoReply?.message);

          setTypeMessage(
            messageTypeData?.find((e) => e.key === autoReply?.reply_type)
          );
        }
      }, 3000);
    }
  }, [isLoadingBusiness]);

  return (
    <SaveCancelContainer
      title="Edit Keyword"
      buttonCancelTitle="Close"
      buttonSaveTitle="Submit"
      buttonDisabled={
        typeMessage?.key === "multiple"
          ? !formMultiples?.length
          : !formik.values.reply?.length
      }
      onCancel={() => props.setEditNewKeyword()}
      onSave={formik.handleSubmit}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} minHeight="540px">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <Typography fontWeight="700" mb={0.5}>
                    Title
                  </Typography>
                  <BasicInput
                    fullWidth
                    disableMargin
                    // multiline
                    rows={5}
                    placeholder="Type title here"
                    {...formik.getFieldProps("name")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <Typography fontWeight="700" mb={0.5}>
                    Device
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={[{ name: "All", device_key: "all" }].concat(
                      devices
                    )}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={formik.values.device ?? []}
                    ChipProps={{
                      style: {
                        backgroundColor: palette.primary.main,
                        color: palette.secondary.main,
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Plase select device"
                        size="small"
                      />
                    )}
                    onChange={(e, value) => {
                      formik.setFieldValue("device", value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  <Typography fontWeight="700" mb={0.5}>
                    Keyword
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={userQuestions}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    value={formik.values.keyword ?? []}
                    ChipProps={{
                      style: {
                        backgroundColor: palette.primary.main,
                        color: palette.secondary.main,
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Type message in here ..."
                        size="small"
                        // onKeyPress={handleKeyPress}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => {
                          handleGetUserQuestions({
                            user_id: user?.id,
                            body: {
                              params: { contains: e.target.value ?? "" },
                            },
                          });
                        }}
                      />
                    )}
                    onChange={(e, value) => {
                      formik.setFieldValue("keyword", value);
                    }}
                  />
                </Box>
                <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                  {isMobile || isIOS || isAndroid ?
                    `use comma "," as a multi-keyword separator`
                    :
                    `use comma "," or Enter as a multi-keyword separator`
                  }
                </Typography>{" "}
              </Grid>
              <Grid item xs={12} sm={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  options={patternData ?? []}
                  getOptionLabel={(option) => option?.name}
                  title="Pattern Matching"
                  placeholder={"Choose Pattern Matching"}
                  value={patternMatching}
                  onChange={(e, value) => {
                    formik.setFieldValue("equals", value.key);
                    setPatternMatching(value);
                  }}
                />
                <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                  What is this Pattern Matching about?{"  "}
                  <a
                    href="https://help.realm.chat/?docs=tips/pattern-matching"
                    style={{
                      textDecoration: "none",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Read Here
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  options={
                    messageTypeData.filter(
                      (val) => val?.key === "text" || val?.key === "multiple"
                    ) ?? []
                  }
                  getOptionLabel={(option) => option?.name}
                  title="Reply By"
                  placeholder={"Choose reply type message"}
                  value={typeMessage}
                  onChange={(e, value) => {
                    formik.setFieldValue("reply_type", value?.key ?? "");
                    formik.setFieldValue("reply", "");

                    if (value?.key === "multiple") {
                      setFormMultiples([
                        {
                          reply: "",
                          fileSelected: "",
                          isVoice: false,
                          isOpenModal: false,
                          index: formMultiples.length
                            ? formMultiples[formMultiples.length - 1].index + 1
                            : 0,
                        },
                      ]);
                    } else {
                      setFormMultiples([]);
                    }

                    setTypeMessage(value);
                  }}
                />
              </Grid>
              {typeMessage?.key !== "multiple" ? (
                <>
                  {typeMessage ? (
                    <Grid item xs={12} sm={12}>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        {typeMessage?.key === "lists" ? (
                          <Box mb={2}>
                            <BasicInput
                              fullWidth
                              disableMargin
                              placeholder="Title"
                              onChange={(e) => {
                                formik.setFieldValue("title", e.target.value);
                                setFormListsTitle(e.target.value);
                              }}
                            />
                          </Box>
                        ) : null}
                        {typeMessage?.key !== "reaction" ? (
                          <Box mb={2}>
                            <Box>
                              <BasicInput
                                fullWidth
                                disableMargin
                                multiline
                                rows={5}
                                placeholder="Type message in here ..."
                                {...formik.getFieldProps("reply")}
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </motion.div>
                    </Grid>
                  ) : null}
                  {typeMessage?.key === "button" ? (
                    <>
                      {formButtons.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <Grid item xs={12} lg={5}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                name="button_title"
                                title="Button Tittle"
                                placeholder="i.e Yes"
                                value={item.button_title || ""}
                                onChange={(e) =>
                                  handleChangeFormButtons(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                multiline
                                rows={5}
                                name="button_reply"
                                title="Button Response"
                                placeholder="{{Reply _ID}} or response text"
                                value={item.button_reply || ""}
                                onChange={(e) =>
                                  handleChangeFormButtons(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={1}>
                              <Box
                                mb={3.3}
                                sx={{ display: { xs: "none", lg: "block" } }}
                              />
                              <IconButton
                                onClick={() => removeFormButtons(index)}
                              >
                                <Iconify
                                  icon="ant-design:close-square-outlined"
                                  color="#F15A28"
                                  height="32px"
                                  width="32px"
                                />
                              </IconButton>
                            </Grid>
                          </Fragment>
                        );
                      })}

                      {formButtons.length < 3 ? (
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            sx={{
                              background: "#FFFFFF",
                              borderRadius: "8px",
                              color: "#7B7B7B",
                              width: "100%",
                              height: "40px",
                              boxShadow: "none",
                              border: "1px solid #CBC8C8",
                            }}
                            startIcon={
                              <Iconify
                                icon="fluent:add-12-filled"
                                color="#7B7B7B"
                                height="16px"
                                width="16px"
                              />
                            }
                            onClick={() => {
                              addFormButtons();
                            }}
                          >
                            <Typography fontSize={{ xs: 12, sm: 14 }}>
                              Add Button
                            </Typography>
                          </Button>
                        </Grid>
                      ) : null}
                    </>
                  ) : typeMessage?.key === "lists" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <BasicInput
                          disableMargin
                          fullWidth
                          title="Button Text"
                          placeholder="Click Here"
                          value={formik.values.buttonText}
                          onChange={(e) => {
                            formik.setFieldValue("buttonText", e.target.value);
                            setFormListsButtonText(e.target.value);
                          }}
                        />
                      </Grid>
                      {formLists.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <Grid item xs={12} lg={11}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                name="lists"
                                title="List Title"
                                placeholder="List Title"
                                value={item.list || ""}
                                onChange={(e) =>
                                  handleChangeFormLists(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={1}>
                              <Box
                                mb={3.3}
                                sx={{ display: { xs: "none", lg: "block" } }}
                              />
                              <IconButton
                                onClick={() => removeFormLists(index)}
                              >
                                <Iconify
                                  icon="ant-design:close-square-outlined"
                                  color="#F15A28"
                                  height="32px"
                                  width="32px"
                                />
                              </IconButton>
                            </Grid>
                            {formListsButton
                              .filter((e) => e.listIndex === item.index)
                              .map((item, i) => {
                                return (
                                  <Fragment key={i}>
                                    <Grid item xs={12} lg={4}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        name="title"
                                        title="Create Tittle"
                                        placeholder="i.e Yes"
                                        value={item.title || ""}
                                        onChange={(e) =>
                                          handleChangeFormListsButton(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        multiline
                                        rows={5}
                                        name="description"
                                        title="Description ( Optional )"
                                        placeholder="Select description here"
                                        value={item.description || ""}
                                        onChange={(e) =>
                                          handleChangeFormListsButton(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        multiline
                                        rows={5}
                                        name="row_reply"
                                        title="Response"
                                        placeholder="{{Reply _ID}} or response text"
                                        value={item.row_reply || ""}
                                        onChange={(e) =>
                                          handleChangeFormListsButton(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                  </Fragment>
                                );
                              })}
                            <Grid item xs={12} lg={6}></Grid>
                            <Grid item xs={12} lg={6}>
                              <Grid
                                container
                                spacing={2}
                                sx={{ justifyContent: "flex-end" }}
                              >
                                {formListsButton.length ? (
                                  <Grid item xs={6}>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        background: "#FFFFFF",
                                        borderRadius: "8px",
                                        color: "#7B7B7B",
                                        width: "100%",
                                        height: "40px",
                                        boxShadow: "none",
                                        border: "1px solid #CBC8C8",
                                      }}
                                      onClick={() =>
                                        removeFormListsButton(index)
                                      }
                                    >
                                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                                        Delete
                                      </Typography>
                                    </Button>
                                  </Grid>
                                ) : null}

                                <Grid item xs={6}>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "#FFFFFF",
                                      borderRadius: "8px",
                                      color: "#7B7B7B",
                                      width: "100%",
                                      height: "40px",
                                      boxShadow: "none",
                                      border: "1px solid #CBC8C8",
                                    }}
                                    startIcon={
                                      <Iconify
                                        icon="fluent:add-12-filled"
                                        color="#7B7B7B"
                                        height="16px"
                                        width="16px"
                                      />
                                    }
                                    onClick={() => {
                                      addFormListsButton(index);
                                    }}
                                  >
                                    <Typography fontSize={{ xs: 12, sm: 14 }}>
                                      Add Button
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Fragment>
                        );
                      })}
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "8px",
                            color: "#7B7B7B",
                            width: "100%",
                            height: "40px",
                            boxShadow: "none",
                            border: "1px solid #CBC8C8",
                          }}
                          startIcon={
                            <Iconify
                              icon="fluent:add-12-filled"
                              color="#7B7B7B"
                              height="16px"
                              width="16px"
                            />
                          }
                          onClick={() => {
                            addFormLists();
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            Add List
                          </Typography>
                        </Button>
                      </Grid>
                    </>
                  ) : typeMessage?.key === "template" ? (
                    <>
                      {formTemplates.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <Grid item xs={12} lg={5}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                name="displayText"
                                title="Display Text"
                                placeholder="i.e Yes"
                                value={item.displayText || ""}
                                onChange={(e) =>
                                  handleChangeFormTemplates(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                multiline
                                rows={5}
                                name="action"
                                title={`${item.type === "quick-reply"
                                  ? "Reply"
                                  : item.type === "url"
                                    ? "Website"
                                    : item.type === "call"
                                      ? "Phone Number"
                                      : null
                                  }`}
                                placeholder={`${item.type === "quick-reply"
                                  ? "{{Reply _ID}} or response text"
                                  : item.type === "url"
                                    ? "https://realm.chat"
                                    : item.type === "call"
                                      ? "Input number"
                                      : null
                                  }`}
                                value={item.action || ""}
                                onChange={(e) =>
                                  handleChangeFormTemplates(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} lg={1}>
                              <Box
                                mb={3.3}
                                sx={{ display: { xs: "none", lg: "block" } }}
                              />
                              <IconButton
                                onClick={() => removeFormTemplates(index)}
                              >
                                <Iconify
                                  icon="ant-design:close-square-outlined"
                                  color="#F15A28"
                                  height="32px"
                                  width="32px"
                                />
                              </IconButton>
                            </Grid>
                          </Fragment>
                        );
                      })}

                      {formTemplates.length < 3 ? (
                        <>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                color: "#7B7B7B",
                                width: "100%",
                                height: "40px",
                                boxShadow: "none",
                                border: "1px solid #CBC8C8",
                              }}
                              startIcon={
                                <Iconify
                                  icon="fluent:add-12-filled"
                                  color="#7B7B7B"
                                  height="16px"
                                  width="16px"
                                />
                              }
                              onClick={() => {
                                addFormTemplates("quick-reply");
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 14 }}>
                                Add Button
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                color: "#7B7B7B",
                                width: "100%",
                                height: "40px",
                                boxShadow: "none",
                                border: "1px solid #CBC8C8",
                              }}
                              startIcon={
                                <Iconify
                                  icon="fluent:add-12-filled"
                                  color="#7B7B7B"
                                  height="16px"
                                  width="16px"
                                />
                              }
                              onClick={() => {
                                addFormTemplates("url");
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 14 }}>
                                Add Website Button
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                color: "#7B7B7B",
                                width: "100%",
                                height: "40px",
                                boxShadow: "none",
                                border: "1px solid #CBC8C8",
                              }}
                              startIcon={
                                <Iconify
                                  icon="fluent:add-12-filled"
                                  color="#7B7B7B"
                                  height="16px"
                                  width="16px"
                                />
                              }
                              onClick={() => {
                                addFormTemplates("call");
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 14 }}>
                                Add Call Button
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                    </>
                  ) : typeMessage?.key === "reaction" ? (
                    <Grid item xs={12} sm={12}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <BasicButton onClick={() => setShowEmoji(!showEmoji)}>
                          <Iconify
                            icon="ic:outline-emoji-emotions"
                            height="24px"
                            width="24px"
                          />
                        </BasicButton>
                        <Box sx={{ m: "0 0 0 16px" }}>
                          <Emoji
                            unified={formik.values.reply}
                            emojiStyle={EmojiStyle.APPLE}
                            size={32}
                          />
                        </Box>
                      </Box>
                      {showEmoji ? (
                        <Box
                          sx={{
                            margin: "12px 0 0 0",
                            position: "absolute",
                            zIndex: "99",
                          }}
                        >
                          <EmojiPicker
                            onEmojiClick={(emojiData) => {
                              formik.setFieldValue("reply", emojiData.unified);
                            }}
                          />
                        </Box>
                      ) : null}
                    </Grid>
                  ) : null}
                  {typeMessage?.key !== "lists" &&
                    typeMessage?.key !== "reaction" ? (
                    <Grid item xs={12} sm={12}>
                      <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                        Attachment (Optional)
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <BasicButton
                          title="Choose File"
                          onClick={() => setIsOdpenUploadModal(true)}
                        />
                        <Typography color="#131313" sx={{ ml: 2 }}>
                          {formik?.values?.fileSelected?.file_name}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                  {formik.values.fileSelected?.file_type === "audio" ? (
                    <Grid item xs={12} sm={12}>
                      <BasicInput
                        type="checkbox"
                        title="Send as Voice Note"
                        sx={{ width: "24px" }}
                        onChange={() => {
                          formik.setFieldValue(
                            "isVoice",
                            !formik.values.isVoice ? true : false
                          );
                        }}
                      />
                    </Grid>
                  ) : null}
                </>
              ) : (
                <>
                  {/* form multiples */}
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ border: "1px solid #CBC8C8" }}></Box>
                  </Grid>
                  {formMultiples.map((item, index) => {
                    const currentType = messageTypeData.find(data => data.key === item.reply_type)
                    return (
                      <Fragment key={index}>
                        <Grid item xs={11}>
                          <ComboBox
                            fullWidth
                            disableMargin
                            options={
                              messageTypeData.filter(
                                (e) => e.key !== "multiple"
                              ) ?? []
                            }
                            getOptionLabel={(option) => option?.name}
                            title="Message Type"
                            placeholder={"Choose type message"}
                            value={currentType}
                            onChange={(e, value) => {
                              handleChangeFormMultiples(
                                index,
                                "reply_type",
                                value ? value?.key : null
                              );

                              handleChangeFormMultiples(index, "reply", "");

                              if (value?.key !== "button") {
                                handleChangeFormMultiples(
                                  index,
                                  "buttonText",
                                  ""
                                );
                              }

                              if (value?.key !== "list") {
                                handleChangeFormMultiples(index, "title", "");
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <Box mt={2.8} />
                          <IconButton
                            onClick={() => removeFormMultiples(index)}
                          >
                            <Iconify
                              icon="ant-design:close-square-outlined"
                              color="#F15A28"
                              height="32px"
                              width="32px"
                            />
                          </IconButton>
                        </Grid>
                        {item.reply_type === "text" ? (
                          <Grid item xs={12} sm={12}>
                            <Typography
                              fontSize={16}
                              fontWeight="600"
                              sx={{ mb: 1 }}
                            >
                              Attachment (Optional)
                            </Typography>
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <BasicButton
                                title="Choose File"
                                onClick={() =>
                                  handleChangeFormMultiples(
                                    index,
                                    "isOpenModal",
                                    true
                                  )
                                }
                              />
                              <Typography color="#131313" sx={{ ml: 2 }}>
                                {item?.fileSelected?.file_name}
                              </Typography>
                              <CustomModal open={item.isOpenModal}>
                                <Upload
                                  onCancle={() =>
                                    handleChangeFormMultiples(
                                      index,
                                      "isOpenModal",
                                      false
                                    )
                                  }
                                  onSelect={(value) => {
                                    handleChangeFormMultiples(
                                      index,
                                      "fileSelected",
                                      value
                                    );
                                  }}
                                  value={item.fileSelected || ""}
                                />
                              </CustomModal>
                            </Box>
                          </Grid>
                        ) : null}
                        {item.reply_type ? (
                          <Grid item xs={12} sm={12}>
                            <motion.div
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ duration: 0.3 }}
                            >
                              {item.reply_type === "list" ? (
                                <Box mb={2}>
                                  <BasicInput
                                    fullWidth
                                    disableMargin
                                    name="title"
                                    placeholder="Title"
                                    value={item.title || ""}
                                    onChange={(e) =>
                                      handleChangeFormMultiples(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Box>
                              ) : null}
                              {item.reply_type === "reaction" ? (
                                <Grid item xs={12} sm={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <BasicButton
                                      onClick={() => {
                                        document.getElementById(
                                          `reaction-${index}`
                                        ).style.display =
                                          document.getElementById(
                                            `reaction-${index}`
                                          ).style.display === "block"
                                            ? "none"
                                            : "block";
                                      }}
                                    >
                                      <Iconify
                                        icon="ic:outline-emoji-emotions"
                                        height="24px"
                                        width="24px"
                                      />
                                    </BasicButton>
                                    <Box sx={{ m: "0 0 0 16px" }}>
                                      <Emoji
                                        unified={item.reply}
                                        emojiStyle={EmojiStyle.APPLE}
                                        size={32}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    id={`reaction-${index}`}
                                    sx={{
                                      display: "none",
                                      margin: "12px 0 0 0",
                                      position: "absolute",
                                      zIndex: 99,
                                    }}
                                  >
                                    <EmojiPicker
                                      onEmojiClick={(emojiData) => {
                                        handleChangeFormMultiples(
                                          index,
                                          "reply",
                                          emojiData.unified
                                        );
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              ) : (
                                <Box mb={2}>
                                  <BasicInput
                                    fullWidth
                                    disableMargin
                                    multiline
                                    rows={5}
                                    title="Message"
                                    placeholder="Type message in here ..."
                                    name="reply"
                                    value={item.reply || ""}
                                    onChange={(e) =>
                                      handleChangeFormMultiples(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Box>
                              )}
                            </motion.div>
                          </Grid>
                        ) : null}
                        {item.reply_type === "button" ? (
                          <>
                            {formMultiplesButtons
                              .filter((e) => e.multipleIndex === item.index)
                              .map((item, i) => {
                                return (
                                  <Fragment key={i}>
                                    <Grid item xs={12} lg={5}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        name="button_title"
                                        title="Button Tittle"
                                        placeholder="i.e Yes"
                                        value={item.button_title || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiplesButtons(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        multiline
                                        rows={5}
                                        name="button_reply"
                                        title="Button Response"
                                        placeholder="{{Reply_ID}} or response text"
                                        value={item.button_reply || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiplesButtons(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                      <>
                                        <Box
                                          mb={3.3}
                                          sx={{
                                            display: {
                                              xs: "none",
                                              lg: "block",
                                            },
                                          }}
                                        />
                                        <IconButton
                                          onClick={() =>
                                            removeFormMultiplesButtons(i)
                                          }
                                        >
                                          <Iconify
                                            icon="ant-design:close-square-outlined"
                                            color="#F15A28"
                                            height="32px"
                                            width="32px"
                                          />
                                        </IconButton>
                                      </>
                                    </Grid>
                                  </Fragment>
                                );
                              })}
                            {formMultiplesButtons.filter(
                              (e) => e.multipleIndex === item.index
                            ).length < 3 ? (
                              <Grid item xs={12}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "8px",
                                    color: "#7B7B7B",
                                    width: "100%",
                                    height: "40px",
                                    boxShadow: "none",
                                    border: "1px solid #CBC8C8",
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="fluent:add-12-filled"
                                      color="#7B7B7B"
                                      height="16px"
                                      width="16px"
                                    />
                                  }
                                  onClick={() => {
                                    addFormMultiplesButtons(item.index);
                                  }}
                                >
                                  <Typography fontSize={{ xs: 12, sm: 14 }}>
                                    Add Button
                                  </Typography>
                                </Button>
                              </Grid>
                            ) : null}
                          </>
                        ) : item.reply_type === "template" ? (
                          <>
                            {formMultiplesTemplates
                              .filter((e) => e.multipleIndex === item.index)
                              .map((item, index) => {
                                return (
                                  <Fragment key={index}>
                                    <Grid item xs={12} lg={5}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        name="displayText"
                                        title="Display Text"
                                        placeholder="i.e Yes"
                                        value={item.displayText || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiplesTemplates(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        multiline
                                        rows={5}
                                        name="action"
                                        title={`${item.type === "quick-reply"
                                          ? "Reply"
                                          : item.type === "url"
                                            ? "Website"
                                            : item.type === "call"
                                              ? "Phone Number"
                                              : null
                                          }`}
                                        placeholder={`${item.type === "quick-reply"
                                          ? "{{Reply_ID}} or response text"
                                          : item.type === "url"
                                            ? "https://realm.chat"
                                            : item.type === "call"
                                              ? "Input number"
                                              : null
                                          }`}
                                        value={item.action || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiplesTemplates(
                                            item.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                      <Box
                                        mb={3.3}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            lg: "block",
                                          },
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          removeFormMultiplesTemplates(index)
                                        }
                                      >
                                        <Iconify
                                          icon="ant-design:close-square-outlined"
                                          color="#F15A28"
                                          height="32px"
                                          width="32px"
                                        />
                                      </IconButton>
                                    </Grid>
                                  </Fragment>
                                );
                              })}

                            {formMultiplesTemplates.filter(
                              (e) => e.multipleIndex === item.index
                            ).length < 3 ? (
                              <>
                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "#FFFFFF",
                                      borderRadius: "8px",
                                      color: "#7B7B7B",
                                      width: "100%",
                                      height: "40px",
                                      boxShadow: "none",
                                      border: "1px solid #CBC8C8",
                                    }}
                                    startIcon={
                                      <Iconify
                                        icon="fluent:add-12-filled"
                                        color="#7B7B7B"
                                        height="16px"
                                        width="16px"
                                      />
                                    }
                                    onClick={() => {
                                      addFormMultiplesTemplates(
                                        item.index,
                                        "quick-reply"
                                      );
                                    }}
                                  >
                                    <Typography fontSize={{ xs: 12, sm: 14 }}>
                                      Add Button
                                    </Typography>
                                  </Button>
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "#FFFFFF",
                                      borderRadius: "8px",
                                      color: "#7B7B7B",
                                      width: "100%",
                                      height: "40px",
                                      boxShadow: "none",
                                      border: "1px solid #CBC8C8",
                                    }}
                                    startIcon={
                                      <Iconify
                                        icon="fluent:add-12-filled"
                                        color="#7B7B7B"
                                        height="16px"
                                        width="16px"
                                      />
                                    }
                                    onClick={() => {
                                      addFormMultiplesTemplates(
                                        item.index,
                                        "url"
                                      );
                                    }}
                                  >
                                    <Typography fontSize={{ xs: 12, sm: 14 }}>
                                      Add Website Button
                                    </Typography>
                                  </Button>
                                </Grid>

                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "#FFFFFF",
                                      borderRadius: "8px",
                                      color: "#7B7B7B",
                                      width: "100%",
                                      height: "40px",
                                      boxShadow: "none",
                                      border: "1px solid #CBC8C8",
                                    }}
                                    startIcon={
                                      <Iconify
                                        icon="fluent:add-12-filled"
                                        color="#7B7B7B"
                                        height="16px"
                                        width="16px"
                                      />
                                    }
                                    onClick={() => {
                                      addFormMultiplesTemplates(
                                        item.index,
                                        "call"
                                      );
                                    }}
                                  >
                                    <Typography fontSize={{ xs: 12, sm: 14 }}>
                                      Add Call Button
                                    </Typography>
                                  </Button>
                                </Grid>
                              </>
                            ) : null}
                          </>
                        ) : item.reply_type === "list" ? (
                          <>
                            <Grid item xs={12} sm={12}>
                              <BasicInput
                                disableMargin
                                fullWidth
                                name="buttonText"
                                title="Button Text"
                                placeholder="Click Here"
                                value={item.buttonText || ""}
                                onChange={(e) => {
                                  handleChangeFormMultiples(
                                    item.index,
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                            {formMultiplesLists
                              .filter((e) => e.multipleIndex === item.index)
                              .map((x, i) => {
                                return (
                                  <Fragment key={i}>
                                    <Grid item xs={12} lg={11}>
                                      <BasicInput
                                        disableMargin
                                        fullWidth
                                        name="list"
                                        title="List Title"
                                        placeholder="List Title"
                                        value={x.list || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiplesLists(
                                            x.index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} lg={1}>
                                      <Box
                                        mb={3.3}
                                        sx={{
                                          display: {
                                            xs: "none",
                                            lg: "block",
                                          },
                                        }}
                                      />
                                      <IconButton
                                        onClick={() =>
                                          removeFormMultiplesLists(x.index)
                                        }
                                      >
                                        <Iconify
                                          icon="ant-design:close-square-outlined"
                                          color="#F15A28"
                                          height="32px"
                                          width="32px"
                                        />
                                      </IconButton>
                                    </Grid>
                                    {formMultiplesListsButton
                                      .filter(
                                        (e) => e.multipleListIndex === x.index
                                      )
                                      .map((y, i) => {
                                        return (
                                          <Fragment key={i}>
                                            <Grid item xs={12} lg={4}>
                                              <BasicInput
                                                disableMargin
                                                fullWidth
                                                name="title"
                                                title="Create Title"
                                                placeholder="i.e Yes"
                                                value={y.title || ""}
                                                onChange={(e) =>
                                                  handleChangeFormMultiplesListsButton(
                                                    y.index,
                                                    e.target.name,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                              <BasicInput
                                                disableMargin
                                                fullWidth
                                                multiline
                                                rows={5}
                                                name="description"
                                                title="Description ( Optional )"
                                                placeholder="Select description here"
                                                value={y.description || ""}
                                                onChange={(e) =>
                                                  handleChangeFormMultiplesListsButton(
                                                    y.index,
                                                    e.target.name,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                              <BasicInput
                                                disableMargin
                                                fullWidth
                                                multiline
                                                rows={5}
                                                name="row_reply"
                                                title="Response"
                                                placeholder="{{Reply_ID}} or response text"
                                                value={y.row_reply || ""}
                                                onChange={(e) =>
                                                  handleChangeFormMultiplesListsButton(
                                                    y.index,
                                                    e.target.name,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </Grid>
                                          </Fragment>
                                        );
                                      })}
                                    <Grid item xs={12} lg={6}></Grid>
                                    <Grid item xs={12} lg={6}>
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{ justifyContent: "flex-end" }}
                                      >
                                        {formMultiplesListsButton.filter(
                                          (e) =>
                                            e.multipleListIndex === item.index
                                        ).length ? (
                                          <Grid item xs={6}>
                                            <Button
                                              variant="outlined"
                                              sx={{
                                                background: "#FFFFFF",
                                                borderRadius: "8px",
                                                color: "#7B7B7B",
                                                width: "100%",
                                                height: "40px",
                                                boxShadow: "none",
                                                border: "1px solid #CBC8C8",
                                              }}
                                              onClick={() =>
                                                removeFormMultiplesListsButton(
                                                  i
                                                )
                                              }
                                            >
                                              <Typography
                                                fontSize={{
                                                  xs: 12,
                                                  sm: 14,
                                                }}
                                              >
                                                Delete
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        ) : null}

                                        {
                                          <Grid item xs={6}>
                                            <Button
                                              variant="outlined"
                                              sx={{
                                                background: "#FFFFFF",
                                                borderRadius: "8px",
                                                color: "#7B7B7B",
                                                width: "100%",
                                                height: "40px",
                                                boxShadow: "none",
                                                border: "1px solid #CBC8C8",
                                              }}
                                              startIcon={
                                                <Iconify
                                                  icon="fluent:add-12-filled"
                                                  color="#7B7B7B"
                                                  height="16px"
                                                  width="16px"
                                                />
                                              }
                                              onClick={() => {
                                                addFormMultiplesListsButton(
                                                  x.index
                                                );
                                              }}
                                            >
                                              <Typography
                                                fontSize={{
                                                  xs: 12,
                                                  sm: 14,
                                                }}
                                              >
                                                Add Button
                                              </Typography>
                                            </Button>
                                          </Grid>
                                        }
                                      </Grid>
                                    </Grid>
                                  </Fragment>
                                );
                              })}

                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                sx={{
                                  background: "#FFFFFF",
                                  borderRadius: "8px",
                                  color: "#7B7B7B",
                                  width: "100%",
                                  height: "40px",
                                  boxShadow: "none",
                                  border: "1px solid #CBC8C8",
                                }}
                                startIcon={
                                  <Iconify
                                    icon="fluent:add-12-filled"
                                    color="#7B7B7B"
                                    height="16px"
                                    width="16px"
                                  />
                                }
                                onClick={() => {
                                  addFormMultiplesLists(item.index);
                                }}
                              >
                                <Typography fontSize={{ xs: 12, sm: 14 }}>
                                  Add List
                                </Typography>
                              </Button>
                            </Grid>
                          </>
                        ) : item.reply_type !== "list" ? (
                          <>
                            {item.reply_type === "reaction" ? (
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  fontSize={16}
                                  fontWeight="600"
                                  sx={{ mb: 1 }}
                                >
                                  Attachment (Optional)
                                </Typography>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <BasicButton
                                    title="Choose File"
                                    onClick={() =>
                                      handleChangeFormMultiples(
                                        index,
                                        "isOpenModal",
                                        true
                                      )
                                    }
                                  />
                                  <Typography color="#131313" sx={{ ml: 2 }}>
                                    {item?.fileSelected?.file_name}
                                  </Typography>
                                  <CustomModal open={item.isOpenModal}>
                                    <Upload
                                      onCancle={() =>
                                        handleChangeFormMultiples(
                                          index,
                                          "isOpenModal",
                                          false
                                        )
                                      }
                                      onSelect={(value) => {
                                        handleChangeFormMultiples(
                                          index,
                                          "fileSelected",
                                          value
                                        );
                                      }}
                                      value={item.fileSelected || ""}
                                    />
                                  </CustomModal>
                                </Box>
                              </Grid>
                            ) : null}
                          </>
                        ) : null}
                        {item.fileSelected?.file_type === "audio" ? (
                          <Grid item xs={12} sm={12}>
                            <BasicInput
                              type="checkbox"
                              title="Send as Voice Note"
                              sx={{ width: "24px" }}
                              onChange={() => {
                                handleChangeFormMultiples(
                                  index,
                                  "isVoice",
                                  item.isVoice ? false : true
                                );
                              }}
                            />
                          </Grid>
                        ) : null}
                      </Fragment>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        color: "#7B7B7B",
                        width: "100%",
                        height: "40px",
                        boxShadow: "none",
                        border: "1px solid #CBC8C8",
                      }}
                      startIcon={
                        <Iconify
                          icon="fluent:add-12-filled"
                          color="#7B7B7B"
                          height="16px"
                          width="16px"
                        />
                      }
                      onClick={() => {
                        addFormMultiples();
                      }}
                    >
                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                        Add Message
                      </Typography>
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <DeviceFrame
            single={{
              formButtons: formButtons ?? [],
              formTemplates: formTemplates ?? [],
              formLists: formLists ?? [],
              formListsButton: formListsButton ?? [],
              formListsOther: {
                title: formListsTitle,
                buttonText: formListsButtonText,
              },
              fileSelected: formik.values.fileSelected,
              isVoice: formik.values.isVoice,
              typeMessage: typeMessage,
            }}
            multiples={{
              formMultiples: formMultiples ?? [],
              formMultiplesButtons: formMultiplesButtons ?? [],
              formMultiplesTemplates: formMultiplesTemplates ?? [],
              formMultiplesLists: formMultiplesLists ?? [],
              formMultiplesListsButton: formMultiplesListsButton ?? [],
            }}
            setFooter={(value) => formik.setFieldValue("footer", value)}
          >
            {typeMessage?.key !== "reaction" ? (
              <p id="chat-message" style={{ whiteSpace: "pre-line" }}>
                {formik.values.reply?.split("\n").join("<br />")}
              </p>
            ) : (
              <Emoji
                unified={formik.values.reply}
                emojiStyle={EmojiStyle.APPLE}
                size={24}
              />
            )}
          </DeviceFrame>
        </Grid>
      </Grid>
      <CustomModal
        handleClose={() => setIsOdpenUploadModal(false)}
        open={isOpenUploadModal}
      >
        <Upload
          onCancle={() => setIsOdpenUploadModal(false)}
          onSelect={(value) => {
            formik.setFieldValue("fileSelected", value);
          }}
          value={formik.values?.fileSelected}
        />
      </CustomModal>
    </SaveCancelContainer>
  );
}
