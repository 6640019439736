import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  addCatalogVoucher,
  getCatalogVouchers,
} from "../../../../store/catalog/catalogAction";

const voucherTypes = [
  { name: "Subtotal", key: "subtotal" },
  { name: "Shipping", key: "shipping" },
];

const discountTypes = [
  { name: "Percentage", key: "percentage" },
  { name: "Fixed", key: "fixed" },
];

export default function Form({ onCancel }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      type_voucher: null,
      type_discount: null,
      amount: "",
      quantity: "",
      start_date: "",
      end_date: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        name: value.name,
        code: value.code,
        type_voucher: value.type_voucher?.key,
        type_discount: value.type_discount?.key,
        amount: value.amount,
        quantity: value.quantity,
        start_date: value.start_date,
        end_date: value.end_date,
      };

      await dispatch(addCatalogVoucher(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          handleGetCatalogVouchers();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
    },
  });

  const handleGetCatalogVouchers = async () => {
    await dispatch(getCatalogVouchers());
  };

  return (
    <FormContainer title="Add Voucher">
      <BasicInput
        fullWidth
        disableMargin
        title="Name"
        {...formik.getFieldProps("name")}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        title="Code"
        {...formik.getFieldProps("code")}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Voucher Type"
        placeholder="Please select voucher type"
        options={voucherTypes}
        getOptionLabel={(option) => option?.name}
        value={formik.values.type_voucher}
        onChange={(e, value) => {
          formik.setFieldValue("type_voucher", value);
        }}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Discount Type"
        placeholder="Please select discount type"
        options={discountTypes}
        getOptionLabel={(option) => option?.name}
        value={formik.values.type_discount}
        onChange={(e, value) => {
          formik.setFieldValue("type_discount", value);
        }}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        type="number"
        title="Amount"
        {...formik.getFieldProps("amount")}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        title="Quantity"
        {...formik.getFieldProps("quantity")}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        type="date"
        title="Start Date"
        {...formik.getFieldProps("start_date")}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        type="date"
        title="End Date"
        {...formik.getFieldProps("end_date")}
      />
      <Box mb={2} />
      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          title="Save"
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
      <Box mb={2} />
    </FormContainer>
  );
}
