import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DeviceFrame } from "../../../../../components/containers/DeviceFrame";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { getDevices } from "../../../../../store/device/deviceAction";
import { Box } from "@mui/system";
import ComboBox from "../../../../../components/select/ComboBox";
import palette from "../../../../../theme/palette";
import BasicInput from "../../../../../components/input/BasicInput";
import { motion } from "framer-motion";
import formatWppMarkdown from "whatsapp-markdown";
import toast from 'react-hot-toast';
import Iconify from "../../../../../components/Iconify";
import BasicButton from "../../../../../components/button/BasicButton";
import CustomModal from "../../../../../components/modal/CustomModal";
import Upload from "./Upload";
import SaveCancelContainer from "../../../../../components/containers/SaveCancelContainer";
import EmojiPicker, { Emoji, EmojiStyle } from "emoji-picker-react";
import { updateGreetingMessage } from "../../../../../store/marketing/marketingAction";

const patternData = [
  { name: "Message Contain", key: 0 },
  { name: "Exact Match", key: 1 },
];

const messageTypeData = [
  { name: "Text", key: "text" },
  { name: "Text With Button", key: "button" },
  { name: "Custom Templates", key: "template" },
  { name: "Lists", key: "lists" },
  { name: "Reaction", key: "reaction" },
];

export default function EditGreetingMessage(props) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { user } = useSelector((state) => state.user);
  const { greetingMessage } = useSelector((state) => state.marketing);

  const [typeMessage, setTypeMessage] = useState(null);

  const [patternMatching, setPatternMatching] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      device: [],
      equals: "",
      reply_type: "",
      reply: "",
      fileSelected: "",
      footer: "",
      isVoice: false,
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      let data = null;

      const device = [];

      value.device.map((item) => {
        device.push(item.device_key);
      });

      data = {
        id: greetingMessage?.id,
        device: device,
        keyword: value.keyword,
        equals: value.equals,
        reply_type: value.reply_type,
        reply:
          typeMessage?.key !== "reaction"
            ? value.reply
            : String.fromCodePoint(parseInt(value.reply, 16)),
        file: null,
        footer: value.footer,
        isVoice: value.isVoice,
      };

      data.user_id = user?.id;
      data.file = value.fileSelected
        ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
        : null;

      await dispatch(updateGreetingMessage(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          setTypeMessage(null);

          props.setEditGreetingMessage();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    },
  });

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp",
      },
    };

    await dispatch(getDevices(body));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values]);

  useEffect(() => {
    if (greetingMessage) {
      const devicesRaw = [];

      greetingMessage?.device_key?.map((item) => {
        const device = devices.find((e) => e.device_key === item);

        if (device) {
          devicesRaw.push(device);
        }
      });

      formik.setFieldValue("keyword", greetingMessage?.keyword);
      formik.setFieldValue("reply_type", greetingMessage?.reply_type);
      formik.setFieldValue("equals", greetingMessage?.equals);
      formik.setFieldValue("device", devicesRaw);

      if (greetingMessage?.reply_type === "reaction") {
        formik.setFieldValue(
          "reply",
          greetingMessage?.answer.codePointAt(0).toString(16)
        );
      } else {
        formik.setFieldValue("reply", greetingMessage?.answer);
      }

      setPatternMatching(
        patternData?.find((e) => e.key === greetingMessage?.equals)
      );
      setTypeMessage(
        messageTypeData?.find((e) => e.key === greetingMessage?.reply_type)
      );
    }
  }, [greetingMessage]);

  return (
    <SaveCancelContainer
      title="Edit Greeting Message"
      buttonCancelTitle="Close"
      buttonSaveTitle="Submit"
      buttonDisabled={!formik.values.reply.length}
      onCancel={() => props.setEditGreetingMessage()}
      onSave={formik.handleSubmit}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} minHeight="540px">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Box>
                  <Typography fontWeight="700" mb={0.5}>
                    Device
                  </Typography>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={[{ name: "All", device_key: "all" }].concat(
                      devices
                    )}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    ChipProps={{
                      style: {
                        backgroundColor: palette.primary.main,
                        color: palette.secondary.main,
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Plase select device"
                        size="small"
                      />
                    )}
                    value={formik.values.device}
                    onChange={(e, value) => {
                      formik.setFieldValue("device", value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <BasicInput
                  fullWidth
                  disableMargin
                  title="Keyword"
                  placeholder="Type message in here ..."
                  {...formik.getFieldProps("keyword")}
                />
                <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                  use coma "," or Enter as a multi-keyword separator
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  options={patternData ?? []}
                  getOptionLabel={(option) => option?.name}
                  title="Pattern Matching"
                  placeholder={"Choose Pattern Matching"}
                  value={patternMatching}
                  onChange={(e, value) => {
                    formik.setFieldValue("equals", value.key);
                    setPatternMatching(value);
                  }}
                />
                <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                  What is this Pattern Matching about?{"  "}
                  <a
                    href="https://help.realm.chat/?docs=tips/pattern-matching"
                    style={{
                      textDecoration: "none",
                      color: "blue",
                      cursor: "pointer",
                    }}
                  >
                    Read Here
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  options={messageTypeData ?? []}
                  getOptionLabel={(option) => option?.name}
                  title="Reply Type / Message Type"
                  placeholder={"Choose type message"}
                  value={typeMessage}
                  onChange={(e, value) => {
                    formik.setFieldValue("reply_type", value.key);
                    setTypeMessage(value);
                  }}
                />
              </Grid>
              {typeMessage ? (
                <Grid item xs={12} sm={12}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {typeMessage?.key !== "reaction" ? (
                      <Box mb={2}>
                        <Box>
                          <BasicInput
                            fullWidth
                            disableMargin
                            multiline
                            rows={5}
                            placeholder="Type message in here ..."
                            {...formik.getFieldProps("reply")}
                          />
                        </Box>
                      </Box>
                    ) : null}
                  </motion.div>
                </Grid>
              ) : null}
              {typeMessage?.key === "reaction" ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BasicButton onClick={() => setShowEmoji(!showEmoji)}>
                      <Iconify
                        icon="ic:outline-emoji-emotions"
                        height="24px"
                        width="24px"
                      />
                    </BasicButton>
                    <Box sx={{ m: "0 0 0 16px" }}>
                      <Emoji
                        unified={formik.values.reply}
                        emojiStyle={EmojiStyle.APPLE}
                        size={32}
                      />
                    </Box>
                  </Box>
                  {showEmoji ? (
                    <Box
                      sx={{
                        margin: "12px 0 0 0",
                        position: "absolute",
                        zIndex: "99",
                      }}
                    >
                      <EmojiPicker
                        onEmojiClick={(emojiData) => {
                          formik.setFieldValue("reply", emojiData.unified);
                        }}
                      />
                    </Box>
                  ) : null}
                </Grid>
              ) : null}
              {typeMessage?.key !== "lists" &&
              typeMessage?.key !== "reaction" ? (
                <Grid item xs={12} sm={12}>
                  <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                    Attachment (Optional)
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BasicButton
                      title="Choose File"
                      onClick={() => setOpenUploadModal(true)}
                    />
                    <Typography color="#131313" sx={{ ml: 2 }}>
                      {formik?.values?.fileSelected?.file_name}
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
              {formik.values.fileSelected?.file_type === "audio" ? (
                <Grid item xs={12} sm={12}>
                  <BasicInput
                    type="checkbox"
                    title="Send as Voice Note"
                    sx={{ width: "24px" }}
                    onChange={() => {
                      formik.setFieldValue(
                        "isVoice",
                        !formik.values.isVoice ? true : false
                      );
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <DeviceFrame
            single={{
              fileSelected: formik.values.fileSelected,
              isVoice: formik.values.isVoice,
              typeMessage: typeMessage,
            }}
            setFooter={(value) => formik.setFieldValue("footer", value)}
          >
            {typeMessage?.key !== "reaction" ? (
              <p id="chat-message" style={{ whiteSpace: "pre-line" }}>
                {formik.values.reply.split("\n").join("<br />")}
              </p>
            ) : (
              <Emoji
                unified={formik.values.reply}
                emojiStyle={EmojiStyle.APPLE}
                size={24}
              />
            )}
          </DeviceFrame>
        </Grid>
      </Grid>
      <CustomModal
        handleClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
      >
        <Upload onCancle={() => setOpenUploadModal(false)} formik={formik} />
      </CustomModal>
    </SaveCancelContainer>
  );
}
