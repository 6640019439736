import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

const styleWrap = {
  position: "absolute",
  top: "50%",
  left: "50%",
  alignItems: "center",
  transform: "translate(-50%, -50%)",
  width: { xs: "calc(100% - 24px)", sm: 400, md: 600 },
  bgcolor: "transparent",
  overflow: "auto",  // Add overflow: "auto" to enable scrolling
};

export default function CustomModal({ children, open, handleClose, style = styleWrap }) {
  return (
    <div>
      <Modal
        disableAutoFocus={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} >
          <Box sx={style}>{children}</Box>
        </Fade>
      </Modal>
    </div>
  );
}
