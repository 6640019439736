import InputLimitText from "../../../../../../../../../components/input/InputLimitText";
import TemplateSection from "../../../../../TemplateSection";

export default function Footer({
    changeFooter,
    footerValue
}) {
    return (
        <TemplateSection
            title="Footer"
            desc="Add a short line of text to the bottom of your message template."
            isOptional={true}
        >
            <InputLimitText
                onChange={changeFooter}
                value={footerValue}
                length={footerValue.length}
                limit={60}
                width={'100%'}
            />
        </TemplateSection>
    )
}