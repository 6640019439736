import React, { useState } from "react";
import ContainerWithSomeButton from "../../../components/containers/ContainerWithSomeButton";
import List from "./List";
import AddTemplate from "./add-template";
import EditTemplate from "./edit-template";

export default function Template() {
  const [addTemplate, setAddTemplate] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);

  return (
    <div>
      {addTemplate ? (
        <AddTemplate setAddTemplate={() => setAddTemplate(!addTemplate)} />
      ) : editTemplate ? (
        <EditTemplate setEditTemplate={() => setEditTemplate(!editTemplate)} />
      ) : (
        <ContainerWithSomeButton
          title="Message Templates"
          button1
          button1OnClick={() => setAddTemplate(!addTemplate)}
          button1Title="Create New"
          button1Icon="carbon:add-alt"
        >
          <List setEditTemplate={() => setEditTemplate(!editTemplate)} />
        </ContainerWithSomeButton>
      )}
    </div>
  );
}
