import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getAllWabaTemplate = createAsyncThunk(
  "waba-template",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("waba-template", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWabaTemplate = createAsyncThunk(
  "waba-template/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("waba-template/detail", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resetWabaTemplate = createAsyncThunk(
  'reset-waba-template',
  async (_, { rejectWithValue }) => {
    return []
  }
)

export const fetchWabaTemplate = createAsyncThunk(
  'waba-template/fetch',
  async (data, { rejectWithValue }) => {
    try {
      const result = await clientApi.post('waba-template/fetch', data)

      if (result.data.result) {
        return result.data
      } else {
        return rejectWithValue(result.data)
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const addWabaTemplate = createAsyncThunk(
  'waba-template/add',
  async (data, { rejectWithValue }) => {
    try {
      const result = await clientApi.post('waba-template/add', data)

      if (result.data.result) {
        return result.data
      } else {
        return rejectWithValue(result.data)
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)