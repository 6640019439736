import { Box, Typography } from "@mui/material";
import Iconify from "./Iconify";

function RenderDesc({ desc }) {
    return <p
        dangerouslySetInnerHTML={{ __html: desc }}
    >

    </p>
}

export default function WarningContent({
    title = 'We recommend adding an opt-out button',
    desc = 'Enable customers to request to reject all marketing messages. This can help reduce blocking from customers and improve your quality rating.',
    icon = 'lets-icons:lamp-light',
    colorWarning = '#4DBBA6',
    background,
    isBorderLeft = true,
    mt,
    colorDesc = '#000',
    colorTitle = '#000',
    children
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 1,
                overflow: 'hidden',
                maxWidth: 700,
                background: background
            }}
            mb={2}
            mt={mt}
        >
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Box
                    sx={{
                        borderLeft: isBorderLeft ? `4px solid ${colorWarning}` : 'none'
                    }}
                >
                    <Iconify
                        icon={icon}
                        sx={{
                            marginLeft: 1,
                            marginTop: 2,
                            color: colorWarning,
                            fontSize: 25
                        }}
                    />
                </Box>
                <Box
                    py={2}
                    px={1}
                >
                    <Typography
                        fontSize={{
                            xs: 14,
                            sm: 16,
                            md: 16
                        }}
                        fontWeight={700}
                        color={colorTitle}
                    >
                        {title}
                    </Typography>
                    <Typography
                        fontSize={{
                            xs: 12.5,
                            sm: 14,
                            md: 14
                        }}
                        fontWeight={400}
                        color={colorDesc}
                        mt={1}
                    >
                        <RenderDesc desc={desc} />
                    </Typography>
                </Box>
            </Box>
            {children}
        </Box>
    )
}