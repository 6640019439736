import { Box, Typography } from "@mui/material";
import Connect from "../Connect";
import NoAccount from "../NoAccount";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeviceChannel from "../device-channel/DeviceChannel";

export default function EmailChannel({
    loading
}) {
    const { devices } = useSelector((state) => state.device)
    const navigate = useNavigate()

    function clickConnect(){
        navigate('/dashboard/channels/connect-email')
    }

    return (
        <>
            <Connect
                icon="email.png"
                title="Email"
                textBtn="Connect"
                clickConnect={clickConnect}
            />

            {!loading && devices?.length > 0 ?
                <Box
                    p={2}
                >
                    <Typography
                        fontSize={{ xs: 16, sm: 16, md: 16 }}
                        fontWeight={600}
                        color={"#131313"}
                        mt={3}
                        mb={2}
                    >
                        Accounts
                    </Typography>

                    {devices.map((item, i) => {
                        return (
                            <DeviceChannel
                                key={i}
                                item={item}
                                icon="email.png"
                                platform="email"
                            />
                        )
                    })}
                </Box> :
                <>
                    {!loading ?
                        <NoAccount
                            desc={`Click "Connect" to sync your first Email account`}
                        /> :
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 400
                            }}
                        >
                            <Box
                                component="img"
                                width={40}
                                height={40}
                                src="/images/loading.svg"
                            />
                        </Box>
                    }
                </>
            }
        </>
    )
}