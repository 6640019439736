import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import CustomerService from "../sections/dashboard/chat-team/CustomersService";
import LogActivity from "../sections/dashboard/chat-team/LogActivity";

export default function TeamInbox() {
  const [pages, setPages] = React.useState({
    name: "Customer Service",
    component: <CustomerService />,
  });

  const items = [
    {
      name: "Customer Service",
      component: <CustomerService />,
    },
    // {
    //   name: "Log Activity",
    //   component: <LogActivity />,
    // },
  ];

  return (
    <Page title="Customer Service">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Customer Service" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <Stack direction="row" spacing={1}>
              {items.map((v, i) => {
                return (
                  <Button
                    variant={pages.name === v.name ? "contained" : "text"}
                    key={i}
                    onClick={() => setPages(v)}
                    sx={
                      pages?.name === v.name
                        ? {
                            background: "GRADIENTS.custom",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                        : { color: "#7b7b7b" }
                    }
                  >
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                      {" "}
                      {v.name}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
          </Box>
          <Box mt={3}>{pages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
