import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getCloudFile = createAsyncThunk(
"cloud-file",
  async (data, { rejectWithValue }) => {
    try {
    const response = await clientApi.get("cloud-file", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadCloudFile = createAsyncThunk(
  "cloud-file/upload",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("cloud-file/upload", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCloudFile = createAsyncThunk(
  "cloud-file/delete",
  async ({ id }, { rejectWithValue }) => {
    try {
      const data = {
        id: id,
      };

      const response = await clientApi.post("cloud-file/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMultipleCloudFile = createAsyncThunk(
  "cloud-file/delete/multiple",
  async ({ ids }, { rejectWithValue }) => {
    try {
      const data = {
        ids: ids,
      };

      const response = await clientApi.post("cloud-file/delete/multiple", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
