import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"

export default function RadioInputCategory({
    inputs,
    radioInputValue,
    clickInput
}) {
    return (
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={radioInputValue?.value}
                name="radio-buttons-group"
            >
                {inputs?.map((item, i) => {
                    return (
                        <FormControlLabel
                            key={i}
                            value={item.value}
                            onClick={(e)=>{
                                clickInput(item)
                                e.stopPropagation()
                            }}
                            control={
                                <Radio
                                    color="info"
                                    size="small"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: {
                                                xs: 18,
                                                sm: 20,
                                                md: 20
                                            },
                                        },
                                    }}
                                />
                            }
                            label={
                                <Box
                                    mt={3}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: 12,
                                                sm: 14,
                                                md: 14
                                            },
                                            textAlign: 'start'
                                        }}
                                    >
                                        {item.label}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: 12,
                                                sm: 14,
                                                md: 14
                                            },
                                            textAlign: 'start'
                                        }}
                                    >
                                        {item.desc}
                                    </Typography>
                                </Box>
                            }
                            style={{
                                color: '#212B36',
                            }}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    )
}