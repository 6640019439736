import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import MenuWhatsAppWeb from "./whatsapp";
import MenuCloudAPICatalogSetting from "./cloud-api";
import MenuCloudAPI from "./cloud-api/NotCatalogListing";
import MenuInstagramCatalogSetting from "./instagram";
import MenuInstagram from "./instagram/NotCatalogListing";
import MenuEmail from "./email";
import MenuTelegram from "./telegram";
import MenuFacebook from "./facebook";

export default function MobileMode({
    icon,
    wabaStatus,
    item,
    status,
    platform,
    navigate,
    isFarming,
    login,
    handleLogoutDevice,
    handleGetQrDevice,
    setQrModal,
    handleDeleteFarming,
    handleCreateFarming,
    handleDeleteDevice,
    handleUpdateCatalogSetting,
    handleAddCatalogWaba,
    handleUpdateCatalogSettingInstagram,
    handleAddCatalogInstagram,
    handleUnlinkDevice,
    handleAddTelegramLink,
    borderRadius,
    borderBottom,
    padding,
    paddingSummary,
    paddingDetails,
}) {
    return (
        <Accordion
            sx={{
                background: '#EEF0FA',
                borderRadius: borderRadius,
                padding: padding,
                marginBottom: 1,
                borderBottom: borderBottom
            }}
        >
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                    padding: paddingSummary
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        fontWeight={600}
                        fontSize={14}
                    >
                        {(wabaStatus
                            ? `${item.name} [ ${wabaStatus?.verified_name} - ${wabaStatus?.code_verification_status} ]`
                            : item.name
                        ).length > 24
                            ? (wabaStatus
                                ? `${item.name} [ ${wabaStatus?.verified_name} - ${wabaStatus?.code_verification_status} ]`
                                : item.name
                            ).substr(0, 24) + "..."
                            : (wabaStatus
                                ? `${item.name} [ ${wabaStatus?.verified_name} - ${wabaStatus?.code_verification_status} ]`
                                : item.name
                            ).substr(0, 24)}
                    </Typography>
                    <Icon
                        icon="carbon:dot-mark"
                        sx={{
                            height: 11,
                            width: 11,
                            borderRadius: 500,
                            background:
                                status === "Connected"
                                    ? "#37E849"
                                    : status === "Disconnected" || status === "Logged Out"
                                        ? "#F15A28"
                                        : "#f5cd3d"
                        }}
                    />
                </Box>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    padding: paddingDetails
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <Box
                        component="img"
                        src={`/device/${icon}`}
                        sx={{ width: "40px", minWidth: "40px" }}
                    />
                </Box>
                <Typography variant="body2" color="text.secondary">
                    Phone: <b>{item.phone}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Device Key: <b>{item.device_key}</b>
                </Typography>
                <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 2, alignItems: 'center' }}
                >
                    <Box>
                        <Typography
                            width="100px"
                            fontWeight="700"
                            color={
                                status === "Connected"
                                    ? "#37E849"
                                    : status === "Disconnected" || status === "Logged Out"
                                        ? "#F15A28"
                                        : "#f5cd3d"
                            }
                        >
                            {status}
                        </Typography>
                    </Box>

                    {platform == 'whatsapp' &&
                        <MenuWhatsAppWeb
                            status={status}
                            isFarming={isFarming}
                            login={login}
                            handleLogoutDevice={handleLogoutDevice}
                            handleGetQrDevice={handleGetQrDevice}
                            setQrModal={setQrModal}
                            handleCreateFarming={handleCreateFarming}
                            handleDeleteFarming={handleDeleteFarming}
                            handleDeleteDevice={handleDeleteDevice}
                        />
                    }
                    {platform == 'waba' && item?.catalog_id &&
                        <MenuCloudAPICatalogSetting
                            status={status}
                            navigate={navigate}
                            item={item}
                            handleUpdateCatalogSetting={handleUpdateCatalogSetting}
                        />
                    }
                    {platform == 'waba' && !item?.catalog_id &&
                        <MenuCloudAPI handleAddCatalogWaba={handleAddCatalogWaba} />
                    }
                    {platform == 'instagram' && item?.catalog_id &&
                        <MenuInstagramCatalogSetting
                            status={status}
                            navigate={navigate}
                            item={item}
                            handleUpdateCatalogSettingInstagram={handleUpdateCatalogSettingInstagram}
                        />
                    }
                    {platform == 'instagram' && !item?.catalog_id &&
                        <MenuInstagram handleAddCatalogInstagram={handleAddCatalogInstagram} />
                    }
                    {platform == 'email' &&
                        <MenuEmail handleDeleteDevice={handleDeleteDevice} />
                    }
                    {platform == 'facebook' &&
                        <MenuFacebook
                            status={status}
                            isFarming={isFarming}
                            login={login}
                            handleLogoutDevice={handleLogoutDevice}
                            handleGetQrDevice={handleGetQrDevice}
                            setQrModal={setQrModal}
                            handleCreateFarming={handleCreateFarming}
                            handleDeleteFarming={handleDeleteFarming}
                            handleDeleteDevice={handleDeleteDevice}
                        />
                    }
                    {platform == 'telegram' &&
                        <MenuTelegram
                            item={item}
                            handleUnlinkDevice={handleUnlinkDevice}
                            handleAddTelegramLink={handleAddTelegramLink}
                        />
                    }
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}