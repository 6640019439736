import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import ScrollBar from "../components/Scrollbar";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import APIDocumentation from "../sections/dashboard/integration/api-integration";
import WebhookList from "../sections/dashboard/integration/webhook-list";

export default function Integration() {
  const [integrationPages, setIntegrationPages] = React.useState({
    name: "Webhook List",
    component: <WebhookList />,
  });

  const items = [
    {
      name: "Webhook List",
      component: <WebhookList />,
    },
    {
      name: "API Documentation",
      component: <APIDocumentation />,
    },
  ];

  return (
    <Page title="Integration">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Integration" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {items.map((v, i) => {
                  return (
                    <Button
                      variant={
                        integrationPages?.name === v.name ? "contained" : "text"
                      }
                      key={i}
                      onClick={() => setIntegrationPages(v)}
                      sx={
                        integrationPages?.name === v.name
                          ? {
                              background: "GRADIENTS.custom",
                              borderRadius: "24px",
                              color: "#fdfdfd",
                            }
                          : { color: "#7b7b7b" }
                      }
                    >
                      <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                        {v.name}
                      </Typography>
                    </Button>
                  );
                })}
              </Stack>
            </ScrollBar>
          </Box>
          <Box mt={3}>{integrationPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
