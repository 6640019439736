import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const sendMessage = createAsyncThunk(
  "send-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/send-message", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bulkMessage = createAsyncThunk(
  "bulk-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/bulk-message-new", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const stopBulkMessage = createAsyncThunk(
  "bulk-message/stop",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/bulk-stop", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const scheduleMessage = createAsyncThunk(
  "schedule-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/schedule-message-new", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const cancelScheduleMessage = createAsyncThunk(
  "schedule-message/cancel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/schedule-cancel", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAutoReplies = createAsyncThunk(
  "auto-reply",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("auto-reply", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAutoReplies2 = createAsyncThunk(
  "auto-reply/list-v2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get(`auto-reply/list-v2?device_key=${data?.device_key}&page=${data?.page ?? ''}`, data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateDeviceAutoReply = createAsyncThunk(
  "auto-reply/update-device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("update/device", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addAutoReply = createAsyncThunk(
  "auto-reply/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addAutoReply2 = createAsyncThunk(
  "auto-reply/add-v2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/add-v2", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAutoReply = createAsyncThunk(
  "auto-reply/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAutoReply2 = createAsyncThunk(
  "auto-reply/update-v2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/update-v2", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAutoReplyOld = createAsyncThunk(
  "auto-reply/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAutoReply = createAsyncThunk(
  "auto-reply/delete-v2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("auto-reply/delete-v2", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getGreetingMessages = createAsyncThunk(
  "greeting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("greeting", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addGreetingMessage = createAsyncThunk(
  "greeting/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("greeting/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateGreetingMessage = createAsyncThunk(
  "greeting/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("greeting/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteGreetingMessage = createAsyncThunk(
  "greeting/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("greeting/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bulkEmail = createAsyncThunk(
  "bulk-email/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/bulk-email/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bulkWaba = createAsyncThunk(
  "bulk-waba/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/bulk-waba/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
