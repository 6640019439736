import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getMetaData = createAsyncThunk(
  "device/data-meta",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/data-meta", data);
      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateMetaData = createAsyncThunk(
  "device/update-meta",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/update-meta", data);
      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getPaymentGateways = createAsyncThunk(
  "catalog/payment-gateway",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/payment-gateway", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaymentGatewayStatus = createAsyncThunk(
  "catalog/payment-gateway/update-status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/payment-gateway/update-status",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePaymentGateway = createAsyncThunk(
  "catalog/payment-gateway/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/payment-gateway/update",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogAddresses = createAsyncThunk(
  "catalog/address",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/address", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCatalogAddress = createAsyncThunk(
  "catalog/address/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/address/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCatalogAddress = createAsyncThunk(
  "catalog/address/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/address/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getShippingGateways = createAsyncThunk(
  "catalog/shipping-gateway",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/shipping-gateway", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateShippingGateway = createAsyncThunk(
  "catalog/shipping-gateway/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/shipping-gateway/update",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateShippingGatewayRate = createAsyncThunk(
  "catalog/shipping-gateway/update-rate/",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/shipping-gateway/update-rate/",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateShippingGatewayStatus = createAsyncThunk(
  "catalog/shipping-gateway/update-status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/shipping-gateway/update-status",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateShippingGatewayCourier = createAsyncThunk(
  "catalog/shipping-gateway/update-courier",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/shipping-gateway/update-courier",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogProducts = createAsyncThunk(
  "catalog/product",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/product", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createCatalogProduct = createAsyncThunk(
  "catalog/product/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/product/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchCatalogProducts = createAsyncThunk(
  "catalog/product/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/product/fetch", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const duplicateCatalogProducts = createAsyncThunk(
  "catalog/product/duplicate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/product/duplicate", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCatalogProducts = createAsyncThunk(
  "catalog/product/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/product/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateQuantityProducts = createAsyncThunk(
  'catalog/product/update-qty',
  async (data, {rejectWithValue})=>{
    try {
      const result = await clientApi.post('catalog/product/update-qty', data)
      if(result?.data?.result){
        return result.data
      }
      return rejectWithValue(result.data)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const updateAttributeProducts = createAsyncThunk(
  'catalog/product/update-attribute',
  async (data, {rejectWithValue})=>{
    try {
      const result = await clientApi.post('catalog/product/update-attribute', data)
      if(result?.data?.result){
        return result.data
      }
      return rejectWithValue(result.data)
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const deleteCatalogProduct = createAsyncThunk(
  "catalog/product/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/product/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogVouchers = createAsyncThunk(
  "catalog/voucher",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/voucher", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addCatalogVoucher = createAsyncThunk(
  "catalog/voucher/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/voucher/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCatalogVoucher = createAsyncThunk(
  "catalog/voucher/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/voucher/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCatalogVoucher = createAsyncThunk(
  "catalog/voucher/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/voucher/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogOrders = createAsyncThunk(
  "catalog/order",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/order", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogOrder = createAsyncThunk(
  "catalog/order/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/order/detail", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCatalogOrdersMessage = createAsyncThunk(
  "catalog/order-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/catalog/order-message", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCatalogOrderMessage = createAsyncThunk(
  "catalog/order-message/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/order-message/update",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCatalogOrderMessage = createAsyncThunk(
  "catalog/order-message/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post(
        "catalog/order-message/delete",
        data
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCatalogSetting = createAsyncThunk(
  "catalog/official/setting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("catalog/official/setting", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
