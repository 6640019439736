import { Box, Card, IconButton, Typography } from "@mui/material";
import Iconify from "../../../components/Iconify";
import BasicButton from "../../../components/button/BasicButton";

function DesktopMode({
    clickBack,
    icon,
    isBackBtn,
    iconDesc,
    desc,
    title
}) {
    return (
        <Box
            sx={{
                // display: {
                //     xs: 'none',
                //     sm: 'flex',
                //     md: 'flex'
                // },
                display: 'flex',
                alignItems: 'center'
            }}
        >
            {isBackBtn &&
                <IconButton
                    onClick={clickBack}
                >
                    <Iconify
                        icon={"solar:arrow-left-outline"}
                        color="#1A776F"
                        width={{
                            xs: 20,
                            sm: 24,
                            md: 24
                        }}
                        height={{
                            xs: 20,
                            sm: 24,
                            md: 24
                        }}
                    />
                </IconButton>
            }

            <Box
                mx={1}
                component="img"
                src={`/device/${icon}`}
                sx={{
                    width: { xs: "50px", md: "50px" },
                    height: { xs: "50px", md: "50px" },
                    objectFit: "contain",
                }}
            />

            <Box>
                <Typography
                    fontSize={{ xs: 16, sm: 16, md: 20 }}
                    fontWeight="700"
                    color="#131313"
                >
                    {title}
                </Typography>
                {iconDesc &&
                    <Box
                        component="img"
                        mt={1}
                        src={`/device/${iconDesc}`}
                        sx={{
                            width: '120px',
                            objectFit: 'contain'
                        }}
                    />
                }
                {desc &&
                    <Typography
                        fontSize={{
                            xs: 10,
                            sm: 12,
                            md: 14
                        }}
                        fontWeight={400}
                        color="#7B7B7B"
                    >
                        {desc}
                    </Typography>
                }
            </Box>
        </Box>
    )
}

function MobileMode({
    clickBack,
    icon,
    isBackBtn,
    iconDesc,
    desc,
    title
}) {
    return (
        <Box
            sx={{
                display: {
                    xs: 'flex',
                    sm: 'none',
                    md: 'none'
                },
            }}
        >
            {isBackBtn &&
                <IconButton
                    onClick={clickBack}
                >
                    <Iconify
                        icon={"solar:arrow-left-outline"}
                        color="#1A776F"
                        width={{
                            xs: 20,
                            sm: 24,
                            md: 24
                        }}
                        height={{
                            xs: 20,
                            sm: 24,
                            md: 24
                        }}
                    />
                </IconButton>
            }

            <Box

            >
                <Box
                    sx={{
                        display: 'flex',
                    }}
                >
                    <Box
                        mx={1}
                        component="img"
                        src={`/device/${icon}`}
                        sx={{
                            width: { xs: "50px", md: "50px" },
                            height: { xs: "50px", md: "50px" },
                            objectFit: "contain",
                        }}
                    />
                    <Typography
                        fontSize={{ xs: 16, sm: 16, md: 20 }}
                        fontWeight="700"
                        color="#131313"
                    >
                        {title}
                    </Typography>
                </Box>

                <Box>
                    {iconDesc &&
                        <Box
                            component="img"
                            mt={1}
                            src={`/device/${iconDesc}`}
                            sx={{
                                width: '120px',
                                objectFit: 'contain'
                            }}
                        />
                    }
                    {desc &&
                        <Typography
                            fontSize={{
                                xs: 10,
                                sm: 12,
                                md: 14
                            }}
                            fontWeight={400}
                            color="#7B7B7B"
                        >
                            {desc}
                        </Typography>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default function Connect({
    icon,
    title,
    textBtn,
    iconDesc,
    isBackBtn,
    clickConnect,
    clickBack,
    desc,
}) {
    return (
        <Card
            sx={{
                background: '#FFF',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center",
                padding: 2,
            }}
        >
            <DesktopMode
                clickBack={clickBack}
                icon={icon}
                isBackBtn={isBackBtn}
                iconDesc={iconDesc}
                desc={desc}
                title={title}
            />
            {/* <MobileMode
                clickBack={clickBack}
                icon={icon}
                isBackBtn={isBackBtn}
                iconDesc={iconDesc}
                desc={desc}
                title={title}
            /> */}

            {textBtn &&
                <Box>
                    <BasicButton
                        onClick={clickConnect}
                    >
                        <Typography fontSize={{ xs: 10, sm: 14 }}>
                            {textBtn}
                        </Typography>
                    </BasicButton>
                </Box>
            }
        </Card>
    )
}