import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Iconify from "../../components/Iconify";

export default function Notification() {
  return (
    <Box textAlign="center">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Typography variant="h6">Notifications</Typography>
        <Button variant="text">Clear all notification</Button>
      </Box>
      <Box p={6}>
        <Iconify icon="iconoir:message-text" style={{ width: 90, height: 90, color: "#7B7B7B" }} />
        <Typography color="#7B7B7B" fontWeight="600" fontSize={16}>
          No Notification
        </Typography>
        <Typography color="#7B7B7B">The notification will show here</Typography>
      </Box>
      <Button variant="text">
        <Typography color="#7B7B7B">Mark as read</Typography>
      </Button>
    </Box>
  );
}
