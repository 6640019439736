import { useNavigate } from "react-router-dom";
import ContainerWithSomeButton from "../../../../../components/containers/ContainerWithSomeButton";
import Connect from "../../Connect";
import { Box, Typography } from "@mui/material";
import WarningContent from "../../../../../components/WarningContent";
import TextButton from "../../../../../components/button/TextButton";
import BasicButton from "../../../../../components/button/BasicButton";
import { useFormik } from "formik";
import { useState } from "react";
import BasicInput from "../../../../../components/input/BasicInput";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addDevice } from "../../../../../store/device/deviceAction";

function RenderNoted({ desc }) {
    return <p dangerouslySetInnerHTML={{ __html: desc }}>

    </p>
}

export default function ConnectTelegram() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            accessToken: '',
        },
        onSubmit: async (value, { resetForm }) => {
            setLoading(true)

            const data = {
                platform: 'telegram',
                name: value.name ?? null,
                access_token: value.accessToken ?? null,
            }

            dispatch(addDevice(data)).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();
                    navigate('/dashboard/channels')
                } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                }

                setLoading(false);
            });
        }
    })

    function clickBack() {
        navigate('/dashboard/channels')
    }

    return (
        <ContainerWithSomeButton>
            <Connect
                icon="telegram.png"
                title="Telegram"
                isBackBtn={true}
                clickBack={clickBack}
                desc="Sign in to your Telegram account and fill in the required fields below to connect your Telegram to Komers."
            />

            <Box
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    },
                    marginRight: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    }
                }}
            >
                <WarningContent
                    title="Tips"
                    desc="To connect with Telegram, a <b>Telegram Bot</b> is reuired.
                Please register an account on Telegram and create a Telegram Bot."
                    background='#64D88B14'
                    isBorderLeft={false}
                    colorWarning="#7B7B7B"
                    mt={4}
                    colorDesc="#7B7B7B"
                    colorTitle="#7B7B7B"
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        ml={4}
                        mb={1}
                    >
                        <TextButton title="View Guide" />
                    </Box>
                </WarningContent>

                <Box
                    mt={4}
                    mb={2}
                >
                    <BasicInput
                        title="Name"
                        fullWidth
                        disableMargin
                        id="name"
                        placeholder="Type name in here ..."
                        value={formik.values.name}
                        {...formik.getFieldProps("name")}
                    />
                    <Box mb={2} />
                    <BasicInput
                        title="Access Token"
                        fullWidth
                        disableMargin
                        id="accessToken"
                        placeholder="Type access token in here ..."
                        value={formik.values.accessToken}
                        {...formik.getFieldProps("accessToken")}
                    />
                    <Box mb={2} />
                </Box>

                <Typography
                    fontSize={12}
                    fontWeight={400}
                    textAlign="start"
                    color="#7B7B7B"
                >
                    <RenderNoted desc={
                        `Search <span style="color: #6CA0DC;">@BotFather on Telegram and open the chat. Click on the button "Start" and type the command /newbot on the chat to create a bot. After that, you'll have to define a name, as well as a username for your bot. Finally, you will receive a confirmation message with your bot token and other info.</span>`
                    } />
                </Typography>

                <Box mt={6} mb={4}>
                    <BasicButton
                        type="submit"
                        title="Create"
                        disabled={loading}
                        onClick={formik.handleSubmit}
                    />
                </Box>
            </Box>
        </ContainerWithSomeButton>
    )
}