import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getCatalogOrdersMessage,
  updateShippingGatewayRate,
} from "../../../../store/catalog/catalogAction";
import { getRegions } from "../../../../store/country/countryAction";

export default function ShippingRateForm({ onCancel }) {
  const dispatch = useDispatch();

  const { regions } = useSelector((state) => state.country);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "ninjavan",
      target: null,
      rate: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        name: value.name,
        target: value.target?.name,
        rate: value.rate,
      };

      await dispatch(updateShippingGatewayRate(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
      handleGetCatalogOrdersMessage();
    },
  });

  const handleGetRegions = async () => {
    await dispatch(getRegions());
  };

  const handleGetCatalogOrdersMessage = async () => {
    await dispatch(getCatalogOrdersMessage());
  };

  useEffect(() => {
    handleGetRegions();
  }, []);

  return (
    <FormContainer title="Add Shipping Rate">

      <Box mb={2} />
      <Box sx={{ position: "relative", height: "68px" }}>
        <Box
          sx={{ position: "fixed", width: "calc(100% - 40px)", zIndex: "1400" }}
        >
          <ComboBox
            fullWidth
            disableMargin
            title="Region"
            placeholder="Please select region"
            options={regions}
            getOptionLabel={(option) => option?.name}
            value={formik.values.target}
            onChange={(e, value) => {
              formik.setFieldValue("target", value);
            }}
          />
        </Box>
      </Box>
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        title="Shipping "
        placeholder="Comma separated list (weight:shipping cost,weight:shipping cost)"
        {...formik.getFieldProps("rate")}
      />
      <Box mb={2} />
      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          title="Save"
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
    </FormContainer>
  );
}
