import { useSelector } from "react-redux";
import Connect from "../Connect";
import NoAccount from "../NoAccount";
import { Box, Typography } from "@mui/material";
import ModalWaba from "../../../../components/DeviceItem/sections/ModalWaba";
import { useNavigate } from "react-router-dom";
import DeviceChannel from "../device-channel/DeviceChannel";
import DeviceGroup from "../device-channel/cloud-api/DeviceGroup";
import { useMemo } from "react";

export default function CloudAPI({
    loading
}) {
    const { devices } = useSelector((state) => state.device)

    const navigate = useNavigate()

    function clickConnect() {
        navigate('/dashboard/channels/connect-cloud-api')
    }

    const listDeviceGroup = useMemo(() => {
        if (devices.length === 0) {
            return []
        }
        let group = []
        devices.forEach(item => {
            // if already have list group of official_id
            const findCurrent_official_id = group.find(data => data.official_id === item.official_id)
            if (findCurrent_official_id) {
                const getIndex_official_id = group.findIndex(data => data.official_id === item.official_id)
                group[getIndex_official_id].data.push(item)
            } else {
                group.push({
                    name: `${item.name} [Official Id - ${item.official_id}]`,
                    official_id: item.official_id,
                    data: [item]
                })
            }
        })
        return group
    }, [devices])

    return (
        <>
            <ModalWaba />
            <Connect
                icon="cloud-api.png"
                title="Cloud API"
                textBtn="Connect"
                clickConnect={clickConnect}
            />

            {!loading && devices?.length > 0 ?
                <Box
                    p={2}
                >
                    <Typography
                        fontSize={{ xs: 16, sm: 16, md: 16 }}
                        fontWeight={600}
                        color={"#131313"}
                        mt={3}
                        mb={2}
                    >
                        Accounts
                    </Typography>

                    {listDeviceGroup.map((group, iGroup) => {
                        return (
                            <DeviceGroup
                                key={iGroup}
                                name={group.name}
                            >
                                {group.data.map((item, i) => {
                                    return (
                                        <DeviceChannel
                                            key={i}
                                            item={item}
                                            icon="cloud-api.png"
                                            platform="waba"
                                            borderRadius="0 !important"
                                            borderBottom="1px solid #ddd"
                                            padding="0.5rem 0 0.5rem 0"
                                            paddingDetails={0}
                                            paddingSummary={0}
                                            borderBottomDesktop="1px solid #ddd"
                                            borderRadiusDesktop={0}
                                            paddingDesktop="1rem 0 1rem 0"
                                        />
                                    )
                                })}
                            </DeviceGroup>
                        )
                    })}
                </Box> :
                <>
                    {!loading ?
                        <NoAccount
                            desc={`Click "Connect" to sync your first Cloud API account`}
                        /> :
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 400
                            }}
                        >
                            <Box
                                component="img"
                                width={40}
                                height={40}
                                src="/images/loading.svg"
                            />
                        </Box>
                    }
                </>
            }
        </>
    )
}