import { Box, Button, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Iconify from "../../../../components/Iconify";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import BasicInput from "../../../../components/input/BasicInput";
import { getCustomerServices } from "../../../../store/customer-service/customerServiceAction";
import { getDevices } from "../../../../store/device/deviceAction";
import { updateUser } from "../../../../store/user/userAction";
import { getBusiness } from "../../../../store/business/businessAction";
import ComboBox from "../../../../components/select/ComboBox";
import CustomModal from "../../../../components/modal/CustomModal";
import QRCode from "react-qr-code";
import palette from "../../../../theme/palette";

const planFeatures = [
  {
    label: "Send Message",
    value: "is_sendmessage",
  },
  {
    label: "WhatsApp Broadcast",
    value: "is_bulkmessage",
  },
  {
    label: "WhatsApp Schedule",
    value: "is_schedulemessage",
  },
  {
    label: "Auto Reply",
    value: "is_autoreply",
  },
  {
    label: "Api Access",
    value: "is_apiaccess",
  },
  {
    label: "Webhook",
    value: "is_webhook",
  },
  {
    label: "Custom Attribute",
    value: "custom_attribute",
  },
  // {
  //   label: "Contact Scrapping",
  //   value: "is_contactscraping",
  // },
  {
    label: "Copyright",
    value: "is_copyright",
  },
];

export default function Profile() {
  const dispatch = useDispatch();

  const { plan, user } = useSelector((state) => state.user);
  const { devices } = useSelector((state) => state.device);
  const { customerServices } = useSelector((state) => state.customerService);
  const { business } = useSelector((state) => state.business);

  const [isLoading, setIsLoading] = useState(false);

  const [isOpenForgotPasswordModal, setIsOpenForgotPasswordModal] =
    useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: async (values) => {
      toast.loading("Please wait...");
      setIsLoading(true);

      const selectedBusinessId = formik.values.business_id;

      const body = {
        ...values,
        business_id: selectedBusinessId,
      };

      await dispatch(updateUser(body)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
          setIsLoading(false);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
          setIsLoading(false);
        }
      });
    },
  });

  const handleGetDevices = async () => {
    await dispatch(getDevices());
  };

  const handleGetBusiness = async () => {
    await dispatch(getBusiness());
  };

  const handleGetCustomerService = async () => {
    await dispatch(getCustomerServices({ page: 1 }));
  };

  useEffect(() => {
    if (user) {
      formik.setFieldValue("name", user?.name);
    }
  }, [user]);

  useEffect(() => {
    handleGetDevices();
    handleGetCustomerService();
    handleGetBusiness();
  }, []);

  return (
    <div>
      <ContainerWithSomeButton title="Profile">
        <Box
          sx={{
            p: 2,
            background: "#ededed",
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#FFFFFF",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"material-symbols:workspace-premium"}
                    color="#242323"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Your Plan
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#242323">
                    {plan?.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#FFFFFF",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"solar:calendar-linear"}
                    color="#242323"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Expired
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#242323">
                    {plan?.expired_at}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#FFFFFF",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"material-symbols:workspace-premium"}
                    color="#242323"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Account Status
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#242323">
                    {user?.status}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#FFFFFF",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"ci:user-check"}
                    color="#242323"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Contact
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#242323">
                    {plan?.contacts_count}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            p: 2,
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                <Box>
                  <Box
                    sx={{
                      border: `4px solid #878787`,
                      borderRadius: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        px: 2,
                        py: 1,
                        background: "#242323",
                        borderRadius: "10px 10px 0 0",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          items: "center",
                        }}
                      >
                        <Typography variant="h5" color="#FFFFFF">
                          {plan?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ px: 2, py: 1 }}>
                      {planFeatures?.map((planFeature, index) => {
                        return (
                          <Box
                            sx={{
                              mb: 0.5,
                              display: "flex",
                              alignItems: "center",
                              opacity: `${plan[planFeature.value] ? 1 : 0.5}`,
                            }}
                            key={index}
                          >
                            <Box sx={{ mr: 1 }}>
                              {plan[planFeature.value] === "1" ? (
                                <Iconify
                                  icon="material-symbols:check"
                                  color="#383838"
                                  height={24}
                                  width={24}
                                />
                              ) : (
                                <Iconify
                                  icon="material-symbols:close"
                                  color="#FF0000"
                                  height={24}
                                  width={24}
                                />
                              )}
                            </Box>
                            <Typography
                              color="#7B7B7B"
                              fontWeight={600}
                              sx={{ mt: -1, textAlign: "left" }}
                            >
                              {planFeature?.value === "device"
                                ? plan[planFeature.value]
                                : ""}
                              {planFeature?.value === "customer_service"
                                ? plan[planFeature.value]
                                : ""}{" "}
                              {planFeature?.label}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ mb: 2, display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#383838",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"uil:mobile-android"}
                    color="#FFFFFF"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Total Device
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#383838">
                    {plan?.total_device} / {plan?.device}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mb: 2, display: "flex" }}>
                <Box
                  sx={{
                    mr: 2,
                    height: "48px",
                    width: "48px",
                    background: "#383838",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Iconify
                    icon={"ri:headphone-line"}
                    color="#FFFFFF"
                    width={24}
                    height={24}
                  />
                </Box>
                <Box>
                  <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                    Total Customer Service
                  </Typography>
                  <Typography fontSize="24px" fontWeight="700" color="#383838">
                    {plan?.total_cs} / {plan?.customer_service}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={9}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  General Settings
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      title="Name"
                      placeholder="Enter name"
                      {...formik.getFieldProps("name")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      title="Phone"
                      placeholder="Enter name"
                      value={user?.phone}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      title="Email"
                      placeholder="Enter name"
                      value={user?.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Company Info"
                      placeholder={user?.business}
                      options={business ?? []}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, value) => {
                        const selectedBusinessId = value?.id;
                        formik.setFieldValue("business_id", selectedBusinessId);

                        const body = Object.assign({}, value, {
                          business_id: selectedBusinessId,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <ButtonWithIcon
                        variant="contained"
                        disabled={isLoading}
                        onClick={formik.handleSubmit}
                      >
                        <Typography
                          ml={{ xs: 0, sm: 1 }}
                          fontSize={{ xs: 12, sm: 14 }}
                        >
                          Save
                        </Typography>
                      </ButtonWithIcon>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Reset Password
                </Typography>
                <ButtonWithIcon variant="contained">
                  <Typography
                    ml={{ xs: 0, sm: 1 }}
                    fontSize={{ xs: 12, sm: 14 }}
                    onClick={() => {
                      const isMobile = window.innerWidth <= 768;

                      if (isMobile) {
                        window.location.href =
                          "https://wa.me/message/H3VKZ5XMWHMBA1";
                      } else {
                        setIsOpenForgotPasswordModal(true);
                      }
                    }}
                  >
                    Click Here
                  </Typography>
                </ButtonWithIcon>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ContainerWithSomeButton>

      <CustomModal open={isOpenForgotPasswordModal}>
        <Box
          sx={{
            // ml: { xs: "0", md: "-120px" },
            ml: { xs: "0", md: "auto" },
            p: { xs: 3, md: 5 },
            // width: { xs: "100%", md: "720px" },
            width: { xs: "100%", md: "auto" },
            background: "#FFF",
            borderRadius: 2,
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography fontSize={24} fontWeight={600} color="#131313">
              Reset Password
            </Typography>
            <Button
              variant="text"
              sx={{ p: 0, minWidth: "36px", cursor: "pointer" }}
              onClick={() =>
                setIsOpenForgotPasswordModal(!isOpenForgotPasswordModal)
              }
            >
              <Iconify icon={"bi:x"} color="#7B7B7B" width={36} height={36} />
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                1. Open Camera on your phone.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                2. Scan the QR Code.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: "4px" }}
              >
                3. Open the link in your browser.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                4. Open your Whatsapp and send the message.
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                5. Enter your new password
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                6. Confirm your new password
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                color="#131313"
                sx={{ mb: 1 }}
              >
                7. Password changed successfully
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: "2px",
                  mx: "auto",
                  height: "auto",
                  maxWidth: "160px",
                  width: "100%",
                }}
              >
                <QRCode
                  size={256}
                  style={{
                    height: "auto",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  value="https://wa.me/message/H3VKZ5XMWHMBA1"
                  viewBox={`0 0 256 256`}
                />
              </Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                sx={{ color: palette.primary.main, textAlign: "center" }}
              >
                Reset Password with Qr Code
              </Typography>
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
}
