import { Button,Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, {useState } from "react";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import { updateCatalogProducts } from "../../../../store/catalog/catalogAction";

const productTypes = [
  { name: "Physical", key: 1 },
  { name: "Digital", key: 2 },
];

export default function UpdateForm({
  onCancel,
  detail,
  getData,
  resetCatalogs,
}) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      product_id: detail?.id,
      type: productTypes?.find((e) => e.key === detail?.type),
      // quantity: detail?.quantity,
      weight: detail?.weight,
      description: detail?.description,
      price: detail?.price_int,
      name: detail?.name,
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        product_id: value.product_id,
        type: value.type?.key,
        // quantity: value.quantity,
        weight: value.type?.key === 1 ? value.weight : null,
        description: value.description,
        price: value.price,
        name: value.name,
      };

      await dispatch(updateCatalogProducts(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetCatalogs();
          getData();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
    },
  });

  return (
    <FormContainer title="Edit Product">
      <Box
        overflow={"auto"}
        paddingRight={2}
        height={{
          xs: 300, // Height for extra small screens
          sm: 350, // Height for small screens
          md: 350, // Height for medium screens
          lg: 400, // Height for large screens
          xl: 400, // Height for extra large screens
        }}
      >
        <ComboBox
          fullWidth
          disableMargin
          title="Message Type"
          placeholder="Please select product type"
          options={productTypes}
          getOptionLabel={(option) => option?.name}
          value={formik.values.type}
          onChange={(e, value) => {
            formik.setFieldValue("type", value);
          }}
        />
        <Box mb={2} />
        {formik.values.type?.key === 1 ? (
          <>
            <BasicInput
              fullWidth
              disableMargin
              title="Name"
              {...formik.getFieldProps("name")}
            />
            <Box mb={2} />

            <BasicInput
              fullWidth
              disableMargin
              multiline
              rows={5}
              title="Description"
              {...formik.getFieldProps("description")}
            />
            {/* <Box mb={2} />
            <BasicInput
              fullWidth
              disableMargin
              title="Quantity"
              {...formik.getFieldProps("quantity")}
            /> */}
            <Box mb={2} />
            <BasicInput
              fullWidth
              disableMargin
              title="Weight"
              {...formik.getFieldProps("weight")}
            />
            <Box mb={2} />
            <BasicInput
              fullWidth
              disableMargin
              title="Price"
              {...formik.getFieldProps("price")}
            />
            <Box mb={2} />
          </>
        ) : formik.values.type?.key === 2 ? (
          <>
            <BasicInput
              fullWidth
              disableMargin
              title="Name"
              {...formik.getFieldProps("name")}
            />
            <Box mb={2} />
            <BasicInput
              fullWidth
              disableMargin
              multiline
              rows={5}
              title="Description"
              {...formik.getFieldProps("description")}
            />
            <Box mb={2} />
            <BasicInput
              fullWidth
              disableMargin
              title="Price"
              {...formik.getFieldProps("price")}
            />
            <Box mb={2} />
            {/* <BasicInput
              fullWidth
              disableMargin
              title="Quantity"
              {...formik.getFieldProps("quantity")}
            /> */}
          </>
        ) : null}

        <Stack
          direction="row"
          spacing={1}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Button onClick={onCancel}>Cancel</Button>
          <BasicButton
            title="Save"
            type="submit"
            disabled={isLoading}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Box>
      <Box mb={2} />
    </FormContainer>
  );
}
