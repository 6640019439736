import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import CustomModal from "../../../../components/modal/CustomModal";
import { getDevices } from "../../../../store/device/deviceAction";
import palette from "../../../../theme/palette";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const tableCell = ["No", "Device Key", "Name", "Google Analytics ID", "Facebook Pixel", "Action"];

export default function AnalyticsSetting() {
  const dispatch = useDispatch();

  const { catalogOrdersMessage } = useSelector((state) => state.catalog);
  const [device, setDevice] = useState(null);
  const [devices, setDevices] = useState([])

  const [isLoading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [page, setPage] = useState(1);

  const [detail, setDetail] = useState(null);

  const _openAddModal = () => setOpenAddModal(!openAddModal);
  const _onCloseAddModal = () => setOpenAddModal(!openAddModal);

  const _openUpdateModal = () => setOpenUpdateModal(!openUpdateModal);
  const _onCloseUpdateModal = () => setOpenUpdateModal(!openUpdateModal);

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba,telegram,instagram",
      },
    };
    const result = await dispatch(getDevices(body))
    if (result.type == 'get-devices/fulfilled' && result?.payload?.data?.length > 0) {
      //   setDevices(result.payload.data)
      const filteredDevices = result.payload.data.filter(device => device.meta_data.gtag_id && device.meta_data.gtag_id !== '-' && device.meta_data.pixel_id && device.meta_data.pixel_id !== '-');
      setDevices(filteredDevices);
    } else {
      toast.dismiss()
      toast.error(result?.payload?.message ?? 'Device not found')
      setDevices([])
    }
  };


  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (devices?.length) {
      setDevice(devices[0]);
    }
  }, [devices]);

  return (
    <Container
      withButton
      buttonTitle="Configure"
      title="Analytics Setting"
      buttonIcon="carbon:add-alt"
      onClick={_openAddModal}
    >
      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <Table aria-label="simple table">
            <TableHead
              style={{
                background: palette.gradients.custom,
              }}
            >
              <TableRow>
                {tableCell.map((item, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography color="#FDFDFD">{item}</Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={tableCell.length} align="center">
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  </TableCell>
                </TableRow>
              ) : devices?.length ? (
                devices?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {page === 1
                        ? index + 1
                        : devices?.paginations?.per_page * 10 -
                        9 +
                        index}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.device_key}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.name?.length >= 12
                        ? `${item?.name?.substring(0, 24)}...`
                        : item?.name}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.meta_data?.gtag_id || '-'}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.meta_data?.pixel_id || '-'}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      <Stack direction="row" spacing={1}>
                        <ButtonTooltip
                          title="Edit"
                          handleClick={() => {
                            setDetail(item);
                            _openUpdateModal();
                          }}
                          icon="akar-icons:edit"
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={tableCell.length}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      {/* <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title="<"
              onClick={() => {
                if (parseInt(page) > 1) {
                  setPage(parseInt(page) - 1);
                }
              }}
              disabled={parseInt(page) === 1}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title=">"
              onClick={() => {
                if (
                  parseInt(page) < catalogOrdersMessage?.paginations?.last_page
                ) {
                  setPage(parseInt(page) + 1);
                }
              }}
              disabled={
                parseInt(page) >= catalogOrdersMessage?.paginations?.last_page
              }
            />
          </Box>
        </Box> */}

      <CustomModal open={openAddModal}>
        <Form
          onCancel={_onCloseAddModal}
          setDevices={setDevices}
        />
      </CustomModal>
      <CustomModal open={openUpdateModal}>
        <UpdateForm
          onCancel={_onCloseUpdateModal}
          detail={detail}
          setDevices={setDevices}
        />
      </CustomModal>
    </Container>
  );
}

