// material
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Container from "../../../components/containers/Container";
import Scrollbar from "../../../components/Scrollbar";
import { getDashboard } from "../../../store/user/userAction";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const rows = [];

export default function AppTopProducts() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  const [dashboardValue, setDashboardValue] = useState(null);

  const handleGetDashboard = async () => {
    await dispatch(getDashboard({})).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return;
      }

      setDashboardValue(res.payload.data);
    });
  };

  useEffect(() => {
    handleGetDashboard();
  }, []);

  // for (let i = 0; i < 10; i++) {
  //   // console.log(dashboardValue?.top_products[i]?.name);
  // }

  return (
    <Container title="Top Product">
      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD", border: "1px solid #CBC8C8" }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: "1.2px solid #CBC8C8",
                }}
              >
                <TableCell align="left">
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography> Product</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography> Sales Qty</Typography>
                  </Box>
                </TableCell>
                <TableCell align="left">
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography>Sales Volume</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {dashboardValue?.top_products.length > 0 ? (
    dashboardValue?.top_products.map((product, i) => (
      <TableRow key={i}>
        <TableCell align="left">{dashboardValue?.top_products[i]?.name}</TableCell>
        <TableCell align="left">{dashboardValue?.top_products[i]?.quantity}</TableCell>
        <TableCell align="left">{dashboardValue?.top_products[i]?.sales}</TableCell>
      </TableRow>
    ))
  ) : (
    <TableRow>
      <TableCell align="left">No Data</TableCell>
      <TableCell align="left">No Data</TableCell>
      <TableCell align="left">No Data</TableCell>
    </TableRow>
  )}
</TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Container>
  );
}
