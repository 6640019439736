import { Box, Grid, Typography } from "@mui/material"
import ContentAddButton from "../../../../../ContentAddButton"
import ContentBody from "../../../../../ContentBody"
import Selector from "../../../../../../../../../components/select/Selector"
import InputLimitText from "../../../../../../../../../components/input/InputLimitText"
import WarningOption from "../../../../../WarningOption"
import ComboBox from "../../../../../../../../../components/select/ComboBox"
import VariableContent from "../../../../../VariableContent"

export default function MainContentAddButton({
    contentAddButton,
    clickDelete,
    changeSelectOfQuckReply,
    changeTxtBtnSpecialSelect,
    changeWarningRejection,
    changeSelectOfCall,
    changeTxtCall,
    changeCallSearchSelect,
    changeTxtVariable
}) {
    return (
        <>
            {contentAddButton.length > 0 && contentAddButton.map((content, i) => {
                return (
                    <ContentAddButton
                        key={i}
                        title={content.name}
                    >
                        {content?.buttons?.map((act, iBtn) => {
                            return (
                                <ContentBody
                                    key={iBtn}
                                    clickDelete={() => clickDelete(content, i, iBtn)}
                                >
                                    <Grid container spacing={0.5}>
                                        {act?.actions?.map((item, iItem) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={item?.grid?.xs}
                                                    sm={item?.grid?.sm}
                                                    md={item?.grid?.md}
                                                    key={iItem}
                                                // mr={0.5}
                                                // my={0.5}
                                                >
                                                    {item.contentType == 'quick-reply' &&
                                                        <>
                                                            {item?.titleElement ? item.titleElement : ''}
                                                            {item.type == 'select' &&
                                                                <Selector
                                                                    menu={item?.menuSelection ?? []}
                                                                    value={item.value}
                                                                    name={item?.name}
                                                                    // width={item?.width ?? 150}
                                                                    fullWidth
                                                                    width="100%"
                                                                    onChange={(e) => changeSelectOfQuckReply(e, iBtn, iItem, i)}
                                                                />
                                                            }
                                                            {item.type == 'text' &&
                                                                <InputLimitText
                                                                    onChange={(e) => changeTxtBtnSpecialSelect(e, iBtn, iItem, i, item)}
                                                                    value={item.value}
                                                                    length={item?.value?.length}
                                                                    limit={item?.limitText}
                                                                    disabled={item?.isDisable ?? false}
                                                                    // width={item?.width}
                                                                    width="100%"
                                                                />
                                                            }
                                                            {item.type == 'warning' &&
                                                                <WarningOption
                                                                    label={item.desc}
                                                                    desc={item.warningDesc}
                                                                    onChange={(e) => changeWarningRejection(e, iBtn, iItem, i)}
                                                                />
                                                            }
                                                        </>
                                                    }

                                                    {item.contentType == 'call' &&
                                                        <>
                                                            {item?.titleElement ? item.titleElement : ''}
                                                            {item?.description ? item.description : ''}
                                                            {item.type == 'select' &&
                                                                <Selector
                                                                    menu={item?.menuSelection ?? []}
                                                                    name={item?.name}
                                                                    value={item.value}
                                                                    // width={item?.width ?? 150}
                                                                    fullWidth
                                                                    onChange={(e) => changeSelectOfCall(e, iBtn, iItem, i, item)}
                                                                />
                                                            }
                                                            {item.type == 'text' &&
                                                                <Box
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        display: 'flex'
                                                                    }}
                                                                >
                                                                    <InputLimitText
                                                                        onChange={(e) => changeTxtCall(e, iBtn, iItem, i, item)}
                                                                        value={item.value}
                                                                        length={item?.value?.length}
                                                                        limit={item?.limitText}
                                                                        disabled={item?.isDisable}
                                                                        padding={item?.padding ?? '8.5px 50px 8.5px 14px'}
                                                                        // width={item?.width ?? {
                                                                        //     xs: '50vw',
                                                                        //     sm: 250,
                                                                        //     md: 250
                                                                        // }}
                                                                        width="100%"
                                                                        placeholder={item?.placeholder}
                                                                    />
                                                                    {item?.showVariable &&
                                                                        <Typography ml={0.5} color="#7B7B7B">
                                                                            {item.showVariable}
                                                                        </Typography>
                                                                    }
                                                                </Box>
                                                            }
                                                            {item.type == 'search-select' &&
                                                                <ComboBox
                                                                    disableMargin
                                                                    placeholder={item?.placeholder}
                                                                    options={item?.menuSelection ?? []}
                                                                    value={item.value}
                                                                    getOptionLabel={(option) => option?.name}
                                                                    onChange={(e, v) => changeCallSearchSelect(v, iBtn, iItem, i)}
                                                                    fullWidth
                                                                // style={{
                                                                //     width: item.width
                                                                // }}
                                                                />
                                                            }
                                                            {item.type == 'variable' &&
                                                                <VariableContent
                                                                    desc={item.desc}
                                                                    totalVariables={item.totalVariables}
                                                                    changeInput={(e, itemVariable) => changeTxtVariable(e, itemVariable, iBtn, iItem, i, item)}
                                                                    placeholder={item?.placeholder ?? 'Enter content for '}
                                                                />
                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </ContentBody>
                            )
                        })}
                    </ContentAddButton>
                )
            })}
        </>
    )
}