import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import palette from "../../../../theme/palette";

export default function Details({ products, isLoading }) {
  const tableCell = ["Name", "Attribute", "Type", "Qty", "Price", "Subtotal"];

  return (
    <div>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: "#a6a6a6",
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => {
                return (
                  <TableCell align="left" key={index}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography color="#FDFDFD">{item}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : products?.products?.length ? (
              products?.products?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Box display="flex" sx={{ whiteSpace: "nowrap" }}>
                      <Box
                        component="img"
                        src={item?.image}
                        sx={{
                          width: "80px",
                          height: "80px",
                          objectFit: "cover",
                        }}
                      />
                      <Box
                        ml={2}
                        display="flex"
                        justifyContent="space-between"
                        sx={{ maxWidth: "160px", overflow: "hidden" }}
                      >
                        <Typography ml={2} fontSize={14} fontWeight="600">
                          {item?.name}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {item?.attribute || "N/A"}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    {item?.type}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    {item?.quantity}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    {parseFloat(item?.price).toFixed(2)} {item?.currency}
                  </TableCell>
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    {parseFloat(item?.total).toFixed(2)} {item?.currency}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            paddingX: { xs: 2.5, sm: 8 },
            mt: 2,
          }}
        >
          <Stack spacing={1}>
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Subtotal :</Typography>
              <Typography fontWeight="600" color="#212B36">
                {parseFloat(products?.subtotal).toFixed(2)}{" "}
                {products?.currency ?? "MYR"}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>Shipping Charge :</Typography>
              <Typography fontWeight="600" color="#212B36">
                {parseFloat(products?.shipping_fee).toFixed(2)}{" "}
                {products?.currency ?? "MYR"}
              </Typography>
            </Box>
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontWeight="600" color="#212B36">
                Total Amount :
              </Typography>
              <Typography
                fontWeight="600"
                color={palette.primary.main}
                fontSize="18px"
              >
                {parseFloat(products?.total).toFixed(2)}{" "}
                {products?.currency ?? "MYR"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </TableContainer>
    </div>
  );
}
