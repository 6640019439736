import {
  Button,
  Button as Paper,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../../components/button/BasicButton";
import FormContainer from "../../../../../components/containers/FormContainer";
import Iconify from "../../../../../components/Iconify";
import ScrollBar from "../../../../../components/Scrollbar";
import { getCloudFile } from "../../../../../store/cloudFile/cloudFileAction";

const items = [
  {
    name: "image",
    component: (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="material-symbols:image-rounded"
            color="#FFC107"
            height="20px"
            width="20px"
          />
          <Typography sx={{ fontSize: { xs: 10, sm: 14 }, ml: 1 }}>
            Images
          </Typography>
        </Box>
      </>
    ),
  },
  {
    name: "video",
    component: (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="fluent:video-clip-16-filled"
            color="#00BCD4"
            height="20px"
            width="20px"
          />
          <Typography sx={{ fontSize: { xs: 10, sm: 14 }, ml: 1 }}>
            Video
          </Typography>
        </Box>
      </>
    ),
  },
  {
    name: "document",
    component: (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="mdi:drive-document"
            color="#8D6E63"
            height="20px"
            width="20px"
          />
          <Typography sx={{ fontSize: { xs: 10, sm: 14 }, ml: 1 }}>
            Document
          </Typography>
        </Box>
      </>
    ),
  },
  {
    name: "audio",
    component: (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Iconify
            icon="material-symbols:music-video"
            color="#4AC367"
            height="20px"
            width="20px"
          />
          <Typography sx={{ fontSize: { xs: 10, sm: 14 }, ml: 1 }}>
            Audio
          </Typography>
        </Box>
      </>
    ),
  },
];

export default function Upload({ onCancle, onSelect, value }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { cloudFiles } = useSelector((state) => state.cloudFile);

  const [isLoading, setIsLoading] = useState(false);

  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);

  const [category, setCategory] = useState({
    name: "image",
    component: <></>,
  });

  const handleGetCloudFile = async (data) => {
    setIsLoading(true);

    await dispatch(getCloudFile(data));

    setIsLoading(false);
  };

  useEffect(() => {
    const data = {
      params: {
        type: category?.name,
        page: page,
      },
    };

    handleGetCloudFile(data);
  }, [category, page]);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  return (
    <FormContainer title="Upload File">
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16} fontWeight="500" color="#7B7B7B">
          Choose your file
        </Typography>
        <BasicButton
          title="Upload"
          onClick={() => navigate("/dashboard/cloud-storage")}
        />
      </Box>
      <Box>
        <Box sx={{ width: "100%", mb: 2 }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {items.map((v, i) => {
                  return (
                    <Button
                      variant={category?.name === v.name ? "contained" : "text"}
                      key={i}
                      onClick={() => setCategory(v)}
                      sx={
                        category?.name === v.name
                          ? {
                              background: "#fff !important",
                              width: "25%",
                              borderBottom: "4px solid #21C2C1 ",
                              borderRadius: "0",
                              color: "#262626",
                              boxShadow: "0",
                            }
                          : { width: "25%", color: "#262626" }
                      }
                    >
                      {v.component}
                    </Button>
                  );
                })}
              </Stack>
            </ScrollBar>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ maxHeight: "400px", overflowY: "auto" }}
        >
          {isLoading ? (
            <Box sx={{ py: 2, mx: "auto" }}>
              <Box
                component="img"
                src="/images/loading.svg"
                alt="loading"
                height="48px"
                sx={{ mx: "auto" }}
              />
            </Box>
          ) : (
            cloudFiles?.data?.map((item, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box
                    onClick={() => {
                      if (selected?.id === item.id) {
                        setSelected(null);
                      } else {
                        setSelected(item);
                      }
                    }}
                  >
                    <Card
                      sx={
                        selected?.id === item.id
                          ? {
                              border: "4px solid rgba(33,194,193,1)",
                              opacity: "0.6",
                              background: "#F5F5F5",
                            }
                          : { background: "#F5F5F5" }
                      }
                    >
                      <CardActionArea>
                        {item.file_type === "image" ? (
                          <CardMedia
                            component="img"
                            height="140"
                            image={`https://new-client.realm.chat/cloud_storage/${item.file_url}`}
                            loading="lazy"
                          />
                        ) : item.file_type === "video" ? (
                          <CardMedia
                            component="img"
                            height="140"
                            image={`/cloud/video.png`}
                            loading="lazy"
                          />
                        ) : item.file_type === "audio" ? (
                          <CardMedia
                            component="img"
                            height="140"
                            image={`/cloud/audio.png`}
                            loading="lazy"
                          />
                        ) : item.file_type === "document" ? (
                          <CardMedia
                            component="img"
                            height="140"
                            image={`/cloud/document.png`}
                            loading="lazy"
                          />
                        ) : null}
                        <CardContent
                          sx={{
                            px: 2,
                            py: 1,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.file_type === "image" ? (
                            <Iconify
                              icon="material-symbols:image-rounded"
                              color="#FFC107"
                              height="18px"
                              width="18px"
                            />
                          ) : item.file_type === "video" ? (
                            <Iconify
                              icon="fluent:video-clip-16-filled"
                              color="#00BCD4"
                              height="18px"
                              width="18px"
                            />
                          ) : item.file_type === "audio" ? (
                            <Iconify
                              icon="material-symbols:music-video"
                              color="#4AC367"
                              height="18px"
                              width="18px"
                            />
                          ) : item.file_type === "document" ? (
                            <Iconify
                              icon="mdi:drive-document"
                              color="#8D6E63"
                              height="18px"
                              width="18px"
                            />
                          ) : null}
                          <Typography
                            gutterBottom
                            fontSize={14}
                            fontWeight={500}
                            sx={{ mb: 0, ml: 1 }}
                          >
                            {item.file_name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Box>
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
      <Box mb={3} />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title="<"
              onClick={() =>
                parseInt(page) > 1 ? setPage(parseInt(page) - 1) : page
              }
              disabled={parseInt(page) === 1}
            />
          </Box>
          <Box sx={{ mx: 1 }}>
            <BasicButton
              title=">"
              onClick={() =>
                parseInt(page) < cloudFiles?.last_page
                  ? setPage(parseInt(page) + 1)
                  : null
              }
              disabled={parseInt(page) >= cloudFiles?.last_page}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Paper onClick={onCancle}>Cancel</Paper>
          <BasicButton
            title="Submit"
            onClick={() => {
              onSelect(selected);
              onCancle();
              setSelected(null);
            }}
          />
        </Box>
      </Stack>
    </FormContainer>
  );
}
