import { Box, Typography } from "@mui/material";

export default function ContentAddButton({
    title,
    children
}) {
    return (
        <>
            <Box
                sx={{
                    border: {
                        xs: 'none',
                        sm: '1px solid #ddd',
                        md: '1px solid #ddd'
                    },
                    borderBottom: {
                        xs: '1px solid #ddd'
                    },
                    borderRadius: {
                        xs: 'none',
                        sm: 1,
                        md: 1
                    },
                    background: '#fff'
                }}
                pt={{
                    xs: 1,
                    sm: 2,
                    md: 2
                }}
                px={{
                    xs: 0,
                    sm: 3,
                    md: 3
                }}
                my={2}
            >
                <Typography
                    fontWeight={700}
                    mb={2}
                >
                    {title}
                </Typography>
                {children}
            </Box>
        </>
    )
}