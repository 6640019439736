import { Box, Typography } from "@mui/material";

export default function NoAccount({
    desc
}) {
    return (
        <Box
            sx={{
                minHeight: 400,
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: 3
            }}
        >
            <Box
                component="img"
                src="/device/frame.png"
                sx={{
                    width: { xs: "45px", md: "55px" },
                    height: { xs: "45px", md: "55px" },
                    objectFit: "contain",
                }}
            />
            <Typography
                my={1}
                fontWeight={700}
                fontSize={{ xs: 16, sm: 16, md: 20 }}
                color="#131313"
                textAlign="center"
            >
                No Account Connected yet
            </Typography>

            <Typography
                fontWeight={400}
                fontSize={{ xs: 14, sm: 16, md: 16 }}
                color="#7B7B7B"
                textAlign="center"
            >
                {desc}
            </Typography>
        </Box>
    )
}