import { Box, Button, Card, Stack, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import ButtonWithIcon from "../button/ButtonWithIcon";
export default function SaveCancelContainer({
  children,
  title,
  width,
  buttonSaveTitle,
  minHeight,
  buttonCancelTitle,
  onSave,
  onCancel,
  customTitle,
  buttonDisabled,
}) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: 2.5,
        paddingBottom: 16,
        alignItems: "center",
        minHeight: minHeight,
        height: "100%",
        width: width,
        backgroundColor: palette.secondary.main,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        {title && (
          <Typography
            fontSize={{ xs: 20, sm: 24 }}
            fontWeight="700"
            color="#131313"
          >
            {title}
          </Typography>
        )}
        {customTitle}
        <Stack direction="row" spacing={1}>
          {buttonCancelTitle && (
            <Button variant="text" onClick={onCancel} sx={{height: 'fit-content'}}>
              <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 12, sm: 14 }}>
                {buttonCancelTitle}
              </Typography>
            </Button>
          )}
          {buttonSaveTitle && (
            <ButtonWithIcon
              variant="contained"
              onClick={onSave}
              disabled={buttonDisabled}
            >
              <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 12, sm: 14 }}>
                {buttonSaveTitle}
              </Typography>
            </ButtonWithIcon>
          )}
        </Stack>
      </Box>
      <Box mb={3} />
      {children}
    </Card>
  );
}
