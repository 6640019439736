import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../../components/containers/Container";
import EmptyBox from "../../../../components/EmptyBox";
import { getPaymentGateways } from "../../../../store/catalog/catalogAction";
import Form from "./Form";
import List from "./List";

export default function PaymentGetaway() {
  const dispatch = useDispatch();

  const { paymentGateways } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetPaymentGateways = async () => {
    setIsLoading(true);

    await dispatch(getPaymentGateways());

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetPaymentGateways();
  }, []);

  return (
    <Container
      // withButton
      // buttonTitle="Tutorial"
      title="Payment Gateway"
    >
      {isLoading ? (
        <Box sx={{ py: 2 }}>
          <Box
            component="img"
            src="/images/loading.svg"
            alt="loading"
            height="48px"
            sx={{ mx: "auto" }}
          />
        </Box>
      ) : paymentGateways?.data?.length ? (
        paymentGateways?.data?.map((item, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary sx={{ px: 0 }}>
                <List key={index} item={item} />
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  pt: 2,
                  backgroundColor: "#F5F5F5",
                  borderRadius: "0px 0px 16px 16px",
                }}
              >
                <Form forms={item.form} name={item.name} />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <Box mt={6}>
          <EmptyBox leaf title1="Data Not Found" />
        </Box>
      )}
    </Container>
  );
}
