import React from "react";
import { Button as Paper, Typography } from "@mui/material";
import palette from "../../theme/palette";

export default function TextButton({ title, disabled, onClick, fullWidth, children, ...props }) {
  return (
    <Paper fullWidth={fullWidth} disabled={disabled} variant="text" onClick={onClick} {...props}>
      {title && <Typography fontSize={{ xs: 12, sm: 14 }}>{title}</Typography>}
      {children}
    </Paper>
  );
}
