import { createSlice } from "@reduxjs/toolkit";
import { getIntegrationWebhooks } from "./integrationAction";

const initialState = {
  integrationWebhooks: null,
};

const integrationSlice = createSlice({
  name: "integration",
  initialState: initialState,
  extraReducers: {
    [getIntegrationWebhooks.fulfilled]: (state, { payload }) => {
      state.integrationWebhooks = payload;
    },
    [getIntegrationWebhooks.rejected]: (state) => {
      state.integrationWebhooks = null;
    },
  },
});

export const {} = integrationSlice.actions;

export default integrationSlice.reducer;
