import {
  Box,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import CustomModal from "../../../../components/modal/CustomModal";
import {
  deleteIntegrationWebhook,
  getIntegrationWebhooks,
} from "../../../../store/integration/integrationAction";
import palette from "../../../../theme/palette";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const tableCell = ["No", "Device", "Url Webhook", "Status", "Action"];

const informationTableCell = ["from", "Sender Name"];

const informationTableRows = [
  {
    label: "ID Message",
    key: "id_message",
  },
  {
    label: "TYpe of Message (text/image)",
    key: "type_message",
  },
  {
    label: "Message",
    key: "message",
  },
  {
    label: "Image URL if message type is image",
    key: "image",
  },
  {
    label: "Timestamp",
    key: "timestamp",
  },
];

export default function WebhookList() {
  const dispatch = useDispatch();

  const { integrationWebhooks } = useSelector((state) => state.integration);

  const [isLoading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [page, setPage] = useState(1);

  const [detail, setDetail] = useState(null);

  const _openAddModal = () => setOpenAddModal(!openAddModal);
  const _onCloseAddModal = () => setOpenAddModal(!openAddModal);

  const _openUpdateModal = () => setOpenUpdateModal(!openUpdateModal);
  const _onCloseUpdateModal = () => setOpenUpdateModal(!openUpdateModal);

  const { plan } = useSelector((state) => state.user);

  const handleGetIntegrationWebhook = async () => {
    setIsLoading(true);

    const data = {
      params: {
        page: page,
      },
    };

    await dispatch(getIntegrationWebhooks(data));

    setIsLoading(false);
  };

  const handleDeleteIntegrationWebhook = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteIntegrationWebhook(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        handleGetIntegrationWebhook();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    handleGetIntegrationWebhook();
  }, [page]);

  if (plan?.is_webhook === "1") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Container
            withButton
            title="Webhook List"
            buttonTitle="Add Webhook"
            buttonIcon="carbon:add-alt"
            onClick={_openAddModal}
          >
            <Scrollbar>
              <TableContainer
                component={Paper}
                style={{ backgroundColor: "#FDFDFD" }}
              >
                <Table aria-label="simple table">
                  <TableHead
                    style={{
                      background: palette.gradients.custom,
                    }}
                  >
                    <TableRow>
                      {tableCell.map((item, index) => {
                        return (
                          <TableCell align="left" key={index}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography color="#FDFDFD">{item}</Typography>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={tableCell.length} align="center">
                          <Box
                            component="img"
                            src="/images/loading.svg"
                            alt="loading"
                            height="48px"
                            sx={{ mx: "auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : integrationWebhooks?.data?.length ? (
                      integrationWebhooks?.data?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {page === 1
                              ? index + 1
                              : integrationWebhooks?.paginations?.per_page *
                                  10 -
                                9 +
                                index}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item?.device?.device_key}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item?.url}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item?.is_active ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            <Stack direction="row" spacing={1}>
                              <ButtonTooltip
                                title="Edit"
                                handleClick={() => {
                                  setDetail(item);
                                  _openUpdateModal();
                                }}
                                icon="akar-icons:edit"
                                color="#0d0d0d"
                              />
                              <ButtonTooltip
                                title="Delete"
                                handleClick={() => {
                                  handleDeleteIntegrationWebhook({
                                    device_key: item?.device?.device_key,
                                  });
                                }}
                                icon="fluent:delete-24-regular"
                                color="#F15A28"
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={tableCell.length}>
                          Data Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
              <Box sx={{ mx: 1 }}>
                <BasicButton
                  title="<"
                  onClick={() => {
                    if (parseInt(page) > 1) {
                      setPage(parseInt(page) - 1);
                    }
                  }}
                  disabled={parseInt(page) === 1}
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <BasicButton
                  title=">"
                  onClick={() => {
                    if (
                      parseInt(page) <
                      integrationWebhooks?.paginations?.last_page
                    ) {
                      setPage(parseInt(page) + 1);
                    }
                  }}
                  disabled={
                    parseInt(page) >=
                    integrationWebhooks?.paginations?.last_page
                  }
                />
              </Box>
            </Box>

            <CustomModal open={openAddModal}>
              <Form onCancel={_onCloseAddModal} />
            </CustomModal>
            <CustomModal open={openUpdateModal}>
              <UpdateForm onCancel={_onCloseUpdateModal} detail={detail} />
            </CustomModal>
          </Container>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container title="Information">
            <Typography fontSize={16} color="#7B7B7B">
              With <span style={{ fontWeight: "bold" }}>Webhook</span> we will
              send the latest message data received on the device you have
              registered in real time.
            </Typography>
            <Typography fontSize={16} color="#7B7B7B">
              Following is an example of the data we will send to your server :
            </Typography>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {informationTableCell?.map((item, index) => {
                        return (
                          <TableCell align="left" key={index}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography>{item}</Typography>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {informationTableRows?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {item?.key}
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {item?.label}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Container>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Container title="Webhook">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
