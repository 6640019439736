import { createSlice } from "@reduxjs/toolkit";
import { getAllWabaTemplate, resetWabaTemplate } from "./wabaTemplateAction";

const initialState = {
  wabaTemplates: [],
};

const wabaTemplateSlice = createSlice({
  name: "plan",
  initialState: initialState,
  extraReducers: {
    [getAllWabaTemplate.fulfilled]: (state, { payload }) => {
      const filters = payload?.data?.filter(
        (item) => item?.status === "APPROVED"
      );

      state.wabaTemplates = filters;
    },
    [getAllWabaTemplate.rejected]: (state) => {
      state.wabaTemplates = [];
    },
    [resetWabaTemplate.fulfilled]: (state)=>{
      state.wabaTemplates = []
    }
  },
});

export const {} = wabaTemplateSlice.actions;

export default wabaTemplateSlice.reducer;
