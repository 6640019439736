import { createSlice } from "@reduxjs/toolkit";
import {
  authLogin,
  authLogout,
  getUser,
  getUserQuestions,
  verifyRegister,
} from "./userAction";

const authToken = localStorage.getItem("auth-token") ?? null;

const initialState = {
  user: null,
  plan: null,
  apiKey: null,
  login: null,
  authToken: authToken,
  userQuestions: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [authLogin.fulfilled]: (state, { payload }) => {
      state.user = payload.data?.data;
      state.plan = payload.data?.plan;
      state.apiKey = payload.data?.api_key;
      state.authToken = payload.data?.token;
    },
    [authLogin.rejected]: (state, { payload }) => {
      state.user = null;
      state.plan = null;
      state.authToken = null;
    },
    [authLogout.fulfilled]: (state, { payload }) => {
      localStorage.removeItem("auth-token");

      state.user = initialState.user;
      state.login = false;
      state.authToken = null;
    },
    [authLogout.rejected]: (state, { payload }) => {
      state.login = false;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      state.user = payload.data;
      state.apiKey = payload.api_key;
      state.plan = payload.plan;
      state.login = true;
    },
    [getUser.rejected]: (state) => {
      state.login = false;
    },
    [getUserQuestions.fulfilled]: (state, { payload }) => {
      state.userQuestions = payload;
    },
    [getUserQuestions.rejected]: (state) => {
      state.userQuestions = null;
    },
    [verifyRegister.fulfilled]: (state, { payload }) => {
      state.user = payload.data?.user;
      state.plan = payload.data?.plan;
      state.apiKey = payload.data?.api_key;
      state.authToken = payload.data?.token;
    },
    [verifyRegister.rejected]: (state, { payload }) => {
      state.user = null;
      state.plan = null;
      state.authToken = null;
    },
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
