import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import BuyPlan from "../sections/dashboard/plan/buy-plan";
import Credits from "../sections/dashboard/plan/credits";

const items = [
  {
    name: "Buy Plan",
    component: <BuyPlan />,
  },
  {
    name: "Credits",
    component: <Credits />,
  },
];

export default function Plan() {
  const [planPages, setPlanPages] = useState(items[0]);

  return (
    <Page title="Plan">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Plan" />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <Stack direction="row" spacing={1}>
              {items.map((v, i) => {
                return (
                  <Button
                    variant={planPages.name === v.name ? "contained" : "text"}
                    sx={
                      planPages?.name === v.name
                        ? {
                            background:
                              "linear-gradient(154.66deg, #93DF93 5.17%, #21C2C1 68.62%)",
                            borderRadius: "24px",
                            color: "#fdfdfd",
                          }
                        : { color: "#7b7b7b" }
                    }
                    key={i}
                    onClick={() => setPlanPages(v)}
                  >
                    <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                      {" "}
                      {v.name}
                    </Typography>
                  </Button>
                );
              })}
            </Stack>
          </Box>
          <Box mt={3}>{planPages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
