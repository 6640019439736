import { Box, useMediaQuery } from "@mui/material";
import palette from "../../../../theme/palette";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

export default function ContentBody({
    children,
    clickDelete
}) {
    const tablet = useMediaQuery('(min-width:900px)')
    const miniDesktop = useMediaQuery('(min-width:1030px)')
    const mobile = useMediaQuery('(max-width:680px)')
    return (
        <>
            {/* content */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    flexDirection: tablet && !miniDesktop ? 'column' : mobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                }}
                mb={3}
            >
                <Box
                    sx={{
                        // background: '#F6F7FF',
                        background: '#F8FAFB',
                        borderRadius: 1.2,
                        border: '1px solid #ddd',
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '100%',
                        },
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}
                    p={2}
                >
                    <Box
                        mr={1}
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        {children}
                    </Box>
                </Box>
                {/* btn delete */}
                <Box sx={{
                    marginLeft: 1,
                    marginTop: 1
                }}>
                    <ButtonTooltip
                        title="Delete"
                        handleClick={clickDelete}
                        icon="iconamoon:close-light"
                        color={palette.secondary.dark}
                    />
                </Box>
            </Box>
        </>
    )
}