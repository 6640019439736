import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const getReportScheduleMessage = createAsyncThunk(
  "report/schedule-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("schedule-message/list", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportBulkMessage = createAsyncThunk(
  "report/bulk-message",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("bulk-message/list", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportBulkEmail = createAsyncThunk(
  "report/bulk-email",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("bulk-email", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportBulkWaba = createAsyncThunk(
  "report/bulk-waba",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("bulk-waba", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportScheduleMessageStatus = createAsyncThunk(
  "report/schedule-status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/schedule-status", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportBulkMessageStatus = createAsyncThunk(
  "report/bulk-status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/bulk-status", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportReport = createAsyncThunk(
  "report/report-excel",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api({
        method: "GET",
        url: "report-excel",
        responseType: "blob",
        params: { id: id },
      });

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
