import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Iconify from "../Iconify";

export default function InputSearch({ id, name, value, ...props }) {
  return (
    <TextField
      id={id}
      name={name}
      value={value}
      fullWidth
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="heroicons:magnifying-glass"
              sx={{ width: 24, height: 24, ml: 1 }}
            />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      size="small"
      {...props}
    />
  );
}
