import { Box, List, ListItem, ListItemText, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import CardContainer from "../../../../components/containers/CardContainer";
import Scrollbar from "../../../../components/Scrollbar";
import {
  getShippingGateways,
  updateShippingGatewayCourier,
} from "../../../../store/catalog/catalogAction";
import BoxImage from "../payment-getaway/BoxImage";

const Item = ({ item, index, onChecked }) => {
  const [checked, setChecked] = useState(item.enable);

  return (
    <List
      sx={{
        width: "100%",
        borderBottom: "1px solid #CBC8C8",
        alignItems: "center",
        display: "flex",
      }}
      key={index}
    >
      <ListItem alignItems="center" sx={{ padding: 0 }}>
        <BoxImage src={item.logo} alt={item.name} />

        <ListItemText
          primary={item.title}
          primaryTypographyProps={{
            style: {
              fontSize: "14px",
              fontWeight: "600",
            },
          }}
        />
      </ListItem>

      <Box display="flex" alignItems="center">
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            onChecked({ name: item.name, enable: !checked });
          }}
        />
      </Box>
    </List>
  );
};

export default function EasyParcel() {
  const dispatch = useDispatch();

  const { shippingGateways } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetShippingGateways = async () => {
    setIsLoading(true);

    await dispatch(getShippingGateways());

    setIsLoading(false);
  };

  const handleUpdateCourier = async ({ name, enable }) => {
    toast.loading("Please wait...");

    const couriers_enable = [];

    shippingGateways?.courier_enable?.map((item) => {
      if (item.enable) {
        couriers_enable.push(item.name);
      }
    });

    if (enable) {
      couriers_enable?.push(name);
    } else {
      const index = couriers_enable?.indexOf(name);
      if (index > -1) {
        couriers_enable?.splice(index, 1);
      }
    }

    const data = {
      courier_enable: couriers_enable,
    };

    await dispatch(updateShippingGatewayCourier(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);
        handleGetShippingGateways();
      } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });
  };

  useEffect(() => {
    handleGetShippingGateways();
  }, []);

  return (
    <CardContainer title="Easy Parcel">
      <Scrollbar>
        {shippingGateways?.courier_enable?.map((item, index) => {
          return (
            <Item
              item={item}
              index={index}
              onChecked={(e) => handleUpdateCourier(e)}
            />
          );
        })}
        <Box mb={1} />
      </Scrollbar>
    </CardContainer>
  );
}
