import { configureStore } from "@reduxjs/toolkit";
import businessSlice from "./business/businessSlice";
import catalogSlice from "./catalog/catalogSlice";
import cloudFileSlice from "./cloudFile/cloudFileSlice";
import contactSlice from "./contact/contactSlice";
import counterSlice from "./counter/counterSlice";
import countrySlice from "./country/countrySlice";
import deviceSlice from "./device/deviceSlice";
import groupSlice from "./group/groupSlice";
import marketingSlice from "./marketing/marketingSlice";
import planSlice from "./plan/planSlice";
import reportSlice from "./report/reportSlice";
import statisticSlice from "./statistic/statisticSlice";
import templateSlice from "./template/templateSlice";
import userSlice from "./user/userSlice";
import customerServiceSlice from "./customer-service/customerServiceSlice";
import integrationSlice from "./integration/integrationSlice";
import topupSlice from "./topup/topupSlice";
import wabaTemplateSlice from "./waba-template/wabaTemplateSlice";
import languageSlice from "./languages/languageSlice";
import channelSlice from "./channel/channelSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    device: deviceSlice,
    marketing: marketingSlice,
    contact: contactSlice,
    counter: counterSlice,
    group: groupSlice,
    report: reportSlice,
    cloudFile: cloudFileSlice,
    statistic: statisticSlice,
    template: templateSlice,
    catalog: catalogSlice,
    country: countrySlice,
    business: businessSlice,
    plan: planSlice,
    customerService: customerServiceSlice,
    integration: integrationSlice,
    topup: topupSlice,
    wabaTemplate: wabaTemplateSlice,
    language: languageSlice,
    channel: channelSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
