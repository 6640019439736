import {
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import InputSearch from "../../../../components/input/InputSearch";
import Scrollbar from "../../../../components/Scrollbar";
import GreetingList from "./List";
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import AddGreetingMessage from "./add-greeting-message";
import EditGreetingMessage from "./edit-greeting-message";
import { useSelector } from "react-redux";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import palette from "../../../../theme/palette";
import Container from "../../../../components/containers/Container";
import Iconify from "../../../../components/Iconify";
import toast from 'react-hot-toast';

export default function GreetingMessage() {
  const [addGreetingMessage, setAddGreetingMessage] = useState(false);
  const [editGreetingMessage, setEditGreetingMessage] = useState(false);
  

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));


const { plan } = useSelector((state) => state.user);

if (plan?.is_autoreply === "1") {
  return (
    <div>
      {addGreetingMessage ? (
        <AddGreetingMessage
          setAddGreetingMessage={() =>
            setAddGreetingMessage(!addGreetingMessage)
          }
        />
      ) : editGreetingMessage ? (
        <EditGreetingMessage
          setEditGreetingMessage={() =>
            setEditGreetingMessage(!editGreetingMessage)
          }
        />
      ) : (
        <ContainerWithSomeButton
          title="Greeting List"
          button1
          button1Title="Add Greeting Message"
          button1OnClick={() => setAddGreetingMessage(true)}
        >
          {/* <InputSearch /> */}
          <Box mb={3} />
          <Scrollbar>
            <GreetingList
              setEditGreetingMessage={() =>
                setEditGreetingMessage(!editGreetingMessage)
              }
            />
          </Scrollbar>
        </ContainerWithSomeButton>
      )}
    </div>
  );
} else {
  return (
    <Container title="Greeting Message">
      <Grid container spacing={4}>
        <Grid item xs={12} minHeight="540px">
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography variant="h3" paragraph>
              Oopss.. this page is not available.
            </Typography>
            <Box
              component="img"
              src="/static/illustrations/404.svg"
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />

            <Button
              variant={"contained"}
              sx={{
                background: palette.gradients.custom,
                borderRadius: "12px",
                color: "#fdfdfd",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Iconify
                icon="bx:medal"
                sx={{
                  width: 24,
                  height: 24,
                  ml: 1,
                }}
              />
              <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                Upgrade Package Now!
              </Typography>
            </Button>
          </ContentStyle>
        </Grid>
      </Grid>
    </Container>
  );
}
}




