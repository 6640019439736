import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography } from "@mui/material";
import palette from "../../theme/palette";
import Iconify from "../Iconify";

export default function ComboBox({
  title,
  fullWidth,
  options,
  placeholder,
  onChange,
  onSearch,
  getOptionLabel,
  value,
  id,
  titleIcon,
  multiple,
  disableMargin,
  disabled,
  fontWeight = '700',
  ...props
}) {
  return (
    <div>
      {title && (
        <Typography fontWeight={fontWeight} mb={0.5}>
          {title}{" "}
          {titleIcon && <Iconify icon={titleIcon} alignItems="center" />}
        </Typography>
      )}
      <Autocomplete
        fullWidth={fullWidth}
        multiple={multiple}
        disablePortal
        id={id}
        size="small"
        options={options}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={onChange}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params}
            placeholder={placeholder}
            sx={{ backgroundColor: palette.secondary.main }}
            onChange={onSearch}
            {...props}
          />
        )}
        sx={{ backgroundColor: palette.secondary.main }}
      />
      {!disableMargin && <Box mb={3} />}
    </div>
  );
}
