import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import formatWppMarkdown from "whatsapp-markdown";
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import { DeviceFrame } from "../../../../components/containers/DeviceFrame";
import BasicInput from "../../../../components/input/BasicInput";
import CustomModal from "../../../../components/modal/CustomModal";
import ComboBox from "../../../../components/select/ComboBox";
import { getContacts } from "../../../../store/contact/contactAction";
import {
  getDevices,
  getStatusDevice,
} from "../../../../store/device/deviceAction";
import { sendMessage } from "../../../../store/marketing/marketingAction";
import palette from "../../../../theme/palette";
import Upload from "./Upload";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const messageTypes = [{ name: "Text", key: "text" }];

export default function SendMessage() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { contacts } = useSelector((state) => state.contact);
  const { plan } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const [editor, setEditor] = useState(false);

  const [device, setDevice] = useState(null);
  const [contact, setContact] = useState(null);
  const [typeMessage, setTypeMessage] = useState(null);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      accountid: "",
      number: "",
      message: "",
      type: "",
      fileUrl: "",
      fileName: "",
      isVoice: false,
      file: "",
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");
      setIsLoading(true);

      await dispatch(getStatusDevice({ accountid: value.accountid })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            if (res.payload.result) {
              if (res.payload.ready) {
                const data = {
                  accountid: value.accountid,
                  number: value.number,
                  message: value.message,
                  type: value.type,
                  fileUrl: value.fileSelected
                    ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                    : null,
                  fileName: null,
                  isVoice: value.isVoice,
                };

                await dispatch(sendMessage(data)).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();

                    setDevice(null);
                    setContact(null);
                    setTypeMessage(null);
                  } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                  }
                });
              } else {
                toast.dismiss();
                toast.error("Device Not Connected");
              }
            } else {
              toast.dismiss();
              toast.error("Device Not Connected");
            }
          } else {
            toast.dismiss();
            toast.error("Device Not Connected");
          }

          setIsLoading(false);
        }
      );
    },
  });

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetContacts = async (data) => {
    await dispatch(getContacts(data));
  };

  useEffect(() => {
    handleGetDevices();
    handleGetContacts({ params: { search: null } });
  }, []);

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values]);

  if (plan?.is_sendmessage === "1") {
    return (
      <>
        <Container
          withButton
          title="Send Message"
          buttonTitle="Submit"
          buttonDisabled={isLoading || !formik.values.message.length}
          onClick={formik.handleSubmit}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} minHeight="540px">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}> 
                  <Tooltip 
                  title="You can manage your subscription plan & credits here">
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Channel"
                      placeholder="Please select channel"
                      options={devices}
                      getOptionLabel={(option) => option?.name}
                      value={device}
                      onChange={(e, value) => {
                        formik.setFieldValue("accountid", value.device_key);
                        setDevice(value);
                      }}
                    />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Contact"
                      placeholder="Select contact/Skip this section"
                      options={contacts?.data ?? []}
                      getOptionLabel={(option) => option?.name}
                      value={contact}
                      onChange={(e, value) => {
                        formik.setFieldValue("number", value.phone);
                        setContact(value);
                      }}
                      onSearch={(e) => {
                        const data = {
                          search: e.target.value,
                        };

                        handleGetContacts(data);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <BasicInput
                      disableMargin
                      fullWidth
                      title="Phone Number"
                      placeholder="Country code and phone number (ex.6013993280)"
                      {...formik.getFieldProps("number")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Message Type"
                      placeholder="Choose type message"
                      options={messageTypes}
                      getOptionLabel={(option) => option?.name}
                      value={typeMessage}
                      onChange={(e, value) => {
                        formik.setFieldValue("type", value.key);
                        setTypeMessage(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => setEditor(!editor)}
                      sx={{
                        background: palette.gradients.custom,
                        borderRadius: "8px",
                        color: "#fdfdfd",
                        width: "100%",
                        height: "36px",
                      }}
                    >
                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                        {!editor ? "Add Manual Template" : "Close Editor"}
                      </Typography>
                    </Button>
                  </Grid>
                  {editor && (
                    <Grid item xs={12} sm={12}>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.3 }}
                      >
                        <Box mb={2}>
                          <BasicInput
                            fullWidth
                            disableMargin
                            multiline
                            rows={5}
                            placeholder="Type message in here ..."
                            {...formik.getFieldProps("message")}
                          />
                        </Box>
                      </motion.div>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                      Attachment (Optional)
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <BasicButton
                        title="Choose File"
                        onClick={() => setOpenUploadModal(true)}
                      />
                      <Typography color="#131313" sx={{ ml: 2 }}>
                        {formik?.values?.fileSelected?.file_name}
                      </Typography>
                    </Box>
                  </Grid>
                  {formik.values.fileSelected?.file_type === "audio" ? (
                    <Grid item xs={12} sm={12}>
                      <BasicInput
                        type="checkbox"
                        title="Send as Voice Note"
                        sx={{ width: "24px" }}
                        onChange={() => {
                          formik.setFieldValue(
                            "isVoice",
                            !formik.values.isVoice ? true : false
                          );
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <DeviceFrame
                single={{
                  fileSelected: formik.values.fileSelected,
                  isVoice: formik.values.isVoice,
                }}
                setFooter={(value) => formik.setFieldValue("footer", value)}
              >
                <p
                  id="chat-message"
                  style={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {formik.values.message.split("\n").join("<br />")}
                </p>
              </DeviceFrame>
            </Grid>
          </Grid>
        </Container>
        <CustomModal
          handleClose={() => setOpenUploadModal(false)}
          open={openUploadModal}
        >
          <Upload
            onCancle={() => setOpenUploadModal(false)}
            onSelect={(value) => {
              formik.setFieldValue("fileSelected", value);
            }}
            value={formik.values?.fileSelected}
          />
        </CustomModal>
      </>
    );
  } else {
    return (
      <Container title="Send Message">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
