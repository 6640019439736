import { Avatar, Box, Button, Grid, Stack, Switch, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getCatalogAddresses,
  updateCatalogAddress,
} from "../../../../store/catalog/catalogAction";
import {
  getDistricts,
  getRegions,
  getStates,
} from "../../../../store/country/countryAction";
import {
  resetDistricts,
  resetStates,
} from "../../../../store/country/countrySlice";
import MapLocation from "../../../../components/map";

export default function AddAdress({ detail, setIsAddAddress }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { regions, states, districts } = useSelector((state) => state.country);

  // map location
  const mapRef = useRef(null)
  const [coordinateValue, setCoordinateValue] = useState({})
  const [onResetCoordinate, setOnResetCoordinate] = useState(false)
  const [loadOpenMap, setLoadOpenMap] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      device: null,
      name: detail?.name ?? "",
      phone: detail?.phone ?? "",
      email: detail?.email ?? "",
      address: detail?.address ?? "",
      city: null,
      country: null,
      state: null,
      zip: detail?.zip ?? "",
      latitude: detail?.latitude ?? null,
      longitude: detail?.longitude ?? null
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        device_key: value.device?.device_key,
        name: value.name,
        phone: value.phone,
        email: value.email,
        address: value.address,
        city: value.city?.name,
        state: value.state?.name,
        short_state: value.state?.short_name,
        country: value?.country?.name,
        short_country: value.country?.short_name,
        zip: value.zip,
        latitude: detail?.latitude ?? (coordinateValue?.latitude ? `${coordinateValue.latitude}` : null),
        longitude: detail?.longitude ?? (coordinateValue?.longitude ? `${coordinateValue.longitude}` : null)
      };

      await dispatch(updateCatalogAddress(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
      setIsAddAddress(false);

      resetForm();
      handleGetCatalogOrdersMessage();
    },
  });

  const handleGetCatalogOrdersMessage = async () => {
    await dispatch(getCatalogAddresses());
  };

  const handleGetDistricts = async (data) => {
    await dispatch(getDistricts(data));
  };

  const handleGetStates = async (data) => {
    await dispatch(getStates(data));
  };

  const handleGetRegions = async () => {
    await dispatch(getRegions());
  };

  useEffect(() => {
    handleGetRegions();
  }, []);

  useEffect(() => {
    if (detail) {
      if (devices) {
        const device = devices.find(
          (item) => item.device_key === detail.device_key
        );
        formik.setFieldValue("device", device);
      }
      if (detail?.latitude) {
        setCoordinateValue({
          latitude: detail.latitude,
          longitude: detail.longitude,
          zoom: 14
        })
      }
    }
  }, [detail, devices]);

  useEffect(() => {
    if (detail && regions) {
      const country = regions?.find((item) => item.name === detail.country);
      formik.setFieldValue("country", country);

      handleGetStates({ region: country?.name });
    }
  }, [detail, regions]);

  useEffect(() => {
    if (detail && regions && states) {
      const state = states?.find((item) => item.name === detail.state);

      if (state) {
        formik.setFieldValue("state", state);

        handleGetDistricts({ state: state?.name });
      }
    }
  }, [detail, regions, states]);

  useEffect(() => {
    if (detail && regions && states && districts) {
      const district = districts?.find((item) => item.name === detail.city);

      if (district) {
        formik.setFieldValue("city", district);
      }
    }
  }, [detail, regions, states, districts]);

  return (
    <FormContainer title="Add New Address">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ComboBox
            fullWidth
            disableMargin
            title="Channel"
            placeholder="Please select channel"
            options={devices}
            getOptionLabel={(option) => option?.name}
            value={formik.values.device}
            onChange={(e, value) => {
              formik.setFieldValue("device", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Name"
            {...formik.getFieldProps("name")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Email"
            {...formik.getFieldProps("email")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Phone"
            {...formik.getFieldProps("phone")}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <BasicInput
            fullWidth
            disableMargin
            title="Address"
            {...formik.getFieldProps("address")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComboBox
            fullWidth
            disableMargin
            title="Country"
            placeholder="Please select country"
            options={regions}
            getOptionLabel={(option) => option?.name}
            value={formik.values.country}
            onChange={(e, value) => {
              formik.setFieldValue("country", value);

              dispatch(resetStates());

              handleGetStates({ region: value.name });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComboBox
            fullWidth
            disableMargin
            title="State"
            placeholder="Please select state"
            options={states}
            getOptionLabel={(option) => option?.name}
            value={formik.values.state}
            onChange={(e, value) => {
              formik.setFieldValue("state", value);

              dispatch(resetDistricts());

              handleGetDistricts({ state: value.name });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ComboBox
            fullWidth
            disableMargin
            title="City"
            placeholder="Please select city"
            options={districts}
            getOptionLabel={(option) => option?.name}
            value={formik.values.city}
            onChange={(e, value) => {
              formik.setFieldValue("city", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BasicInput
            fullWidth
            title="Zip Code"
            {...formik.getFieldProps("zip")}
          />
        </Grid>
        <Grid item xs={12} sm={12} mb={4}>
          <Typography fontWeight={700} style={{
            marginBottom: '4px'
          }}>
            Location (Optional)
          </Typography>
          {!loadOpenMap ?
            <MapLocation
              mapRef={mapRef}
              coordinateValue={coordinateValue}
              setCoordinateValue={setCoordinateValue}
              onResetCoordinate={onResetCoordinate}
              setOnResetCoordinate={setOnResetCoordinate}
              setLoadOpenMap={setLoadOpenMap}
              prevCoordinate={{
                latitude: detail?.latitude ?? null,
                longitude: detail?.longitude ?? null
              }}
            /> :
            <Box
              width="100%"
              height="200px"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar
                src="/images/loading.svg"
                alt="loading map"
              />
            </Box>
          }
        </Grid>
      </Grid>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={() => {
          setIsAddAddress(false)
          setCoordinateValue({})
          setOnResetCoordinate(false)
        }}>Close</Button>
        <ButtonWithIcon
          title="Save"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
        {/* <Typography fontWeight="600">Set as default address</Typography>
        <Switch sx={{ width: "60px" }} /> */}
      </Stack>
    </FormContainer>
  );
}
