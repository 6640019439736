import { Box } from "@mui/material";
import TemplateSection from "../../../../../TemplateSection";
import Selector from "../../../../../../../../../components/select/Selector";
import InputLimitText from "../../../../../../../../../components/input/InputLimitText";
import HeaderText from "./HeaderText";
import HeaderMedia from "./HeaderMedia";

export default function Header({
    headers,
    headerValue,
    changeHeader,
    changeTextHeader,
    textHeader,
    addVariableHeader,
    totalVariableHeader,
    changeHeaderContent,
    headerMedia,
    changeHeaderMedia
}) {
    return (
        <TemplateSection
            title="Header"
            desc={`Add a title or choose which type of media you'll use for this header.`}
            isOptional={true}
        >
            <Box
                sx={{
                    display: {
                        xs: 'block',
                        sm: 'flex',
                        md: 'flex'
                    },
                    alignItems: 'center',
                }}
                mb={1}
            >
                <Box
                    sx={{
                        width: 150,
                    }}
                    mr={2}
                    mb={{
                        xs: 2,
                        sm: 0,
                        md: 0
                    }}
                >
                    <Selector
                        fullWidth
                        menu={headers}
                        value={headerValue?.name ?? `There isn't any`}
                        onChange={changeHeader}
                    />
                </Box>
                {headerValue?.value == 'text' &&
                    <InputLimitText
                        onChange={changeTextHeader}
                        value={textHeader?.value}
                        length={textHeader.value.length}
                        limit={60}
                        disableMargin={true}
                        width={'100%'}
                    />
                }
            </Box>
            {headerValue?.value == 'text' &&
                <>
                    {/* <Box
                        sx={{
                            justifyContent: 'flex-end',
                            width: '100%',
                            display: 'flex'
                        }}
                        mb={1}
                    >
                        <Button
                            variant="text"
                            sx={{
                                fontSize: {
                                    xs: 12,
                                    sm: 14,
                                    md: 14
                                },
                                fontWeight: 500
                            }}
                            onClick={addVariableHeader}
                        >
                            <Iconify icon="ic:round-plus" sx={{
                                marginRight: 0.5
                            }} /> Add variable
                        </Button>
                    </Box>

                    {
                        textHeader?.value?.includes('{{1}}') &&
                        <VariableContent
                            title="Example for header content"
                            desc="To help us review your content, provide examples of variables or media in the header. Do not include customer information. Meta's hosted cloud API reviews templates and variable parameters to protect the security and integrity of our service."
                            totalVariables={totalVariableHeader}
                            changeInput={changeHeaderContent}
                        />
                    } */}

                    <HeaderText 
                        addVariableHeader={addVariableHeader}
                        textHeader={textHeader}
                        totalVariableHeader={totalVariableHeader}
                        changeHeaderContent={changeHeaderContent}
                    />
                </>
            }
            {headerValue?.value == 'media' &&
                <HeaderMedia
                    headerMedia={headerMedia}
                    changeHeaderMedia={changeHeaderMedia}
                />
                // <FormControl
                //     fullWidth
                //     sx={{
                //         marginTop: 2,
                //         marginLeft: 2
                //     }}
                // >
                //     <RadioGroup
                //         row
                //         aria-labelledby="demo-radio-buttons-group-label"
                //         defaultValue={headerMedia?.extension}
                //         name="radio-buttons-group"
                //         onChange={changeHeaderMedia}
                //     >
                //         <Grid container spacing={1.5}>
                //             <Grid item xs={12} sm={4} md={4}>
                //                 <FormControlLabel
                //                     sx={{
                //                         height: 'auto',
                //                         width: '100%',
                //                         flexDirection: 'column',
                //                         border: '2px solid #bbb',
                //                         borderRadius: 1.5,
                //                         padding: '8px 0'
                //                     }}
                //                     value="image"
                //                     control={
                //                         <Radio
                //                             color="info"
                //                             size="small"
                //                             sx={{
                //                                 '& .MuiSvgIcon-root': {
                //                                     fontSize: {
                //                                         xs: 18,
                //                                         sm: 20,
                //                                         md: 20
                //                                     },
                //                                 },
                //                             }}
                //                         />
                //                     }
                //                     label={
                //                         <Box>
                //                             <Iconify
                //                                 icon="bi:image"
                //                                 sx={{
                //                                     fontSize: {
                //                                         xs: 70,
                //                                         sm: 70,
                //                                         md: 70
                //                                     },
                //                                     color: '#bbb'
                //                                 }}
                //                             />
                //                             <Typography
                //                                 fontSize={{
                //                                     xs: 14,
                //                                     sm: 16,
                //                                     md: 16
                //                                 }}
                //                                 fontWeight={600}
                //                                 textAlign="center"
                //                             >
                //                                 Image
                //                             </Typography>
                //                         </Box>
                //                     }
                //                 />
                //             </Grid>

                //             <Grid item xs={12} sm={4} md={4}>
                //                 <FormControlLabel
                //                     sx={{
                //                         height: 'auto',
                //                         width: '100%',
                //                         flexDirection: 'column',
                //                         border: '2px solid #bbb',
                //                         borderRadius: 1.5,
                //                         padding: '8px 0'
                //                     }}
                //                     value="video"
                //                     control={
                //                         <Radio
                //                             color="info"
                //                             size="small"
                //                             sx={{
                //                                 '& .MuiSvgIcon-root': {
                //                                     fontSize: {
                //                                         xs: 18,
                //                                         sm: 20,
                //                                         md: 20
                //                                     },
                //                                 },
                //                             }}
                //                         />
                //                     }
                //                     label={
                //                         <Box>
                //                             <Iconify
                //                                 icon="lets-icons:video-fill"
                //                                 sx={{
                //                                     fontSize: {
                //                                         xs: 70,
                //                                         sm: 70,
                //                                         md: 70
                //                                     },
                //                                     color: '#bbb'
                //                                 }}
                //                             />
                //                             <Typography
                //                                 fontSize={{
                //                                     xs: 14,
                //                                     sm: 16,
                //                                     md: 16
                //                                 }}
                //                                 fontWeight={600}
                //                                 textAlign="center"
                //                             >
                //                                 Video
                //                             </Typography>
                //                         </Box>
                //                     }
                //                 />
                //             </Grid>

                //             <Grid item xs={12} sm={4} md={4}>
                //                 <FormControlLabel
                //                     sx={{
                //                         height: 'auto',
                //                         width: '100%',
                //                         flexDirection: 'column',
                //                         border: '2px solid #bbb',
                //                         borderRadius: 1.5,
                //                         padding: '8px 0'
                //                     }}
                //                     value="document"
                //                     control={
                //                         <Radio
                //                             color="info"
                //                             size="small"
                //                             sx={{
                //                                 '& .MuiSvgIcon-root': {
                //                                     fontSize: {
                //                                         xs: 18,
                //                                         sm: 20,
                //                                         md: 20
                //                                     },
                //                                 },
                //                             }}
                //                         />
                //                     }
                //                     label={
                //                         <Box>
                //                             <Iconify
                //                                 icon="solar:document-text-bold"
                //                                 sx={{
                //                                     fontSize: {
                //                                         xs: 70,
                //                                         sm: 70,
                //                                         md: 70
                //                                     },
                //                                     color: '#bbb'
                //                                 }}
                //                             />
                //                             <Typography
                //                                 fontSize={{
                //                                     xs: 14,
                //                                     sm: 16,
                //                                     md: 16
                //                                 }}
                //                                 fontWeight={600}
                //                                 textAlign="center"
                //                             >
                //                                 Document
                //                             </Typography>
                //                         </Box>
                //                     }
                //                 />
                //             </Grid>
                //         </Grid>
                //     </RadioGroup>
                // </FormControl>
            }
        </TemplateSection>
    )
}