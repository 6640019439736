import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import Container from "../../../../components/containers/Container";
import { DeviceWabaFrame } from "../../../../components/containers/DeviceWabaFrame";
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import ComboBox from "../../../../components/select/ComboBox";
import { getDevices } from "../../../../store/device/deviceAction";
import { bulkWaba } from "../../../../store/marketing/marketingAction";
import { motion } from "framer-motion";
import {
  getAllWabaTemplate,
  getWabaTemplate,
  resetWabaTemplate
} from "../../../../store/waba-template/wabaTemplateAction";
import palette from "../../../../theme/palette";
import BasicInput from "../../../../components/input/BasicInput";
import { getTemplates } from "../../../../store/template/templateAction";
import formatWppMarkdown from "whatsapp-markdown";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function BulkWaba() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { user, plan } = useSelector((state) => state.user);
  const { wabaTemplates } = useSelector((state) => state.wabaTemplate);

  const [device, setDevice] = useState(null);
  const [template, setTemplate] = useState(null);
  const [file, setFile] = useState(null);

  const [detailTemplate, setDetailTemplate] = useState(null);

  const [newDevices, setNewDevices] = useState([])

  // form message type with button
  const [formButtons, setFormButtons] = useState([])
  // {displayTxt, response, type, placeholder, title}[]
  const [formTemplates, setFormTemplates] = useState([])
  // {list, index, childList: {title, desc, response}[]}[]
  const [formList, setFormList] = useState([])
  const [buttonText, setButtonText] = useState('')

  const [isReadyToSubmit, setIsReadyToSubmit] = useState(true)

  const templateMessageType = [
    { name: "Text", key: "text" },
    // { name: "Text With Button", key: "button" },
    // { name: "Custom Templates", key: "template" },
    // { name: "Lists", key: "list" },
    { name: "Subsequent", key: "multiple" },
  ];

  const formik = useFormik({
    initialValues: {
      device_key: "",
      reply_to: "",
      type: "",
      subject: "",
      content: "",
      message: '',
      templateId: '',
      title: ''
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      const data = new FormData();

      data.append("device_key", value?.device_key);
      data.append("template_id", template?.template_id ?? null);
      data.append("file", file);

      await dispatch(bulkWaba(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          setTemplate(null);
          setDevice(null);
          setFile(null);
          setDetailTemplate(null)
          setIsReadyToSubmit(true)
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    },
  });

  useEffect(() => {
    const data = {
      userId: user?.id,
    };

    dispatch(getTemplates(data));
  }, [user])

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "waba",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetAllWabaTemplate = async () => {
    toast.loading('Please wait...')
    const data = {
      params: {
        device_key: device?.device_key,
      },
    };

    const result = await dispatch(getAllWabaTemplate(data));
    const payloadData = result?.payload?.data
    if (result?.type == 'waba-template/fulfilled' && payloadData?.length === 0) {
      // no template
      toast.dismiss()
      toast.error('There is no template for the selected device, Choose another.')
      setTemplate(null)
      setDetailTemplate(null)
    } else if (result?.type == 'waba-template/rejected') {
      toast.dismiss()
      toast.error(result?.payload?.response?.data?.message)
      setTemplate(null)
      setDetailTemplate(null)
    } else {
      toast.dismiss()
    }
  };

  const handleGetWabaTemplate = async () => {
    toast.loading('Please wait...')
    const body = {
      template_id: template?.template_id ?? null,
    };

    await dispatch(getWabaTemplate(body)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setDetailTemplate(res.payload.data)
        const findTxtMessage = res?.payload?.data?.components?.find(d => d.type == 'BODY')
        formik.setFieldValue('message', findTxtMessage?.text)
        formik.setFieldValue('templateId', null)
        toast.dismiss()
      } else {
        setDetailTemplate(null)
        formik.setFieldValue('message', '')
        toast.dismiss()
        toast.error('There is no template available on this device, please choose another one.')
      }
    })
  }

  useEffect(() => {
    if (devices?.length > 0) {
      const arr = []
      devices.forEach((d) => {
        const findSameName = arr.filter(newD => newD?.name == d?.name)
        if (findSameName?.length > 0) {
          arr.push({
            catalog_id: d?.catalog_id,
            catalog_setting: d?.catalog_setting,
            device_key: d?.device_key,
            linked: d?.linked,
            name: d?.name,
            optionName: `${d?.name} ${findSameName.length + 1}`,
            official_id: d?.official_id,
            phone: d?.phone,
            platform: d?.platform,
            status: d?.status
          })
        } else {
          arr.push({
            catalog_id: d?.catalog_id,
            catalog_setting: d?.catalog_setting,
            device_key: d?.device_key,
            linked: d?.linked,
            name: d?.name,
            optionName: d?.name,
            official_id: d?.official_id,
            phone: d?.phone,
            platform: d?.platform,
            status: d?.status
          })
        }
      })
      setNewDevices(arr)
    }
  }, [devices])

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (device) {
      handleGetAllWabaTemplate();
    }
  }, [device]);

  useEffect(() => {
    if (template) {
      handleGetWabaTemplate();
    } else {
      setDetailTemplate(null)
      formik.setFieldValue('message', '')
    }
  }, [template]);

  const pushResetWabaTemplates = () => {
    setTemplate(null)
    setDevice(null)
    dispatch(resetWabaTemplate())
  }

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values])

  function getFiles(input) {
    if (input?.target?.files?.length > 0) {
      setFile(input.target.files[0])
    } else {
      setFile(null)
    }
  }

  function validateOnSubmit() {
    let err = {}
    if (!formik.values.device_key?.trim()) {
      err.device_key = 'err'
    }
    if (!template?.template_id) {
      err.template = 'err'
    }
    if (!file) {
      err.file = 'err'
    }
    if (Object.keys(err).length !== 0) {
      setIsReadyToSubmit(true)
      return
    }
    setIsReadyToSubmit(false)
  }

  useEffect(() => {
    validateOnSubmit()
  }, [
    formik.values.device_key,
    template,
    file
  ])

  if (plan?.is_bulkmessage === "1") {
    return (
      <>
        <SaveCancelContainer
          title="Cloud API Broadcast"
          buttonSaveTitle="Submit"
          buttonDisabled={isReadyToSubmit}
          onSave={formik.handleSubmit}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={8}
            // minHeight="540px"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={11}>
                  <ComboBox
                    fullWidth
                    disableMargin
                    title="Channel"
                    placeholder="Please select channel"
                    options={newDevices ?? []}
                    getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
                    value={device}
                    onChange={(e, value) => {
                      formik.setFieldValue("device_key", value?.device_key);
                      setDevice(value);
                      if (value === null) {
                        pushResetWabaTemplates()
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <ComboBox
                    fullWidth
                    disableMargin
                    options={wabaTemplates ?? []}
                    getOptionLabel={(option) => option?.name}
                    title="Template"
                    placeholder={"Choose template"}
                    value={template}
                    onChange={(e, value) => {
                      formik.setFieldValue(
                        "template_id",
                        value ? value?.template_id : null
                      );

                      setTemplate(value);
                    }}
                    disabled={wabaTemplates?.length === 0}
                  />
                </Grid>

                {/* upload file */}
                <Grid item xs={12} sm={11}>
                  <Typography
                    fontWeight="700"
                    mb={0.5}
                    alignItems="center"
                  >
                    File
                  </Typography>
                  <TextField
                    fullWidth
                    style={{
                      overflow: 'hidden'
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Paper
                            component="label"
                            variant="contained"
                            sx={{
                              background: palette.gradients.custom,
                              padding: "5px 10px",
                              borderRadius: "12px",
                              color: "#fdfdfd",
                            }}
                          >
                            <Typography fontSize={{ xs: 12, sm: 14 }} style={{ cursor: 'pointer' }}>
                              Choose File
                            </Typography>
                            <input
                              hidden
                              accept="*"
                              multiple
                              type="file"
                              onChange={getFiles}
                            />
                          </Paper>
                          <Typography
                            fontSize={{ xs: 12, sm: 14 }}
                            sx={{ mx: 2 }}
                          >
                            {file ? file?.name : null}
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              ml={0}
            >
              <DeviceWabaFrame
                detail={detailTemplate}
                message={formik.getFieldProps('message').value}
                single={{
                  formButtons,
                  formTemplates,
                  titleInListType: formik.values.title,
                  formList,
                  buttonText
                }}
              />
            </Grid>
          </Grid>
        </SaveCancelContainer>
      </>
    );
  } else {
    return (
      <Container title="Cloud API Broadcast">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
