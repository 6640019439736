import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import InputCategory from "../../../../../components/input/InputCategory";
import toast from "react-hot-toast";
import TemplateSection from "../TemplateSection";
import { useDispatch, useSelector } from "react-redux";
import { getLanguages } from "../../../../../store/languages/languageAction";
import ComboBox from "../../../../../components/select/ComboBox";
import InputLimitText from "../../../../../components/input/InputLimitText";

export default function CreateTemplate({
    setValidateNextPage,
    currentCategory,
    setCurrentCategory,
    radioInputValue,
    setRadioInputValue,
    nameValue,
    setNameValue,
    languageValue,
    setLanguageValue
}) {
    // category
    // const [currentCategory, setCurrentCategory] = useState({})
    // const [radioInputValue, setRadioInputValue] = useState({})
    // name
    // const [nameValue, setNameValue] = useState('')
    // languages
    // const [languageValue, setLanguageValue] = useState(null)

    const { languages } = useSelector((state) => state.language)
    const dispatch = useDispatch()

    const spaceRegex = /\s/g
    // const numberRegex = /^\d+$/
    const specialCharRegex = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\\-]/g

    const marketingInputs = [
        {
            label: 'Custom',
            desc: 'send promotional offers, announcments and more to increase awareness and engangement.',
            value: 'custom'
        },
        {
            label: 'Product Messages',
            desc: 'Send messages about your entire catalog or multiple products from it',
            value: 'product_messages'
        },
    ]
    const category = [
        {
            icon: 'teenyicons:sound-on-solid',
            category: 'Marketing',
            desc: 'Send promotion or information about your products, service',
            inputs: marketingInputs
        },
        // {
        //     icon: 'teenyicons:sound-on-solid',
        //     category: 'Utility',
        //     desc: 'Send utility'
        // },
        // {
        //     icon: 'teenyicons:sound-on-solid',
        //     category: 'Auth',
        //     desc: 'Send'
        // },
    ]

    async function loadLanguage() {
        await dispatch(getLanguages())
    }

    useEffect(() => {
        loadLanguage()
    }, [])

    function validatePage() {
        let err = {}
        if (!currentCategory?.category) {
            err.category = ''
        } else if (currentCategory?.inputs && !radioInputValue?.value) {
            err.category = ''
        }
        if (nameValue.length === 0) {
            err.nameValue = ''
        }
        if (!languageValue) {
            err.languageValue = ''
        }
        if (Object.keys(err).length === 0) {
            setValidateNextPage({
                next: true,
                status: 'EDIT-TEMPLATE'
            })
            return
        }
        setValidateNextPage({})
    }

    useEffect(() => {
        validatePage()
    }, [
        currentCategory,
        radioInputValue,
        nameValue,
        languageValue
    ])

    function clickCategory(item) {
        if (item.category !== currentCategory?.category) {
            setCurrentCategory(item)
            if (item.inputs?.length > 0) {
                setRadioInputValue(item.inputs[0])
            }
            return
        }
        setCurrentCategory({})
        setRadioInputValue({})
    }

    function clickInput(item) {
        setRadioInputValue(item)
    }

    const changeName = useCallback((e) => {
        if (e.target.value.length <= 512) {
            if (e.target.value.replaceAll(spaceRegex, '_') == '_' || e.target.value == '_') {
                setNameValue(e.target.value.replaceAll(spaceRegex, '_'))
            } else {
                setNameValue(e.target.value.replaceAll(spaceRegex, '_').replaceAll(specialCharRegex, ''))
            }
        } else {
            toast.dismiss()
            toast.error('Cannot exceed 512 characters')
        }
    }, [])

    function changeLanguage(e, v) {
        setLanguageValue(v)
    }

    return (
        <>
            {/* category */}
            <TemplateSection
                title="Category"
                desc="Choose a category that best describes your message template."
            >
                <Box>
                    {category.map((item, i) => {
                        return <InputCategory
                            key={i}
                            category={item.category}
                            icon={item.icon}
                            desc={item.desc}
                            inputs={item.inputs}
                            clickCategory={() => clickCategory(item)}
                            currentCategory={currentCategory}
                            radioInputValue={radioInputValue}
                            clickInput={clickInput}
                        />
                    })}
                </Box>
            </TemplateSection>

            {/* name */}
            <TemplateSection
                title="Name"
                desc="Name your message template."
            >
                <InputLimitText
                    onChange={changeName}
                    value={nameValue}
                    length={nameValue.length}
                    limit={512}
                    disableMargin={true}
                    width={'100%'}
                    marginRight="50px"
                    padding="8.5px 60px 8.5px 14px"
                />
            </TemplateSection>

            {/* languages */}
            <TemplateSection
                title="Language"
                desc="Select the language for your message template. You can delete or add more languages ​​later."
            >
                <ComboBox
                    fullWidth
                    disableMargin
                    placeholder="Choose language"
                    options={languages?.languages ?? []}
                    value={languageValue}
                    getOptionLabel={(option) => option?.display_name}
                    onChange={changeLanguage}
                />
            </TemplateSection>
        </>
    )
}