import { createSlice } from "@reduxjs/toolkit";
import {
  getMessageTemplate,
  getPublicTemplates,
  getTemplates,
} from "./templateAction";

const initialState = {
  template: null,
  isHoverSidebar: false,
  isOpenSidebar:false,
  templates: [],
  publicTemplates: [],
};

const templateSlice = createSlice({
  name: "marketing",
  initialState: initialState,
  reducers: {
    getTemplate: (state, { payload }) => {
      state.template = payload;
    },
    setIsHoverSidebar: (state, { payload }) => {
      state.isHoverSidebar = payload;
    },
    setIsOpenSidebar: (state, { payload }) => {
      state.isOpenSidebar = payload;
    },
  },
  extraReducers: {
    [getTemplates.fulfilled]: (state, { payload }) => {
      state.templates = payload;
    },
    [getTemplates.rejected]: (state, { payload }) => {
      state.templates = [];
    },
    [getPublicTemplates.fulfilled]: (state, { payload }) => {
      state.publicTemplates = payload;
    },
    [getPublicTemplates.rejected]: (state, { payload }) => {
      state.publicTemplates = [];
    },
    [getMessageTemplate.fulfilled]: (state, { payload }) => {
      state.template = payload;
    },
    [getMessageTemplate.rejected]: (state, { payload }) => {
      state.template = [];
    },
  },
});

export const { getTemplate, setIsHoverSidebar,setIsOpenSidebar } = templateSlice.actions;

export default templateSlice.reducer;
