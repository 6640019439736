import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Scrollbar from "../../../../components/Scrollbar";
import CardContainer from "../../../../components/containers/CardContainer";
import CustomModal from "../../../../components/modal/CustomModal";
import ShippingRateForm from "./ShippingRateForm";
import RateDetails from "./ShippingRateDetails";

export default function ShippingRate() {
  const dispatch = useDispatch();

  const [openAddModal, setOpenAddModal] = useState(false);

  const _openAddModal = () => setOpenAddModal(!openAddModal);
  const _onCloseAddModal = () => setOpenAddModal(!openAddModal);

  return (
    <>
      <CardContainer
        title="Shipping Rate"
        withButton
        buttonTitle="Add"
        buttonIcon="carbon:add-alt"
        onClick={() => _openAddModal(!openAddModal)}
      >
        <br />
        <Scrollbar>
        <RateDetails />
        </Scrollbar>
      </CardContainer>
      <CustomModal open={openAddModal}>
        <ShippingRateForm onCancel={_onCloseAddModal} />
      </CustomModal>
    </>
  );
}
