import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BasicButton from "../../../../components/button/BasicButton";
import { getAllTopup } from "../../../../store/topup/topupAction";
import palette from "../../../../theme/palette";

const tableCell = [
  "Order Number",
  "Payment Name",
  "Amount",
  "Status",
  "Action",
];

export default function CreditsList(props) {
  const dispatch = useDispatch();

  const { topups } = useSelector((state) => state.topup);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const handleGetAllTopup = async (data) => {
    setIsLoading(true);

    await dispatch(getAllTopup(data));

    setIsLoading(false);
  };

  useEffect(() => {
    const data = {
      params: {
        page: page,
      },
    };

    handleGetAllTopup(data);
  }, [page]);

  return (
    <>
      <Typography fontSize={20} fontWeight="700">
        Recharge Log History
      </Typography>
      <Typography fontSize={14} fontWeight="500" sx={{ mb: 2 }}>
        Membership fees are billed at the beginning of each period and may take
        a few days after the billing date to appear on your account.
      </Typography>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: palette.gradients.custom,
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => {
                return (
                  <TableCell align="left" key={index}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography color="#FDFDFD">{item}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : topups?.data?.length ? (
              topups?.data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item?.order_number}</TableCell>
                  <TableCell align="left">{item?.payment_name}</TableCell>
                  <TableCell align="left">{item?.amount}</TableCell>
                  <TableCell align="left">
                    <Box
                      component="button"
                      paddingX={1}
                      paddingY={0.5}
                      width="fit-content"
                      borderRadius="8px"
                      sx={{
                        backgroundColor:
                          item?.status === "paid"
                            ? "#c8e6c9"
                            : item?.status === "pending"
                            ? "#FFF5D7"
                            : item?.status === "unpaid"
                            ? " #ffcdd2"
                            : null,
                        border: "none",
                      }}
                    >
                      <Typography color="#131313" fontSize="14px">
                        {item?.status === "paid"
                          ? "Paid"
                          : item?.status === "pending"
                          ? "Pending"
                          : item?.status === "unpaid"
                          ? "Unpaid"
                          : null}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item?.status === "pending" || item?.status === "unpaid" ? (
                      <Stack direction="row" spacing={1}>
                        <BasicButton
                          title="Repay"
                          onClick={() => {
                            window.location.href = item?.url_direct;
                          }}
                        />
                      </Stack>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (parseInt(page) < topups?.pagination?.last_page) {
                setPage(parseInt(page) + 1);
              }
            }}
            disabled={parseInt(page) >= topups?.pagination?.last_page}
          />
        </Box>
      </Box>
    </>
  );
}
