import {
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import FormContainer from "../../../../components/containers/FormContainer";
import {
  getContactScrappings,
  stopContactScrapping,
} from "../../../../store/contact/contactAction";
import palette from "../../../../theme/palette";
import Form from "./Form";

const tableCell = ["No", "Device Key", "Valid", "Invalid", "Status", "Action"];

function ContactScrapping() {
  const dispatch = useDispatch();

  const { contactScrappings } = useSelector((state) => state.contact);

  const [isLoading, setIsLoading] = useState(false);

  const handleStopContactScrapping = async (data) => {
    toast.loading("Please wait...");

    await dispatch(stopContactScrapping(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.error(res.payload.message);

        handleGetContactScrapping();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  const handleGetContactScrapping = async () => {
    setIsLoading(true);

    await dispatch(getContactScrappings());

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetContactScrapping();
  }, []);

  console.log(contactScrappings);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Form />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormContainer title="Results">
          <Scrollbar>
            <TableContainer
              component={Paper}
              style={{ backgroundColor: "#FDFDFD" }}
            >
              <Table aria-label="simple table">
                <TableHead
                  style={{
                    background: palette.gradients.custom,
                  }}
                >
                  <TableRow>
                    {tableCell.map((item, index) => {
                      return (
                        <TableCell align="left" key={index}>
                          <Box
                            alignItems="center"
                            display="flex"
                            justifyContent={"space-between"}
                          >
                            <Typography color="#FDFDFD">{item}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={tableCell.length} align="center">
                        <Box
                          component="img"
                          src="/images/loading.svg"
                          alt="loading"
                          height="48px"
                          sx={{ mx: "auto" }}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (contactScrappings?.data ?? [])?.filter(
                      (val) =>
                        val?.status === "1" ||
                        val?.status === "2" ||
                        val?.status === "3"
                    )?.length ? (
                    (contactScrappings?.data ?? [])
                      ?.filter(
                        (val) =>
                          val?.status === "1" ||
                          val?.status === "2" ||
                          val?.status === "3"
                      )
                      ?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            <Box sx={{ width: "160px", overflow: "hidden" }}>
                              {item?.device}
                            </Box>
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item?.valid}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item?.invalid}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            <Box
                              component="button"
                              paddingX={1}
                              paddingY={0.5}
                              width="fit-content"
                              borderRadius="8px"
                              sx={{
                                backgroundColor:
                                  item?.status === "Success"
                                    ? "#c8e6c9"
                                    : item?.status === "Canceled"
                                    ? "#ffcdd2"
                                    : "#f5f5f5",
                                border: "none",
                              }}
                            >
                              <Typography color="#131313" fontSize="14px">
                                {item?.status === "1"
                                  ? "Processing"
                                  : item?.status === "2"
                                  ? "In Progresss"
                                  : item?.status === "3"
                                  ? "Pending"
                                  : "Pending"}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                onClick={() => {
                                  handleStopContactScrapping({
                                    scraping_id: item.scraping_id,
                                  });
                                }}
                              >
                                <Iconify
                                  icon={"ant-design:stop-outlined"}
                                  color="#F15A28"
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={tableCell.length}>
                        Data Not Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </FormContainer>
      </Grid>
    </Grid>
  );
}

export default ContactScrapping;
