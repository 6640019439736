import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import formatWppMarkdown from "whatsapp-markdown";
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import { DeviceFrame } from "../../../../components/containers/DeviceFrame";
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import BasicInput from "../../../../components/input/BasicInput";
import CustomModal from "../../../../components/modal/CustomModal";
import ComboBox from "../../../../components/select/ComboBox";
import { getContacts } from "../../../../store/contact/contactAction";
import {
  getDevices,
  getIsBusiness,
  getStatusDevice,
} from "../../../../store/device/deviceAction";
import {
  getGroupMembers,
  getGroups,
} from "../../../../store/group/groupAction";
import { bulkMessage } from "../../../../store/marketing/marketingAction";
import { getTemplates } from "../../../../store/template/templateAction";
import palette from "../../../../theme/palette";
import Upload from "./Upload";

const targetData = [
  {
    name: "Contact",
    key: "contact",
  },
  {
    name: "Recent Chat Whatsapp",
    key: "recent",
  },
  {
    name: "From Excel",
    key: "excel",
  },
  {
    name: "Input Manual",
    key: "manual",
  },
  {
    name: "From Whatsapp Group",
    key: "group",
  },
];

const messageTypeData = [
  { name: "Text", key: "text" },
  // { name: "Text With Button", key: "button" },
  // { name: "Custom Templates", key: "template" },
  // { name: "Lists", key: "list" },
  { name: "Subsequent", key: "multiple" },
];

const headGroupMembers = [
  {
    field: "",
    headerName: "Number",
    renderCell: (params) => {
      const { row } = params;

      return row;
    },
  },
];

const rangeData = ["Yes", "No"];

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function BulkMessage() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { user, plan } = useSelector((state) => state.user);
  const { groups, groupMembers } = useSelector((state) => state.group);
  const { templates } = useSelector((state) => state.template);

  const [editor, setEditor] = useState(false);

  const [device, setDevice] = useState(null);
  const [target, setTarget] = useState(null);
  const [group, setGroup] = useState(null);

  const [typeMessage, setTypeMessage] = useState(null);
  const [templateMessage, setTemplateMessage] = useState(null);

  const [visibleRange, setVisibleRange] = useState(null);
  const isRange = visibleRange === "Yes";
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);

  const [isBusiness, setIsBusiness] = useState(false);

  const [minRangeLimit, setMinRangeLimit] = useState(1);
  const [maxRangeLimit, setMaxRangeLimit] = useState(1000);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(1000);

  const [formButtons, setFormButtons] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formLists, setFormLists] = useState([]);
  const [formListsButton, setFormListsButton] = useState([]);
  const [formListsTitle, setFormListsTitle] = useState(null);
  const [formListsButtonText, setFormListsButtonText] = useState(null);

  const [formMultiples, setFormMultiples] = useState([]);
  const [formMultiplesButtons, setFormMultiplesButtons] = useState([]);
  const [formMultiplesTemplates, setFormMultiplesTemplates] = useState([]);
  const [formMultiplesLists, setFormMultiplesLists] = useState([]);
  const [formMultiplesListsButton, setFormMultiplesListsButton] = useState([]);

  // button type
  let handleChangeFormButtons = (index, name, value) => {
    let newFormValues = [...formButtons];
    newFormValues[index][name] = value;
    setFormButtons(newFormValues);
  };

  let addFormButtons = () => {
    setFormButtons([...formButtons, { button_reply: "", button_title: "" }]);
  };

  let removeFormButtons = (index) => {
    let newFormValues = [...formButtons];
    newFormValues.splice(index, 1);
    setFormButtons(newFormValues);
  };

  // list type
  let handleChangeFormLists = (index, name, value) => {
    let newFormValues = [...formLists];
    newFormValues[index][name] = value;
    setFormLists(newFormValues);
  };

  let addFormLists = () => {
    setFormLists([
      ...formLists,
      {
        list: "",
        index: formLists.length ? formLists[formLists.length - 1].index + 1 : 0,
      },
    ]);
  };

  let removeFormLists = (index) => {
    let newFormValues = [...formLists];
    newFormValues.splice(index, 1);
    setFormLists(newFormValues);
  };

  // list type button
  let handleChangeFormListsButton = (index, name, value) => {
    let newFormValues = [...formListsButton];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormListsButton(newFormValues);
  };

  let addFormListsButton = (listIndex) => {
    setFormListsButton([
      ...formListsButton,
      {
        title: "",
        description: "",
        row_reply: "",
        listIndex: listIndex,
        index: formListsButton.length
          ? formListsButton[formListsButton.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormListsButton = (index) => {
    let newFormValues = [...formListsButton];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormListsButton(newFormValues);
  };

  // template type
  let handleChangeFormTemplates = (index, name, value) => {
    let newFormValues = [...formTemplates];
    newFormValues[index][name] = value;
    setFormTemplates(newFormValues);
  };

  let addFormTemplates = (data) => {
    setFormTemplates([
      ...formTemplates,
      { type: data, displayText: "", action: "" },
    ]);
  };

  let removeFormTemplates = (index) => {
    let newFormValues = [...formTemplates];
    newFormValues.splice(index, 1);
    setFormTemplates(newFormValues);
  };

  // multiple type
  let handleChangeFormMultiples = (index, name, value) => {
    let newFormValues = [...formMultiples];
    newFormValues[index][name] = value;
    setFormMultiples(newFormValues);
  };

  let addFormMultiples = () => {
    setFormMultiples([
      ...formMultiples,
      {
        message: "",
        fileSelected: "",
        isVoice: false,
        isOpenModal: false,
        index: formMultiples.length
          ? formMultiples[formMultiples.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiples = (index) => {
    let newFormValues = [...formMultiples];
    newFormValues.splice(index, 1);
    setFormMultiples(newFormValues);
  };

  // list type button
  let handleChangeFormMultiplesButtons = (index, name, value) => {
    let newFormValues = [...formMultiplesButtons];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesButtons(newFormValues);
  };

  let addFormMultiplesButtons = (multipleIndex) => {
    setFormMultiplesButtons([
      ...formMultiplesButtons,
      {
        button_title: "",
        button_reply: "",
        multipleIndex: multipleIndex,
        index: formMultiplesButtons.length
          ? formMultiplesButtons[formMultiplesButtons.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesButtons = (index) => {
    let newFormValues = [...formMultiplesButtons];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesButtons(newFormValues);
  };

  // list type button
  let handleChangeFormMultiplesTemplates = (index, name, value) => {
    let newFormValues = [...formMultiplesTemplates];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesTemplates(newFormValues);
  };

  let addFormMultiplesTemplates = (multipleIndex, type) => {
    setFormMultiplesTemplates([
      ...formMultiplesTemplates,
      {
        type: type,
        displayText: "",
        action: "",
        multipleIndex: multipleIndex,
        index: formMultiplesTemplates.length
          ? formMultiplesTemplates[formMultiplesTemplates.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesTemplates = (index) => {
    let newFormValues = [...formMultiplesTemplates];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesTemplates(newFormValues);
  };

  // list type list
  let handleChangeFormMultiplesLists = (index, name, value) => {
    let newFormValues = [...formMultiplesLists];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesLists(newFormValues);
  };

  let addFormMultiplesLists = (multipleIndex) => {
    setFormMultiplesLists([
      ...formMultiplesLists,
      {
        title: "",
        buttonText: "",
        multipleIndex: multipleIndex,
        index: formMultiplesLists.length
          ? formMultiplesLists[formMultiplesLists.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesLists = (index) => {
    let newFormValues = [...formMultiplesLists];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesLists(newFormValues);
  };

  // list type list
  let handleChangeFormMultiplesListsButton = (index, name, value) => {
    let newFormValues = [...formMultiplesListsButton];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormMultiplesListsButton(newFormValues);
  };

  let addFormMultiplesListsButton = (multipleListIndex) => {
    setFormMultiplesListsButton([
      ...formMultiplesListsButton,
      {
        title: "",
        buttonText: "",
        multipleListIndex: multipleListIndex,
        index: formMultiplesListsButton.length
          ? formMultiplesListsButton[formMultiplesListsButton.length - 1]
              .index + 1
          : 0,
      },
    ]);
  };

  let removeFormMultiplesListsButton = (index) => {
    let newFormValues = [...formMultiplesListsButton];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormMultiplesListsButton(newFormValues);
  };

  const formik = useFormik({
    initialValues: {
      accountid: "",
      number: "",
      message: "",
      type: "",
      fileUrl: "",
      fileName: "",
      isVoice: false,
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      await dispatch(getStatusDevice({ accountid: value.accountid })).then(
        async (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            if (res.payload.result) {
              if (res.payload.ready) {
                let data = null;

                if (target?.key === "contact") {
                  data = {
                    accountid: value.accountid,
                    number: target?.key,
                    message: value.message,
                    range: isRange ? value.range : [],
                    filter: value.filter ?? null,
                    type: value.type,
                    fileUrl: value.fileSelected
                      ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                      : null,
                    fileName: null,
                    isVoice: value.isVoice,
                    footer: value.footer,
                    user_id: user.id,
                  };
                } else if (target?.key === "recent") {
                  data = {
                    accountid: value.accountid,
                    number: target?.key,
                    message: value.message,
                    type: value.type,
                    fileUrl: value.fileSelected
                      ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                      : null,
                    fileName: null,
                    isVoice: value.isVoice,
                    footer: value.footer,
                    user_id: user.id,
                  };
                } else if (target?.key === "excel") {
                  data = {
                    accountid: value.accountid,
                    number: value.number,
                    message: value.message,
                    range: isRange ? value.range : [],
                    type: value.type,
                    fileUrl: value.fileSelected
                      ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                      : null,
                    fileName: null,
                    isVoice: value.isVoice,
                    footer: value.footer,
                    user_id: user.id,
                  };
                } else if (target?.key === "manual") {
                  const numbers = [];

                  value.number.split("\n").map((item) => {
                    numbers.push(item);
                  });

                  data = {
                    accountid: value.accountid,
                    number: numbers,
                    message: value.message,
                    type: value.type,
                    fileUrl: value.fileSelected
                      ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                      : null,
                    fileName: null,
                    isVoice: value.isVoice,
                    footer: value.footer,
                    user_id: user.id,
                  };
                } else if (target?.key === "group") {
                  data = {
                    accountid: value.accountid,
                    number: value.number,
                    message: value.message,
                    type: value.type,
                    fileUrl: value.fileSelected
                      ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
                      : null,
                    fileName: null,
                    isVoice: value.isVoice,
                    footer: value.footer,
                    user_id: user.id,
                  };
                }

                if (typeMessage?.key === "button") {
                  data.type = "button";
                  data.buttons = formButtons ?? null;
                } else if (typeMessage?.key === "template") {
                  let templates = [];

                  formTemplates.map((item, index) => {
                    if (item.type === "quick-reply") {
                      templates.push({
                        index: index + 1,
                        quickReplyButton: {
                          displayText: item.displayText,
                          button_reply: item.action,
                        },
                      });
                    } else if (item.type === "url") {
                      templates.push({
                        index: index + 1,
                        urlButton: {
                          displayText: item.displayText,
                          url: item.action,
                        },
                      });
                    } else if (item.type === "call") {
                      templates.push({
                        index: index + 1,
                        callButton: {
                          displayText: item.displayText,
                          phoneNumber: item.action,
                        },
                      });
                    }
                  });

                  data.type = "template";
                  data.templates = templates ?? null;
                } else if (typeMessage?.key === "list") {
                  let lists = [];

                  formLists.map((item, index) => {
                    let buttonLists = [];

                    formListsButton.map((item) => {
                      if (item.listIndex === index) {
                        buttonLists.push({
                          row_reply: item.row_reply,
                          title: item.title,
                          description: item.description,
                        });
                      }
                    });

                    lists.push({
                      title: item.list,
                      rows: buttonLists,
                    });
                  });

                  data.type = "list";
                  data.listSections = lists ?? null;
                  data.title = value.title;
                  data.buttonText = value.buttonText;
                } else if (typeMessage?.key === "multiple") {
                  let multiples = [];

                  formMultiples.map((item) => {
                    if (item.type === "text") {
                      multiples.push({
                        type: "text",
                        message: item.message,
                        fileUrl: item.fileSelected
                          ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                          : null,
                        fileName: null,
                        IsVoice: item.isVoice,
                      });
                    } else if (item.type === "button") {
                      const buttons = [];

                      formMultiplesButtons.map((i) => {
                        buttons.push({
                          button_title: i.button_title,
                          button_reply: i.button_reply,
                        });
                      });

                      multiples.push({
                        type: "button",
                        message: item.message,
                        fileUrl: item.fileSelected
                          ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                          : null,
                        fileName: null,
                        IsVoice: item.isVoice,
                        buttons: buttons,
                      });
                    } else if (item.type === "template") {
                      let templates = [];

                      formMultiplesTemplates.map((x, idx) => {
                        if (x.type === "quick-reply") {
                          templates.push({
                            index: idx + 1,
                            quickReplyButton: {
                              displayText: x.displayText,
                              button_reply: x.action,
                            },
                          });
                        } else if (x.type === "url") {
                          templates.push({
                            index: idx + 1,
                            urlButton: {
                              displayText: x.displayText,
                              url: x.action,
                            },
                          });
                        } else if (x.type === "call") {
                          templates.push({
                            index: idx + 1,
                            callButton: {
                              displayText: x.displayText,
                              phoneNumber: x.action,
                            },
                          });
                        }
                      });

                      multiples.push({
                        type: "template",
                        message: item.message,
                        fileUrl: item.fileSelected
                          ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                          : null,
                        fileName: null,
                        IsVoice: item.isVoice,
                        templates: templates ?? [],
                      });
                    } else if (item.type === "list") {
                      let lists = [];

                      formMultiplesLists.map((x, index) => {
                        let buttonLists = [];

                        formMultiplesListsButton.map((y) => {
                          if (y.multipleListIndex === index) {
                            buttonLists.push({
                              row_reply: y.row_reply,
                              title: y.title,
                              description: y.description,
                            });
                          }
                        });

                        lists.push({
                          title: x.list,
                          rows: buttonLists,
                        });
                      });

                      multiples.push({
                        type: "list",
                        message: item.message,
                        fileUrl: item.fileSelected
                          ? `https://new-client.realm.chat/cloud_storage/${item.fileSelected.file_url}`
                          : null,
                        fileName: null,
                        IsVoice: item.isVoice,
                        title: item.title,
                        buttonText: item.buttonText,
                        listSections: lists ?? [],
                      });
                    }
                  });

                  data.type = "multiple";
                  data.messageContents = multiples ?? [];
                }

                data.template_id = value?.templateId ?? null;

                await dispatch(bulkMessage(data)).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message)
                    resetForm();

                    setDevice(null);
                    setTarget(null);
                    setTypeMessage(null);
                    setTemplateMessage(null);
                    setFormButtons([]);
                    setFormLists([]);
                    setFormListsButton([]);
                    setFormTemplates([]);
                    setFormListsTitle(null);
                    setFormListsButtonText(null);
                    setFormMultiples([]);
                    setFormMultiplesButtons([]);
                    setFormMultiplesTemplates([]);
                    setFormMultiplesLists([]);
                    setFormMultiplesListsButton([]);
                    setVisibleRange("No");
                    setMinRangeLimit(1);
                    setMaxRangeLimit(1000);
                    setMinLimit(1);
                    setMaxLimit(1000);
                  } else {
                    toast.dismiss();
                    toast.error(res.payload.message)
                  }
                });
              } else {
                toast.dismiss();
                toast.error("Device Not Connected")
              }
            } else {
              toast.dismiss();
              toast.error("Device Not Connected")
            }
          } else {
            toast.dismiss();
            toast.error("Device Not Connected")
          }
        }
      );
    },
  });

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetContacts = async (data) => {
    await dispatch(getContacts(data));
  };

  const handleGetGroups = async (data) => {
    await dispatch(getGroups(data));
  };

  const handleGetGroupMembers = async (data) => {
    await dispatch(getGroupMembers(data));
  };

  const handleGetTemplates = async (data) => {
    await dispatch(getTemplates(data));
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((item) =>
      groupMembers.find((row) => row === item)
    );

    formik.setFieldValue("number", selectedRowsData);
  };

  const handleSelectAllGroupMembers = () => {
    let _ids = [];

    if (formik.values?.number?.length > 0) {
      _ids = [];
    } else {
      _ids = groupMembers.map((item) => item);
    }

    formik.setFieldValue("number", _ids);
  };

  useEffect(() => {
    handleGetDevices();
    handleGetContacts({ params: { search: null } });

    const data = {
      userId: user?.id,
    };

    handleGetTemplates(data);
  }, []);

  useEffect(() => {
    if (device) {
      handleGetGroups({ accountid: device.device_key });
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      if (group) {
        handleGetGroupMembers({
          accountid: device.device_key,
          target: group.id,
        });
      } else {
        handleGetGroupMembers({ accountid: device.device_key, target: "all" });
      }
    }
  }, [device, group]);

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values]);

  useEffect(() => {
    if (minLimit > maxLimit) {
      setMinLimit(maxLimit - 1);
      setMinRangeLimit(maxLimit - 1);
    }
  }, [minLimit]);

  useEffect(() => {
    if (minLimit > maxLimit) {
      setMaxLimit(minLimit + 1);
      setMaxRangeLimit(minLimit + 1);
    }
  }, [maxLimit]);

  useEffect(() => {
    if (templateMessage) {
      setTypeMessage(
        messageTypeData?.find((e) => e.key === templateMessage?.type)
      );

      if (templateMessage?.type === "text") {
        formik.setFieldValue(
          "message",
          templateMessage?.message_template?.message
        );
      }

      if (templateMessage?.type === "button") {
        formik.setFieldValue(
          "message",
          templateMessage?.message_template?.message
        );

        setFormButtons(templateMessage?.message_template?.buttons);
      }

      if (templateMessage?.type === "list") {
        formik.setFieldValue(
          "message",
          templateMessage?.message_template?.message
        );
        formik.setFieldValue("title", templateMessage?.message_template?.title);
        formik.setFieldValue(
          "buttonText",
          templateMessage?.message_template?.buttonText
        );

        let formListsRaw = [];
        let formListsButtonRaw = [];

        templateMessage?.message_template?.listSections.map((item, index) => {
          formListsRaw.push({
            index: index,
            list: item.title,
          });

          item.rows.map((x, i) => {
            formListsButtonRaw.push({
              index: i,
              description: x.description,
              listIndex: index,
              row_reply: x.row_reply,
              title: x.title,
            });
          });
        });

        setFormLists(formListsRaw);
        setFormListsButton(formListsButtonRaw);
      }

      if (templateMessage?.type === "template") {
        formik.setFieldValue(
          "message",
          templateMessage?.message_template?.message
        );

        let templates = [];

        templateMessage?.message_template?.templates.map((item) => {
          if (item?.quickReplyButton) {
            templates.push({
              action: item?.quickReplyButton.button_reply,
              displayText: item?.quickReplyButton.displayText,
              type: "quick-reply",
            });
          }
          if (item?.urlButton) {
            templates.push({
              action: item?.urlButton.url,
              displayText: item?.urlButton.displayText,
              type: "url",
            });
          }
          if (item?.callButton) {
            templates.push({
              action: item?.callButton.phoneNumber,
              displayText: item?.callButton.displayText,
              type: "call",
            });
          }
        });

        setFormTemplates(templates);
      }
    }
  }, [templateMessage]);

  const handleGetIsBusiness = async (data) => {
    await dispatch(getIsBusiness(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setIsBusiness(res.payload.isBusiness);
        formik.setFieldValue("type", null);
        setTypeMessage(null);
      }
    });
  };

  useEffect(() => {
    if (formik.values.accountid) {
      handleGetIsBusiness(formik.values.accountid);
    } else {
      setIsBusiness(false);
    }
  }, [formik.values.accountid]);

  if (plan?.is_bulkmessage === "1") {
    return (
      <>
        <SaveCancelContainer
          title="WhatsApp Broadcast"
          buttonSaveTitle="Submit"
          buttonDisabled={
            typeMessage?.key === "multiple"
              ? !formMultiples.length
              : !formik.values.message.length
          }
          onSave={formik.handleSubmit}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} minHeight="540px">
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Channel"
                      placeholder="Please select channel"
                      options={devices ?? []}
                      getOptionLabel={(option) => option?.name}
                      value={device}
                      onChange={(e, value) => {
                        formik.setFieldValue("accountid", value.device_key);
                        setDevice(value);
                        setGroup(null);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Target"
                      placeholder="Please choose target"
                      options={targetData ?? []}
                      getOptionLabel={(option) => option?.name}
                      value={target}
                      onChange={(e, value) => {
                        formik.setFieldValue("target", value.key);
                        setTarget(value);
                      }}
                    />
                  </Grid>
                  {target?.key === "contact" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <ComboBox
                          disableMargin
                          // disabled
                          options={rangeData ?? []}
                          onChange={(e, value) => {
                            setVisibleRange(value);
                          }}
                          fullWidth
                          title="Use Range Limit"
                          placeholder="Please choose target"
                        />
                      </Grid>
                      {isRange && (
                        <>
                          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
                            <Box sx={{ width: "50%", pr: { xs: 0, lg: 1 } }}>
                              <BasicInput
                                fullWidth
                                disableMargin
                                title="Min. Range"
                                value={minLimit}
                                onChange={(e) => {
                                  setMinLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  setMinRangeLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  formik.setFieldValue("range", [
                                    parseFloat(e.target.value),
                                    maxRangeLimit,
                                  ]);
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "50%", pl: { xs: 0, lg: 1 } }}>
                              <BasicInput
                                fullWidth
                                disableMargin
                                title="Max. Range"
                                value={maxLimit}
                                onChange={(e) => {
                                  setMaxLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  setMaxRangeLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  formik.setFieldValue("range", [
                                    minRangeLimit,
                                    parseFloat(e.target.value),
                                  ]);
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box display="flex" gap={2} alignItems="center">
                              <Chip
                                label={minRangeLimit}
                                sx={{
                                  color: "white",
                                  backgroundColor: palette.primary.main,
                                }}
                              />
                              <Slider
                                min={minLimit}
                                max={maxLimit}
                                value={[minRangeLimit, maxRangeLimit]}
                                onChange={(e, value) => {
                                  formik.setFieldValue("range", value);
                                  setMinRangeLimit(value[0]);
                                  setMaxRangeLimit(value[1]);
                                }}
                                valueLabelDisplay="auto"
                              />
                              <Chip
                                label={maxRangeLimit}
                                sx={{
                                  color: "white",
                                  backgroundColor: palette.primary.main,
                                }}
                              />
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12}>
                        <label
                          style={{ marginBottom: "4px", fontWeight: "700" }}
                        >
                          Filter Contact
                        </label>
                        <Autocomplete
                          multiple
                          freeSolo
                          options={[]}
                          title="Filter Contact"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              title="Filter Contact"
                              placeholder="Filter by name or phone number, ex: 60123,Hassan,FB"
                            />
                          )}
                          onChange={(e, value) => {
                            formik.setFieldValue("filter", value);
                          }}
                          size="small"
                        />
                      </Grid>
                    </>
                  ) : target?.key === "excel" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <ComboBox
                          disableMargin
                          // disabled
                          options={rangeData ?? []}
                          onChange={(e, value) => {
                            setVisibleRange(value);
                          }}
                          fullWidth
                          title="Use Range Limit"
                          placeholder="Please choose target"
                        />
                      </Grid>
                      {isRange && (
                        <>
                          <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
                            <Box sx={{ width: "50%", pr: { xs: 0, lg: 1 } }}>
                              <BasicInput
                                fullWidth
                                disableMargin
                                title="Min. Range"
                                value={minLimit}
                                onChange={(e) => {
                                  setMinLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  setMinRangeLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  formik.setFieldValue("range", [
                                    parseFloat(e.target.value),
                                    maxRangeLimit,
                                  ]);
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "50%", pl: { xs: 0, lg: 1 } }}>
                              <BasicInput
                                fullWidth
                                disableMargin
                                title="Max. Range"
                                value={maxLimit}
                                onChange={(e) => {
                                  setMaxLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  setMaxRangeLimit(
                                    e.target.value
                                      ? parseFloat(e.target.value)
                                      : 1
                                  );
                                  formik.setFieldValue("range", [
                                    minRangeLimit,
                                    parseFloat(e.target.value),
                                  ]);
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Box display="flex" gap={2} alignItems="center">
                              <Chip
                                label={minRangeLimit}
                                sx={{
                                  color: "white",
                                  backgroundColor: palette.primary.main,
                                }}
                              />
                              <Slider
                                min={minLimit}
                                max={maxLimit}
                                value={[minRangeLimit, maxRangeLimit]}
                                onChange={(e, value) => {
                                  formik.setFieldValue("range", value);
                                  setMinRangeLimit(value[0]);
                                  setMaxRangeLimit(value[1]);
                                }}
                                valueLabelDisplay="auto"
                              />
                              <Chip
                                label={maxRangeLimit}
                                sx={{
                                  color: "white",
                                  backgroundColor: palette.primary.main,
                                }}
                              />
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={12} sx={{ position: "relative" }}>
                        <BasicInput
                          fullWidth
                          disableMargin
                          title="URL Spreadsheet"
                          placeholder="Type your file url spreadsheet"
                          {...formik.getFieldProps("number")}
                        />
                      </Grid>
                    </>
                  ) : target?.key === "manual" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <BasicInput
                          fullWidth
                          disableMargin
                          multiline
                          rows={5}
                          title="Phone Number"
                          placeholder="Format:&#10;60139932680&#10;60139932680&#10;60139932680"
                          {...formik.getFieldProps("number")}
                        />
                      </Grid>
                    </>
                  ) : target?.key === "group" ? (
                    <>
                      <Grid item xs={12} sm={12}>
                        <ComboBox
                          fullWidth
                          disableMargin
                          title="Group"
                          placeholder="Please select group"
                          options={groups ?? []}
                          getOptionLabel={(option) => option?.name}
                          value={group}
                          onChange={(e, value) => {
                            formik.setFieldValue("group", value.id);
                            setGroup(value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Checkbox
                            type="checkbox"
                            onChange={() => {
                              handleSelectAllGroupMembers();
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "400", color: "#7B7B7B" }}
                          >
                            Select All
                          </Typography>
                        </Box>
                        {groupMembers?.length ? (
                          <div style={{ height: 400, width: "100%" }}>
                            <DataGrid
                              rows={groupMembers ?? []}
                              columns={headGroupMembers}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              checkboxSelection
                              getRowId={(row) => row}
                              selectionModel={formik.values.number ?? []}
                              onSelectionModelChange={(ids) =>
                                onRowsSelectionHandler(ids)
                              }
                            />
                          </div>
                        ) : (
                          <Box
                            component="img"
                            src="/images/loading.svg"
                            alt="loading"
                            height="48px"
                            sx={{ mx: "auto" }}
                          />
                        )}
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} sm={12}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      options={
                        isBusiness
                          ? messageTypeData.filter(
                              (val) =>
                                val?.key === "text" || val?.key === "multiple"
                            ) ?? []
                          : messageTypeData ?? []
                      }
                      getOptionLabel={(option) => option?.name}
                      title="Message Type"
                      placeholder={"Choose type message"}
                      value={typeMessage}
                      onChange={(e, value) => {
                        formik.setFieldValue("type", value ? value?.key : null);

                        setFormButtons([]);
                        setFormLists([]);
                        setFormListsButton([]);
                        setFormTemplates([]);
                        if (value?.key === "multiple") {
                          setFormMultiples([
                            {
                              message: "",
                              fileSelected: "",
                              isVoice: false,
                              isOpenModal: false,
                              index: formMultiples.length
                                ? formMultiples[formMultiples.length - 1]
                                    .index + 1
                                : 0,
                            },
                          ]);
                        } else {
                          setFormMultiples([]);
                        }

                        formik.setFieldValue("title", "");
                        formik.setFieldValue("buttonText", "");
                        formik.setFieldValue("message", "");

                        setEditor(false);
                        setTemplateMessage(null);
                        setTypeMessage(value);
                      }}
                    />
                  </Grid>
                  {typeMessage?.key !== "multiple" ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <ComboBox
                          disableMargin
                          options={
                            isBusiness
                              ? templates?.data.filter(
                                  (val) =>
                                    val?.type === "text" ||
                                    val?.type === "multiple"
                                ) ?? []
                              : templates?.data ?? []
                          }
                          getOptionLabel={(option) => option?.template_name}
                          fullWidth
                          title="Add Message Template"
                          placeholder="Choose template"
                          value={templateMessage}
                          onChange={(e, value) => {
                            formik.setFieldValue(
                              "templateId",
                              value ? value?.id : null
                            );

                            setFormButtons([]);
                            setFormLists([]);
                            setFormListsButton([]);
                            setFormTemplates([]);
                            formik.setFieldValue("title", "");
                            formik.setFieldValue("buttonText", "");
                            formik.setFieldValue("message", "");

                            setEditor(false);
                            setTypeMessage(null);
                            setTemplateMessage(value);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="contained"
                          onClick={() => setEditor(!editor)}
                          sx={{
                            mt: 3.6,
                            background: palette.gradients.custom,
                            borderRadius: "8px",
                            color: "#fdfdfd",
                            width: "100%",
                            height: "36px",
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            {!editor ? "Add Manual Template" : "Close Editor"}
                          </Typography>
                        </Button>
                      </Grid>

                      {editor || typeMessage ? (
                        <Grid item xs={12} sm={12}>
                          <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.3 }}
                          >
                            {typeMessage?.key === "list" ? (
                              <Box mb={2}>
                                <BasicInput
                                  fullWidth
                                  disableMargin
                                  placeholder="Title"
                                  value={formik.values.title}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "title",
                                      e.target.value
                                    );
                                    setFormListsTitle(e.target.value);
                                  }}
                                />
                              </Box>
                            ) : null}
                            <Box mb={2}>
                              <BasicInput
                                fullWidth
                                disableMargin
                                multiline
                                rows={5}
                                placeholder="Type message in here ..."
                                {...formik.getFieldProps("message")}
                              />
                            </Box>
                          </motion.div>
                        </Grid>
                      ) : null}
                      {typeMessage?.key === "button" ? (
                        <>
                          {formButtons.map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <Grid item xs={12} lg={5}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    name="button_title"
                                    title="Button Tittle"
                                    placeholder="i.e Yes"
                                    value={item.button_title || ""}
                                    onChange={(e) =>
                                      handleChangeFormButtons(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    multiline
                                    rows={5}
                                    name="button_reply"
                                    title="Button Response"
                                    placeholder="{{Reply_ID}} or response text"
                                    value={item.button_reply || ""}
                                    onChange={(e) =>
                                      handleChangeFormButtons(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                  <>
                                    <Box
                                      mb={3.3}
                                      sx={{
                                        display: { xs: "none", lg: "block" },
                                      }}
                                    />
                                    <IconButton
                                      onClick={() => removeFormButtons(index)}
                                    >
                                      <Iconify
                                        icon="ant-design:close-square-outlined"
                                        color="#F15A28"
                                        height="32px"
                                        width="32px"
                                      />
                                    </IconButton>
                                  </>
                                </Grid>
                              </Fragment>
                            );
                          })}
                          {formButtons.length < 3 ? (
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                sx={{
                                  background: "#FFFFFF",
                                  borderRadius: "8px",
                                  color: "#7B7B7B",
                                  width: "100%",
                                  height: "40px",
                                  boxShadow: "none",
                                  border: "1px solid #CBC8C8",
                                }}
                                startIcon={
                                  <Iconify
                                    icon="fluent:add-12-filled"
                                    color="#7B7B7B"
                                    height="16px"
                                    width="16px"
                                  />
                                }
                                onClick={() => {
                                  addFormButtons();
                                }}
                              >
                                <Typography fontSize={{ xs: 12, sm: 14 }}>
                                  Add Button
                                </Typography>
                              </Button>
                            </Grid>
                          ) : null}
                        </>
                      ) : typeMessage?.key === "template" ? (
                        <>
                          {formTemplates.map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <Grid item xs={12} lg={5}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    name="displayText"
                                    title="Display Text"
                                    placeholder="i.e Yes"
                                    value={item.displayText || ""}
                                    onChange={(e) =>
                                      handleChangeFormTemplates(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    multiline
                                    rows={5}
                                    name="action"
                                    title={`${
                                      item.type === "quick-reply"
                                        ? "Reply"
                                        : item.type === "url"
                                        ? "Website"
                                        : item.type === "call"
                                        ? "Phone Number"
                                        : null
                                    }`}
                                    placeholder={`${
                                      item.type === "quick-reply"
                                        ? "{{Reply_ID}} or response text"
                                        : item.type === "url"
                                        ? "https://realm.chat"
                                        : item.type === "call"
                                        ? "Input number"
                                        : null
                                    }`}
                                    value={item.action || ""}
                                    onChange={(e) =>
                                      handleChangeFormTemplates(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                  <Box
                                    mb={3.3}
                                    sx={{
                                      display: { xs: "none", lg: "block" },
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => removeFormTemplates(index)}
                                  >
                                    <Iconify
                                      icon="ant-design:close-square-outlined"
                                      color="#F15A28"
                                      height="32px"
                                      width="32px"
                                    />
                                  </IconButton>
                                </Grid>
                              </Fragment>
                            );
                          })}

                          {formTemplates.length < 3 ? (
                            <>
                              <Grid item xs={12}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "8px",
                                    color: "#7B7B7B",
                                    width: "100%",
                                    height: "40px",
                                    boxShadow: "none",
                                    border: "1px solid #CBC8C8",
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="fluent:add-12-filled"
                                      color="#7B7B7B"
                                      height="16px"
                                      width="16px"
                                    />
                                  }
                                  onClick={() => {
                                    addFormTemplates("quick-reply");
                                  }}
                                >
                                  <Typography fontSize={{ xs: 12, sm: 14 }}>
                                    Add Button
                                  </Typography>
                                </Button>
                              </Grid>

                              <Grid item xs={12}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "8px",
                                    color: "#7B7B7B",
                                    width: "100%",
                                    height: "40px",
                                    boxShadow: "none",
                                    border: "1px solid #CBC8C8",
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="fluent:add-12-filled"
                                      color="#7B7B7B"
                                      height="16px"
                                      width="16px"
                                    />
                                  }
                                  onClick={() => {
                                    addFormTemplates("url");
                                  }}
                                >
                                  <Typography fontSize={{ xs: 12, sm: 14 }}>
                                    Add Website Button
                                  </Typography>
                                </Button>
                              </Grid>

                              <Grid item xs={12}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "8px",
                                    color: "#7B7B7B",
                                    width: "100%",
                                    height: "40px",
                                    boxShadow: "none",
                                    border: "1px solid #CBC8C8",
                                  }}
                                  startIcon={
                                    <Iconify
                                      icon="fluent:add-12-filled"
                                      color="#7B7B7B"
                                      height="16px"
                                      width="16px"
                                    />
                                  }
                                  onClick={() => {
                                    addFormTemplates("call");
                                  }}
                                >
                                  <Typography fontSize={{ xs: 12, sm: 14 }}>
                                    Add Call Button
                                  </Typography>
                                </Button>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : typeMessage?.key === "list" ? (
                        <>
                          <Grid item xs={12} sm={12}>
                            <BasicInput
                              disableMargin
                              fullWidth
                              title="Button Text"
                              placeholder="Click Here"
                              value={formik.values.buttonText}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "buttonText",
                                  e.target.value
                                );
                                setFormListsButtonText(e.target.value);
                              }}
                            />
                          </Grid>
                          {formLists.map((item, index) => {
                            return (
                              <Fragment key={index}>
                                <Grid item xs={12} lg={11}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    name="list"
                                    title="List Title"
                                    placeholder="List Title"
                                    value={item.list || ""}
                                    onChange={(e) =>
                                      handleChangeFormLists(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={1}>
                                  <Box
                                    mb={3.3}
                                    sx={{
                                      display: { xs: "none", lg: "block" },
                                    }}
                                  />
                                  <IconButton
                                    onClick={() => removeFormLists(index)}
                                  >
                                    <Iconify
                                      icon="ant-design:close-square-outlined"
                                      color="#F15A28"
                                      height="32px"
                                      width="32px"
                                    />
                                  </IconButton>
                                </Grid>
                                {formListsButton
                                  .filter((e) => e.listIndex === item.index)
                                  .map((item, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <Grid item xs={12} lg={4}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            name="title"
                                            title="Create Title"
                                            placeholder="i.e Yes"
                                            value={item.title || ""}
                                            onChange={(e) =>
                                              handleChangeFormListsButton(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            multiline
                                            rows={5}
                                            name="description"
                                            title="Description ( Optional )"
                                            placeholder="Select description here"
                                            value={item.description || ""}
                                            onChange={(e) =>
                                              handleChangeFormListsButton(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            multiline
                                            rows={5}
                                            name="row_reply"
                                            title="Response"
                                            placeholder="{{Reply_ID}} or response text"
                                            value={item.row_reply || ""}
                                            onChange={(e) =>
                                              handleChangeFormListsButton(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                      </Fragment>
                                    );
                                  })}
                                <Grid item xs={12} lg={6}></Grid>
                                <Grid item xs={12} lg={6}>
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ justifyContent: "flex-end" }}
                                  >
                                    {formListsButton.length ? (
                                      <Grid item xs={6}>
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            background: "#FFFFFF",
                                            borderRadius: "8px",
                                            color: "#7B7B7B",
                                            width: "100%",
                                            height: "40px",
                                            boxShadow: "none",
                                            border: "1px solid #CBC8C8",
                                          }}
                                          onClick={() =>
                                            removeFormListsButton(index)
                                          }
                                        >
                                          <Typography
                                            fontSize={{ xs: 12, sm: 14 }}
                                          >
                                            Delete
                                          </Typography>
                                        </Button>
                                      </Grid>
                                    ) : null}

                                    {
                                      <Grid item xs={6}>
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            background: "#FFFFFF",
                                            borderRadius: "8px",
                                            color: "#7B7B7B",
                                            width: "100%",
                                            height: "40px",
                                            boxShadow: "none",
                                            border: "1px solid #CBC8C8",
                                          }}
                                          startIcon={
                                            <Iconify
                                              icon="fluent:add-12-filled"
                                              color="#7B7B7B"
                                              height="16px"
                                              width="16px"
                                            />
                                          }
                                          onClick={() => {
                                            addFormListsButton(index);
                                          }}
                                        >
                                          <Typography
                                            fontSize={{ xs: 12, sm: 14 }}
                                          >
                                            Add Button
                                          </Typography>
                                        </Button>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </Fragment>
                            );
                          })}

                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                color: "#7B7B7B",
                                width: "100%",
                                height: "40px",
                                boxShadow: "none",
                                border: "1px solid #CBC8C8",
                              }}
                              startIcon={
                                <Iconify
                                  icon="fluent:add-12-filled"
                                  color="#7B7B7B"
                                  height="16px"
                                  width="16px"
                                />
                              }
                              onClick={() => {
                                addFormLists();
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 14 }}>
                                Add List
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                      ) : null}
                      {formik.values.type !== "list" ? (
                        <Grid item xs={12} sm={12}>
                          <Typography
                            fontSize={16}
                            fontWeight="600"
                            sx={{ mb: 1 }}
                          >
                            Attachment (Optional)
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <BasicButton
                              title="Choose File"
                              onClick={() => setIsOpenUploadModal(true)}
                            />
                            <Typography color="#131313" sx={{ ml: 2 }}>
                              {formik?.values?.fileSelected?.file_name}
                            </Typography>
                          </Box>
                        </Grid>
                      ) : null}
                      {formik.values.fileSelected?.file_type === "audio" ? (
                        <Grid item xs={12} sm={12}>
                          <BasicInput
                            type="checkbox"
                            title="Send as Voice Note"
                            sx={{ width: "24px" }}
                            onChange={() => {
                              formik.setFieldValue(
                                "isVoice",
                                !formik.values.isVoice ? true : false
                              );
                            }}
                          />
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* form multiples */}
                      <Grid item xs={12} sm={12}>
                        <Box sx={{ border: "1px solid #CBC8C8" }}></Box>
                      </Grid>
                      {formMultiples.map((item, index) => {
                        return (
                          <Fragment key={index}>
                            <Grid item xs={11}>
                              <ComboBox
                                fullWidth
                                disableMargin
                                options={
                                  messageTypeData.filter(
                                    (e) => e.key !== "multiple"
                                  ) ?? []
                                }
                                getOptionLabel={(option) => option?.name}
                                title="Message Type"
                                placeholder={"Choose type message"}
                                value={item.type}
                                onChange={(e, value) => {
                                  handleChangeFormMultiples(
                                    index,
                                    "type",
                                    value ? value?.key : null
                                  );

                                  handleChangeFormMultiples(
                                    index,
                                    "message",
                                    ""
                                  );

                                  if (value?.key !== "button") {
                                    handleChangeFormMultiples(
                                      index,
                                      "buttonText",
                                      ""
                                    );
                                  }

                                  if (value?.key !== "list") {
                                    handleChangeFormMultiples(
                                      index,
                                      "title",
                                      ""
                                    );
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Box mt={2.8} />
                              <IconButton
                                onClick={() => removeFormMultiples(index)}
                              >
                                <Iconify
                                  icon="ant-design:close-square-outlined"
                                  color="#F15A28"
                                  height="32px"
                                  width="32px"
                                />
                              </IconButton>
                            </Grid>
                            {item.type ? (
                              <Grid item xs={12} sm={12}>
                                <motion.div
                                  initial={{ opacity: 0, scale: 0.5 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{ duration: 0.3 }}
                                >
                                  {item.type === "list" ? (
                                    <Box mb={2}>
                                      <BasicInput
                                        fullWidth
                                        disableMargin
                                        name="title"
                                        placeholder="Title"
                                        value={item.title || ""}
                                        onChange={(e) =>
                                          handleChangeFormMultiples(
                                            index,
                                            e.target.name,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>
                                  ) : null}
                                  <Box mb={2}>
                                    <BasicInput
                                      fullWidth
                                      disableMargin
                                      multiline
                                      rows={5}
                                      title="Message"
                                      placeholder="Type message in here ..."
                                      name="message"
                                      value={item.message || ""}
                                      onChange={(e) =>
                                        handleChangeFormMultiples(
                                          index,
                                          e.target.name,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Box>
                                </motion.div>
                              </Grid>
                            ) : null}
                            {item.type === "button" ? (
                              <>
                                {formMultiplesButtons
                                  .filter((e) => e.multipleIndex === item.index)
                                  .map((item, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <Grid item xs={12} lg={5}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            name="button_title"
                                            title="Button Tittle"
                                            placeholder="i.e Yes"
                                            value={item.button_title || ""}
                                            onChange={(e) =>
                                              handleChangeFormMultiplesButtons(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            multiline
                                            rows={5}
                                            name="button_reply"
                                            title="Button Response"
                                            placeholder="{{Reply_ID}} or response text"
                                            value={item.button_reply || ""}
                                            onChange={(e) =>
                                              handleChangeFormMultiplesButtons(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                          <>
                                            <Box
                                              mb={3.3}
                                              sx={{
                                                display: {
                                                  xs: "none",
                                                  lg: "block",
                                                },
                                              }}
                                            />
                                            <IconButton
                                              onClick={() =>
                                                removeFormMultiplesButtons(i)
                                              }
                                            >
                                              <Iconify
                                                icon="ant-design:close-square-outlined"
                                                color="#F15A28"
                                                height="32px"
                                                width="32px"
                                              />
                                            </IconButton>
                                          </>
                                        </Grid>
                                      </Fragment>
                                    );
                                  })}
                                {formMultiplesButtons.filter(
                                  (e) => e.multipleIndex === item.index
                                ).length < 3 ? (
                                  <Grid item xs={12}>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        background: "#FFFFFF",
                                        borderRadius: "8px",
                                        color: "#7B7B7B",
                                        width: "100%",
                                        height: "40px",
                                        boxShadow: "none",
                                        border: "1px solid #CBC8C8",
                                      }}
                                      startIcon={
                                        <Iconify
                                          icon="fluent:add-12-filled"
                                          color="#7B7B7B"
                                          height="16px"
                                          width="16px"
                                        />
                                      }
                                      onClick={() => {
                                        addFormMultiplesButtons(item.index);
                                      }}
                                    >
                                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                                        Add Button
                                      </Typography>
                                    </Button>
                                  </Grid>
                                ) : null}
                              </>
                            ) : item.type === "template" ? (
                              <>
                                {formMultiplesTemplates
                                  .filter((e) => e.multipleIndex === item.index)
                                  .map((item, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <Grid item xs={12} lg={5}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            name="displayText"
                                            title="Display Text"
                                            placeholder="i.e Yes"
                                            value={item.displayText || ""}
                                            onChange={(e) =>
                                              handleChangeFormMultiplesTemplates(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            multiline
                                            rows={5}
                                            name="action"
                                            title={`${
                                              item.type === "quick-reply"
                                                ? "Reply"
                                                : item.type === "url"
                                                ? "Website"
                                                : item.type === "call"
                                                ? "Phone Number"
                                                : null
                                            }`}
                                            placeholder={`${
                                              item.type === "quick-reply"
                                                ? "{{Reply_ID}} or response text"
                                                : item.type === "url"
                                                ? "https://realm.chat"
                                                : item.type === "call"
                                                ? "Input number"
                                                : null
                                            }`}
                                            value={item.action || ""}
                                            onChange={(e) =>
                                              handleChangeFormMultiplesTemplates(
                                                item.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                          <Box
                                            mb={3.3}
                                            sx={{
                                              display: {
                                                xs: "none",
                                                lg: "block",
                                              },
                                            }}
                                          />
                                          <IconButton
                                            onClick={() =>
                                              removeFormMultiplesTemplates(
                                                index
                                              )
                                            }
                                          >
                                            <Iconify
                                              icon="ant-design:close-square-outlined"
                                              color="#F15A28"
                                              height="32px"
                                              width="32px"
                                            />
                                          </IconButton>
                                        </Grid>
                                      </Fragment>
                                    );
                                  })}

                                {formMultiplesTemplates.filter(
                                  (e) => e.multipleIndex === item.index
                                ).length < 3 ? (
                                  <>
                                    <Grid item xs={12}>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          background: "#FFFFFF",
                                          borderRadius: "8px",
                                          color: "#7B7B7B",
                                          width: "100%",
                                          height: "40px",
                                          boxShadow: "none",
                                          border: "1px solid #CBC8C8",
                                        }}
                                        startIcon={
                                          <Iconify
                                            icon="fluent:add-12-filled"
                                            color="#7B7B7B"
                                            height="16px"
                                            width="16px"
                                          />
                                        }
                                        onClick={() => {
                                          addFormMultiplesTemplates(
                                            item.index,
                                            "quick-reply"
                                          );
                                        }}
                                      >
                                        <Typography
                                          fontSize={{ xs: 12, sm: 14 }}
                                        >
                                          Add Button
                                        </Typography>
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          background: "#FFFFFF",
                                          borderRadius: "8px",
                                          color: "#7B7B7B",
                                          width: "100%",
                                          height: "40px",
                                          boxShadow: "none",
                                          border: "1px solid #CBC8C8",
                                        }}
                                        startIcon={
                                          <Iconify
                                            icon="fluent:add-12-filled"
                                            color="#7B7B7B"
                                            height="16px"
                                            width="16px"
                                          />
                                        }
                                        onClick={() => {
                                          addFormMultiplesTemplates(
                                            item.index,
                                            "url"
                                          );
                                        }}
                                      >
                                        <Typography
                                          fontSize={{ xs: 12, sm: 14 }}
                                        >
                                          Add Website Button
                                        </Typography>
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          background: "#FFFFFF",
                                          borderRadius: "8px",
                                          color: "#7B7B7B",
                                          width: "100%",
                                          height: "40px",
                                          boxShadow: "none",
                                          border: "1px solid #CBC8C8",
                                        }}
                                        startIcon={
                                          <Iconify
                                            icon="fluent:add-12-filled"
                                            color="#7B7B7B"
                                            height="16px"
                                            width="16px"
                                          />
                                        }
                                        onClick={() => {
                                          addFormMultiplesTemplates(
                                            item.index,
                                            "call"
                                          );
                                        }}
                                      >
                                        <Typography
                                          fontSize={{ xs: 12, sm: 14 }}
                                        >
                                          Add Call Button
                                        </Typography>
                                      </Button>
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : item.type === "list" ? (
                              <>
                                <Grid item xs={12} sm={12}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    name="buttonText"
                                    title="Button Text"
                                    placeholder="Click Here"
                                    value={item.buttonText || ""}
                                    onChange={(e) => {
                                      handleChangeFormMultiples(
                                        item.index,
                                        e.target.name,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                {formMultiplesLists
                                  .filter((e) => e.multipleIndex === item.index)
                                  .map((x, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <Grid item xs={12} lg={11}>
                                          <BasicInput
                                            disableMargin
                                            fullWidth
                                            name="list"
                                            title="List Title"
                                            placeholder="List Title"
                                            value={x.list || ""}
                                            onChange={(e) =>
                                              handleChangeFormMultiplesLists(
                                                x.index,
                                                e.target.name,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={12} lg={1}>
                                          <Box
                                            mb={3.3}
                                            sx={{
                                              display: {
                                                xs: "none",
                                                lg: "block",
                                              },
                                            }}
                                          />
                                          <IconButton
                                            onClick={() =>
                                              removeFormMultiplesLists(x.index)
                                            }
                                          >
                                            <Iconify
                                              icon="ant-design:close-square-outlined"
                                              color="#F15A28"
                                              height="32px"
                                              width="32px"
                                            />
                                          </IconButton>
                                        </Grid>
                                        {formMultiplesListsButton
                                          .filter(
                                            (e) =>
                                              e.multipleListIndex === x.index
                                          )
                                          .map((y, i) => {
                                            return (
                                              <Fragment key={i}>
                                                <Grid item xs={12} lg={4}>
                                                  <BasicInput
                                                    disableMargin
                                                    fullWidth
                                                    name="title"
                                                    title="Create Title"
                                                    placeholder="i.e Yes"
                                                    value={y.title || ""}
                                                    onChange={(e) =>
                                                      handleChangeFormMultiplesListsButton(
                                                        y.index,
                                                        e.target.name,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                  <BasicInput
                                                    disableMargin
                                                    fullWidth
                                                    multiline
                                                    rows={5}
                                                    name="description"
                                                    title="Description ( Optional )"
                                                    placeholder="Select description here"
                                                    value={y.description || ""}
                                                    onChange={(e) =>
                                                      handleChangeFormMultiplesListsButton(
                                                        y.index,
                                                        e.target.name,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                                <Grid item xs={12} lg={4}>
                                                  <BasicInput
                                                    disableMargin
                                                    fullWidth
                                                    multiline
                                                    rows={5}
                                                    name="row_reply"
                                                    title="Response"
                                                    placeholder="{{Reply_ID}} or response text"
                                                    value={y.row_reply || ""}
                                                    onChange={(e) =>
                                                      handleChangeFormMultiplesListsButton(
                                                        y.index,
                                                        e.target.name,
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                </Grid>
                                              </Fragment>
                                            );
                                          })}
                                        <Grid item xs={12} lg={6}></Grid>
                                        <Grid item xs={12} lg={6}>
                                          <Grid
                                            container
                                            spacing={2}
                                            sx={{ justifyContent: "flex-end" }}
                                          >
                                            {formMultiplesListsButton.filter(
                                              (e) =>
                                                e.multipleListIndex ===
                                                item.index
                                            ).length ? (
                                              <Grid item xs={6}>
                                                <Button
                                                  variant="outlined"
                                                  sx={{
                                                    background: "#FFFFFF",
                                                    borderRadius: "8px",
                                                    color: "#7B7B7B",
                                                    width: "100%",
                                                    height: "40px",
                                                    boxShadow: "none",
                                                    border: "1px solid #CBC8C8",
                                                  }}
                                                  onClick={() =>
                                                    removeFormMultiplesListsButton(
                                                      i
                                                    )
                                                  }
                                                >
                                                  <Typography
                                                    fontSize={{
                                                      xs: 12,
                                                      sm: 14,
                                                    }}
                                                  >
                                                    Delete
                                                  </Typography>
                                                </Button>
                                              </Grid>
                                            ) : null}

                                            {
                                              <Grid item xs={6}>
                                                <Button
                                                  variant="outlined"
                                                  sx={{
                                                    background: "#FFFFFF",
                                                    borderRadius: "8px",
                                                    color: "#7B7B7B",
                                                    width: "100%",
                                                    height: "40px",
                                                    boxShadow: "none",
                                                    border: "1px solid #CBC8C8",
                                                  }}
                                                  startIcon={
                                                    <Iconify
                                                      icon="fluent:add-12-filled"
                                                      color="#7B7B7B"
                                                      height="16px"
                                                      width="16px"
                                                    />
                                                  }
                                                  onClick={() => {
                                                    addFormMultiplesListsButton(
                                                      x.index
                                                    );
                                                  }}
                                                >
                                                  <Typography
                                                    fontSize={{
                                                      xs: 12,
                                                      sm: 14,
                                                    }}
                                                  >
                                                    Add Button
                                                  </Typography>
                                                </Button>
                                              </Grid>
                                            }
                                          </Grid>
                                        </Grid>
                                      </Fragment>
                                    );
                                  })}

                                <Grid item xs={12}>
                                  <Button
                                    variant="outlined"
                                    sx={{
                                      background: "#FFFFFF",
                                      borderRadius: "8px",
                                      color: "#7B7B7B",
                                      width: "100%",
                                      height: "40px",
                                      boxShadow: "none",
                                      border: "1px solid #CBC8C8",
                                    }}
                                    startIcon={
                                      <Iconify
                                        icon="fluent:add-12-filled"
                                        color="#7B7B7B"
                                        height="16px"
                                        width="16px"
                                      />
                                    }
                                    onClick={() => {
                                      addFormMultiplesLists(item.index);
                                    }}
                                  >
                                    <Typography fontSize={{ xs: 12, sm: 14 }}>
                                      Add List
                                    </Typography>
                                  </Button>
                                </Grid>
                              </>
                            ) : item.type !== "list" ? (
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  fontSize={16}
                                  fontWeight="600"
                                  sx={{ mb: 1 }}
                                >
                                  Attachment (Optional)
                                </Typography>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <BasicButton
                                    title="Choose File"
                                    onClick={() =>
                                      handleChangeFormMultiples(
                                        index,
                                        "isOpenModal",
                                        true
                                      )
                                    }
                                  />
                                  <Typography color="#131313" sx={{ ml: 2 }}>
                                    {item?.fileSelected?.file_name}
                                  </Typography>
                                  <CustomModal open={item.isOpenModal}>
                                    <Upload
                                      onCancle={() =>
                                        handleChangeFormMultiples(
                                          index,
                                          "isOpenModal",
                                          false
                                        )
                                      }
                                      onSelect={(value) => {
                                        handleChangeFormMultiples(
                                          index,
                                          "fileSelected",
                                          value
                                        );
                                      }}
                                      value={item.fileSelected || ""}
                                    />
                                  </CustomModal>
                                </Box>
                              </Grid>
                            ) : null}
                            {item.fileSelected?.file_type === "audio" ? (
                              <Grid item xs={12} sm={12}>
                                <BasicInput
                                  type="checkbox"
                                  title="Send as Voice Note"
                                  sx={{ width: "24px" }}
                                  onChange={() => {
                                    handleChangeFormMultiples(
                                      index,
                                      "isVoice",
                                      item.isVoice ? false : true
                                    );
                                  }}
                                />
                              </Grid>
                            ) : null}
                          </Fragment>
                        );
                      })}
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "8px",
                            color: "#7B7B7B",
                            width: "100%",
                            height: "40px",
                            boxShadow: "none",
                            border: "1px solid #CBC8C8",
                          }}
                          startIcon={
                            <Iconify
                              icon="fluent:add-12-filled"
                              color="#7B7B7B"
                              height="16px"
                              width="16px"
                            />
                          }
                          onClick={() => {
                            addFormMultiples();
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            Add Message
                          </Typography>
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <DeviceFrame
                single={{
                  formButtons: formButtons ?? [],
                  formTemplates: formTemplates ?? [],
                  formLists: formLists ?? [],
                  formListsButton: formListsButton ?? [],
                  formListsOther: {
                    title: formListsTitle,
                    buttonText: formListsButtonText,
                  },
                  fileSelected: formik.values.fileSelected,
                  isVoice: formik.values.isVoice,
                }}
                multiples={{
                  formMultiples: formMultiples ?? [],
                  formMultiplesButtons: formMultiplesButtons ?? [],
                  formMultiplesTemplates: formMultiplesTemplates ?? [],
                  formMultiplesLists: formMultiplesLists ?? [],
                  formMultiplesListsButton: formMultiplesListsButton ?? [],
                }}
                setFooter={(value) => formik.setFieldValue("footer", value)}
              >
                <p id="chat-message" style={{ whiteSpace: "pre-line" }}>
                  {formik.values.message.split("\n").join("<br />")}
                </p>
              </DeviceFrame>
            </Grid>
          </Grid>
        </SaveCancelContainer>
        {typeMessage?.key !== "multiple" ? (
          <CustomModal open={isOpenUploadModal}>
            <Upload
              onCancle={() => setIsOpenUploadModal(false)}
              onSelect={(value) => {
                formik.setFieldValue("fileSelected", value);
              }}
              value={formik.values?.fileSelected}
            />
          </CustomModal>
        ) : null}
      </>
    );
  } else {
    return (
      <Container title="WhatsApp Broadcast">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
