import { Box, Container } from "@mui/material";
import React from "react";
import Page from "../components/Page";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import CloudStorageSection from "../sections/dashboard/cloud-storage";

export default function CloudStorage() {
  return (
    <Page title="Cloud Storage">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Report" />
        <Box sx={{ width: "100%" }}>
          <Box mt={3}>
            <CloudStorageSection />
          </Box>
        </Box>
      </Container>
    </Page>
  );
}
