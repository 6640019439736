import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Page from "../components/Page";
import AppDealByStage from "../sections/dashboard/app/AppDealByStage";
import AppDevice from "../sections/dashboard/app/AppDevice";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import AppIncomeStatistic from "../sections/dashboard/app/AppIncomeStatistic";
import AppLostReason from "../sections/dashboard/app/AppLostReason";
import AppMessageSendByTeam from "../sections/dashboard/app/AppMessageSendByTeam";
import AppMostActiveUser from "../sections/dashboard/app/AppMostActiveUser";
import AppPlan from "../sections/dashboard/app/AppPlan";
import AppShareLink from "../sections/dashboard/app/AppShareLink";
import AppStatisticCard from "../sections/dashboard/app/AppStatisticCard";
import AppTopProducts from "../sections/dashboard/app/AppTopProduct";
import { getDashboard } from "../store/user/userAction";

export default function Dashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [dashboardValue, setDashboardValue] = useState(null);

  const handleGetDashboard = async () => {
    await dispatch(getDashboard({})).then((res) => {
      if (res.meta.requestStatus === "rejected") {
        return;
      }

      setDashboardValue(res.payload.data);
    });
  };

  useEffect(() => {
    handleGetDashboard();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <AppDropDown title="Dashboard" />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Total Sales"
              total={dashboardValue?.total_sales}
              icon={"ant-design:android-filled"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Total Order"
              total={dashboardValue?.total_order || 0}
              icon={"ant-design:android-filled"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Item Sold"
              total={dashboardValue?.item_sold}
              color="warning"
              icon={"ant-design:windows-filled"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Customer (Opt-in)"
              total={dashboardValue?.customer}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Conversion Rate"
              total={dashboardValue?.conversion_rate || 0}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppStatisticCard
              title="Abandon Cart"
              total={dashboardValue?.abandon_cart || 0}
              color="warning"
              icon={"ant-design:windows-filled"}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12} md={8}>
            <AppPlan />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={4}>
            <AppShareLink />
          </Grid> */}
          {/* <Grid item xs={12} sm={12} md={12}>
            <AppDevice />
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <AppIncomeStatistic />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppTopProducts />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AppMessageSendByTeam />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppLostReason
              title="Current Subject"
              chartLabels={[
                "No Response",
                "Budged Issue",
                "Choose Competitor",
                "Not interested",
                "Other",
              ]}
              chartData={[
                { name: "Series 1", data: [0, 0, 0, 0, 0] },
                { name: "Series 2", data: [0, 0, 0, 0, 0] },
                { name: "Series 3", data: [0, 0, 0, 0, 0] },
              ]}
              chartColors={[...Array(6)].map(
                () => theme.palette.text.secondary
              )}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppDealByStage
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ]}
              chartData={[
                {
                  name: "Deal",
                  type: "column",
                  fill: "solid",
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                  name: "New Customer",
                  type: "area",
                  fill: "gradient",
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                  name: "Payment in process",
                  type: "line",
                  fill: "solid",
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
                {
                  name: "Lost",
                  type: "line",
                  fill: "solid",
                  data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <AppMostActiveUser />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
