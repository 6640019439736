import { Box } from "@mui/material";
import Iconify from "../../../../../../../components/Iconify";

export default function RenderHeaderMedia({
    icon
}) {
    return (
        <>
            <Box sx={{ mb: 1, px: 1, }}>
                <Box
                    sx={{
                        height: '140px',
                        width: '100%',
                        borderRadius: '8px',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        background: '#ccc'
                    }}
                >
                    <Iconify
                        icon={icon}
                        sx={{
                            color: '#fff',
                            fontSize: 80
                        }}
                    />
                </Box>
            </Box>
        </>
    )
}