import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import CardContainer from "../../../../components/containers/CardContainer";
import Iconify from "../../../../components/Iconify";
import ScrollBar from "../../../../components/Scrollbar";
import palette from "../../../../theme/palette";

export default function Profile({ detail, onEdit, onDelete }) {
  const menus = ["Name", "Device Key", "Phone", "Address"];

  return (
    <CardContainer width="100%">
      <ScrollBar>
        <Box display="flex" alignItems="start" justifyContent="space-between">
          <Stack direction="row" spacing={3}>
            <Box>
              <Iconify
                icon="clarity:map-marker-line"
                sx={{
                  width: 30,
                  height: 30,
                  color: palette.primary.main,
                }}
              />
            </Box>
            <Box display="block">
              {menus.map((item, index) => {
                return (
                  <Typography fontSize={12} mb={1} key={index}>
                    {item}
                  </Typography>
                );
              })}
            </Box>
            <Box display="block" sx={{ minWidth: "200px" }}>
              <div style={{ maxWidth: "280px" }}>
                <Typography
                  fontSize={12}
                  mb={1}
                  sx={{ maxLines: 1, fontWeight: "600" }}
                >
                  {detail?.name}
                </Typography>
                <Typography fontSize={12} mb={1} sx={{ maxLines: 1 }}>
                  {detail?.device_key}
                </Typography>
                <Typography fontSize={12} mb={1} sx={{ maxLines: 1 }}>
                  {detail?.phone}
                </Typography>
                <Typography fontSize={12}>
                  {detail?.address}, {detail?.state}, {detail?.city},{" "}
                  {detail?.country}, {detail?.zip}
                </Typography>
              </div>
            </Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <ButtonWithIcon title={"Change"} onClick={() => onEdit(detail)} />
            <Button
              color="error"
              onClick={() => onDelete({ device_key: detail?.device_key })}
            >
              <Typography fontSize={{ xs: 10, sm: 14 }}>Delete</Typography>
            </Button>
          </Stack>
        </Box>
      </ScrollBar>
    </CardContainer>
  );
}
