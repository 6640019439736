import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import toast from 'react-hot-toast';
import BasicButton from "../../components/button/BasicButton";
import BasicInput from "../../components/input/BasicInput";
import { resetPassword } from "../../store/user/userAction";

export default function ResetPasswordSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { user } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      if (value.password !== value.password_confirmation) {
        toast.dismiss();
        toast.error("Password confirmation doesnt match");
      } else {
        const data = {
          password: value.password,
          password_confirmation: value.password_confirmation,
          token: searchParams.get("token"),
        };

        await dispatch(resetPassword(data)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            toast.dismiss();
            toast.success(res.payload.message);

            resetForm();
            navigate("/auth/signin");
          } else {
            toast.dismiss();
            toast.error(res.payload.message);
          }
        });
      }

      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [user, dispatch]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: "16px", md: "24px" },
          width: "100%",
          maxWidth: "480px",
          background: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ margin: "0 0 8px 0" }}>
              <Box
                component="img"
                src="/images/realm-logo.svg"
                sx={{ height: "48px" }}
              />
            </Box>
            <Typography
              variant="h4"
              sx={{ fontWeight: "700", color: "#131313", textAlign: "center" }}
            >
              Reset Password
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: "16px",
                fontWeight: "400",
                color: "#7B7B7B",
                textAlign: "center",
              }}
            >
              Reset your password easily and securely
            </Typography>
            <Box sx={{ width: "100%" }}>
              <BasicInput
                type="password"
                title="Password"
                {...formik.getFieldProps("password")}
              />
              <BasicInput
                type="password"
                title="Password Confirmation"
                {...formik.getFieldProps("password_confirmation")}
              />
            </Box>
            <Box sx={{ my: "4px" }} />
            <BasicButton type="submit" disabled={isLoading} fullWidth>
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "#FFFFFF" }}
              >
                Confirm
              </Typography>
            </BasicButton>
            <Box sx={{ my: "32px" }} />
            <Typography
              variant="body1"
              sx={{ fontWeight: "400", color: "#131313", textAlign: "center" }}
            >
              Already have an account?{" "}
              <Link
                to="/auth/signin"
                style={{ fontWeight: "700", color: "#1A776F" }}
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </form>
      </Box>
    </>
  );
}
