import { useNavigate } from "react-router-dom";
import { useState } from "react";
import toast from "react-hot-toast";
import { Box, Typography } from "@mui/material";
import ContainerWithSomeButton from "../../../../../components/containers/ContainerWithSomeButton";
import Connect from "../../Connect";
import BasicButton from "../../../../../components/button/BasicButton";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addDevice } from "../../../../../store/device/deviceAction";
import { resetDevice } from "../../../../../store/device/deviceSlice";
import BasicInput from "../../../../../components/input/BasicInput";

export default function ConnectWhatsAppWeb() {
    const dispatch = useDispatch()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    
    const Walists = [
        'WhatsApp / WhatsApp Business Account',
    ]

    function clickBack() {
        navigate('/dashboard/channels')
    }

    const formik = useFormik({
        initialValues: {
            platform: 'whatsapp',
            name: '',
            phone: ''
        },
        onSubmit: async (value, { resetForm }) => {
            setLoading(true)

            let data = {
                platform: value.platform,
                name: value.name ?? null,
                phone: value.phone ?? null
            }

            dispatch(addDevice(data)).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();
                    dispatch(resetDevice())

                    navigate('/dashboard/channels')
                } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                }

                setLoading(false)
            });
        }
    })

    return (
        <ContainerWithSomeButton>
            <Connect
                icon="whatsapp.png"
                title="WhatsApp Web"
                isBackBtn={true}
                clickBack={clickBack}
            />

            <Box
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    },
                    marginRight: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    }
                }}
                mb={4}
                mt={4}
            >
                <Typography
                    fontSize={16}
                    color={'#7B7B7B'}
                    fontWeight={600}
                    mt={4}
                    mb={3}
                >
                    What will you need:
                </Typography>

                {Walists.map((item, i) => (
                    <Box
                        key={i}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        mb={1}
                    >
                        <Box
                            sx={{
                                height: 7,
                                width: 7,
                                borderRadius: 500,
                                background: '#64D88B'
                            }}
                            mr={1}
                        />
                        <Typography
                            fontWeight={400}
                            fontSize={14}
                            color={'#7B7B7B'}
                            textAlign="start"
                        >
                            {item}
                        </Typography>
                    </Box>
                ))}
                <Box mb={4} />

                <BasicInput
                    title="Device Name"
                    fullWidth
                    disableMargin
                    id="name"
                    placeholder="Type device name in here ..."
                    value={formik.values.name}
                    {...formik.getFieldProps("name")}
                />
                <Box mb={2} />
                <BasicInput
                    title="Phone Number"
                    fullWidth
                    disableMargin
                    id="phone"
                    placeholder="Type phone number in here ..."
                    value={formik.values.phone}
                    {...formik.getFieldProps("phone")}
                />
                <Box mb={2} />

                <Box mt={6} mb={4}>
                    <BasicButton
                        type="submit"
                        title="Create"
                        disabled={loading}
                        onClick={formik.handleSubmit}
                    />
                </Box>
            </Box>
        </ContainerWithSomeButton>
    )
}