import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import palette from "../../theme/palette";
import { Typography } from "@mui/material";

export default function Selector({ width, fullWidth, title, label, menu, value, onChange, disableMenu, ...props }) {
  return (
    <div>
      {title && (
        <Typography fontWeight="700" mb={0.5}>
          {title}
        </Typography>
      )}
      <FormControl fullWidth={fullWidth} sx={{ width: width }}>
        {label && (
          <InputLabel id="select-helper-label" sx={{ fontSize: 12 }}>
            {label}
          </InputLabel>
        )}
        <Select
          value={value}
          size="small"
          onChange={onChange}
          sx={{ backgroundColor: palette.secondary.main }}
          {...props}
        >
          {menu
            ? menu.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.name} disabled={disableMenu}>
                    {item.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
    </div>
  );
}
