import React from "react";
import CustomModal from "../../modal/CustomModal";
import SubmitFormContainer from "../../containers/SubmitFormContainer";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  addLinkedDevice,
  addWabaCatalog,
  getDevices,
} from "../../../store/device/deviceAction";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ComboBox from "../../select/ComboBox";
import { toggleModalWaba } from "../../../store/device/deviceSlice";
import BasicInput from "../../input/BasicInput";

export default function ModalWaba() {
  const dispatch = useDispatch();
  const { device_key, modalWaba } = useSelector(
    (state) => state.device
  );

  const formik = useFormik({
    initialValues: {
      device_key: device_key,
      catalog_id: "",
    },
    onSubmit: async (value, { resetForm }) => {
      const formData = {
        ...value,
        device_key: device_key,
      };
      await toast.promise(dispatch(addWabaCatalog(formData)), {
        loading: "Please wait...",
        success: (res) => {
          resetForm();
          const body = {
            params: {
              platform: "waba",
            },
          };
          dispatch(getDevices(body));
          dispatch(toggleModalWaba(false));
          return res.payload.message;
        },
        error: (error) => "Error, try again later",
      });
    },
  });
  return (
    <CustomModal open={modalWaba}>
      <SubmitFormContainer
        onSubmit={formik.handleSubmit}
        title="Add Catalog ID"
        onCancel={() => dispatch(toggleModalWaba(false))}
      >
        <Box height={"auto"}>
            <BasicInput
              fullWidth
              disableMargin
              id="catalog-id"
              placeholder="Type your Catalog ID ..."
              {...formik.getFieldProps("catalog_id")}
            />
          </Box>
      </SubmitFormContainer>
    </CustomModal>
  );
}
