import { createSlice } from "@reduxjs/toolkit";
import {
  getReportBulkEmail,
  getReportBulkMessage,
  getReportBulkMessageStatus,
  getReportBulkWaba,
  getReportScheduleMessage,
  getReportScheduleMessageStatus,
} from "./reportAction";

const initialState = {
  bulkMessageAll: [],
  scheduleMessageAll: [],
  bulkMessageStatusAll: [],
  scheduleMessageStatusAll: [],
  bulkEmailAll: [],
  bulkWabaAll: [],
  isDetail: false,
};

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    resetReportMessageStatus: (state) => {
      state.bulkMessageStatusAll = [];
      state.scheduleMessageStatusAll = [];
    },
    setIsDetail: (state, payload) => {
      state.isDetail = payload;
    },
    setReportBulkMessageStatus: (state, { payload }) => {
      let data = state.bulkMessageStatusAll;

      let selected = data.find((e) => e.number === payload.number);

      selected.status = payload.status;

      if (payload.status.toLowerCase() === "read") {
        selected.timestamp = payload.readAt;
      }

      if (payload.status.toLowerCase() === "replied") {
        selected.timestamp = payload.repliedAt;
      }

      data[data.findIndex((e) => e.number === payload.number)] = selected;

      state.bulkMessageStatusAll = data;
    },
    setReportScheduleMessageStatus: (state, { payload }) => {
      let data = state.scheduleMessageStatusAll;

      let selected = data.find((e) => e.number === payload.number);

      selected.status = payload.status;

      if (payload.status.toLowerCase() === "replied") {
        selected.timestamp = payload.readAt;
      }

      if (payload.status === "Replied") {
        selected.timestamp = payload.repliedAt;
      }

      data[data.findIndex((e) => e.number === payload.number)] = selected;

      state.scheduleMessageStatusAll = data;
    },
  },
  extraReducers: {
    [getReportBulkMessage.fulfilled]: (state, { payload }) => {
      state.bulkMessageAll = payload.data;
    },
    [getReportBulkMessage.rejected]: (state, { payload }) => {
      state.bulkMessageAll = [];
    },
    [getReportScheduleMessage.fulfilled]: (state, { payload }) => {
      state.scheduleMessageAll = payload.data;
    },
    [getReportScheduleMessage.rejected]: (state, { payload }) => {
      state.scheduleMessageAll = [];
    },
    [getReportBulkMessageStatus.fulfilled]: (state, { payload }) => {
      state.bulkMessageStatusAll = payload;
    },
    [getReportBulkMessageStatus.rejected]: (state, { payload }) => {
      state.bulkMessageStatusAll = [];
    },
    [getReportScheduleMessageStatus.fulfilled]: (state, { payload }) => {
      state.scheduleMessageStatusAll = payload;
    },
    [getReportScheduleMessageStatus.rejected]: (state, { payload }) => {
      state.scheduleMessageStatusAll = [];
    },
    [getReportBulkEmail.fulfilled]: (state, { payload }) => {
      state.bulkEmailAll = payload.data;
    },
    [getReportBulkEmail.rejected]: (state, { payload }) => {
      state.bulkEmailAll = [];
    },
    [getReportBulkWaba.fulfilled]: (state, { payload }) => {
      state.bulkWabaAll = payload.data;
    },
    [getReportBulkWaba.rejected]: (state, { payload }) => {
      state.bulkWabaAll = [];
    },
  },
});

export const {
  resetReportMessageStatus,
  setIsDetail,
  setReportBulkMessageStatus,
  setReportScheduleMessageStatus,
} = reportSlice.actions;

export default reportSlice.reducer;
