import React, { useCallback, useEffect, useMemo, useState } from "react";
import FormContainer from "../../../../components/containers/FormContainer";
import { Autocomplete, Box, Button, Grid, IconButton, InputAdornment, Menu, MenuItem, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import BasicButton from "../../../../components/button/BasicButton";
import ComboBox from "../../../../components/select/ComboBox";
import palette from "../../../../theme/palette";
import InputLimitText from "../../../../components/input/InputLimitText";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import Iconify from "../../../../components/Iconify";
import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateAttributeProducts } from "../../../../store/catalog/catalogAction";
import BasicInput from "../../../../components/input/BasicInput";
import { isAndroid, isIOS, isMobile } from "react-device-detect";

export default function UpdateAttribute({
    detail,
    onCancel,
    getData,
    resetCatalogs,
    dataAttribute,
    defaultAttribute,
    dataAttributeName,
    setDataAttributeName,
    dataAttributeName2,
    setDataAttributeName2,
    planIsCustomAttribute
}) {
    const { watch, setValue, reset, handleSubmit } = useForm();

    const storageAttributeName = 'attribute-name'
    const storageAttributeName2 = 'attribute-name-2'

    const spaceRegex = /\s/g

    const [isLoading, setIsLoading] = useState(false);
    // attribute
    const [checkedAttribute, setCheckedAttribute] = useState(false)
    const [rowsAttribute, setRowsAttribute] = useState([])
    const [createAttributeValue, setCreateAttributeValue] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [openMenuCustom, setOpenMenuCustom] = useState(false)
    const [activeEditCustomAttr, setActiveEditCustomAttr] = useState({})
    const [currentValueEditable, setCurrentValueEditable] = useState({})
    const [page, setPage] = useState(1)
    const [currentAttribute, setCurrentAttribute] = useState(defaultAttribute)
    const [batchEdit, setBatchEdit] = useState({
        attribute: {
            name: 'attribute',
            attributeValue: ['All'],
            value: 'All'
        },
        second_attribute: {
            name: 'second Attribute',
            attributeValue: ['All'],
            value: 'All'
        },
        stock: '',
        price: '',
        sku: '',
        images: '',
        file: null
    })

    const dispatch = useDispatch()

    function randomId() {
        let crypto = window.crypto
        let typedArray = new Uint8Array(10)
        crypto.getRandomValues(typedArray)
        return Number(typedArray.join(''))
    }

    function resetAutomaticAttr() {
        // reload automatically if no attributes in local storage
        if (
            planIsCustomAttribute &&
            !JSON.parse(localStorage.getItem(storageAttributeName))?.length > 0 ||
            !JSON.parse(localStorage.getItem(storageAttributeName2))?.length > 0
        ) {
            localStorage.removeItem(storageAttributeName)
            localStorage.removeItem(storageAttributeName2)
            window.location.reload()
            return
        }
    }

    function updtAttrDetailPlanPaid() {
        // update current attribute
        if (detail?.attribute_options?.length > 0) {
            // attribute name
            const currentStorageAttrName = JSON.parse(localStorage.getItem(storageAttributeName))
            const findColorMenu =
                currentStorageAttrName.find(item => item.name == 'Color')?.menu?.length > 0 ?
                    currentStorageAttrName.find(item => item.name == 'Color') :
                    dataAttribute[0]
            const findSizeMenu =
                currentStorageAttrName.find(item => item.name == 'Size')?.menu?.length > 0 ?
                    currentStorageAttrName.find(item => item.name == 'Size') :
                    dataAttribute[1]
            const findShoeSize =
                currentStorageAttrName.find(item => item.name == 'Shoe Size')?.menu?.length > 0 ?
                    currentStorageAttrName.find(item => item.name == 'Shoe Size') :
                    dataAttribute[2]

            // attribute name 2
            const currentStorageAttrName2 = JSON.parse(localStorage.getItem(storageAttributeName2))
            const findColorMenu2 =
                currentStorageAttrName2.find(item => item.name == 'Color')?.menu?.length > 0 ?
                    currentStorageAttrName2.find(item => item.name == 'Color') :
                    dataAttribute[0]
            const findSizeMenu2 =
                currentStorageAttrName2.find(item => item.name == 'Size')?.menu?.length > 0 ?
                    currentStorageAttrName2.find(item => item.name == 'Size') :
                    dataAttribute[1]
            const findShoeSize2 =
                currentStorageAttrName2.find(item => item.name == 'Shoe Size')?.menu?.length > 0 ?
                    currentStorageAttrName2.find(item => item.name == 'Shoe Size') :
                    dataAttribute[2]

            const getOptions = detail.attribute_options.map((option, i) => ({
                name: option.name,
                value: option.name,
                attributeValue: option.values,
                menu: option.name == 'Color' ?
                    i === 0 ?
                        findColorMenu.menu :
                        findColorMenu2.menu
                    :
                    option.name == 'Size' ?
                        i === 0 ?
                            findSizeMenu.menu :
                            findSizeMenu2.menu
                        :
                        option.name == 'Shoe Size' ?
                            i === 0 ?
                                findShoeSize.menu :
                                findShoeSize2.menu :
                            option.values
                ,
                isCustomAttribute: option.name !== 'Color' &&
                    option.name !== 'Size' &&
                    option.name !== 'Shoe Size' ?
                    true : false
            }))
            const newOptions = [...getOptions]
            const removeDuplicateAttr = getOptions[0].menu.filter((v, idx, self) =>
                idx === self.findIndex((t) => (
                    t === v
                ))
            )
            newOptions[0].menu = removeDuplicateAttr
            if (getOptions.length === 2) {
                const removeDuplicateAttr2 = getOptions[1].menu.filter((v, idx, self) =>
                    idx === self.findIndex((t) => (
                        t === v
                    ))
                )
                newOptions[1].menu = removeDuplicateAttr2
            }

            if (getOptions[0]?.isCustomAttribute) {
                // check isAvailable attribute name
                const isAvailableName = dataAttributeName?.findIndex(item => item.name == newOptions[0].name)
                if (isAvailableName !== -1 && isAvailableName !== undefined) {
                    const newAttr = [...dataAttributeName]
                    newAttr[isAvailableName].attributeValue = newOptions[0].attributeValue
                    // if nothing menu value in local storage
                    newOptions[0].attributeValue.forEach(value => {
                        const check = newAttr[isAvailableName].menu.find(menu => menu == value)
                        if (!check) {
                            newAttr[isAvailableName].menu = [...newAttr[isAvailableName].menu, value]
                        }
                    })
                    setDataAttributeName(newAttr)
                    newOptions[0].menu = dataAttributeName[isAvailableName].menu
                } else {
                    const isAvailableAttrName2 = dataAttributeName2?.find(item => item.name == newOptions[1].name)
                    if (!isAvailableAttrName2?.name) {
                        // if attribute is not already yet available in local storage
                        setDataAttributeName((prev) => [
                            ...prev,
                            newOptions[0],
                            {
                                name: newOptions[1].name,
                                value: newOptions[1].value,
                                menu: [],
                                attributeValue: [],
                                isCustomAttribute: true,
                            }
                        ])
                    } else {
                        // if attribute is already available in local storage
                        setDataAttributeName((prev) => [
                            ...prev,
                            newOptions[0]
                        ])
                        setTimeout(() => {
                            setDataAttributeName2((prev) => [
                                ...prev,
                                newOptions[0]
                            ])
                        }, 0)
                    }
                }
            } else {
                if (dataAttributeName === null) {
                    // if first load and no attribute in local storage
                    const findIdxAttr = dataAttribute.findIndex(item => item.name == newOptions[0].name)
                    const newAttribute = dataAttribute
                    newOptions[0].attributeValue.forEach(item => {
                        const check = newAttribute[findIdxAttr].menu.find(menu => menu == item)
                        if (!check) {
                            newAttribute[findIdxAttr].menu.push(item)
                        }
                    })
                    newAttribute[findIdxAttr].attributeValue = newOptions[0].attributeValue
                    newOptions[0].menu = newAttribute[findIdxAttr].menu
                    setDataAttributeName(newAttribute)
                } else {
                    // if first load and have attribute in local storage
                    const findIdxAttr = dataAttributeName.findIndex(item => item.name == newOptions[0].name)
                    const newAttribute = dataAttributeName
                    newOptions[0].attributeValue.forEach(item => {
                        const check = newAttribute[findIdxAttr].menu.find(menu => menu == item)
                        if (!check) {
                            newAttribute[findIdxAttr].menu.push(item)
                        }
                    })
                    newAttribute[findIdxAttr].attributeValue = newOptions[0].attributeValue
                    newOptions[0].menu = newAttribute[findIdxAttr].menu
                    setDataAttributeName(newAttribute)
                }
            }

            if (getOptions.length === 2) {
                if (getOptions[1]?.isCustomAttribute) {
                    const isAvailableName = dataAttributeName2?.findIndex(item => item.name == newOptions[1].name)
                    // check isAvailable attribute name 2
                    if (isAvailableName !== -1 && isAvailableName !== undefined) {
                        const newAttr2 = [...dataAttributeName2]
                        newAttr2[isAvailableName].attributeValue = newOptions[1].attributeValue
                        // if nothing menu value in local storage
                        newOptions[1].attributeValue.forEach(value => {
                            const check = newAttr2[isAvailableName].menu.find(menu => menu == value)
                            if (!check) {
                                newAttr2[isAvailableName].menu = [...newAttr2[isAvailableName].menu, value]
                            }
                        })
                        setDataAttributeName2(newAttr2)
                        newOptions[1].menu = dataAttributeName2[isAvailableName].menu
                    } else {
                        const isAvailableAttrName = dataAttributeName?.find(item => item.name == newOptions[0].name)
                        if (!isAvailableAttrName?.name) {
                            // if attribute is not already yet available in local storage
                            setDataAttributeName2((prev) => [
                                ...prev,
                                {
                                    name: newOptions[0].name,
                                    value: newOptions[0].value,
                                    menu: [],
                                    attributeValue: [],
                                    isCustomAttribute: true,
                                },
                                newOptions[1]
                            ])
                        } else {
                            // if attribute is already available in local storage
                            setDataAttributeName2((prev) => [
                                ...prev,
                                newOptions[1]
                            ])
                            setDataAttributeName((prev) => [
                                ...prev,
                                newOptions[1]
                            ])
                        }
                    }
                } else {
                    if (dataAttributeName2 === null) {
                        // if first load and no attribute in local storage
                        const findIdxAttr = dataAttribute.findIndex(item => item.name == newOptions[1].name)
                        const newAttribute = dataAttribute
                        newOptions[1].attributeValue.forEach(item => {
                            const check = newAttribute[findIdxAttr].menu.find(menu => menu == item)
                            if (!check) {
                                newAttribute[findIdxAttr].menu.push(item)
                            }
                        })
                        newAttribute[findIdxAttr].attributeValue = newOptions[1].attributeValue
                        newOptions[1].menu = newAttribute[findIdxAttr].menu
                        setDataAttributeName2(newAttribute)
                    } else {
                        // if first load and have attribute in local storage
                        const findIdxAttr = dataAttributeName2.findIndex(item => item.name == newOptions[1].name)
                        const newAttribute = dataAttributeName2
                        newOptions[1].attributeValue.forEach(item => {
                            const check = newAttribute[findIdxAttr].menu.find(menu => menu == item)
                            if (!check) {
                                newAttribute[findIdxAttr].menu.push(item)
                            }
                        })
                        newAttribute[findIdxAttr].attributeValue = newOptions[1].attributeValue
                        newOptions[1].menu = newAttribute[findIdxAttr].menu
                        setDataAttributeName2(newAttribute)
                    }
                }
            }
            setCurrentAttribute(newOptions)
        }
        // update rows attribute
        if (detail?.attribute_rows?.length > 0) {
            const getRows = detail.attribute_rows.map(rows => ({
                id: randomId(),
                idx: 0,
                attribute: rows.attribute_value_first,
                second_attribute: rows.attribute_value_second,
                quantity: `${rows.quantity}`,
                price_rm: `${rows?.price_int}`,
                sku: rows.sku,
                images: rows.image,
                image_file: rows?.image_file,
                file: null
            }))
            setRowsAttribute(getRows)
            setCheckedAttribute(true)
        } else {
            setCheckedAttribute(false)
        }
    }

    function updtAttrDetailPlanFree() {
        if (detail?.attribute_options?.length > 0) {
            const findColorMenu = dataAttribute.find(item => item.name == 'Color')
            const findSizeMenu = dataAttribute.find(item => item.name == 'Size')
            const findShoeSize = dataAttribute.find(item => item.name == 'Shoe Size')

            const getOptions = detail.attribute_options.map((option, i) => ({
                name: option.name,
                value: option.name,
                attributeValue: option.values,
                menu: option.name == 'Color' ?
                    findColorMenu.menu
                    :
                    option.name == 'Size' ?
                        findSizeMenu.menu
                        :
                        option.name == 'Shoe Size' ?
                            findShoeSize.menu :
                            option.values
                ,
                isCustomAttribute: option.name !== 'Color' &&
                    option.name !== 'Size' &&
                    option.name !== 'Shoe Size' ?
                    true : false
            }))

            setCurrentAttribute(getOptions)

            setDataAttributeName([getOptions[0]])

            if (getOptions.length === 2) {
                setDataAttributeName2([getOptions[1]])
            }
        }
        // update rows attribute
        if (detail?.attribute_rows?.length > 0) {
            const getRows = detail.attribute_rows.map(rows => ({
                id: randomId(),
                idx: 0,
                attribute: rows.attribute_value_first,
                second_attribute: rows.attribute_value_second,
                quantity: `${rows.quantity}`,
                price_rm: `${rows?.price_int}`,
                sku: rows.sku,
                images: rows.image,
                image_file: rows?.image_file,
                file: null
            }))
            setRowsAttribute(getRows)
            setCheckedAttribute(true)
        } else {
            setCheckedAttribute(false)
        }
    }

    function attributeDetail() {
        setValue('product_id', detail?.id)

        if (planIsCustomAttribute) {
            updtAttrDetailPlanPaid()
        } else {
            updtAttrDetailPlanFree()
        }
    }

    useEffect(() => {
        attributeDetail()
    }, [
        detail,
        planIsCustomAttribute
    ])

    const total_page = useMemo(() => {
        if (rowsAttribute.length > 0) {
            const total = Math.floor(rowsAttribute.length / 100)
            return total
        }
        return 0
    }, [rowsAttribute])

    useEffect(() => {
        // options
        const createOptions = currentAttribute.map(item => item.name)
        setValue('options', JSON.stringify(createOptions))
        // values
        const createValues = rowsAttribute.map(item => {
            return {
                attribute_first: item?.attribute,
                attribute_second: item?.second_attribute?.length > 0 ? item.second_attribute : null,
                quantity: item?.quantity,
                price: item?.price_rm,
                sku: item?.sku
            }
        })
        setValue('values', JSON.stringify(createValues))
    }, [
        currentAttribute,
        rowsAttribute
    ])
    useEffect(() => {
        setValue('active_attribute', checkedAttribute)
    }, [checkedAttribute])

    const createAttributeImages = useMemo(() => {
        // attribute_images
        const createAttributeImages = rowsAttribute.map(item => {
            return {
                file: item.file,
                image_file: item?.image_file ?? null
            }
        })
        return createAttributeImages
    }, [rowsAttribute])

    const clickSaveAttribute = useCallback(async (value) => {
        toast.loading("Please wait...");
        setIsLoading(true);

        const data = new FormData();
        data.append('product_id', value.product_id)
        data.append('active_attribute', value?.active_attribute)
        if (checkedAttribute) {
            data.append('options', value?.options)
            data.append('values', value?.values)

            if (createAttributeImages?.length > 0) {
                createAttributeImages.forEach((item, i) => {
                    data.append(
                        `attribute_images[${i}]`,
                        item.file == null && item.image_file !== null ?
                            item.image_file :
                            item.file
                    )
                })
            } else {
                data.append('attribute_images[0]', null)
            }
        }

        const result = await dispatch(updateAttributeProducts(data))
        if (result.type == 'catalog/product/update-attribute/fulfilled') {
            toast.dismiss()
            toast.success(result.payload?.message)

            setTimeout(() => {
                resetCatalogs();
                getData();
                onCancel()
            }, 1000)
        } else {
            toast.dismiss()
            toast.error(result.payload?.message)
        }
        setIsLoading(false)
    }, [rowsAttribute, checkedAttribute])

    const columnsAttribute = [
        {
            field: 'attribute',
            headerName: 'ATTRIBUTE',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                const { row } = params;

                return <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '80px'
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'wrap',
                        }}
                        fontSize={12}
                    >
                        {row.attribute}
                    </Typography>
                </Box>
            },
        },
        {
            field: 'second_attribute',
            headerName: 'SECOND ATTRIBUTE',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                const { row } = params;

                return <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '80px'
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'wrap',
                        }}
                        fontSize={12}
                    >
                        {row.second_attribute?.length > 0 ? row.second_attribute : '-'}
                    </Typography>
                </Box>
            },
        },
        {
            field: 'quantity',
            headerName: 'STOCK',
            width: 100,
            editable: true,
            sortable: false,
            preProcessEditCellProps: (params) => {
                resetAutomaticAttr()

                const { props } = params
                setCurrentValueEditable((prev) => ({
                    ...prev,
                    quantity: props.value
                }))
                return { ...params.props }
            },
            renderCell: (params) => {
                const { row } = params;

                return <Typography
                    fontSize={12}
                    color={row.quantity?.length > 0 ? '#212B36' : '#aaa'}
                >
                    {row.quantity?.length > 0 ? row.quantity : 'Click to edit'}
                </Typography>
            },
        },
        {
            field: 'price_rm',
            headerName: 'PRICE',
            width: 100,
            editable: true,
            sortable: false,
            preProcessEditCellProps: (params) => {
                resetAutomaticAttr()

                const { props } = params
                setCurrentValueEditable((prev) => ({
                    ...prev,
                    price_rm: props.value
                }))
                return { ...params.props }
            },
            renderCell: (params) => {
                const { row } = params;

                return <Typography
                    fontSize={12}
                    color={row.price_rm?.length > 0 ? '#212B36' : '#aaa'}
                >
                    {row.price_rm?.length > 0 ? row.price_rm : 'Click to edit'}
                </Typography>
            },
        },
        {
            field: 'sku',
            headerName: 'SKU',
            width: 150,
            editable: true,
            sortable: false,
            preProcessEditCellProps: (params) => {
                resetAutomaticAttr()

                const { props } = params
                setCurrentValueEditable((prev) => ({
                    ...prev,
                    sku: props.value
                }))
                return { ...params.props }
            },
            renderCell: (params) => {
                const { row } = params;

                return <Box
                    sx={{
                        overflowY: 'auto',
                        maxHeight: '80px'
                    }}
                >
                    <Typography
                        sx={{
                            whiteSpace: 'wrap',
                        }}
                        fontSize={12}
                        color={row.sku?.length > 0 ? '#212B36' : '#aaa'}
                    >
                        {row.sku?.length > 0 ? row.sku : 'Click to edit'}
                    </Typography>
                </Box>
            },
        },
        {
            field: 'images',
            headerName: 'IMAGES',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                const { row } = params

                return (
                    <Box>
                        {row.images ?
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    src={row.images}
                                    alt=""
                                    height={70}
                                    width={70}
                                    style={{
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        resetAutomaticAttr()
                                        openFiles(row.id)
                                    }}
                                />
                                <IconButton
                                    sx={{ py: 0, minWidth: "36px", height: '36px', marginLeft: 1 }}
                                    onClick={() => {
                                        resetAutomaticAttr()
                                        removeImage(row)
                                    }}
                                >
                                    <Iconify
                                        icon={"fluent:delete-24-regular"}
                                        color="#F15A28"
                                        width={18}
                                        height={18}
                                    />
                                </IconButton>
                            </Box>
                            :
                            <Box
                                sx={{
                                    background: '#ddd',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 1,
                                    height: 70,
                                    width: 70,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    resetAutomaticAttr()
                                    openFiles(row.id)
                                }}
                            >
                                <Typography
                                    fontSize={8}
                                >
                                    150 x 150
                                </Typography>
                            </Box>
                        }
                        <input
                            id={row.id}
                            type="file"
                            accept="image/png, image/gif, image/jpeg"
                            hidden
                            onChange={(e) => {
                                resetAutomaticAttr()
                                changeFile(e, row)
                            }}
                        />
                    </Box>
                )
            },
        },
    ]

    function openFiles(id) {
        const input = document.getElementById(id)
        if (input) {
            input.click()
        }
    }
    function validateExtension(file) {
        if (
            file.type == 'image/jpeg' ||
            file.type == 'image/jpg' ||
            file.type == 'image/png'
        ) {
            return 'accept'
        }
        return
    }
    async function loadLocalImage(file) {
        return await new Promise((resolve, reject) => {
            let reader = new FileReader()

            reader.onloadend = () => {
                resolve(reader.result)
            }

            if (file) {
                reader.readAsDataURL(file)
            }
        })
    }
    const changeFile = useCallback(async (e, row) => {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        if (e.target.files.length !== 0) {
            if (!validateExtension(e.target.files[0])) {
                toast.dismiss()
                toast.error('The image file must be "image/jpeg, image/jpg, image/png"')
                return
            }
            const base64 = await loadLocalImage(e.target.files[0])
            const currentRows = [...rowsAttribute]
            const findImagesIdx = rowsAttribute.findIndex(item => item.id == row.id)
            currentRows[findImagesIdx].images = base64
            currentRows[findImagesIdx].file = e.target.files[0]
            setRowsAttribute(currentRows)
        }
    }, [rowsAttribute])

    const removeImage = useCallback((row) => {
        const currentRows = [...rowsAttribute]
        const findImagesIdx = rowsAttribute.findIndex(item => item.id == row.id)
        currentRows[findImagesIdx].images = ''
        currentRows[findImagesIdx].file = null
        currentRows[findImagesIdx].image_file = null
        setRowsAttribute(currentRows)
    }, [rowsAttribute])

    function pushOnlyFirstAttribute(paramAttributes) {
        let newAttribute = []
        const currentRows = rowsAttribute
        if (!paramAttributes) {
            currentAttribute.forEach((item, i) => {
                if (item.attributeValue.length > 0) {
                    item.attributeValue.forEach((value, idx) => {
                        newAttribute.push({
                            id: randomId(),
                            idx: i,
                            attribute: value,
                            second_attribute: '',
                            quantity: '',
                            price_rm: '',
                            sku: '',
                            images: '',
                            image_file: null,
                            file: null
                        })
                    })
                }
            })
        } else {
            paramAttributes.forEach((item, i) => {
                if (item.attributeValue.length > 0) {
                    item.attributeValue.forEach((value, idx) => {
                        newAttribute.push({
                            id: randomId(),
                            idx: i,
                            attribute: value,
                            second_attribute: '',
                            quantity: '',
                            price_rm: '',
                            sku: '',
                            images: '',
                            image_file: null,
                            file: null
                        })
                    })
                }
            })
        }

        currentRows.forEach((item) => {
            const findItem = newAttribute.find(attr => attr?.idx === item?.idx && attr?.attribute == item?.attribute)
            const findIdx = newAttribute.findIndex(attr => attr?.idx === item?.idx && attr?.attribute == item?.attribute)
            if (findItem) {
                newAttribute[findIdx].quantity = item.quantity
                newAttribute[findIdx].price_rm = item.price_rm
                newAttribute[findIdx].sku = item.sku
                newAttribute[findIdx].images = item.images
                newAttribute[findIdx].image_file = item.image_file
                newAttribute[findIdx].file = item.file
            }
        })
        setRowsAttribute(newAttribute)
    }
    const pushOtherAttribute = useCallback((paramAttributes) => {
        const attributeValueNumber1 = paramAttributes[0]?.attributeValue
        const attributeValueNumber2 = currentAttribute[1]?.attributeValue
        let newAttribute = []
        const currentRows = rowsAttribute
        attributeValueNumber1.forEach((value) => {
            attributeValueNumber2.forEach((value2) => {
                newAttribute.push({
                    id: randomId(),
                    idx: 0,
                    attribute: value,
                    second_attribute: value2,
                    quantity: '',
                    price_rm: '',
                    sku: '',
                    images: '',
                    image_file: null,
                    file: null
                })
            })
        })

        currentRows.forEach((item) => {
            const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
            const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
            if (findItem) {
                newAttribute[findIdx].quantity = item.quantity
                newAttribute[findIdx].price_rm = item.price_rm
                newAttribute[findIdx].sku = item.sku
                newAttribute[findIdx].images = item.images
                newAttribute[findIdx].image_file = item.image_file
                newAttribute[findIdx].file = item.file
            }
        })
        setRowsAttribute(newAttribute)
    }, [
        currentAttribute,
        rowsAttribute
    ])
    function pushOnlySecondAttribute(paramAttributes) {
        const attributeValueNumber1 = paramAttributes[0]?.attributeValue
        if (paramAttributes[1]?.attributeValue?.length === 0) {
            setRowsAttribute([])
            return
        }
        const attributeValueNumber2 = paramAttributes[1]?.attributeValue
        let newAttribute = []
        const currentRows = rowsAttribute
        attributeValueNumber1.forEach((value) => {
            attributeValueNumber2.forEach((value2) => {
                newAttribute.push({
                    id: randomId(),
                    idx: 0,
                    attribute: value,
                    second_attribute: value2,
                    quantity: '',
                    price_rm: '',
                    sku: '',
                    images: '',
                    image_file: null,
                    file: null
                })
            })
        })

        currentRows.forEach((item) => {
            if (attributeValueNumber2?.length === 1) {
                const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
                const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
                if (findItem) {
                    newAttribute[findIdx].quantity = item.quantity
                    newAttribute[findIdx].price_rm = item.price_rm
                    newAttribute[findIdx].sku = item.sku
                    newAttribute[findIdx].images = item.images
                    newAttribute[findIdx].image_file = item.image_file
                    newAttribute[findIdx].file = item.file
                }
            } else {
                const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
                const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
                if (findItem) {
                    newAttribute[findIdx].quantity = item.quantity
                    newAttribute[findIdx].price_rm = item.price_rm
                    newAttribute[findIdx].sku = item.sku
                    newAttribute[findIdx].images = item.images
                    newAttribute[findIdx].image_file = item.image_file
                    newAttribute[findIdx].file = item.file
                }
            }
        })
        setRowsAttribute(newAttribute)
    }

    function pushAttribute(paramAttributes, index) {
        // first attribute (attribute)
        if (index === 0) {
            if (currentAttribute.length === 1) {
                pushOnlyFirstAttribute(paramAttributes)
            } else {
                pushOtherAttribute(paramAttributes, index)
            }
        } else if (index === 1) {
            pushOnlySecondAttribute(paramAttributes)
        }
    }

    const changeAttributeName = useCallback((e, value, index) => {
        if (value) {
            // reload automatically if no attributes in local storage
            resetAutomaticAttr()
            const attr = [...currentAttribute]
            attr[index] = value
            setCurrentAttribute(attr)
            pushAttribute(attr, index)
        }
    }, [
        currentAttribute,
        // dataAttributeName2
    ])

    const changeAttributeValue = useCallback((e, value, index) => {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        const attr = [...currentAttribute]
        if (value) {
            attr[index].attributeValue = value
            setCurrentAttribute(attr)

            pushAttribute(attr, index)
        }
    }, [currentAttribute, rowsAttribute])

    function addCustomMenuAttributeValue(value, currentName) {
        const attributeName = [...dataAttributeName]
        const findIdx = dataAttributeName.findIndex(item => item.name == currentName)
        attributeName[findIdx].menu.push(value)
        const removeDuplicateAttr = attributeName[findIdx].menu.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        const removeDuplicateValue = attributeName[findIdx].attributeValue.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        attributeName[findIdx].menu = removeDuplicateAttr
        attributeName[findIdx].attributeValue = removeDuplicateValue
        setDataAttributeName(attributeName)
    }
    function addCustomMenuAttributeValue2(value, currentName) {
        const attributeName2 = [...dataAttributeName2]
        const findIdx = dataAttributeName2.findIndex(item => item.name == currentName)
        attributeName2[findIdx].menu.push(value)
        const removeDuplicateAttr = attributeName2[findIdx].menu.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        const removeDuplicateValue = attributeName2[findIdx].attributeValue.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        attributeName2[findIdx].menu = removeDuplicateAttr
        attributeName2[findIdx].attributeValue = removeDuplicateValue
        setDataAttributeName2(attributeName2)
    }
    function addCustomInCurrentAttribute(value, index) {
        const attr = currentAttribute
        // attr[index].attributeValue.push(value)
        attr[index].attributeValue = [...attr[index].attributeValue, value]
        attr[index].menu = [...attr[index].menu, value]
        const removeDuplicateAttr = attr[index].menu.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        const removeDuplicateValue = attr[index].attributeValue.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t === v
            ))
        )
        attr[index].menu = removeDuplicateAttr
        attr[index].attributeValue = removeDuplicateValue
        setCurrentAttribute(attr)

        pushAttribute(attr, index)
    }
    const handleKeyPress = useCallback((event, index, attribute) => {
        resetAutomaticAttr()

        let validateKey = null

        if (isMobile || isIOS || isAndroid) {
            validateKey =
                event?.key === "," ||
                event?.code == 'Comma' ||
                event?.keyCode === 188 ||
                event?.which === 188 ||
                event?.which === 44
        } else {
            validateKey =
                event?.key === "Enter" ||
                event?.code == 'Enter' ||
                event?.which === 13 ||
                event?.keyCode === 13 ||
                event?.key === "," ||
                event?.code == 'Comma' ||
                event?.keyCode === 188 ||
                event?.which === 188 ||
                event?.which === 44
        }

        const validateChar = event.target.value.slice(-1) == ','
        if (
            validateKey ||
            validateChar
            &&
            event.target.value.trim() !== ""
        ) {
            const isAvailableValue = currentAttribute[index].attributeValue.find(item => item.toLowerCase().replaceAll(spaceRegex, '') == event.target.value.replaceAll(',', '').toLowerCase().replaceAll(spaceRegex, ''))
            if (isAvailableValue || event.target.value.replaceAll(',', '').trim() == "") {
                return
            }

            const isAvailableMenu = currentAttribute[index].menu.find(item => item.toLowerCase().replaceAll(spaceRegex, '') == event.target.value.replaceAll(',', '').toLowerCase().replaceAll(spaceRegex, ''))
            let newValue = event.target.value
            if (isAvailableMenu) {
                newValue = isAvailableMenu
            }
            if (index === 0) {
                addCustomMenuAttributeValue(newValue.replaceAll(',', ''), currentAttribute[index].name)
            } else if (index === 1) {
                addCustomMenuAttributeValue2(newValue.replaceAll(',', ''), currentAttribute[index].name)
            }
            addCustomInCurrentAttribute(newValue.replaceAll(',', ''), index)
        }
    }, [currentAttribute, rowsAttribute])

    function clickAddAttribute() {
        resetAutomaticAttr()

        let currentAttr = currentAttribute
        currentAttr = [...currentAttr, JSON.parse(localStorage.getItem(storageAttributeName2))[0]]

        setCurrentAttribute(currentAttr)
        if (JSON.parse(localStorage.getItem(storageAttributeName2))[0]?.attributeValue?.length > 0) {
            pushAttribute(currentAttr, 1)
        }
    }

    function updateOnDeleteAttribute(paramAttributes) {
        if (paramAttributes[0]?.attributeValue?.length > 0) {
            let newAttribute = []
            paramAttributes[0].attributeValue.forEach((value) => {
                newAttribute.push({
                    id: randomId(),
                    idx: 0,
                    attribute: value,
                    second_attribute: '',
                    quantity: '',
                    price_rm: '',
                    sku: '',
                    images: '',
                    image_file: null,
                    file: null
                })
            })
            setRowsAttribute(newAttribute)
        }
    }

    function clickDeleteAttribute() {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()
        const removeIt = currentAttribute.filter((item, i) => i !== (currentAttribute.length - 1))
        setCurrentAttribute(removeIt)
        updateOnDeleteAttribute(removeIt)
        // setCreateAttributeValue('')
        // if (activeEditCustomAttr?.name) {
        //     setActiveEditCustomAttr({})
        // }
    }

    function changeCustomAttribute(e) {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        if (e.target.value.length <= 30) {
            let newValue = e.target.value
            if (e.target.value.includes('/') || e.target.value.includes(',')) {
                newValue = newValue.replaceAll('/', '').replaceAll(',', '')
            }
            setCreateAttributeValue(newValue)
        }
    }

    const isHaveCustomAttributeName = useMemo(() => {
        const found = dataAttributeName2?.filter(item => item?.isCustomAttribute)
        if (found?.length > 0) {
            return found
        }
        return []
    }, [dataAttributeName2])

    function deleteCustomAttribute(item, i) {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        const removeAttribute = dataAttributeName.filter(data => data?.name !== item.name)
        const removeAttribute2 = dataAttributeName2.filter(data => data?.name !== item.name)
        setDataAttributeName(removeAttribute)
        setDataAttributeName2(removeAttribute2)
        setOpenMenuCustom(false)
        setAnchorEl(null)
        const attr = [...currentAttribute]
        if (currentAttribute[0].name == item.name) {
            attr[0] = JSON.parse(localStorage.getItem(storageAttributeName))[0]
        }
        if (currentAttribute[1]?.name == item.name) {
            attr[1] = JSON.parse(localStorage.getItem(storageAttributeName2))[0]
        }
        if (currentAttribute[0].name == item.name || currentAttribute[1]?.name == item.name) {
            setCurrentAttribute(attr)
            setRowsAttribute([])
        }
        if (activeEditCustomAttr?.name == item?.name) {
            setActiveEditCustomAttr({})
            setCreateAttributeValue('')
        }
        toast.dismiss()
        toast.success('Deleted successfully.')
    }

    function clickEditCustomAttrName(item) {
        setAnchorEl(null)
        setOpenMenuCustom(false)
        setActiveEditCustomAttr(item)
        setCreateAttributeValue(item.name)
    }

    function clickCancelEdit() {
        setActiveEditCustomAttr({})
        setCreateAttributeValue('')
    }

    const clickAddCustomAttribute = useCallback(() => {
        const checkAvailableAttr2 = dataAttributeName2.find(item => item.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
        const checkAvailableAttr = dataAttributeName.find(item => item.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
        if (!checkAvailableAttr2 && !checkAvailableAttr) {
            setDataAttributeName2((prev) => [
                ...prev,
                {
                    name: createAttributeValue,
                    value: createAttributeValue,
                    attributeValue: [],
                    isCustomAttribute: true,
                    menu: [],
                },
            ])
            setDataAttributeName((prev) => [
                ...prev,
                {
                    name: createAttributeValue,
                    value: createAttributeValue,
                    attributeValue: [],
                    isCustomAttribute: true,
                    menu: [],
                },
            ])
            setCreateAttributeValue('')
            toast.dismiss()
            toast.success('Added successfully')
            return
        }
        toast.dismiss()
        toast.error('Attribute name already exists.')
    }, [
        createAttributeValue,
        dataAttributeName2,
        dataAttributeName
    ])

    const clickSaveEdit = useCallback(() => {
        const checkAvailableAttr = dataAttributeName2.find(data => data.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
        if (checkAvailableAttr?.name) {
            toast.dismiss()
            toast.error('Attribute name already exists.')
            return
        }
        const attr = [...currentAttribute]
        // attribute name
        const attributeName = [...dataAttributeName]
        const findIdx = dataAttributeName.findIndex(data => data.name == activeEditCustomAttr.name)
        attributeName[findIdx].name = createAttributeValue
        attributeName[findIdx].value = createAttributeValue
        setDataAttributeName(attributeName)
        if (currentAttribute[0].name == activeEditCustomAttr.name) {
            attr[0].name = createAttributeValue
            attr[0].value = createAttributeValue
        }
        if (currentAttribute[1]?.name == activeEditCustomAttr.name) {
            attr[1].name = createAttributeValue
            attr[1].value = createAttributeValue
        }
        // attribute name 2
        const attributeName2 = [...dataAttributeName2]
        const findIdxAttr2 = dataAttributeName2.findIndex(data => data.name == activeEditCustomAttr.name)
        attributeName2[findIdxAttr2].name = createAttributeValue
        attributeName2[findIdxAttr2].value = createAttributeValue
        setDataAttributeName2(attributeName2)

        setCurrentAttribute(attr)

        setActiveEditCustomAttr({})
        setCreateAttributeValue('')
        toast.dismiss()
        toast.success('Saved successfully')
    }, [
        dataAttributeName2,
        currentAttribute,
        createAttributeValue,
        dataAttributeName,
        activeEditCustomAttr,
    ])

    const rowEditStart = useCallback((params) => {
        resetAutomaticAttr()

        const { row } = params
        setCurrentValueEditable({
            quantity: row.quantity,
            price_rm: row.price_rm,
            sku: row.sku
        })
    }, [rowsAttribute])

    const rowEditStop = useCallback((params) => {
        resetAutomaticAttr()

        const { row } = params
        const currentRows = [...rowsAttribute]
        const findRowIdx = rowsAttribute.findIndex(item => item.id == row.id)
        currentRows[findRowIdx].quantity = currentValueEditable?.quantity
        currentRows[findRowIdx].price_rm = currentValueEditable?.price_rm
        currentRows[findRowIdx].sku = currentValueEditable?.sku
        setRowsAttribute(currentRows)
        setCurrentValueEditable({})
    }, [rowsAttribute, currentValueEditable])

    function toggleActiveAttribute() {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()
        setCheckedAttribute(!checkedAttribute)
        if (detail?.have_attribute) {
            setRowsAttribute([])
            setCreateAttributeValue('')
            setCurrentAttribute([])
            // setDataAttributeName2(dataAttributeName)
            if (!checkedAttribute) {
                attributeDetail()
            }
        } else {
            if (checkedAttribute) {
                if (planIsCustomAttribute) {
                    setCurrentAttribute([
                        JSON.parse(localStorage.getItem(storageAttributeName))[0]
                    ])
                } else {
                    setCurrentAttribute([dataAttribute[0]])
                }
                setRowsAttribute([])
            } else if (currentAttribute[0]?.attributeValue?.length > 0) {
                pushAttribute(currentAttribute, 0)
            } else {
                setRowsAttribute([])
            }
            setCreateAttributeValue('')
        }

        if (activeEditCustomAttr?.name) {
            setAnchorEl(null)
            setOpenMenuCustom(false)
            setActiveEditCustomAttr({})
        }
    }

    // batch edit logic
    function updateBatchAttributeMenu(attribute) {
        const getAttributeName = rowsAttribute.filter((v, idx, self) =>
            idx === self.findIndex((t) => (
                t[attribute] === v[attribute]
            ))
        )
        let newAttribute = ['All']
        const value = getAttributeName.map(item => item[attribute])
        if (value.length > 0 && value[0] !== '') {
            newAttribute = [...newAttribute, ...value]
        }
        return newAttribute
    }
    useEffect(() => {
        if (rowsAttribute.length > 0) {
            setBatchEdit({
                attribute: {
                    name: 'attribute',
                    attributeValue: updateBatchAttributeMenu('attribute'),
                    value: updateBatchAttributeMenu('attribute')[0]
                },
                second_attribute: {
                    name: 'second Attribute',
                    attributeValue: updateBatchAttributeMenu('second_attribute'),
                    value: updateBatchAttributeMenu('second_attribute')[0]
                },
                stock: '',
                price: '',
                sku: '',
                images: '',
                file: null
            })
        } else {
            setBatchEdit({
                attribute: {
                    name: 'attribute',
                    attributeValue: ['All'],
                    value: 'All'
                },
                second_attribute: {
                    name: 'second Attribute',
                    attributeValue: ['All'],
                    value: 'All'
                },
                stock: '',
                price: '',
                sku: '',
                images: '',
                file: null
            })
        }
    }, [rowsAttribute])

    function changeBatchAttribute(e, v, name) {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        setBatchEdit({
            ...batchEdit,
            [name]: {
                name,
                attributeValue: updateBatchAttributeMenu(name),
                value: v
            }
        })
    }
    function changeInputBatchText(e, name) {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        if (name == 'batch-price') {
            setBatchEdit({
                ...batchEdit,
                price: e.target.value
            })
        } else if (name == 'batch-stock') {
            setBatchEdit({
                ...batchEdit,
                stock: e.target.value
            })
        } else if (name == 'batch-sku') {
            setBatchEdit({
                ...batchEdit,
                sku: e.target.value
            })
        }
    }

    function apllyBothNotAll() {
        // reload automatically if no attributes in local storage
        resetAutomaticAttr()

        const currentRows = [...rowsAttribute]

        currentRows.forEach((item, index) => {
            const check = item.attribute == batchEdit.attribute.value && item.second_attribute == batchEdit.second_attribute.value
            if (check) {
                currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
                currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
                currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
                currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
                currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
            }
        })
        setRowsAttribute(currentRows)
    }
    function applySecondAttrIsAll() {
        const currentRows = [...rowsAttribute]

        currentRows.forEach((item, index) => {
            const check = item.attribute == batchEdit.attribute.value
            if (check) {
                currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
                currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
                currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
                currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
                currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
            }
        })
        setRowsAttribute(currentRows)
    }
    function applyAttrIsAll() {
        const currentRows = [...rowsAttribute]

        currentRows.forEach((item, index) => {
            const check = item.second_attribute == batchEdit.second_attribute.value
            if (check) {
                currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
                currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
                currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
                currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
                currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
            }
        })
        setRowsAttribute(currentRows)
    }
    function applyBothIsAll() {
        const currentRows = [...rowsAttribute]

        currentRows.forEach((item, index) => {
            currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
            currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
            currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
            currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
            currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
        })
        setRowsAttribute(currentRows)
    }

    function applyBatchEdit() {
        if (
            batchEdit.attribute.value !== 'All' &&
            batchEdit.second_attribute.value !== 'All'
        ) {
            apllyBothNotAll()
        } else if (
            batchEdit.attribute.value !== 'All' &&
            batchEdit.second_attribute.value == 'All'
        ) {
            applySecondAttrIsAll()
        } else if (
            batchEdit.attribute.value == 'All' &&
            batchEdit.second_attribute.value !== 'All'
        ) {
            applyAttrIsAll()
        } else if (
            batchEdit.attribute.value == 'All' &&
            batchEdit.second_attribute.value == 'All'
        ) {
            applyBothIsAll()
        }
        toast.dismiss()
        toast.success('Apply successfully.')
    }
    const isDisabledApply = useMemo(() => {
        let err = {}
        if (batchEdit.attribute.attributeValue.length > 1 && !batchEdit.attribute.value) {
            err.attribute = ''
        }
        if (batchEdit.second_attribute.attributeValue.length > 0 && !batchEdit.second_attribute.value?.trim()) {
            err.second_attribute = ''
        }
        if (
            !batchEdit.stock.trim() &&
            !batchEdit.price.trim() &&
            !batchEdit.sku.trim() &&
            !batchEdit.file
        ) {
            err.textInput = ''
        }

        if (Object.keys(err).length === 0) {
            return false
        }
        return true
    }, [batchEdit])

    const handleChooseImgBatchEdit = useCallback(async (e) => {
        resetAutomaticAttr()

        if (e.target.files.length !== 0) {
            if (!validateExtension(e.target.files[0])) {
                toast.dismiss()
                toast.error('The image file must be "image/jpeg, image/jpg, image/png"')
                return
            }
            const base64 = await loadLocalImage(e.target.files[0])
            setBatchEdit({
                ...batchEdit,
                images: base64,
                file: e.target.files[0]
            })
        }
    }, [batchEdit])

    return (
        <FormContainer title="Edit Attribute">
            <Box
                overflow={"auto"}
                paddingRight={2}
                height={{
                    xs: 300, // Height for extra small screens
                    sm: 350, // Height for small screens
                    md: 350, // Height for medium screens
                    lg: 400, // Height for large screens
                    xl: 400, // Height for extra large screens
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {
                                xs: "column",
                                sm: 'row',
                                md: 'row'
                            },
                            alignItems: {
                                xs: 'none',
                                sm: 'center',
                                md: 'center'
                            },
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography fontWeight="700">
                            Attribute Setting
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Switch
                                checked={checkedAttribute}
                                onChange={toggleActiveAttribute}
                            />
                            <Typography>
                                Active Attribute
                            </Typography>
                        </Box>
                    </Box>
                    <Box mb={2} />
                    {checkedAttribute && currentAttribute.map((attr, i) => {
                        return (
                            <Box key={i}>
                                <Box>
                                    <ComboBox
                                        fullWidth
                                        disableMargin
                                        title="Attribute Name"
                                        fontWeight="400"
                                        placeholder="Please select attribute"
                                        options={i === 0 ? dataAttributeName : dataAttributeName2}
                                        getOptionLabel={(option) => option?.name}
                                        value={attr}
                                        onChange={(e, value) => changeAttributeName(e, value, i)}
                                    />
                                </Box>
                                <Box mb={2} />
                                <Typography mb={0.5}>
                                    Attribute Value
                                </Typography>
                                {!planIsCustomAttribute ?
                                    <Autocomplete
                                        multiple
                                        id="tags-outlined"
                                        options={attr.menu}
                                        getOptionLabel={(option) => option}
                                        value={attr.attributeValue}
                                        filterSelectedOptions
                                        ChipProps={{
                                            style: {
                                                backgroundColor: palette.primary.main,
                                                color: palette.secondary.main,
                                            },
                                        }}
                                        renderInput={(params) => <TextField {...params} size="small" />}
                                        onChange={(e, value) => changeAttributeValue(e, value, i)}
                                    /> :
                                    <>
                                        <Autocomplete
                                            multiple
                                            id="tags-outlined"
                                            options={attr.menu}
                                            getOptionLabel={(option) => option}
                                            filterSelectedOptions
                                            value={attr.attributeValue}
                                            ChipProps={{
                                                style: {
                                                    backgroundColor: palette.primary.main,
                                                    color: palette.secondary.main,
                                                },
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Type to add attribute values ..."
                                                    size="small"
                                                    // onKeyPress={(e) => handleKeyPress(e, i, attr)}
                                                    onKeyDown={(e) => handleKeyPress(e, i, attr)}
                                                />
                                            )}
                                            onChange={(e, value) => changeAttributeValue(e, value, i)}
                                        />
                                        <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                                            {isMobile || isIOS || isAndroid ?
                                                `use comma "," as a multi-keyword separator`
                                                :
                                                `use comma "," or Enter as a multi-keyword separator`
                                            }
                                        </Typography>
                                    </>
                                }
                                <Box mb={2} />
                            </Box>
                        )
                    })}
                    <Box>
                        {/* add variant if user plan paid */}
                        {
                            planIsCustomAttribute &&
                            checkedAttribute &&
                            <Box
                                sx={{
                                    background: '#F6F7FF',
                                    borderRadius: 1,
                                    padding: 1.5
                                }}
                            >
                                <Typography
                                    fontWeight={700}
                                    mb={1}
                                >
                                    Create Attribute Name
                                </Typography>
                                <Typography
                                    mb={0.5}
                                    fontSize={14}
                                >
                                    Add attribute names manually.
                                </Typography>
                                <InputLimitText
                                    limit={30}
                                    width="auto"
                                    value={createAttributeValue}
                                    length={createAttributeValue.length}
                                    onChange={changeCustomAttribute}
                                    placeholder="Enter a new Attribute Name ..."
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: isHaveCustomAttributeName.length === 0 ? 'flex-end' : 'space-between',
                                        alignItems: 'center'
                                    }}
                                    my={1}
                                >
                                    {isHaveCustomAttributeName.length > 0 &&
                                        <Stack>
                                            <ButtonWithIcon
                                                title="View"
                                                style={{
                                                    width: 'fit-content',
                                                    marginTop: 10
                                                }}
                                                icon={'ic:baseline-arrow-drop-down'}
                                                onClick={(e) => {
                                                    setAnchorEl(e.currentTarget)
                                                    setOpenMenuCustom(!openMenuCustom)
                                                }}
                                            />
                                            <div>
                                                <Menu
                                                    id="basic-menu"
                                                    anchorEl={anchorEl}
                                                    open={openMenuCustom}
                                                    onClose={() => {
                                                        setOpenMenuCustom(!openMenuCustom)
                                                        setAnchorEl(null)
                                                    }}
                                                    MenuListProps={{
                                                        "aria-labelledby": "basic-button",
                                                    }}
                                                    sx={{
                                                        maxWidth: 500,
                                                    }}
                                                >
                                                    <Typography
                                                        ml={2}
                                                        mb={0.5}
                                                        fontWeight={700}
                                                    >
                                                        Attribute Name
                                                    </Typography>
                                                    {isHaveCustomAttributeName.map((item, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            sx={{
                                                                justifyContent: 'space-between',
                                                                whiteSpace: 'wrap'
                                                            }}
                                                        >
                                                            <Typography
                                                                mr={1}
                                                                minWidth={100}
                                                            // fontSize={14}
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                <IconButton
                                                                    sx={{ py: 0, minWidth: "36px", height: '36px' }}
                                                                    onClick={() => deleteCustomAttribute(item, i)}
                                                                >
                                                                    <Iconify
                                                                        icon={"fluent:delete-24-regular"}
                                                                        color="#F15A28"
                                                                        width={18}
                                                                        height={18}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    sx={{ py: 0, minWidth: "36px", height: '36px' }}
                                                                    onClick={() => clickEditCustomAttrName(item)}
                                                                >
                                                                    <Iconify
                                                                        icon={"ph:note-pencil"}
                                                                        color="#1A776F"
                                                                        width={18}
                                                                        height={18}
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </div>
                                        </Stack>
                                    }
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {activeEditCustomAttr?.name &&
                                            <Button
                                                onClick={clickCancelEdit}
                                                sx={{
                                                    marginRight: 1
                                                }}
                                            >
                                                <Typography
                                                    fontSize={{ xs: 12, sm: 14 }}
                                                    color={palette.secondary.dark}
                                                >
                                                    Cancel
                                                </Typography>
                                            </Button>
                                        }
                                        {/* <ButtonWithIcon
                                            title={!activeEditCustomAttr?.name ? 'Add Attribute' : 'Save'}
                                            style={{
                                                width: 'fit-content',
                                            }}
                                            disabled={!activeEditCustomAttr?.name ?
                                                createAttributeValue.trim() ? false :
                                                    true
                                                :
                                                createAttributeValue.trim() ? false :
                                                    true}
                                            icon={!activeEditCustomAttr?.name ? 'simple-line-icons:plus' : 'ph:note-pencil-light'}
                                            onClick={() => !activeEditCustomAttr?.name ? clickAddCustomAttribute() : clickSaveEdit()}
                                        /> */}

                                        <Button
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}
                                            disabled={
                                                !activeEditCustomAttr?.name ?
                                                    createAttributeValue.trim() ? false :
                                                        true
                                                    :
                                                    createAttributeValue.trim() ? false :
                                                        true
                                            }
                                            onClick={() => !activeEditCustomAttr?.name ? clickAddCustomAttribute() : clickSaveEdit()}
                                        >
                                            <Iconify
                                                icon={!activeEditCustomAttr?.name ? 'simple-line-icons:plus' : 'ph:note-pencil-light'}
                                                sx={{
                                                    fontSize: 16
                                                }}
                                            />
                                            <Typography
                                                fontSize={{ xs: 12, sm: 14 }}
                                                ml={1}
                                            >
                                                {!activeEditCustomAttr?.name ? 'Add Attribute' : 'Save'}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                    {checkedAttribute &&
                        <>
                            <Box mb={2} />
                            <Box>
                                <Button
                                    variant="contained"
                                    color={currentAttribute.length < 2 ? 'primary' : 'error'}
                                    sx={{
                                        background: currentAttribute.length < 2 ? palette.gradients.custom : '#FF4842',
                                        borderRadius: "12px",
                                        color: "#fdfdfd",
                                        width: 'fit-content',
                                        padding: '6px 16px'
                                    }}
                                    onClick={currentAttribute.length < 2 ? clickAddAttribute : clickDeleteAttribute}
                                >
                                    <Typography fontSize={{ xs: 12, md: 14 }}>{currentAttribute.length < 2 ? 'Add Attribute' : 'Delete Attribute'}</Typography>
                                </Button>
                            </Box>
                        </>
                    }
                    <Box mb={2} />
                    {/* batch edit */}
                    {
                        checkedAttribute &&
                        rowsAttribute.length > 0 &&
                        <>
                            <Box>
                                <Typography
                                    fontSize={16}
                                    fontWeight={700}
                                >
                                    Batch Edit
                                </Typography>
                                <Box mb={1} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <ComboBox
                                            fullWidth
                                            disableMargin
                                            placeholder="Select"
                                            options={batchEdit.attribute.attributeValue}
                                            getOptionLabel={(option) => option}
                                            value={batchEdit.attribute.value}
                                            onChange={(e, v) => changeBatchAttribute(e, v, 'attribute')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <ComboBox
                                            fullWidth
                                            disableMargin
                                            placeholder="Select"
                                            options={batchEdit.second_attribute.attributeValue}
                                            getOptionLabel={(option) => option}
                                            value={batchEdit.second_attribute.value}
                                            onChange={(e, v) => changeBatchAttribute(e, v, 'second_attribute')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <BasicInput
                                            fullWidth
                                            disableMargin
                                            multiline
                                            type="number"
                                            name="batch-stock"
                                            value={batchEdit.stock}
                                            placeholder="Stock"
                                            onChange={(e) => changeInputBatchText(e, 'batch-stock')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <BasicInput
                                            fullWidth
                                            disableMargin
                                            multiline
                                            name="batch-price"
                                            value={batchEdit.price}
                                            placeholder="Price"
                                            onChange={(e) => changeInputBatchText(e, 'batch-price')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <BasicInput
                                            fullWidth
                                            disableMargin
                                            multiline
                                            name="batch-sku"
                                            value={batchEdit.sku}
                                            placeholder="SKU"
                                            onChange={(e) => changeInputBatchText(e, 'batch-sku')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={7} md={4}>
                                        <TextField
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Paper
                                                            component="label"
                                                            variant="contained"
                                                            sx={{
                                                                background: palette.gradients.custom,
                                                                padding: "5px 10px",
                                                                borderRadius: "12px",
                                                                color: "#fdfdfd",
                                                            }}
                                                        >
                                                            <Typography fontSize={{ xs: 12, sm: 14 }}>
                                                                Choose File
                                                            </Typography>
                                                            <input
                                                                hidden
                                                                accept="image/png, image/jpeg, image/jpg"
                                                                multiple
                                                                type="file"
                                                                onChange={handleChooseImgBatchEdit}
                                                            />
                                                        </Paper>
                                                        <Typography fontSize={{ xs: 12, sm: 14 }} sx={{
                                                            mx: 2,
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: '2',
                                                            WebkitBoxOrient: 'vertical',
                                                            whiteSpace: 'break-spaces',
                                                            width: 'full'
                                                        }}>
                                                            {batchEdit.file?.name}
                                                        </Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            disabled
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3} alignItems="center" display="flex">
                                        <BasicButton
                                            title="Apply"
                                            disabled={isDisabledApply}
                                            onClick={applyBatchEdit}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mb={2} />
                        </>
                    }
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <DataGrid
                            hideFooter
                            getRowId={(row) => row?.id}
                            getRowHeight={() => 100}
                            rows={rowsAttribute}
                            columns={columnsAttribute}
                            autoHeight={true}
                            editMode="row"
                            page={page - 1}
                            pageSize={100}
                            onRowEditStart={rowEditStart}
                            onRowEditStop={rowEditStop}
                            disableColumnMenu
                            disableColumnFilter
                            disableColumnSelector
                            disableSelectionOnClick
                            sx={{
                                fontSize: 12
                            }}
                        />
                    </Box>
                    <Box mb={2} />
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Box sx={{ mx: 1 }}>
                            <BasicButton
                                title="<"
                                onClick={() => {
                                    if (parseInt(page) > 1) {
                                        setPage(parseInt(page) - 1);
                                    }
                                }}
                                disabled={parseInt(page) === 1}
                            />
                        </Box>
                        <Box sx={{ mx: 1 }}>
                            <BasicButton
                                title=">"
                                onClick={() => {
                                    if (parseInt(page) <= total_page) {
                                        setPage(parseInt(page) + 1);
                                    }
                                }}
                                disabled={parseInt(page) > total_page}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box mb={2} />

                <Stack
                    direction="row"
                    spacing={1}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    sx={{
                        bottom: '30px',
                        right: '30px'
                    }}
                >
                    <Button onClick={onCancel}>Cancel</Button>
                    <BasicButton
                        title="Save"
                        type="submit"
                        disabled={isLoading}
                        onClick={handleSubmit(clickSaveAttribute)}
                    />
                </Stack>
                <Box mb={2} />
            </Box>
        </FormContainer>
    )
}