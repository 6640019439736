import { Box, Card, Stack, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import ButtonWithIcon from "../button/ButtonWithIcon";

export default function ContainerWithSomeButton({
  children,
  title,
  width,
  button1,
  button1Icon,
  button1Title,
  button1OnClick,
  button1Disabled,
  button2,
  button2OnClick,
  button2Title,
  button2Disabled,
  button2Icon,
  button3,
  button3OnClick,
  button3Title,
  button3Disabled,
  button3Icon,
  buttonBack,
  buttonBackOnClick,
  buttonBackTitle,
  buttonBackDisabled,
}) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: 2.5,
        alignItems: "center",
        minHeight: "500px",
        width: width,
        backgroundColor: palette.secondary.main,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          fontSize={{ xs: 20, sm: 24 }}
          fontWeight="700"
          color="#131313"
        >
          {title}
        </Typography>

        <Stack direction="row" spacing={1}>
          {buttonBack && (
            <ButtonWithIcon
              variant="outlined"
              backgroundColor="primary"
              title={buttonBackTitle}
              disabled={buttonBackDisabled}
              onClick={buttonBackOnClick}
              icon="akar-icons:arrow-back-thick"
            />
          )}
          {button1 && (
            <ButtonWithIcon
              title={button1Title}
              disabled={button1Disabled}
              onClick={button1OnClick}
              icon={button1Icon}
            />
          )}
          {button2 && (
            <ButtonWithIcon
              title={button2Title}
              icon={button2Icon}
              disabled={button2Disabled}
              onClick={button2OnClick}
            />
          )}
          {button3 && (
            <ButtonWithIcon
              title={button3Title}
              icon={button3Icon}
              disabled={button3Disabled}
              onClick={button3OnClick}
            />
          )}
        </Stack>
      </Box>
      <Box mb={3} />
      {children}
    </Card>
  );
}
