import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getCustomerServices = createAsyncThunk(
  "customer-service",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/customer-service", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createCustomerService = createAsyncThunk(
  "customer-service/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/customer-service/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCustomerService = createAsyncThunk(
  "customer-service/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/customer-service/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteCustomerService = createAsyncThunk(
  "customer-service/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/customer-service/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
