import { Box, Typography } from "@mui/material"
import palette from "../../../../theme/palette"
import BasicInput from "../../../../components/input/BasicInput"
import WarningOption from "./WarningOption"

export default function VariableContent({
    title,
    desc,
    totalVariables,
    changeInput,
    label,
    placeholder = 'Enter content for ',
    showWarning = true,
    warningDesc = 'This variable needs a text sample'
}) {
    return (
        <Box
            sx={{
                background: '#F8FAFB',
                borderRadius: 1,
                padding: 1.5
            }}
        >
            {title &&
                <Typography
                    sx={{
                        fontSize: 16,
                        fontWeight: 700
                    }}
                >
                    {title}
                </Typography>
            }
            <Typography
                sx={{
                    fontSize: 14,
                    fontWeight: 400
                }}
                color={palette.secondary.dark}
                mt={title ? 2 : 0}
            >
                {desc}
            </Typography>
            <Typography
                sx={{
                    fontSize: 16,
                    fontWeight: 700
                }}
                mt={2}
            >
                {label}
            </Typography>
            {totalVariables?.map((item, i) => {
                return <Box
                    key={i}
                    mt={1}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                        mb={1}
                    >
                        <Typography
                            mr={1}
                        >
                            {`{{${item.number}}}`}
                        </Typography>
                        <BasicInput
                            placeholder={`${placeholder}{{${item.number}}}`}
                            sx={{
                                background: '#fff'
                            }}
                            value={item.value}
                            onChange={(e) => changeInput(e, item)}
                        />
                    </Box>
                </Box>
            })}

            {showWarning &&
                <WarningOption
                    showCheckedBox={false}
                    desc={warningDesc}
                />
            }
        </Box>
    )
}