import { Box, FormControl, Grid, InputAdornment, OutlinedInput, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Iconify from "../../../../../components/Iconify";
import { useEffect, useMemo, useState } from "react";
import Scrollbar from "../../../../../components/Scrollbar";
import palette from "../../../../../theme/palette";
import ButtonWithIcon from "../../../../../components/button/ButtonWithIcon";
import BasicButton from "../../../../../components/button/BasicButton";
import AddedProducts from "./AddedProducts";
import CustomModal from "../../../../../components/modal/CustomModal";
import FormContainer from "../../../../../components/containers/FormContainer";

export default function AddListProduct({
    isLoading,
    catalogs,
    resetPage,
    formik,
    typeMessage,
    formMultiples,
    setFormMultiples,
    index
}) {
    const [search, setSearch] = useState('')
    const [products, setProducts] = useState([])
    const [page, setPage] = useState(1)
    const [openAddProduct, setOpenAddProduct] = useState(false)
    const [productsSearch, setProductsSearch] = useState([])
    // added products
    const [addedProducts, setAddedProducts] = useState([])

    const tableCellList = ["Name", "Type", "Quantity", "Price", "Status", "Action"];

    const productPerPage = 3

    useEffect(() => {
        // when user change the device
        if (resetPage) {
            setPage(1)
            setSearch('')
            setProducts([])
            setAddedProducts([])
        }
    }, [resetPage])

    useEffect(() => {
        if (search.trim() && catalogs?.data) {
            const searchProducts = catalogs.data.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            const startIndex = (page - 1) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = searchProducts.slice(startIndex, endIndex)
            setProductsSearch(searchProducts)
            setProducts(currentProducts)
        } if (catalogs?.data) {
            const startIndex = (page - 1) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = catalogs.data.slice(startIndex, endIndex)
            setProductsSearch([])
            setProducts(currentProducts)
        } else {
            setProducts([])
            setProductsSearch([])
        }
    }, [catalogs, page, search])

    const total_page = useMemo(() => {
        if (search.trim() && catalogs?.data) {
            const searchProducts = catalogs.data.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )

            const calculate = Math.ceil(searchProducts.length / productPerPage)
            const total = calculate
            return total
        } else if (catalogs?.data) {
            const total = Math.ceil(catalogs.data.length / productPerPage)
            return total
        }
        return 0
    }, [catalogs, search, products])

    useEffect(() => {
        if (search.trim() && catalogs?.data) {
            const searchProducts = catalogs.data.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            const startIndex = (0) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = searchProducts.slice(startIndex, endIndex)
            setProducts(currentProducts)
            setProductsSearch(searchProducts)
            setPage(1)
        } else if (catalogs?.data) {
            const startIndex = (0) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = catalogs.data.slice(startIndex, endIndex)
            setProducts(currentProducts)
            setProductsSearch([])
            setPage(1)
        }
    }, [search])

    // update formik when user add product in reply_type "product" in Reply By (Product) || 
    // update formMultiples when user add product in reply_type "product" in Reply By (Subsequent)
    useEffect(() => {
        if (typeMessage?.key === "product") {
            formik.setFieldValue('products', addedProducts)
        } else if (
            typeMessage?.key === "multiple" &&
            formMultiples[index]?.products
        ) {
            let newFormValues = [...formMultiples];
            newFormValues[index].products = addedProducts
            setFormMultiples(newFormValues)
        }
    }, [addedProducts, typeMessage])

    function randomId() {
        let crypto = window.crypto
        let typedArray = new Uint8Array(10)
        crypto.getRandomValues(typedArray)
        return Number(typedArray.join(''))
    }

    function handleAddProduct(item) {
        const data = { ...item, randomId: randomId() }
        setAddedProducts((prev) => [data, ...prev])
    }

    const styleEditProduct = {
        position: "absolute",
        top: "50%",
        left: "50%",
        alignItems: "center",
        transform: "translate(-50%, -50%)",
        width: { xs: "calc(100% - 24px)", sm: '80%', md: 880 },
        bgcolor: "transparent",
        overflow: "auto",  // Add overflow: "auto" to enable scrolling
    };

    function onShowAddProduct() {
        setOpenAddProduct(!openAddProduct)
    }

    function handleGoToDevicePosition(){
        onShowAddProduct()
        const singleDevice = document.getElementById('singleDevice')
        const inputSingleDevice = document.getElementById('inputSingleDevice')
        const bodyRect = document.body.getBoundingClientRect()
        const clientRect = singleDevice.getBoundingClientRect()
        const offset = Math.floor(clientRect.top - bodyRect.top)
        window.scrollTo({
            top: offset - 60,
            left: 0,
            behavior: 'smooth'
        })
        setTimeout(() => {
            inputSingleDevice.focus()
        }, 0);
    }

    return (
        <>
            <CustomModal
                open={openAddProduct}
                style={styleEditProduct}
            >
                <FormContainer title="Add Products">
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            background: "#FFF"
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{ marginBottom: 2 }}
                            onChange={(e) => setSearch(e.target.value)}
                        >
                            <OutlinedInput
                                style={{ height: "40px" }}
                                id="outlined-adornment-amount"
                                placeholder="Search products"
                                value={search}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Iconify
                                            icon={"heroicons:magnifying-glass"}
                                            color="#7B7B7B"
                                            width={24}
                                            height={24}
                                        />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                            List Product ({search.trim() ? `Total ${productsSearch.length} of ${catalogs?.data?.length ?? 0} Products` : `Total ${catalogs?.data?.length ?? 0}`})
                        </Typography>

                        {/* data table */}
                        <Scrollbar>
                            <TableContainer
                                component={Paper}
                                style={{ backgroundColor: "#FDFDFD" }}
                            >
                                <Table aria-label="simple table">
                                    <TableHead
                                        style={{
                                            background: palette.gradients.custom,
                                        }}
                                    >
                                        <TableRow>
                                            {tableCellList.map((item, index) => {
                                                return (
                                                    <TableCell
                                                        sx={{
                                                            padding: 1
                                                        }}
                                                        align="left"
                                                        key={index}
                                                    >
                                                        <Box
                                                            alignItems="center"
                                                            display="flex"
                                                            justifyContent={"space-between"}
                                                        >
                                                            <Typography color="#FDFDFD" fontSize={14}>{item}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoading ?
                                            (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        <Box
                                                            component="img"
                                                            src="/images/loading.svg"
                                                            alt="loading"
                                                            height="48px"
                                                            sx={{ mx: "auto" }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            :
                                            <>
                                                {products.length > 0 && products.map((item, i) => {
                                                    function isDisabledAddBtn() {
                                                        if (addedProducts.length > 0) {
                                                            const productIsAdded = addedProducts.find(data =>
                                                                data.id === item.id
                                                            )
                                                            return productIsAdded?.id ? true : false
                                                        }
                                                    }

                                                    return (
                                                        <TableRow key={i}>
                                                            {/* NAME */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    minWidth: '15rem',
                                                                    maxWidth: '15rem',
                                                                    flex: 1,
                                                                    padding: 1
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        gap: 2
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={item.image}
                                                                        alt={item.name}
                                                                        height={60}
                                                                        width={60}
                                                                        style={{
                                                                            maxWidth: '80px'
                                                                        }}
                                                                    />
                                                                    <Box
                                                                        sx={{
                                                                            flex: 1
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            fontSize={14}
                                                                            fontWeight="600"
                                                                            sx={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                display: '-webkit-box',
                                                                                WebkitLineClamp: '3',
                                                                                WebkitBoxOrient: 'vertical',
                                                                            }}
                                                                        >
                                                                            {item.name}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                            {/* TYPE */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    maxWidth: '5rem',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                {item.type === 1 ? 'Physical' : 'Digital'}
                                                            </TableCell>
                                                            {/* QUANTITY */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    maxWidth: '5rem',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                {item.quantity}
                                                            </TableCell>
                                                            {/* PRICE */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    maxWidth: '5rem',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                {item.price}
                                                            </TableCell>
                                                            {/* STATUS */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    maxWidth: '5rem',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                {item.status}
                                                            </TableCell>
                                                            {/* ACTION */}
                                                            <TableCell
                                                                align="left"
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    padding: 1
                                                                }}
                                                            >
                                                                <ButtonWithIcon
                                                                    title="Add"
                                                                    icon="gg:add"
                                                                    onClick={() => handleAddProduct(item)}
                                                                    disabled={isDisabledAddBtn()}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                                {products.length === 0 && (
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={6}>
                                                            Data Not Found
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    </Grid>
                    {!catalogs?.data &&
                        <Grid item xs={12} sm={12}
                        >
                            <Typography
                                fontSize={12}
                                sx={{
                                    mb: 2,
                                    mt: 1,
                                    color: "#7B7B7B",
                                }}
                            >
                                The device has not been selected to display the product.
                                <span
                                    style={{ color: "#FF0000" }}
                                >
                                    {" "}"Please select your device". <span style={{
                                        cursor: 'pointer',
                                        color: '#0000FF'
                                    }}
                                    onClick={handleGoToDevicePosition}
                                    >
                                        Click Here.
                                    </span>
                                </span>
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12} sm={12} mt={1}
                    >
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Box sx={{ mx: 1 }}>
                                <BasicButton
                                    title="<"
                                    onClick={() => {
                                        if (parseInt(page) > 1) {
                                            setPage(parseInt(page) - 1);
                                        }
                                    }}
                                    disabled={parseInt(page) === 1}
                                />
                            </Box>
                            <Box sx={{ mx: 1 }}>
                                <BasicButton
                                    title=">"
                                    onClick={() => {
                                        if (parseInt(page) <= total_page) {
                                            setPage(parseInt(page) + 1);
                                        }
                                    }}
                                    disabled={parseInt(page) >= total_page}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Box mb={2} />

                    <Stack
                        direction="row"
                        spacing={1}
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        sx={{
                            bottom: '30px',
                            right: '30px'
                        }}
                    >
                        <BasicButton
                            title="Close"
                            type="button"
                            onClick={onShowAddProduct}
                        />
                    </Stack>
                    <Box mb={2} />
                </FormContainer>
            </CustomModal>

            <AddedProducts
                products={addedProducts}
                setProducts={setAddedProducts}
                onShowAddProduct={onShowAddProduct}
            />
        </>
    )
}