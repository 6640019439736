import { Icon } from "@iconify/react";
import { Box, Card, Typography } from "@mui/material";
import Iconify from "../../../components/Iconify";
import { Link } from "react-router-dom";

export default function AppStatisticCard({
  title,
  total,
  icon,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        p: 5,
        bgcolor: "#FDFDFD",
        borderRadius: "10px",
        height: "100%",
        ...sx,
      }}
      {...other}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          fontSize={{ xs: 20, sm: 24 }}
          fontWeight="700"
          color="#131313"
        >
          {title}
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography
          fontWeight="600"
          color="#131313"
          fontSize={30}
          sx={{ mb: 2 }}
        >
          {total}
        </Typography>
        <Link to="#" style={{ textDecoration: "none" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography color="#7B7B7B" fontSize={15} sx={{ mb: 0 }}>
              View Statistic
            </Typography>
            <Iconify
              icon={"iconamoon:arrow-right-6-circle"}
              color="#7B7B7B"
              width={24}
              height={24}
            />
          </Box>
        </Link>
      </Box>
    </Card>
  );
}
