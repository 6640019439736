import { Box, Button, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../components/Page";
import ScrollBar from "../components/Scrollbar";
import AppDropDown from "../sections/dashboard/app/AppDropdown";
import Template from "../sections/dashboard/template-message";
import TemplateLibrary from "../sections/dashboard/template-message/TemplateLibrary";
import PlatformOptions from "../components/PlatformOptions";
import TemplateMessageCloudAPI from "../sections/dashboard/template-message/cloud-api/TemplateMessage";
import { useDispatch } from "react-redux";
import { resetDevice } from "../store/device/deviceSlice";

export default function TemplateMessage() {
  const dispatch = useDispatch();
  const [templatePages, setTemplatePages] = useState({
    name: "Message Templates",
    component: <Template />,
  });

  const platformOptions = [
    {
      label: "WhatsApp",
      value: "whatsapp",
      image: "whatsapp.png",
    },
    {
      label: "Cloud API",
      value: "waba",
      image: "cloud-api.png",
    },
    // {
    //   label: "Email",
    //   value: "email",
    //   image: "email.png",
    // },
    // {
    //   label: "Telegram",
    //   value: "telegram",
    //   image: "telegram.png",
    // },
    // {
    //   label: "Instagram",
    //   value: "instagram",
    //   image: "instagram.png",
    // },
    // {
    //   label: "Facebook",
    //   value: "facebook",
    //   image: "facebook.png",
    // },
  ];
  const [platformSelected, setPlatformSelected] = useState(platformOptions[0]);

  const [selectionOnPlatform, setSelectionOnPlatform] = useState([]);

  const whatsappItems = [
    {
      name: "Message Templates",
      component: <Template />,
    },
    {
      name: "Template Library",
      component: <TemplateLibrary />,
    },
  ];

  const cloudAPIItems = [
    {
      name: "Cloud API Message Templates",
      component: <TemplateMessageCloudAPI />,
    },
  ];

  const emailItems = [
    {
      name: "Email Templates",
      component: <></>,
    },
  ];

  const telegramItems = [
    {
      name: "Telegram Message Templates",
      component: <></>,
    },
  ];

  const instagramItems = [
    {
      name: "Instagram Message Templates",
      component: <></>,
    },
  ];

  const facebookItems = [
    {
      name: "Facebook Message Templates",
      component: <></>,
    },
  ];

  useEffect(() => {
    if (platformSelected.value == "whatsapp") {
      setSelectionOnPlatform(whatsappItems);
      setTemplatePages(whatsappItems[0]);
    } else if (platformSelected?.value == "waba") {
      dispatch(resetDevice());
      setSelectionOnPlatform(cloudAPIItems);
      setTemplatePages(cloudAPIItems[0]);
    } else if (platformSelected?.value == "email") {
      setSelectionOnPlatform(emailItems);
      setTemplatePages(emailItems[0]);
    } else if (platformSelected?.value == "telegram") {
      setSelectionOnPlatform(telegramItems);
      setTemplatePages(telegramItems[0]);
    } else if (platformSelected?.value == "instagram") {
      setSelectionOnPlatform(instagramItems);
      setTemplatePages(instagramItems[0]);
    } else if (platformSelected?.value == "facebook") {
      setSelectionOnPlatform(facebookItems);
      setTemplatePages(facebookItems[0]);
    }
  }, [platformSelected]);

  return (
    <Page title="Message Templates">
      <Container maxWidth="xl">
        <AppDropDown justTitle title="Dashboard / Message Templates" />
        <PlatformOptions
          platformOptions={platformOptions}
          platformSelected={platformSelected}
          setPlatformSelected={setPlatformSelected}
        />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ backgroundColor: "#fdfdfd", p: 1, borderRadius: 1 }}>
            <ScrollBar>
              <Stack direction="row" spacing={1}>
                {selectionOnPlatform.length > 0 &&
                  selectionOnPlatform.map((v, i) => {
                    return (
                      <Button
                        variant={
                          templatePages?.name === v.name ? "contained" : "text"
                        }
                        key={i}
                        onClick={() => setTemplatePages(v)}
                        sx={
                          templatePages?.name === v.name
                            ? {
                                background: "GRADIENTS.custom",
                                borderRadius: "24px",
                                color: "#fdfdfd",
                              }
                            : { color: "#7b7b7b" }
                        }
                      >
                        <Typography sx={{ fontSize: { xs: 10, sm: 14 } }}>
                          {v.name}
                        </Typography>
                      </Button>
                    );
                  })}
              </Stack>
            </ScrollBar>
          </Box>
          <Box mt={3}>{templatePages.component}</Box>
        </Box>
      </Container>
    </Page>
  );
}
