import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const getDailyMessageCount = createAsyncThunk(
  "daily-message-count",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/daily-message-count", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getUserActivity = createAsyncThunk(
  "user-activity",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/user-activity", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
