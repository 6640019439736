import { createSlice } from "@reduxjs/toolkit";
import { getBusiness } from "./businessAction";

const initialState = {
  business: null,
};

const businessSlice = createSlice({
  name: "business",
  initialState: initialState,
  extraReducers: {
    [getBusiness.fulfilled]: (state, { payload }) => {
      state.business = payload.data;
    },
    [getBusiness.rejected]: (state) => {
      state.business = null;
    },
  },
});

export const {} = businessSlice.actions;

export default businessSlice.reducer;
