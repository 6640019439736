import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Container from "../../../../components/containers/Container";
import ComboBox from "../../../../components/select/ComboBox";
import { createCatalogProduct } from "../../../../store/catalog/catalogAction";
import { getDevices } from "../../../../store/device/deviceAction";
import BasicInput from "../../../../components/input/BasicInput";
import palette from "../../../../theme/palette";
import { DataGrid } from "@mui/x-data-grid";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import Iconify from "../../../../components/Iconify";
import InputLimitText from "../../../../components/input/InputLimitText";
import BasicButton from "../../../../components/button/BasicButton";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";

const conditionOptions = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Used",
    value: "used",
  },
];

const typeOptions = [
  {
    label: "Physical",
    value: 1,
  },
  {
    label: "Digital",
    value: 2,
  },
];

export default function CreateProduct() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { plan } = useSelector((state) => state.user);

  const { watch, setValue, reset, handleSubmit } = useForm();

  const storageAttributeName = 'attribute-name'
  const storageAttributeName2 = 'attribute-name-2'

  const [isLoading, setIsLoading] = useState(false);
  // attribute
  const [checkedAttribute, setCheckedAttribute] = useState(false)
  const [rowsAttribute, setRowsAttribute] = useState([])
  const [currentValueEditable, setCurrentValueEditable] = useState({})
  // custom attribute
  const [anchorEl, setAnchorEl] = useState(null)
  const [openMenuCustom, setOpenMenuCustom] = useState(false)
  const [createAttributeValue, setCreateAttributeValue] = useState('')
  const [activeEditCustomAttr, setActiveEditCustomAttr] = useState({})
  const colors = ["Black", "White", "Blue", "Green", "Red", "Yellow", "Orange", "Purple", "Pink", "Brown", "Grey", "Gold", "Silver", "Multi"]
  const sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', 'Free Size', 'Custom Size', '1-2 Years', '3-4 Years', '5-6 Years', '7-8 Years', '9-10 Years', '11-12 Years']
  const shoe_sizes = ['35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45']
  const dataAttribute = [
    {
      name: 'Color',
      value: 'color',
      attributeValue: [],
      menu: colors,
    },
    {
      name: 'Size',
      value: 'size',
      attributeValue: [],
      menu: sizes,
    },
    {
      name: 'Shoe Size',
      value: 'shoe_size',
      attributeValue: [],
      menu: shoe_sizes,
    },
  ]
  const planIsCustomAttribute = useMemo(() => {
    if (plan?.custom_attribute === '1') {
      return true
    }
    return false
  }, [plan])

  const defaultAttribute = useMemo(() => {
    if (planIsCustomAttribute) {
      return !localStorage.getItem(storageAttributeName) ? [dataAttribute[0]] : [JSON.parse(localStorage.getItem(storageAttributeName))[0]]
    }
    return [dataAttribute[0]]
  }, [planIsCustomAttribute])
  const [currentAttribute, setCurrentAttribute] = useState(defaultAttribute)

  const [dataAttributeName, setDataAttributeName] = useState(
    planIsCustomAttribute ?
      JSON.parse(localStorage.getItem(storageAttributeName)) :
      [
        {
          name: 'Color',
          value: 'color',
          attributeValue: [],
          menu: colors,
        },
        {
          name: 'Size',
          value: 'size',
          attributeValue: [],
          menu: sizes,
        },
        {
          name: 'Shoe Size',
          value: 'shoe_size',
          attributeValue: [],
          menu: shoe_sizes,
        },
      ]
  )
  const [dataAttributeName2, setDataAttributeName2] = useState(
    planIsCustomAttribute ?
      JSON.parse(localStorage.getItem(storageAttributeName2)) :
      [
        {
          name: 'Color',
          value: 'color',
          attributeValue: [],
          menu: colors,
        },
        {
          name: 'Size',
          value: 'size',
          attributeValue: [],
          menu: sizes,
        },
        {
          name: 'Shoe Size',
          value: 'shoe_size',
          attributeValue: [],
          menu: shoe_sizes,
        },
      ]
  )
  const [page, setPage] = useState(1)

  const [batchEdit, setBatchEdit] = useState({
    attribute: {
      name: 'attribute',
      attributeValue: ['All'],
      value: 'All'
    },
    second_attribute: {
      name: 'second Attribute',
      attributeValue: ['All'],
      value: 'All'
    },
    stock: '',
    price: '',
    sku: '',
    file: null,
    images: ''
  })

  const spaceRegex = /\s/g

  // update local storage attribute name
  useEffect(() => {
    if (planIsCustomAttribute) {
      const getLocalAttributeName = localStorage.getItem(storageAttributeName)
      if (!getLocalAttributeName) {
        localStorage.setItem(storageAttributeName, JSON.stringify(dataAttribute))
      } else {
        localStorage.setItem(storageAttributeName, JSON.stringify(dataAttributeName))
      }
    }
  }, [planIsCustomAttribute, dataAttributeName])

  useEffect(() => {
    if (planIsCustomAttribute) {
      setDataAttributeName(JSON.parse(localStorage.getItem(storageAttributeName)))
    }
  }, [planIsCustomAttribute, localStorage.getItem(storageAttributeName)])

  // update local storage attribute name 2
  useEffect(() => {
    if (planIsCustomAttribute) {
      const getLocalAttributeName2 = localStorage.getItem(storageAttributeName2)
      if (!getLocalAttributeName2) {
        localStorage.setItem(storageAttributeName2, JSON.stringify(dataAttribute))
      } else {
        localStorage.setItem(storageAttributeName2, JSON.stringify(dataAttributeName2))
      }
    }
  }, [planIsCustomAttribute, dataAttributeName2])

  useEffect(() => {
    if (planIsCustomAttribute) {
      setDataAttributeName2(JSON.parse(localStorage.getItem(storageAttributeName2)))
    }
  }, [planIsCustomAttribute, localStorage.getItem(storageAttributeName2)])

  useEffect(() => {
    // options
    const createOptions = currentAttribute.map(item => item.name)
    setValue('options', JSON.stringify(createOptions))
    // values
    const createValues = rowsAttribute.map(item => {
      return {
        attribute_first: item?.attribute,
        attribute_second: item?.second_attribute?.length > 0 ? item.second_attribute : null,
        quantity: item?.quantity,
        price: item?.price_rm,
        sku: item?.sku
      }
    })
    setValue('values', JSON.stringify(createValues))
  }, [
    currentAttribute,
    rowsAttribute
  ])
  useEffect(() => {
    setValue('active_attribute', checkedAttribute)
  }, [checkedAttribute])

  const createAttributeImages = useMemo(() => {
    // attribute_images
    const createAttributeImages = rowsAttribute.map(item => {
      return {
        file: item.file
      }
    })
    return createAttributeImages
  }, [rowsAttribute])

  function randomId() {
    let crypto = window.crypto
    let typedArray = new Uint8Array(10)
    crypto.getRandomValues(typedArray)
    return Number(typedArray.join(''))
  }

  function resetAutomaticAttr() {
    // reload automatically if no attributes in local storage
    if (
      planIsCustomAttribute &&
      !JSON.parse(localStorage.getItem(storageAttributeName))?.length > 0 ||
      !JSON.parse(localStorage.getItem(storageAttributeName2))?.length > 0
    ) {
      localStorage.removeItem(storageAttributeName)
      localStorage.removeItem(storageAttributeName2)
      window.location.reload()
      return
    }
  }

  function pushOnlyFirstAttribute(paramAttributes) {
    let newAttribute = []
    const currentRows = rowsAttribute
    if (!paramAttributes) {
      currentAttribute.forEach((item, i) => {
        if (item.attributeValue.length > 0) {
          item.attributeValue.forEach((value, idx) => {
            newAttribute.push({
              id: randomId(),
              idx: i,
              attribute: value,
              second_attribute: '',
              quantity: '',
              price_rm: '',
              sku: '',
              images: '',
              file: null
            })
          })
        }
      })
    } else {
      paramAttributes.forEach((item, i) => {
        if (item.attributeValue.length > 0) {
          item.attributeValue.forEach((value, idx) => {
            newAttribute.push({
              id: randomId(),
              idx: i,
              attribute: value,
              second_attribute: '',
              quantity: '',
              price_rm: '',
              sku: '',
              images: '',
              file: null
            })
          })
        }
      })
    }
    currentRows.forEach((item) => {
      const findItem = newAttribute.find(attr => attr?.idx === item?.idx && attr?.attribute == item?.attribute)
      const findIdx = newAttribute.findIndex(attr => attr?.idx === item?.idx && attr?.attribute == item?.attribute)
      if (findItem) {
        newAttribute[findIdx].quantity = item.quantity
        newAttribute[findIdx].price_rm = item.price_rm
        newAttribute[findIdx].sku = item.sku
        newAttribute[findIdx].images = item.images
        newAttribute[findIdx].file = item.file
      }
    })
    setRowsAttribute(newAttribute)
  }
  const pushOtherAttribute = useCallback((paramAttributes) => {
    const attributeValueNumber1 = paramAttributes[0]?.attributeValue
    const attributeValueNumber2 = currentAttribute[1]?.attributeValue
    let newAttribute = []
    const currentRows = rowsAttribute
    attributeValueNumber1.forEach((value) => {
      attributeValueNumber2.forEach((value2) => {
        newAttribute.push({
          id: randomId(),
          idx: 0,
          attribute: value,
          second_attribute: value2,
          quantity: '',
          price_rm: '',
          sku: '',
          images: '',
          file: null
        })
      })
    })

    currentRows.forEach((item) => {
      const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
      const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
      if (findItem) {
        newAttribute[findIdx].quantity = item.quantity
        newAttribute[findIdx].price_rm = item.price_rm
        newAttribute[findIdx].sku = item.sku
        newAttribute[findIdx].images = item.images
        newAttribute[findIdx].file = item.file
      }
    })
    setRowsAttribute(newAttribute)
  }, [
    currentAttribute,
    rowsAttribute
  ])
  function pushOnlySecondAttribute(paramAttributes) {
    const attributeValueNumber1 = paramAttributes[0]?.attributeValue
    if (paramAttributes[1]?.attributeValue?.length === 0) {
      setRowsAttribute([])
      return
    }
    const attributeValueNumber2 = paramAttributes[1]?.attributeValue
    let newAttribute = []
    const currentRows = rowsAttribute
    attributeValueNumber1.forEach((value) => {
      attributeValueNumber2.forEach((value2) => {
        newAttribute.push({
          id: randomId(),
          idx: 0,
          attribute: value,
          second_attribute: value2,
          quantity: '',
          price_rm: '',
          sku: '',
          images: '',
          file: null
        })
      })
    })

    currentRows.forEach((item) => {
      if (attributeValueNumber2?.length === 1) {
        const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
        const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
        if (findItem) {
          newAttribute[findIdx].quantity = item.quantity
          newAttribute[findIdx].price_rm = item.price_rm
          newAttribute[findIdx].sku = item.sku
          newAttribute[findIdx].images = item.images
          newAttribute[findIdx].file = item.file
        }
      } else {
        const findItem = newAttribute.find(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
        const findIdx = newAttribute.findIndex(attr => attr?.attribute == item?.attribute && attr?.second_attribute == item?.second_attribute)
        if (findItem) {
          newAttribute[findIdx].quantity = item.quantity
          newAttribute[findIdx].price_rm = item.price_rm
          newAttribute[findIdx].sku = item.sku
          newAttribute[findIdx].images = item.images
          newAttribute[findIdx].file = item.file
        }
      }
    })
    setRowsAttribute(newAttribute)
  }
  function pushAttribute(paramAttributes, index) {
    // first attribute (attribute)
    if (index === 0) {
      if (currentAttribute.length === 1) {
        pushOnlyFirstAttribute(paramAttributes)
      } else {
        pushOtherAttribute(paramAttributes, index)
      }
    } else if (index === 1) {
      pushOnlySecondAttribute(paramAttributes)
    }
  }

  function updateOnDeleteAttribute(paramAttributes) {
    if (paramAttributes[0]?.attributeValue?.length > 0) {
      let newAttribute = []
      paramAttributes[0].attributeValue.forEach((value) => {
        newAttribute.push({
          id: randomId(),
          idx: 0,
          attribute: value,
          second_attribute: '',
          quantity: '',
          price_rm: '',
          sku: '',
          images: '',
          file: null
        })
      })
      setRowsAttribute(newAttribute)
    }
  }

  function openFiles(id) {
    const input = document.getElementById(id)
    if (input) {
      input.click()
    }
  }
  async function loadLocalImage(file) {
    return await new Promise((resolve, reject) => {
      let reader = new FileReader()

      reader.onloadend = () => {
        resolve(reader.result)
      }

      if (file) {
        reader.readAsDataURL(file)
      }
    })
  }
  function validateExtension(file) {
    if (
      file.type == 'image/jpeg' ||
      file.type == 'image/jpg' ||
      file.type == 'image/png'
    ) {
      return 'accept'
    }
    return
  }
  const changeFile = useCallback(async (e, row) => {
    resetAutomaticAttr()

    if (e.target.files.length !== 0) {
      if (!validateExtension(e.target.files[0])) {
        toast.dismiss()
        toast.error('The image file must be "image/jpeg, image/jpg, image/png"')
        return
      }
      const base64 = await loadLocalImage(e.target.files[0])
      const currentRows = [...rowsAttribute]
      const findImagesIdx = rowsAttribute.findIndex(item => item.id == row.id)
      currentRows[findImagesIdx].images = base64
      currentRows[findImagesIdx].file = e.target.files[0]
      setRowsAttribute(currentRows)
    }
  }, [rowsAttribute])
  const removeImage = useCallback((row) => {
    const currentRows = [...rowsAttribute]
    const findImagesIdx = rowsAttribute.findIndex(item => item.id == row.id)
    currentRows[findImagesIdx].images = ''
    currentRows[findImagesIdx].file = null
    setRowsAttribute(currentRows)
  }, [rowsAttribute])

  const columnsAttribute = [
    {
      field: 'attribute',
      headerName: 'ATTRIBUTE',
      width: 260,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;

        return row.attribute;
      },
    },
    {
      field: 'second_attribute',
      headerName: 'SECOND ATTRIBUTE',
      width: 260,
      sortable: false,
      renderCell: (params) => {
        const { row } = params;

        return <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '80px'
          }}
        >
          <Typography
            sx={{
              whiteSpace: 'wrap',
            }}
          >
            {row.second_attribute?.length > 0 ? row.second_attribute : '-'}
          </Typography>
        </Box>
      },
    },
    {
      field: 'quantity',
      headerName: 'STOCK',
      width: 260,
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        resetAutomaticAttr()

        const { props } = params
        setCurrentValueEditable((prev) => ({
          ...prev,
          quantity: props.value
        }))
        return { ...params.props }
      },
      renderCell: (params) => {
        const { row } = params;

        return <Typography
          fontSize={14}
          color={row.quantity?.length > 0 ? '#212B36' : '#aaa'}
        >
          {row.quantity?.length > 0 ? row.quantity : 'Click to edit'}
        </Typography>
      },
    },
    {
      field: 'price_rm',
      headerName: 'PRICE',
      width: 260,
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        resetAutomaticAttr()

        const { props } = params
        setCurrentValueEditable((prev) => ({
          ...prev,
          price_rm: props.value
        }))
        return { ...params.props }
      },
      renderCell: (params) => {
        const { row } = params;

        return <Typography
          fontSize={14}
          color={row.price_rm?.length > 0 ? '#212B36' : '#aaa'}
        >
          {row.price_rm?.length > 0 ? row.price_rm : 'Click to edit'}
        </Typography>
      },
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: 260,
      editable: true,
      sortable: false,
      preProcessEditCellProps: (params) => {
        resetAutomaticAttr()

        const { props } = params
        setCurrentValueEditable((prev) => ({
          ...prev,
          sku: props.value
        }))
        return { ...params.props }
      },
      renderCell: (params) => {
        const { row } = params;

        return <Typography
          fontSize={14}
          color={row.sku?.length > 0 ? '#212B36' : '#aaa'}
        >
          {row.sku?.length > 0 ? row.sku : 'Click to edit'}
        </Typography>
      },
    },
    {
      field: 'images',
      headerName: 'IMAGES',
      width: 200,
      sortable: false,
      renderCell: (params) => {
        const { row } = params

        return (
          <Box>
            {row.images ?
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  src={row.images}
                  alt=""
                  height={70}
                  width={70}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    resetAutomaticAttr()
                    openFiles(row.id)
                  }}
                />
                <IconButton
                  sx={{ py: 0, minWidth: "36px", height: '36px', marginLeft: 1 }}
                  onClick={() => {
                    resetAutomaticAttr()
                    removeImage(row)
                  }}
                >
                  <Iconify
                    icon={"fluent:delete-24-regular"}
                    color="#F15A28"
                    width={18}
                    height={18}
                  />
                </IconButton>
              </Box>
              :
              <Box
                sx={{
                  background: '#ddd',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 1,
                  height: 70,
                  width: 70,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  resetAutomaticAttr()
                  openFiles(row.id)
                }}
              >
                <Typography
                  fontSize={8}
                >
                  150 x 150
                </Typography>
              </Box>
            }
            <input
              id={row.id}
              type="file"
              accept="image/png, image/gif, image/jpeg"
              hidden
              onChange={(e) => {
                resetAutomaticAttr()
                changeFile(e, row)
              }}
            />
          </Box>
        )
      },
    },
  ]

  const handleCreateCatalogProduct = useCallback(async (value) => {
    toast.loading("Please wait...");
    setIsLoading(true);

    const data = new FormData();

    data.append("device_key", value?.device_key?.device_key);
    data.append("name", value?.name);
    data.append("price", value?.price);
    data.append("description", value?.description);
    data.append("condition", value?.condition?.value);
    // data.append("url", `https://${value?.url}`);
    data.append("retailer_id", value?.retailer_id);
    data.append("type", value?.type?.value);
    if (!checkedAttribute) {
      data.append("stock", value?.stock);
    }
    data.append("image", value?.image);
    data.append('active_attribute', value?.active_attribute)
    if (checkedAttribute) {
      data.append('options', value?.options)
      data.append('values', value?.values)

      if (createAttributeImages?.length > 0) {
        createAttributeImages.forEach((item, idx) => {
          data.append(`attribute_images[${idx}]`, item.file)
        })
      } else {
        data.append('attribute_images[0]', null)
      }
    }

    await dispatch(createCatalogProduct(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);
        setIsLoading(false);

        reset();
        setRowsAttribute([])
        setCheckedAttribute(false)
        setCurrentAttribute(
          planIsCustomAttribute ?
            [JSON.parse(localStorage.getItem(storageAttributeName))[0]] :
            [dataAttribute[0]]
        )
        setCreateAttributeValue('')
        if (activeEditCustomAttr?.name) {
          setAnchorEl(null)
          setOpenMenuCustom(false)
          setActiveEditCustomAttr({})
        }
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
        setIsLoading(false);
      }
    });
  }, [rowsAttribute, checkedAttribute])

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba,instagram",
      },
    };

    await dispatch(getDevices(body));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  // attribute
  const changeAttributeValue = useCallback((e, value, index) => {
    resetAutomaticAttr()

    const attr = [...currentAttribute]
    if (value) {
      attr[index].attributeValue = value
      setCurrentAttribute(attr)

      pushAttribute(attr, index)
    }
  }, [currentAttribute, rowsAttribute])
  const changeAttributeName = useCallback((e, value, index) => {
    if (value) {
      resetAutomaticAttr()

      const attr = [...currentAttribute]
      attr[index] = value
      setCurrentAttribute(attr)
      pushAttribute(attr, index)
    }
  }, [
    currentAttribute,
    // dataAttributeName2
  ])

  function clickAddAttribute() {
    resetAutomaticAttr()

    let currentAttr = currentAttribute
    if (planIsCustomAttribute) {
      currentAttr = [...currentAttr, JSON.parse(localStorage.getItem(storageAttributeName2))[0]]
    } else {
      currentAttr = [...currentAttr, dataAttributeName2[0]]
    }

    setCurrentAttribute(currentAttr)
    if (
      planIsCustomAttribute ?
        JSON.parse(localStorage.getItem(storageAttributeName2))[0]?.attributeValue?.length > 0 :
        dataAttributeName2[0].attributeValue.length > 0
    ) {
      pushAttribute(currentAttr, 1)
    }
  }
  function clickDeleteAttribute() {
    resetAutomaticAttr()

    const removeIt = currentAttribute.filter((item, i) => i !== (currentAttribute.length - 1))
    setCurrentAttribute(removeIt)
    updateOnDeleteAttribute(removeIt)
  }
  function toggleActiveAttribute() {
    resetAutomaticAttr()

    setCheckedAttribute(!checkedAttribute)
    if (checkedAttribute) {
      if (planIsCustomAttribute) {
        setCurrentAttribute([
          JSON.parse(localStorage.getItem(storageAttributeName))[0]
        ])
      } else {
        setCurrentAttribute([dataAttribute[0]])
      }

      setRowsAttribute([])
    } else if (currentAttribute[0]?.attributeValue?.length > 0) {
      pushAttribute(currentAttribute, 0)
    } else {
      setRowsAttribute([])
    }
    setCreateAttributeValue('')
    if (activeEditCustomAttr?.name) {
      setAnchorEl(null)
      setOpenMenuCustom(false)
      setActiveEditCustomAttr({})
    }
  }
  const rowEditStart = useCallback((params) => {
    resetAutomaticAttr()

    const { row } = params
    setCurrentValueEditable({
      quantity: row.quantity,
      price_rm: row.price_rm,
      sku: row.sku
    })
  }, [rowsAttribute])
  const rowEditStop = useCallback((params) => {
    resetAutomaticAttr()

    const { row } = params
    const currentRows = [...rowsAttribute]
    const findRowIdx = rowsAttribute.findIndex(item => item.id == row.id)
    currentRows[findRowIdx].quantity = currentValueEditable?.quantity
    currentRows[findRowIdx].price_rm = currentValueEditable?.price_rm
    currentRows[findRowIdx].sku = currentValueEditable?.sku
    setRowsAttribute(currentRows)
    setCurrentValueEditable({})
  }, [rowsAttribute, currentValueEditable])

  function changeCustomAttribute(e) {
    resetAutomaticAttr()

    if (e.target.value.length <= 30) {
      let newValue = e.target.value
      if (e.target.value.includes('/') || e.target.value.includes(',')) {
        newValue = newValue.replaceAll('/', '').replaceAll(',', '')
      }
      setCreateAttributeValue(newValue)
    }
  }
  const clickAddCustomAttribute = useCallback(() => {
    const checkAvailableAttr2 = dataAttributeName2.find(item => item.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
    const checkAvailableAttr = dataAttributeName.find(item => item.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
    if (!checkAvailableAttr2 && !checkAvailableAttr) {
      setDataAttributeName2((prev) => [
        ...prev,
        {
          name: createAttributeValue,
          value: createAttributeValue,
          attributeValue: [],
          isCustomAttribute: true,
          menu: [],
        },
      ])
      setDataAttributeName((prev) => [
        ...prev,
        {
          name: createAttributeValue,
          value: createAttributeValue,
          attributeValue: [],
          isCustomAttribute: true,
          menu: [],
        },
      ])
      setCreateAttributeValue('')
      toast.dismiss()
      toast.success('Added successfully')
      return
    }
    toast.dismiss()
    toast.error('Attribute name already exists.')
  }, [
    createAttributeValue,
    dataAttributeName2,
    dataAttributeName
  ])

  function addCustomMenuAttributeValue(value, currentName) {
    const attributeName = [...dataAttributeName]
    const findIdx = dataAttributeName.findIndex(item => item.name == currentName)
    attributeName[findIdx].menu.push(value)
    const removeDuplicateAttr = attributeName[findIdx].menu.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    const removeDuplicateValue = attributeName[findIdx].attributeValue.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    attributeName[findIdx].menu = removeDuplicateAttr
    attributeName[findIdx].attributeValue = removeDuplicateValue
    setDataAttributeName(attributeName)
  }
  function addCustomMenuAttributeValue2(value, currentName) {
    const attributeName2 = [...dataAttributeName2]
    const findIdx = dataAttributeName2.findIndex(item => item.name == currentName)
    attributeName2[findIdx].menu.push(value)
    const removeDuplicateAttr = attributeName2[findIdx].menu.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    const removeDuplicateValue = attributeName2[findIdx].attributeValue.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    attributeName2[findIdx].menu = removeDuplicateAttr
    attributeName2[findIdx].attributeValue = removeDuplicateValue
    setDataAttributeName2(attributeName2)
  }
  function addCustomInCurrentAttribute(value, index) {
    const attr = [...currentAttribute]
    attr[index].attributeValue.push(value)
    attr[index].menu.push(value)
    const removeDuplicateAttr = attr[index].menu.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    const removeDuplicateValue = attr[index].attributeValue.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t === v
      ))
    )
    attr[index].menu = removeDuplicateAttr
    attr[index].attributeValue = removeDuplicateValue
    setCurrentAttribute(attr)

    pushAttribute(attr, index)
  }
  const handleKeyPress = useCallback((event, index, attribute) => {
    resetAutomaticAttr()

    let validateKey = null

    if (isMobile || isIOS || isAndroid) {
      validateKey =
        event?.key === "," ||
        event?.code == 'Comma' ||
        event?.keyCode === 188 ||
        event?.which === 188 ||
        event?.which === 44
    } else {
      validateKey =
        event?.key === "Enter" ||
        event?.code == 'Enter' ||
        event?.which === 13 ||
        event?.keyCode === 13 ||
        event?.key === "," ||
        event?.code == 'Comma' ||
        event?.keyCode === 188 ||
        event?.which === 188 ||
        event?.which === 44
    }

    const validateChar = event.target.value.slice(-1) == ','
    if (
      validateKey ||
      validateChar
      &&
      event.target.value.trim() !== ""
    ) {
      const isAvailableValue = currentAttribute[index].attributeValue.find(item => item.toLowerCase().replaceAll(spaceRegex, '') == event.target.value.replaceAll(',', '').toLowerCase().replaceAll(spaceRegex, ''))
      if (isAvailableValue || event.target.value.replaceAll(',', '').trim() == "") {
        return
      }
      const isAvailableMenu = currentAttribute[index].menu.find(item => item.toLowerCase().replaceAll(spaceRegex, '') == event.target.value.replaceAll(',', '').toLowerCase().replaceAll(spaceRegex, ''))
      let newValue = event.target.value
      if (isAvailableMenu) {
        newValue = isAvailableMenu
      }
      if (index === 0) {
        addCustomMenuAttributeValue(newValue.replaceAll(',', ''), currentAttribute[index].name)
      } else if (index === 1) {
        addCustomMenuAttributeValue2(newValue.replaceAll(',', ''), currentAttribute[index].name)
      }
      addCustomInCurrentAttribute(newValue.replaceAll(',', ''), index)
    }
  }, [
    currentAttribute,
    rowsAttribute,
  ])

  const isHaveCustomAttributeName = useMemo(() => {
    const found = dataAttributeName2?.filter(item => item?.isCustomAttribute)
    if (found?.length > 0) {
      return found
    }
    return []
  }, [dataAttributeName2])

  function deleteCustomAttribute(item, i) {
    resetAutomaticAttr()

    const removeAttribute = dataAttributeName.filter(data => data?.name !== item.name)
    const removeAttribute2 = dataAttributeName2.filter(data => data?.name !== item.name)
    setDataAttributeName(removeAttribute)
    setDataAttributeName2(removeAttribute2)
    setOpenMenuCustom(false)
    setAnchorEl(null)
    const attr = [...currentAttribute]
    if (currentAttribute[0].name == item.name) {
      attr[0] = JSON.parse(localStorage.getItem(storageAttributeName))[0]
    }
    if (currentAttribute[1]?.name == item.name) {
      attr[1] = JSON.parse(localStorage.getItem(storageAttributeName2))[0]
    }
    if (currentAttribute[0].name == item.name || currentAttribute[1]?.name == item.name) {
      setCurrentAttribute(attr)
      setRowsAttribute([])
    }
    if (activeEditCustomAttr?.name == item?.name) {
      setActiveEditCustomAttr({})
      setCreateAttributeValue('')
    }
    toast.dismiss()
    toast.success('Deleted successfully.')
  }

  function clickEditCustomAttrName(item) {
    setAnchorEl(null)
    setOpenMenuCustom(false)
    setActiveEditCustomAttr(item)
    setCreateAttributeValue(item.name)
  }
  function clickCancelEdit() {
    setActiveEditCustomAttr({})
    setCreateAttributeValue('')
  }
  // function editAttributeName() {
  //   const attr = [...currentAttribute]
  //   const attributeName = [...dataAttributeName]
  //   const findIdx = dataAttributeName.findIndex(data => data.name == activeEditCustomAttr.name)
  //   attributeName[findIdx].name = createAttributeValue
  //   attributeName[findIdx].value = createAttributeValue
  //   setDataAttributeName(attributeName)
  //   if (currentAttribute[0].name == activeEditCustomAttr.name) {
  //     attr[0].name = createAttributeValue
  //     attr[0].value = createAttributeValue
  //     setTimeout(() => {
  //       setCurrentAttribute(attr)
  //     }, 0)
  //   }
  // }
  // function editAttributeName2() {
  //   const attr = [...currentAttribute]
  //   const attributeName2 = [...dataAttributeName2]
  //   const findIdx = dataAttributeName2.findIndex(data => data.name == activeEditCustomAttr.name)
  //   attributeName2[findIdx].name = createAttributeValue
  //   attributeName2[findIdx].value = createAttributeValue
  //   setDataAttributeName2(attributeName2)
  //   if (currentAttribute[1].name == activeEditCustomAttr.name) {
  //     attr[1].name = createAttributeValue
  //     attr[1].value = createAttributeValue
  //     setTimeout(() => {
  //       setCurrentAttribute(attr)
  //     }, 0)
  //   }
  // }
  const clickSaveEdit = useCallback(() => {
    resetAutomaticAttr()

    const checkAvailableAttr = dataAttributeName2.find(data => data.name.toLowerCase().replace(spaceRegex, '') == createAttributeValue.toLowerCase().replace(spaceRegex, ''))
    if (checkAvailableAttr?.name) {
      toast.dismiss()
      toast.error('Attribute name already exists.')
      return
    }
    const attr = [...currentAttribute]
    // attribute name
    const attributeName = [...dataAttributeName]
    const findIdx = dataAttributeName.findIndex(data => data.name == activeEditCustomAttr.name)
    attributeName[findIdx].name = createAttributeValue
    attributeName[findIdx].value = createAttributeValue
    setDataAttributeName(attributeName)
    if (currentAttribute[0].name == activeEditCustomAttr.name) {
      attr[0].name = createAttributeValue
      attr[0].value = createAttributeValue
    }
    // attribute name 2
    const attributeName2 = [...dataAttributeName2]
    const findIdxAttr2 = dataAttributeName2.findIndex(data => data.name == activeEditCustomAttr.name)
    attributeName2[findIdxAttr2].name = createAttributeValue
    attributeName2[findIdxAttr2].value = createAttributeValue
    setDataAttributeName2(attributeName2)
    if (currentAttribute[1]?.name == activeEditCustomAttr.name) {
      attr[1].name = createAttributeValue
      attr[1].value = createAttributeValue
    }

    setCurrentAttribute(attr)

    setActiveEditCustomAttr({})
    setCreateAttributeValue('')
    toast.dismiss()
    toast.success('Saved successfully')
  }, [
    dataAttributeName2,
    currentAttribute,
    activeEditCustomAttr,
    createAttributeValue,
    dataAttributeName
  ])

  const total_page = useMemo(() => {
    if (rowsAttribute.length > 0) {
      const total = Math.floor(rowsAttribute.length / 100)
      return total
    }
    return 0
  }, [rowsAttribute])

  // batch edit logic
  function updateBatchAttributeMenu(attribute) {
    const getAttributeName = rowsAttribute.filter((v, idx, self) =>
      idx === self.findIndex((t) => (
        t[attribute] === v[attribute]
      ))
    )
    let newAttribute = ['All']
    const value = getAttributeName.map(item => item[attribute])
    if (value.length > 0 && value[0] !== '') {
      newAttribute = [...newAttribute, ...value]
    }
    return newAttribute
  }
  useEffect(() => {
    if (rowsAttribute.length > 0) {
      setBatchEdit({
        attribute: {
          name: 'attribute',
          attributeValue: updateBatchAttributeMenu('attribute'),
          value: updateBatchAttributeMenu('attribute')[0]
        },
        second_attribute: {
          name: 'second Attribute',
          attributeValue: updateBatchAttributeMenu('second_attribute'),
          value: updateBatchAttributeMenu('second_attribute')[0]
        },
        stock: '',
        price: '',
        sku: '',
        images: '',
        file: null
      })
    } else {
      setBatchEdit({
        attribute: {
          name: 'attribute',
          attributeValue: ['All'],
          value: 'All'
        },
        second_attribute: {
          name: 'second Attribute',
          attributeValue: ['All'],
          value: 'All'
        },
        stock: '',
        price: '',
        sku: '',
        images: '',
        file: null
      })
    }
  }, [rowsAttribute])

  function changeBatchAttribute(e, v, name) {
    resetAutomaticAttr()

    setBatchEdit({
      ...batchEdit,
      [name]: {
        name,
        attributeValue: updateBatchAttributeMenu(name),
        value: v
      }
    })
  }
  function changeInputBatchText(e, name) {
    resetAutomaticAttr()

    if (name == 'batch-price') {
      setBatchEdit({
        ...batchEdit,
        price: e.target.value
      })
    } else if (name == 'batch-stock') {
      setBatchEdit({
        ...batchEdit,
        stock: e.target.value
      })
    } else if (name == 'batch-sku') {
      setBatchEdit({
        ...batchEdit,
        sku: e.target.value
      })
    }
  }

  function apllyBothNotAll() {
    const currentRows = [...rowsAttribute]

    currentRows.forEach((item, index) => {
      const check = item.attribute == batchEdit.attribute.value && item.second_attribute == batchEdit.second_attribute.value
      if (check) {
        currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
        currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
        currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
        currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
        currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
      }
    })
    setRowsAttribute(currentRows)
  }
  function applySecondAttrIsAll() {
    const currentRows = [...rowsAttribute]

    currentRows.forEach((item, index) => {
      const check = item.attribute == batchEdit.attribute.value
      if (check) {
        currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
        currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
        currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
        currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
        currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
      }
    })
    setRowsAttribute(currentRows)
  }
  function applyAttrIsAll() {
    const currentRows = [...rowsAttribute]

    currentRows.forEach((item, index) => {
      const check = item.second_attribute == batchEdit.second_attribute.value
      if (check) {
        currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
        currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
        currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
        currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
        currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
      }
    })
    setRowsAttribute(currentRows)
  }
  function applyBothIsAll() {
    const currentRows = [...rowsAttribute]

    currentRows.forEach((item, index) => {
      currentRows[index].quantity = batchEdit.stock.trim() ? batchEdit.stock : currentRows[index].quantity
      currentRows[index].price_rm = batchEdit.price.trim() ? batchEdit.price : currentRows[index].price_rm
      currentRows[index].sku = batchEdit.sku.trim() ? batchEdit.sku : currentRows[index].sku
      currentRows[index].images = batchEdit.images.trim() ? batchEdit.images : currentRows[index].images
      currentRows[index].file = batchEdit.file ? batchEdit.file : currentRows[index].file
    })
    setRowsAttribute(currentRows)
  }

  function applyBatchEdit() {
    if (
      batchEdit.attribute.value !== 'All' &&
      batchEdit.second_attribute.value !== 'All'
    ) {
      apllyBothNotAll()
    } else if (
      batchEdit.attribute.value !== 'All' &&
      batchEdit.second_attribute.value == 'All'
    ) {
      applySecondAttrIsAll()
    } else if (
      batchEdit.attribute.value == 'All' &&
      batchEdit.second_attribute.value !== 'All'
    ) {
      applyAttrIsAll()
    } else if (
      batchEdit.attribute.value == 'All' &&
      batchEdit.second_attribute.value == 'All'
    ) {
      applyBothIsAll()
    }
    toast.dismiss()
    toast.success('Apply successfully.')
  }
  const isDisabledApply = useMemo(() => {
    let err = {}
    if (batchEdit.attribute.attributeValue.length > 1 && !batchEdit.attribute.value) {
      err.attribute = ''
    }
    if (batchEdit.second_attribute.attributeValue.length > 0 && !batchEdit.second_attribute.value?.trim()) {
      err.second_attribute = ''
    }
    if (
      !batchEdit.stock.trim() &&
      !batchEdit.price.trim() &&
      !batchEdit.sku.trim() &&
      !batchEdit.file
    ) {
      err.textInput = ''
    }

    if (Object.keys(err).length === 0) {
      return false
    }
    return true
  }, [batchEdit])

  const handleChooseImgBatchEdit = useCallback(async (e) => {
    resetAutomaticAttr()

    if (e.target.files.length !== 0) {
      if (!validateExtension(e.target.files[0])) {
        toast.dismiss()
        toast.error('The image file must be "image/jpeg, image/jpg, image/png"')
        return
      }
      const base64 = await loadLocalImage(e.target.files[0])
      setBatchEdit({
        ...batchEdit,
        images: base64,
        file: e.target.files[0]
      })
    }
  }, [batchEdit])

  return (
    <Container
      title="Create Product"
      withButton
      buttonDisabled={isLoading}
      buttonTitle="Submit"
      onClick={handleSubmit(handleCreateCatalogProduct)}
    >
      <Box sx={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ComboBox
              fullWidth
              disableMargin
              title="Channel"
              placeholder="Please select channel"
              options={devices}
              getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
              value={watch("device_key") ?? null}
              onChange={(e, value) => {
                setValue("device_key", value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="700" mb={0.5} alignItems="center">
              Image
            </Typography>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Paper
                      component="label"
                      variant="contained"
                      sx={{
                        background: palette.gradients.custom,
                        padding: "5px 10px",
                        borderRadius: "12px",
                        color: "#fdfdfd",
                      }}
                    >
                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                        Choose File
                      </Typography>
                      <input
                        hidden
                        accept="*"
                        multiple
                        type="file"
                        onChange={(e) => {
                          setValue("image", e.target.files[0]);
                        }}
                      />
                    </Paper>
                    <Typography fontSize={{ xs: 12, sm: 14 }} sx={{ mx: 2 }}>
                      {watch("image") ? watch("image")?.name : null}
                    </Typography>
                  </InputAdornment>
                ),
              }}
              disabled
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BasicInput
              fullWidth
              disableMargin
              title="Retailer/Unique ID"
              value={watch("retailer_id") ?? ""}
              onChange={(e) => setValue("retailer_id", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BasicInput
              fullWidth
              disableMargin
              title="Product Name"
              value={watch("name") ?? ""}
              onChange={(e) => setValue("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <BasicInput
              fullWidth
              disableMargin
              multiline
              rows={3}
              title="Product Description"
              value={watch("description") ?? ""}
              onChange={(e) => setValue("description", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ComboBox
              fullWidth
              disableMargin
              title="Condition"
              placeholder=""
              options={conditionOptions}
              getOptionLabel={(option) => option?.label}
              value={watch("condition") ?? null}
              onChange={(e, value) => {
                setValue("condition", value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={checkedAttribute ? 6 : 4}>
            {/* <Box sx={{ position: "relative" }}> */}
            {/* <Box sx={{ position: "absolute", bottom: 0 }}>
                <Typography
                  fontSize={16}
                  fontWeight="600"
                  sx={{
                    px: 2,
                    py: 1,
                    color: "#FFFFFF",
                    background: "#AAAAAA",
                    borderRadius: 1,
                  }}
                >
                  RM
                </Typography>
              </Box> */}
            <BasicInput
              fullWidth
              disableMargin
              title="Price"
              value={watch("price") ?? ""}
              // sx={{ pl: 8 }}
              onChange={(e) => setValue("price", e.target.value)}
            />
            {/* </Box> */}
          </Grid>
          <Grid item xs={12} md={checkedAttribute ? 6 : 4}>
            <ComboBox
              fullWidth
              disableMargin
              title="Type"
              placeholder=""
              options={typeOptions}
              getOptionLabel={(option) => option?.label}
              value={watch("type") ?? null}
              onChange={(e, value) => {
                setValue("type", value);
              }}
            />
          </Grid>
          {!checkedAttribute &&
            <Grid item xs={12} md={4}>
              <BasicInput
                fullWidth
                disableMargin
                title="Stock"
                value={watch("stock") ?? ""}
                onChange={(e) => setValue("stock", e.target.value)}
              />
            </Grid>
          }
          <Grid item xs={12} sx={{
            display: 'flex',
            flexDirection: {
              xs: "column",
              sm: 'row',
              md: 'row'
            },
            alignItems: {
              xs: 'none',
              sm: 'center',
              md: 'center'
            },
            justifyContent: 'space-between',
          }}>
            <Typography fontWeight="700">
              Attribute Setting
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Switch
                checked={checkedAttribute}
                onChange={toggleActiveAttribute}
              />
              <Typography>
                Active Attribute
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {checkedAttribute && currentAttribute?.map((attr, i) => {
              return (
                <Grid key={i}
                  container
                  spacing={2}
                  mb={2}
                >
                  <Grid item xs={12} md={6}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      title="Attribute Name"
                      fontWeight="400"
                      placeholder="Please select attribute"
                      options={i === 0 ? dataAttributeName : dataAttributeName2}
                      getOptionLabel={(option) => option?.name}
                      value={attr}
                      onChange={(e, value) => changeAttributeName(e, value, i)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography mb={0.5}>
                      Attribute Value
                    </Typography>
                    {!planIsCustomAttribute ?
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={attr.menu}
                        getOptionLabel={(option) => option}
                        value={attr.attributeValue}
                        filterSelectedOptions
                        ChipProps={{
                          style: {
                            backgroundColor: palette.primary.main,
                            color: palette.secondary.main,
                          },
                        }}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        onChange={(e, value) => changeAttributeValue(e, value, i)}
                      /> :
                      <>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={attr.menu}
                          getOptionLabel={(option) => option}
                          filterSelectedOptions
                          value={attr.attributeValue}
                          ChipProps={{
                            style: {
                              backgroundColor: palette.primary.main,
                              color: palette.secondary.main,
                            },
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Type to add attribute values ..."
                              size="small"
                              // onKeyPress={(e) => handleKeyPress(e, i, attr)}
                              onKeyDown={(e) => handleKeyPress(e, i, attr)}
                            />
                          )}
                          onChange={(e, value) => changeAttributeValue(e, value, i)}
                        />
                        <Typography color="#7B7B7B" fontSize="12px" ml={2} mt={0.5}>
                          {isMobile || isIOS || isAndroid ?
                            `use comma "," as a multi-keyword separator`
                            :
                            `use comma "," or Enter as a multi-keyword separator`
                          }
                        </Typography>
                      </>
                    }
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
          {/* add variant if user plan paid */}
          {
            planIsCustomAttribute &&
            checkedAttribute &&
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background: '#F6F7FF',
                  borderRadius: 1,
                  padding: 1.5
                }}
              >
                <Typography
                  fontWeight={700}
                  mb={1}
                >
                  Create Attribute Name
                </Typography>
                <Typography
                  mb={0.5}
                  fontSize={14}
                >
                  Add attribute names manually.
                </Typography>
                <InputLimitText
                  limit={30}
                  width="auto"
                  value={createAttributeValue}
                  length={createAttributeValue.length}
                  onChange={changeCustomAttribute}
                  placeholder="Enter a new Attribute Name ..."
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: isHaveCustomAttributeName.length === 0 ? 'flex-end' : 'space-between',
                    alignItems: 'center'
                  }}
                  my={1}
                >
                  {isHaveCustomAttributeName.length > 0 &&
                    <Stack>
                      <ButtonWithIcon
                        title="View"
                        style={{
                          width: 'fit-content',
                          marginTop: 10
                        }}
                        icon={'ic:baseline-arrow-drop-down'}
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget)
                          setOpenMenuCustom(!openMenuCustom)
                        }}
                      />
                      <div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openMenuCustom}
                          onClose={() => {
                            setOpenMenuCustom(!openMenuCustom)
                            setAnchorEl(null)
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          sx={{
                            maxWidth: 500,
                          }}
                        >
                          <Typography
                            ml={2}
                            mb={0.5}
                            fontWeight={700}
                          >
                            Attribute Name
                          </Typography>
                          {isHaveCustomAttributeName.map((item, i) => (
                            <MenuItem
                              key={i}
                              sx={{
                                justifyContent: 'space-between',
                                whiteSpace: 'wrap'
                              }}
                            >
                              <Typography
                                mr={1}
                                minWidth={100}
                              >
                                {item.name}
                              </Typography>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <IconButton
                                  sx={{ py: 0, minWidth: "36px", height: '36px' }}
                                  onClick={() => deleteCustomAttribute(item, i)}
                                >
                                  <Iconify
                                    icon={"fluent:delete-24-regular"}
                                    color="#F15A28"
                                    width={18}
                                    height={18}
                                  />
                                </IconButton>
                                <IconButton
                                  sx={{ py: 0, minWidth: "36px", height: '36px' }}
                                  onClick={() => clickEditCustomAttrName(item)}
                                >
                                  <Iconify
                                    icon={"ph:note-pencil"}
                                    color="#1A776F"
                                    width={18}
                                    height={18}
                                  />
                                </IconButton>
                              </Box>
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </Stack>
                  }
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {activeEditCustomAttr?.name &&
                      <Button onClick={clickCancelEdit}>
                        <Typography
                          fontSize={{ xs: 12, sm: 14 }}
                          color={palette.secondary.dark}
                        >
                          Cancel
                        </Typography>
                      </Button>
                    }
                    <Button
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                      disabled={
                        !activeEditCustomAttr?.name ?
                          createAttributeValue.trim() ? false :
                            true
                          :
                          createAttributeValue.trim() ? false :
                            true
                      }
                      onClick={() => !activeEditCustomAttr?.name ? clickAddCustomAttribute() : clickSaveEdit()}
                    >
                      <Iconify
                        icon={!activeEditCustomAttr?.name ? 'simple-line-icons:plus' : 'ph:note-pencil-light'}
                        sx={{
                          fontSize: 16
                        }}
                      />
                      <Typography
                        fontSize={{ xs: 12, sm: 14 }}
                        ml={1}
                      >
                        {!activeEditCustomAttr?.name ? 'Add Attribute' : 'Save'}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          }
          {checkedAttribute &&
            <Grid item xs={12}>
              <Button
                variant="contained"
                color={currentAttribute.length < 2 ? 'primary' : 'error'}
                sx={{
                  background: currentAttribute.length < 2 ? palette.gradients.custom : '#FF4842',
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  width: 'fit-content',
                  padding: '6px 16px'
                }}
                onClick={currentAttribute.length < 2 ? clickAddAttribute : clickDeleteAttribute}
              >
                <Typography fontSize={{ xs: 12, sm: 14 }}>{currentAttribute.length < 2 ? 'Add Attribute' : 'Delete Attribute'}</Typography>
              </Button>
            </Grid>
          }
          {/* batch edit */}
          {
            checkedAttribute &&
            rowsAttribute.length > 0 &&
            <>
              <Grid item xs={12}>
                <Typography
                  fontSize={16}
                  fontWeight={700}
                >
                  Batch Edit
                </Typography>
                <Box mb={1} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3} md={3}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      placeholder="Select"
                      options={batchEdit.attribute.attributeValue}
                      getOptionLabel={(option) => option}
                      value={batchEdit.attribute.value}
                      onChange={(e, v) => changeBatchAttribute(e, v, 'attribute')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <ComboBox
                      fullWidth
                      disableMargin
                      placeholder="Select"
                      options={batchEdit.second_attribute.attributeValue}
                      getOptionLabel={(option) => option}
                      value={batchEdit.second_attribute.value}
                      onChange={(e, v) => changeBatchAttribute(e, v, 'second_attribute')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      multiline
                      type="number"
                      name="batch-stock"
                      value={batchEdit.stock}
                      placeholder="Stock"
                      onChange={(e) => changeInputBatchText(e, 'batch-stock')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      multiline
                      name="batch-price"
                      value={batchEdit.price}
                      placeholder="Price"
                      onChange={(e) => changeInputBatchText(e, 'batch-price')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <BasicInput
                      fullWidth
                      disableMargin
                      multiline
                      name="batch-sku"
                      value={batchEdit.sku}
                      placeholder="SKU"
                      onChange={(e) => changeInputBatchText(e, 'batch-sku')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6.5} md={4}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Paper
                              component="label"
                              variant="contained"
                              sx={{
                                background: palette.gradients.custom,
                                padding: "5px 10px",
                                borderRadius: "12px",
                                color: "#fdfdfd",
                              }}
                            >
                              <Typography fontSize={{ xs: 12, sm: 14 }}>
                                Choose File
                              </Typography>
                              <input
                                hidden
                                accept="image/png, image/jpeg, image/jpg"
                                multiple
                                type="file"
                                onChange={handleChooseImgBatchEdit}
                              />
                            </Paper>
                            <Typography fontSize={{ xs: 12, sm: 14 }} sx={{
                              mx: 2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '2',
                              WebkitBoxOrient: 'vertical',
                              whiteSpace: 'break-spaces',
                              width: 'full'
                            }}>
                              {batchEdit.file?.name}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                      disabled
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} alignItems="center" display="flex">
                    <BasicButton
                      title="Apply"
                      disabled={isDisabledApply}
                      onClick={applyBatchEdit}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box mb={2} />
            </>
          }
          <Grid item xs={12}>
            <Box
              sx={{
                // height: rowsAttribute.length > 0 ? 600 : 50,
                width: '100%'
              }}
            >
              <DataGrid
                hideFooter
                getRowId={(row) => row?.id}
                getRowHeight={() => 100}
                rows={rowsAttribute}
                columns={columnsAttribute}
                editMode="row"
                onRowEditStart={rowEditStart}
                onRowEditStop={rowEditStop}
                page={page - 1}
                pageSize={100}
                autoHeight={true}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableSelectionOnClick
              />
            </Box>
            <Box mb={2} />
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Box sx={{ mx: 1 }}>
                <BasicButton
                  title="<"
                  onClick={() => {
                    if (parseInt(page) > 1) {
                      setPage(parseInt(page) - 1);
                    }
                  }}
                  disabled={parseInt(page) === 1}
                />
              </Box>
              <Box sx={{ mx: 1 }}>
                <BasicButton
                  title=">"
                  onClick={() => {
                    if (parseInt(page) <= total_page) {
                      setPage(parseInt(page) + 1);
                    }
                  }}
                  disabled={parseInt(page) > total_page}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
