import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import socket from "../services/socket";
import { authLogout } from "../store/user/userAction";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    toast.loading("Please wait...");

    await dispatch(authLogout()).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        socket.emit("disconnect");
        socket.off();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);

        navigate("/dashboard/app");
      }
    });
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return <></>;
};

export default Logout;
