import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import { getCatalogOrder } from "../../../../store/catalog/catalogAction";
import Details from "./Details";
import List from "./List";

const types = [
  {
    label: "Unpaid",
    value: "1",
  },
  {
    label: "Cash On Delivery",
    value: "7",
  },
  {
    label: "Paid",
    value: "2",
  },
  {
    label: "Delivery",
    value: "3",
  },
  {
    label: "Success",
    value: "4",
  },
  {
    label: "Canceled",
    value: "5",
  },
  {
    label: "Failed",
    value: "6",
  },
];

export default function OrderHistory() {
  const dispatch = useDispatch();

  const { catalogOrder } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);
  const [isDetails, setIsDetails] = useState(false);

  const [typeSelected, setTypeSelected] = useState(types[1]);

  const handleGetCatalogOrder = async (data) => {
    setIsLoading(true);

    await dispatch(getCatalogOrder(data));

    setIsLoading(false);
  };

  const handleOrderSelected = async (item) => {
    handleGetCatalogOrder({ order_id: item?.order_id });
    setIsDetails(!isDetails);
  };

  const componentRef = useRef();

  return (
    <ContainerWithSomeButton
      title="Order History"
      buttonBack={isDetails ? true : false}
      buttonBackTitle="Back"
      buttonBackOnClick={() => setIsDetails(!isDetails)}
      // button2={isDetails ? true : false}
      // button2Title="Invoice"
      // button2Icon="ant-design:download-outlined"
      // button2OnClick={handlePrint}
    >
      {!isDetails ? (
        <Box pt={0.5}>
          {/* <InputSearch /> */}
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {types?.map((item, index) => {
              if (typeSelected?.value === item?.value) {
                return (
                  <Box key={index} sx={{ mr: 2, mb: 1 }}>
                    <BasicButton
                      title={item?.label}
                      onClick={() => setTypeSelected(null)}
                    />
                  </Box>
                );
              } else {
                return (
                  <Box key={index} sx={{ mr: 2, mb: 1 }}>
                    <Button
                      variant="outlined"
                      backgroundColor="primary"
                      onClick={() => setTypeSelected(item)}
                    >
                      {item?.label}
                    </Button>
                  </Box>
                );
              }
            })}
          </Box>
          <Box mb={3} />
          <List onClick={(e) => handleOrderSelected(e)} type={typeSelected} />
        </Box>
      ) : (
        <Scrollbar>
          <Stack spacing={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                component="button"
                paddingX={1}
                paddingY={0.5}
                width="fit-content"
                borderRadius="8px"
                sx={{
                  backgroundColor:
                    catalogOrder?.data?.status === "Unpaid"
                      ? "#f5f5f5"
                      : catalogOrder?.data?.status === "Paid"
                      ? " #bbdefb"
                      : catalogOrder?.data?.status === "Waiting Approval"
                      ? "#c3ff59"
                      : catalogOrder?.data?.status === "Delivery"
                      ? "#fff9c4"
                      : catalogOrder?.data?.status === "Success"
                      ? "#c8e6c9"
                      : catalogOrder?.data?.status === "Canceled" ||
                        catalogOrder?.data?.status === "Failed"
                      ? "#ffcdd2"
                      : null,
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <Typography color="#131313" fontSize="14px">
                  {catalogOrder?.data?.status}
                </Typography>
              </Box>
              <Typography fontSize={12}>
                Date: {catalogOrder?.data?.date ?? "-"} {" "} <br />
                Order ID: {catalogOrder?.data?.order_id ?? "-"} {" "} <br />
                Payment Method: {catalogOrder?.data?.payment_method ?? "-"} {" "} <br />
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              border="1px solid  #a6a6a6"
              borderRadius={2}
              padding={1.5}
            >
              <Box>
                <Typography fontSize={12} color="#131313">
                  {catalogOrder?.data?.customer}
                </Typography>
              </Box>
              <Box>
                <ButtonWithIcon
                  variant="outlined"
                  backgroundColor="primary"
                  title="Chat Buyer"
                  icon="logos:whatsapp-icon"
                  onClick={() =>
                    window.open(
                      `https://wa.me/${catalogOrder?.data?.customer_phone}`
                    )
                  }
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
            <Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#131313"
                sx={{ mb: 0.5 }}
              >
                Shipping Address :
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#131313"
                sx={{ maxWidth: "400px" }}
              >
                {catalogOrder?.data?.address?.name},{" "}
                {catalogOrder?.data?.address?.phone}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#131313"
                sx={{ maxWidth: "400px" }}
              >
                {catalogOrder?.data?.address?.address},{" "}
                {catalogOrder?.data?.address?.city},{" "}
                {catalogOrder?.data?.address?.state},{" "}
                {catalogOrder?.data?.address?.country},{" "}
                {catalogOrder?.data?.address?.zipcode}
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize={16}
                fontWeight={600}
                color="#131313"
                sx={{ mb: 0.5 }}
              >
                Courier Detail :
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#131313"
                sx={{ maxWidth: "400px" }}
              >
                Courier Name : {catalogOrder?.data?.courier?.name} {" "} <br />
                Courier Service : {catalogOrder?.data?.courier?.service} {" "} <br />
                Tracking Number : {catalogOrder?.data?.courier?.tracking_number}
              </Typography>
            </Box>

            </Box>
            <div ref={componentRef}>
              <Details products={catalogOrder?.data} isLoading={isLoading} />
            </div>
          </Stack>
        </Scrollbar>
      )}
    </ContainerWithSomeButton>
  );
}
