import React from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  TableBody,
  Checkbox,
} from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";
import Iconify from "../../../components/Iconify";
import palette from "../../../theme/palette";
import Container from "../../../components/containers/Container";
import InputSearch from "../../../components/input/InputSearch";
import CustomPagination from "../../../components/CustomPagination";

function createData(checkbox, no, date, user, description, ipAddress) {
  return { checkbox, no, date, user, description, ipAddress };
}

const rows = [
  createData(
    <Checkbox />,
    1,
    "2022-09-09 23:49:33",
    "Freya",
    "Send Message [ https://github.com/adiwajshing/Baileys ] To [ 923092144374 ]",
    "115.164.72.231"
  ),
  createData(
    <Checkbox />,
    2,
    "2022-09-09 23:49:33",
    "Freya",
    "Send Message [ https://github.com/adiwajshing/Baileys ] To [ 923092144374 ]",
    "115.164.72.231"
  ),
  createData(
    <Checkbox />,
    3,
    "2022-09-09 23:49:33",
    "Freya",
    "Send Message [ https://github.com/adiwajshing/Baileys ] To [ 923092144374 ]",
    "115.164.72.231"
  ),
  createData(
    <Checkbox />,
    4,
    "2022-09-09 23:49:33",
    "Freya",
    "Send Message [ https://github.com/adiwajshing/Baileys ] To [ 923092144374 ]",
    "115.164.72.231"
  ),
];

const tableCell = [
  <Checkbox
    sx={{
      color: "white",
      "&.Mui-checked": {
        color: "white",
      },
    }}
  />,
  "No",
  "Date",
  "User",
  "Description",
  "IP Address",
];

export default function LogActivity() {
  return (
    <Container
      withButton
      title="Log Activity"
      buttonTitle="Delete"
      buttonIcon="ic:baseline-expand-more"
    >
      <Box mb={3} />
      <InputSearch />
      <Box mb={3} />

      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <Table aria-label="simple table">
            <TableHead
              style={{
                background: palette.gradients.custom,
              }}
            >
              <TableRow>
                {tableCell.map((item, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography color="#FDFDFD">{item}</Typography>
                        <Iconify
                          icon={"emojione-monotone:up-down-arrow"}
                          color="#FDFDFD"
                          width={16}
                          height={16}
                          sx={{ display: { xs: "none", sm: "flex" } }}
                        />
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.no}>
                  <TableCell component="th" scope="row">
                    {row.checkbox}
                  </TableCell>
                  <TableCell align="left">{row.no}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                  <TableCell align="left">{row.user}</TableCell>
                  <TableCell align="left">{row.description}</TableCell>
                  <TableCell align="left">{row.ipAddress}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <EmptyBox
          title1="No Contact Database "
          title2="Generate now at"
          link="Contact Scrapping"
        /> */}
        <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
          <CustomPagination />
        </Box>
      </Scrollbar>
    </Container>
  );
}
