import { createSlice } from "@reduxjs/toolkit";
import {
  getContactScrappings,
  getContacts,
  getProfilePictureContact,
} from "./contactAction";

const initialState = {
  contacts: null,
  pictureContacts: [],
  contactScrappings: [],
};

const contactSlice = createSlice({
  name: "contact",
  initialState: initialState,
  extraReducers: {
    [getContacts.fulfilled]: (state, { payload }) => {
      state.contacts = payload;
    },
    [getContacts.rejected]: (state) => {
      state.contacts = null;
    },
    [getProfilePictureContact.fulfilled]: (state, { payload }) => {
      if (payload?.result === false) {
        state.pictureContacts = [];
      } else {
        state.pictureContacts = payload;
      }
    },
    [getProfilePictureContact.rejected]: (state) => {
      state.pictureContacts = [];
    },
    [getContactScrappings.fulfilled]: (state, { payload }) => {
      state.contactScrappings = payload;
    },
    [getContactScrappings.rejected]: (state) => {
      state.contactScrappings = null;
    },
  },
});

export const { resetContact } = contactSlice.actions;

export default contactSlice.reducer;
