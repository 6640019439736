import { Box, FormControl, Grid, InputAdornment, OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import ButtonWithIcon from "../../../../../components/button/ButtonWithIcon";
import Iconify from "../../../../../components/Iconify";
import Scrollbar from "../../../../../components/Scrollbar";
import palette from "../../../../../theme/palette";
import BasicButton from "../../../../../components/button/BasicButton";
import ButtonTooltip from "../../../../../components/button/ButtonTooltip";

export default function AddedProducts({
    products,
    setProducts,
    onShowAddProduct
}) {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [currentLists, setCurrentLists] = useState([])
    const [productsSearch, setProductsSearch] = useState([])

    const tableCellList = ["Name", "Type", "Quantity", "Price", "Status", "Action"];

    const productPerPage = 3

    const total_page = useMemo(() => {
        if (search.trim()) {
            const searchProducts = products.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )

            const calculate = Math.ceil(searchProducts.length / productPerPage)
            const total = calculate
            return total
        } else if (products) {
            const calculate = Math.ceil(products.length / productPerPage)
            const total = calculate
            return total
        }
        return 0
    }, [products, search, currentLists, page])

    useEffect(() => {
        if (search.trim()) {
            const searchProducts = products.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            const startIndex = (page - 1) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = searchProducts.slice(startIndex, endIndex)
            setProductsSearch(searchProducts)
            setCurrentLists(currentProducts)
            if (currentProducts.length === 0 && page > 1) {
                setPage(page - 1)
            }
        } else if (products) {
            const startIndex = (page - 1) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = products.slice(startIndex, endIndex)
            setCurrentLists(currentProducts)
            setProductsSearch([])
            if (currentProducts.length === 0 && page > 1) {
                setPage(page - 1)
            }
        } else {
            setCurrentLists([])
        }
    }, [products, page, search])

    useEffect(() => {
        if (search.trim()) {
            const searchProducts = products.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
            const startIndex = (0) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = searchProducts.slice(startIndex, endIndex)
            setCurrentLists(currentProducts)
            setProductsSearch(searchProducts)
            setPage(1)
        } else if (products) {
            const startIndex = (0) * productPerPage
            const endIndex = startIndex + productPerPage
            const currentProducts = products.slice(startIndex, endIndex)
            setCurrentLists(currentProducts)
            setProductsSearch([])
            setPage(1)
        }
    }, [search])

    function removeProductList(item, i) {
        const remove = products.filter((p, iP) => p.randomId !== item.randomId)
        setProducts(remove)
    }

    return (
        <>
            <Grid item xs={12} sm={12} mt={0}>
                <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                    List of Added Products
                </Typography>
                <FormControl
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    onChange={(e) => setSearch(e.target.value)}
                >
                    <OutlinedInput
                        style={{ height: "40px" }}
                        id="outlined-adornment-amount"
                        placeholder="Search products"
                        value={search}
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify
                                    icon={"heroicons:magnifying-glass"}
                                    color="#7B7B7B"
                                    width={24}
                                    height={24}
                                />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                    mb={2}
                >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography fontSize={14} fontWeight="600">
                            Products ({search.trim() ? `Total ${productsSearch.length} of ${products.length ?? 0}` : `Total ${products.length ?? 0}`})
                        </Typography>
                    </Box>
                    <ButtonWithIcon
                        title="Add Product"
                        icon="formkit:add"
                        // disabled={wabaDevice?.device_key ? false : true}
                        onClick={onShowAddProduct}
                    />
                </Box>

                {/* data table */}
                <Scrollbar>
                    <TableContainer
                        component={Paper}
                        style={{ backgroundColor: "#FDFDFD" }}
                    >
                        <Table aria-label="simple table">
                            <TableHead
                                style={{
                                    background: palette.gradients.custom,
                                }}
                            >
                                <TableRow>
                                    {tableCellList.map((item, index) => {
                                        return (
                                            <TableCell
                                                sx={{
                                                    padding: 1
                                                }}
                                                align="left"
                                                key={index}
                                            >
                                                <Box
                                                    alignItems="center"
                                                    display="flex"
                                                    justifyContent={"space-between"}
                                                >
                                                    <Typography color="#FDFDFD" fontSize={14}>{item}</Typography>
                                                </Box>
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {currentLists.length > 0 && currentLists.map((item, i) => {
                                    return (
                                        <TableRow key={i}>
                                            {/* NAME */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    minWidth: '15rem',
                                                    maxWidth: '15rem',
                                                    flex: 1,
                                                    padding: 1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: 2
                                                    }}
                                                >
                                                    <img
                                                        src={item.image}
                                                        alt={item.name}
                                                        height={60}
                                                        width={60}
                                                        style={{
                                                            maxWidth: '80px'
                                                        }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            flex: 1
                                                        }}
                                                    >
                                                        <Typography
                                                            fontSize={14}
                                                            fontWeight="600"
                                                            sx={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: '3',
                                                                WebkitBoxOrient: 'vertical',
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            {/* TYPE */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    maxWidth: '5rem',
                                                    padding: 1
                                                }}
                                            >
                                                {item.type === 1 ? 'Physical' : 'Digital'}
                                            </TableCell>
                                            {/* QUANTITY */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    maxWidth: '5rem',
                                                    padding: 1
                                                }}
                                            >
                                                {item.quantity}
                                            </TableCell>
                                            {/* PRICE */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    maxWidth: '5rem',
                                                    padding: 1
                                                }}
                                            >
                                                {item.price}
                                            </TableCell>
                                            {/* STATUS */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    maxWidth: '5rem',
                                                    padding: 1
                                                }}
                                            >
                                                {item.status}
                                            </TableCell>
                                            {/* ACTION */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    overflow: 'hidden',
                                                    padding: 1
                                                }}
                                            >
                                                <ButtonTooltip
                                                    title="Delete"
                                                    icon="fluent:delete-24-regular"
                                                    color="#F15A28"
                                                    handleClick={() => removeProductList(item, i)}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                {currentLists.length === 0 && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={6}>
                                            Data Not Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Grid>
            <Grid item xs={12} sm={12} mt={1}>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ mx: 1 }}>
                        <BasicButton
                            title="<"
                            onClick={() => {
                                if (parseInt(page) > 1) {
                                    setPage(parseInt(page) - 1);
                                }
                            }}
                            disabled={parseInt(page) === 1}
                        />
                    </Box>
                    <Box sx={{ mx: 1 }}>
                        <BasicButton
                            title=">"
                            onClick={() => {
                                if (parseInt(page) <= total_page) {
                                    setPage(parseInt(page) + 1);
                                }
                            }}
                            disabled={parseInt(page) >= total_page}
                        />
                    </Box>
                </Box>
            </Grid>
        </>
    )
}