import { IconButton, Link, Stack } from "@mui/material";
import Iconify from "../../../components/Iconify";

export function createData(no, name, phoneNumber, action) {
  return { no, name, phoneNumber, action };
}
export const IconList = ({ onEdit, onDelete }) => {
  return (
    <Stack direction="row" spacing={1}>
      <IconButton onClick={onEdit}>
        <Iconify
          icon={"akar-icons:edit"}
          color="#1A776F"
          width={24}
          height={24}
        />
      </IconButton>
      <IconButton onClick={onDelete}>
        <Iconify
          icon={"fluent:delete-24-regular"}
          color="#F15A28"
          width={24}
          height={24}
        />
      </IconButton>
    </Stack>
  );
};

export const tableCell = ["Bulk ID", "Date", "Status", "Action"];

export const IconListResponsive = ({ onClickAddIcon, onClickDeleteIcon }) => {
  return (
    <Stack
      direction="row"
      spacing={1.5}
      display={{ xs: "flex", sm: "none", md: "none" }}
    >
      <Iconify
        icon={"carbon:add-alt"}
        color="#1A776F"
        width={24}
        height={24}
        onClick={() => onClickAddIcon()}
      />
      <Iconify
        icon={"fluent:delete-24-regular"}
        color="#F15A28"
        width={24}
        height={24}
        onClick={() => onClickDeleteIcon()}
      />
    </Stack>
  );
};

export const addOption = [
  {
    id: 1,
    title: "Add Manual",
  },
  {
    id: 2,
    title: "Import From Excel File",
  },
  {
    id: 3,
    title: "Import From Device",
  },
  {
    id: 4,
    title: "Import From Whatsapp Groups",
  },
];
