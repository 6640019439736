import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../../components/Iconify";
import { getCatalogOrders } from "../../../../store/catalog/catalogAction";
import palette from "../../../../theme/palette";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const tableCell = [
  "No",
  "Order Number",
  "Date",
  "Customer Phone",
  "Payment Method",
  "Subtotal",
  "Shipping",
  "Status",
  "View",
];

export default function List({ onClick, type }) {
  const dispatch = useDispatch();

  const { catalogOrders } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handleGetCatalogOrders = async () => {
    setIsLoading(true);

    const data = {
      params: {
        page: page,
        status: type?.value,
      },
    };

    await dispatch(getCatalogOrders(data));

    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCatalogOrders();
  }, [page, type]);

  return (
    <>
      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <Table aria-label="simple table">
            <TableHead
              style={{
                background: palette.gradients.custom,
              }}
            >
              <TableRow>
                {tableCell.map((item, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          color="#FDFDFD"
                          sx={{ whiteSpace: "nowrap" }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={tableCell.length} align="center">
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  </TableCell>
                </TableRow>
              ) : catalogOrders?.data?.length ? (
                catalogOrders?.data?.map((item, index) => (
                  <TableRow hover={true} key={index}>
                    <TableCell component="th" scope="row">
                      {page === 1
                        ? index + 1
                        : page * catalogOrders?.paginations?.per_page -
                        9 +
                        index}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.order_id}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.date}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.customer}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.payment_method}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {parseFloat(item?.total).toFixed(2)} {item.currency}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.shipping_method}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      <Box
                        component="button"
                        paddingX={1}
                        paddingY={0.5}
                        width="fit-content"
                        borderRadius="8px"
                        sx={{
                          backgroundColor:
                            item?.status === "Unpaid"
                              ? "#f5f5f5"
                              : item?.status === "Paid"
                                ? " #bbdefb"
                                : item?.status === "Waiting Approval"
                                  ? "#c3ff59"
                                  : item?.status === "Delivery"
                                    ? "#fff9c4"
                                    : item?.status === "Success"
                                      ? "#c8e6c9"
                                      : item?.status === "Canceled" ||
                                        item?.status === "Failed"
                                        ? "#ffcdd2"
                                        : null,
                          border: "none",
                        }}
                      >
                        <Typography color="#131313" fontSize="14px">
                          {item?.status}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      <ButtonTooltip
                        title="View"
                        handleClick={() => onClick(item)}
                        icon="akar-icons:eye"
                        color="#0D4AFD"
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={tableCell.length}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (parseInt(page) < catalogOrders?.paginations?.last_page) {
                setPage(parseInt(page) + 1);
              }
            }}
            disabled={parseInt(page) >= catalogOrders?.paginations?.last_page}
          />
        </Box>
      </Box>
    </>
  );
}
