import { Button, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import * as React from "react";

export default function SelectFormatPhoneNumber({ countries, onClick, value, handleSearch, resetCountry, searchValue }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    resetCountry()
  };

  const landScapeSize = useMediaQuery('(min-height:406px)')

  return (
    <>
      <Typography fontWeight="700" mb={0.5} alignItems="center">
        Code
      </Typography>
      <Box>
        <Tooltip title="Choose Country Code">
          <Button
            variant="outlined"
            size="small"
            onClick={handleClick}
            sx={{ border: "1px solid #919eab52" }}
          >
            <Box sx={{ fontSize: "32px", lineHeight: "32px" }}>
              {value?.emoji}
            </Box>
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              maxHeight: "480px",
              // overflowY: "scroll",
              overflowY: "hidden",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Box
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              zIndex: '99',
              background: '#fff',
              borderBottom: '1px solid #eee',
              outline: 'none'
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <TextField
              id="outlined-basic"
              label="Search Country or Code"
              variant="outlined"
              size="small"
              type="text"
              style={{
                margin: '0.9rem 16px',
                width: '85%',
              }}
              onChange={handleSearch}
              value={searchValue}
              autoFocus={true}
            />
          </Box>
          <Box
            style={{
              overflowY: 'scroll',
              height: landScapeSize ? '300px' : '150px',
              maxHeight: '300px',
              minWidth: '368px',
              marginTop: '3.8rem',
            }}
          >
            {countries.length > 0 ? countries.map((item, index) => {
              if (item.name) {
                return (
                  <MenuItem key={index} onClick={() => onClick(item)}>
                    {item.name} (+{item.phone_code})
                  </MenuItem>
                );
              }
            }) :
              <Box style={{
                display: 'flex',
                marginTop: '1rem'
              }}>
                <Typography style={{
                  margin: '0 16px'
                }}>No results from country or code</Typography>
              </Box>
            }
          </Box>
        </Menu>
      </Box>
    </>
  );
}
