import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import Container from "../../../../components/containers/Container";
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import { getDevices } from "../../../../store/device/deviceAction";
import { bulkEmail } from "../../../../store/marketing/marketingAction";
import palette from "../../../../theme/palette";

const messageTypeData = [{ name: "Text", key: "text" }];

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function BulkEmail() {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { user, plan } = useSelector((state) => state.user);

  const [typeMessage, setTypeMessage] = useState(null);
  const [device, setDevice] = useState(null);
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      devicekey: "",
      reply_to: "",
      type: "",
      subject: "",
      content: "",
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      // await dispatch(
      //   getStatusDeviceClient({ accountid: value.accountid })
      // ).then(async (res) => {
      //   if (res.meta.requestStatus === "fulfilled") {
      // if (res.payload.result) {
      //   if (res.payload.ready) {
      const data = new FormData();

      data.append("device_key", value.device_key);
      data.append("reply_to", value.reply_to);
      data.append("type", value.type);
      data.append("subject", value.subject);
      data.append("content", value.content);
      data.append("file", file);

      await dispatch(bulkEmail(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          setTypeMessage(null);
          setDevice(null);
          setFile(null);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
      //     } else {
      //       toast.dismiss();
      //       toast.error("Device Not Connected");
      //     }
      //   } else {
      //     toast.dismiss();
      //     toast.error("Device Not Connected");
      //   }
      // } else {
      //   toast.dismiss();
      //   toast.error("Device Not Connected");
      // }
      // });
    },
  });

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "email",
      },
    };

    await dispatch(getDevices(body));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  if (plan?.is_bulkmessage === "1") {
    return (
      <>
        <SaveCancelContainer
          title="Email Broadcast"
          buttonSaveTitle="Submit"
          buttonDisabled={!formik.values.content.length}
          onSave={formik.handleSubmit}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} minHeight="540px">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <ComboBox
                    fullWidth
                    disableMargin
                    title="Channel"
                    placeholder="Please select channel"
                    options={devices ?? []}
                    getOptionLabel={(option) => option?.name}
                    value={device}
                    onChange={(e, value) => {
                      formik.setFieldValue("device_key", value.device_key);
                      setDevice(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <BasicInput
                    fullWidth
                    disableMargin
                    title="Reply To"
                    placeholder="example@email.com"
                    {...formik.getFieldProps("reply_to")}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ComboBox
                    fullWidth
                    disableMargin
                    options={messageTypeData}
                    getOptionLabel={(option) => option?.name}
                    title="Message Type"
                    placeholder={"Choose type message"}
                    value={typeMessage}
                    onChange={(e, value) => {
                      formik.setFieldValue("type", value ? value?.key : null);

                      setTypeMessage(value);
                    }}
                  />
                </Grid>
                {typeMessage?.key === "text" ? (
                  <>
                    <Grid item xs={12} sm={12}>
                      <BasicInput
                        fullWidth
                        disableMargin
                        title="Subject"
                        placeholder="Type subject in here ..."
                        {...formik.getFieldProps("subject")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <BasicInput
                        fullWidth
                        disableMargin
                        multiline
                        rows={5}
                        title="Content"
                        placeholder="Type content in here ..."
                        {...formik.getFieldProps("content")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Typography fontWeight="700" mb={0.5} alignItems="center">
                        File
                      </Typography>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Paper
                                component="label"
                                variant="contained"
                                sx={{
                                  background: palette.gradients.custom,
                                  padding: "5px 10px",
                                  borderRadius: "12px",
                                  color: "#fdfdfd",
                                }}
                              >
                                <Typography fontSize={{ xs: 12, sm: 14 }}>
                                  Choose File
                                </Typography>
                                <input
                                  hidden
                                  accept="*"
                                  multiple
                                  type="file"
                                  onChange={(e) => {
                                    setFile(e.target.files[0]);
                                  }}
                                />
                              </Paper>
                              <Typography
                                fontSize={{ xs: 12, sm: 14 }}
                                sx={{ mx: 2 }}
                              >
                                {file ? file?.name : null}
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                        disabled
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </SaveCancelContainer>
      </>
    );
  } else {
    return (
      <Container title="Email Broadcast">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
