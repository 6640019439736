import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import BasicButton from "../../../components/button/BasicButton";
import Container from "../../../components/containers/Container";
import InputSearch from "../../../components/input/InputSearch";
import palette from "../../../theme/palette";
import Scrollbar from "../../../components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import {
  copyMessageTemplate,
  getPublicTemplates,
  getTemplates,
} from "../../../store/template/templateAction";
import { useEffect } from "react";
import toast from 'react-hot-toast';

const category = [
  {
    name: "All Category",
  },
];

export default function TemplateLibrary() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { publicTemplates, templates } = useSelector((state) => state.template);

  const [isLoading, setIsLoading] = useState(false);

  const [selected, setSelected] = useState(category[0]);

  const handleGetPublicTemplates = async (data) => {
    setIsLoading(true);

    await dispatch(getPublicTemplates(data));

    setIsLoading(false);
  };

  const handleGetTemplates = async () => {
    await dispatch(getTemplates({}));
  };

  const handleCopyMessageTemplate = async (data) => {
    toast.loading("Please wait...");

    await dispatch(copyMessageTemplate(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        handleGetTemplates();
        handleGetPublicTemplates();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    handleGetPublicTemplates();
  }, []);

  useEffect(() => {
    handleGetTemplates();
  }, []);
  console.log(publicTemplates);
  console.log(templates);

  return (
    <Container title="Template Library">
      <Scrollbar>
        <Box
          sx={{
            width: { xs: "max-content", sm: "100%" },
            display: "flex",
            background: palette.gradients.custom,
            borderRadius: "12px",
            padding: "2px",
          }}
        >
          {category.map((item, index) => {
            return (
              <Stack key={index} spacing={2}>
                <Button
                  onClick={() => setSelected(item)}
                  sx={{
                    boxShadow: `0px 0px 0px 1px rgba(203,200,200,0.59) inset`,
                    borderRadius: "12px",
                    fontWeight: "500",
                    backgroundColor:
                      selected.name === item.name ? "#fdfdfd" : "transparent",
                    color:
                      selected.name === item.name
                        ? palette.primary.main
                        : "#fdfdfd",
                  }}
                >
                  {item.name}
                </Button>
              </Stack>
            );
          })}
        </Box>
      </Scrollbar>
      <Box my={3}>
        <InputSearch />
      </Box>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {isLoading ? (
          <Grid item xs={12} sx={{ py: 2 }}>
            <Box
              component="img"
              src="/images/loading.svg"
              alt="loading"
              height="48px"
              sx={{ mx: "auto" }}
            />
          </Grid>
        ) : publicTemplates?.data?.length ? (
          publicTemplates?.data?.map((item, index) => (
            <Grid key={index} item xs={6} sm={3} md={3}>
              <Paper
                sx={{
                  border: "1px solid #CBC8C8",
                  padding: 1,
                }}
              >
                <Box
                  mb={1}
                  component="img"
                  alt="item"
                  src={
                    item.message_template.fileUrl
                      ? item.message_template.fileUrl
                      : "/cloud/empty.png"
                  }
                  sx={{
                    width: "100%",
                    height: "140px",
                    objectFit: "cover",
                  }}
                />
                <Typography
                  style={{ fontWeight: "600", color: palette.primary.main }}
                >
                  {item.template_name}
                </Typography>
                <Typography color="#777777" fontSize="12px">
                  {item.message_template?.message.length > 48
                    ? item.message_template?.message.substr(0, 48) + "..."
                    : item.message_template?.message.substr(0, 48)}
                </Typography>
                <Box
                  mt={3}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {item?.is_copied ? (
                    <BasicButton disabled>
                      <Typography color="#fff" fontSize="11px">
                        Installed
                      </Typography>
                    </BasicButton>
                  ) : (
                    <BasicButton
                      onClick={() =>
                        handleCopyMessageTemplate({
                          userId: user?.id,
                          id: item.id,
                        })
                      }
                    >
                      <Typography color="#fff" fontSize="11px">
                        Install
                      </Typography>
                    </BasicButton>
                  )}
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sx={{ py: 2 }}>
            <Typography fontSize="0.875rem">Data Not Found</Typography>
          </Grid>
        )}
      </Grid>
      <Box mb={6} />
    </Container>
  );
}
