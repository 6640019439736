import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import Router from "./routes";
import socket from "./services/socket";
import { getDevices } from "./store/device/deviceAction";
import { authLogout, getUser } from "./store/user/userAction";
import "./style/global.css";
import ThemeProvider from "./theme";
import {
  purchaseGoogleAnalythicEvent,
  viewContentGoogleAnalythicEvent,
} from "./utils/google-analythic";
import {
  purchaseMetaPixelEvent,
  viewContentMetaPixelEvent,
} from "./utils/meta-pixel";

export default function App() {
  const queryClient = new QueryClient();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const register = queryParams.get("register");

  const { devices } = useSelector((state) => state.device);

  const { authToken, login, user, plan } = useSelector((state) => state.user);

  const handleAuth = async () => {
    if (authToken) {
      await dispatch(getUser());
    } else {
      await dispatch(authLogout());
    }
  };

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp",
      },
    };
    await dispatch(getDevices(body));
  };

  useEffect(() => {
    if (login) {
      handleGetDevices();
    }
  }, [login]);

  useEffect(() => {
    handleAuth();
  }, [authToken]);

  useEffect(() => {
    if (devices.length) {
      const devicesRaw = [];

      devices.map((item) => {
        devicesRaw.push(item.device_key);
      });

      socket.emit("device-list", { id: socket.id, devices: devicesRaw });
    }
  }, [devices]);

  useEffect(() => {
    viewContentMetaPixelEvent();
    viewContentGoogleAnalythicEvent();

    if (register === "success") {
      purchaseMetaPixelEvent();
      purchaseGoogleAnalythicEvent();
    }
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
        <Toaster position="top-right" reverseOrder={false} />
      </QueryClientProvider>
    </>
  );
}
