import { Box } from "@mui/material";
import Iconify from "../../../../../../../components/Iconify";

export default function ListsModal({
    isListModalOpen,
    setIsListModalOpen,
    title = 'All Options',
    children
}) {
    return (
        <>
            {isListModalOpen &&
                <Box
                    // className={isListModalOpen ? "list-show" : null}
                    sx={{
                        position: "fixed",
                        // bottom: "-1000px",
                        // maxHeight: '700px',
                        left: '25px',
                        zIndex: 100,
                        right: '25px',
                        width: "auto",
                        pb: 4,
                        backgroundColor: "rgba(231, 255, 219, 1)",
                        borderBottomLeftRadius: '30px',
                        borderBottomRightRadius: '30px',
                        // overflowY: 'auto'
                    }}
                    style={{
                        bottom: '25px'
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "6px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "rgba(231, 255, 219, 1)",
                            px: 2,
                            pt: 1,
                            pb: 0.5,
                            mx: 0,
                            fontSize: 16,
                            width: "100%",
                            textAlign: "center",
                            color: "#1FA2FF",
                        }}
                    >
                        <Box sx={{ width: "12px" }}></Box>
                        <Box>{title}</Box>
                        <Box
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                // setListModal(null);
                                setIsListModalOpen(!isListModalOpen);
                            }}
                        >
                            <Iconify icon="eva:close-outline" sx={{ mt: 1 }} />
                        </Box>
                    </Box>

                    {/* children */}
                    <Box
                        className="wrap-modal-device-frame"
                        sx={{
                            maxHeight: '600px',
                            overflowY: 'auto',
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            }
        </>
    )
}