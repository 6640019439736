import { Box } from "@mui/material"
import RenderIconOnButtons from "./RenderIconOnButtons"

export default function ButtonsComponent({
    detail,
    clickOptions,
    isViewsOnLists = false
}) {
    let newData = []
    if (detail?.buttons?.length > 0) {
        detail.buttons.forEach(item => {
            item.actions.forEach(act => {
                newData.push(act)
            })
        })
    }
    const actions = !isViewsOnLists ? newData.filter((item, i) => newData.length > 3 ? i < 2 : i < 3) : newData
    return (
        <>
            {actions.length > 0 && actions.map((item, i) => {
                return (
                    <Box
                        key={i}
                        sx={{ mt: 1, display: "flex" }}
                        style={{
                            borderTop: '1px solid rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "6px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(231, 255, 219, 1)",
                                px: 2,
                                pt: 1,
                                pb: 0.5,
                                mx: 0.5,
                                fontSize: 16,
                                width: "100%",
                                textAlign: "center",
                                color: "#1FA2FF",
                                minHeight: "36px",
                                cursor: 'pointer'
                            }}
                        >
                            <RenderIconOnButtons
                                icon={item?.icon}
                            />
                            {item?.text}
                        </Box>
                    </Box>
                )
            })}

            {!isViewsOnLists && newData.length > 3 &&
                <Box
                    sx={{ mt: 1, display: "flex" }}
                    style={{
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)'
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: "6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(231, 255, 219, 1)",
                            px: 2,
                            pt: 1,
                            pb: 0.5,
                            mx: 0.5,
                            fontSize: 16,
                            width: "100%",
                            textAlign: "center",
                            color: "#1FA2FF",
                            minHeight: "36px",
                            cursor: 'pointer'
                        }}
                        onClick={clickOptions}
                    >
                        <RenderIconOnButtons
                            icon='ic:round-list'
                        />
                        See all options
                    </Box>
                </Box>
            }
        </>
    )
}