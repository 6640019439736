import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getShippingGateways } from "../../../../store/catalog/catalogAction";

export default function RateDetails({ products }) {
  const dispatch = useDispatch();
  const { shippingGateways } = useSelector((state) => state.catalog);
  const [isLoading, setIsLoading] = useState(false);

  const handleGetShippingGateways = async () => {
    setIsLoading(true);
    await dispatch(getShippingGateways());
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetShippingGateways();
  }, [dispatch]);

  const tableCell = ["Target", "Weight", "Price", "Conditional"];

  return (
    <div>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: "#a6a6a6",
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => (
                <TableCell align="left" key={index}>
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Typography color="#FDFDFD">{item}</Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : shippingGateways?.rates ? (
              Object.keys(shippingGateways?.rates).map((gatewayKey) =>
                shippingGateways?.rates[gatewayKey]?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Box
                        ml={2}
                        display="flex"
                        justifyContent="space-between"
                        sx={{ maxWidth: "160px", overflow: "hidden" }}
                      >
                        <Typography ml={2} fontSize={14} fontWeight="600">
                          {item?.target}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Box
                        ml={2}
                        display="flex"
                        justifyContent="space-between"
                        sx={{ maxWidth: "160px", overflow: "hidden" }}
                      >
                        <Typography ml={2} fontSize={14} fontWeight="600">
                          {item?.array[0]?.weight}
                        </Typography>
                        {console.log("Weight:", item?.target, item?.array[0]?.price, item?.array[0]?.weight)}
                      </Box>
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.array[0]?.price}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        whiteSpace: 'nowrap'
                      }}>
                      <Grid container gap={1} component="ul">
                        {item?.array?.map((conditional, iConditional) => (
                          <Grid
                            key={iConditional}
                            item={0}
                            component="li"
                            sx={{
                              listStyleType: 'none'
                            }}
                          >
                            <Box
                              alignItems="center"
                              display="flex"
                            >
                              <Typography fontSize={30} mr={0.5}>
                                •
                              </Typography>
                              <Typography fontSize={14}>
                                {conditional.weight}
                              </Typography>
                              <Typography mx={0.3} fontSize={14}>
                                {'<'}
                              </Typography>
                              <Typography fontSize={14}>
                                {conditional.price}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              )
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
