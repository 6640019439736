import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../components/containers/Container";
import { getDailyMessageCount } from "../../../store/statistic/statisticAction";

export default function AppMessageSendByTeam() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { dailyMessageCount } = useSelector((state) => state.statistic);

  const handleGetDailyMessageCount = async (data) => {
    await dispatch(getDailyMessageCount(data));
  };

  const state = {
    series: [
      {
        name: "Actual",
        data: [
          {
            x: "monday",
            y: dailyMessageCount?.data?.["monday"] ?? 0,
          },
          {
            x: "tuesday",
            y: dailyMessageCount?.data?.["tuesday"] ?? 0,
          },
          {
            x: "wednesday",
            y: dailyMessageCount?.data?.["wednesday"] ?? 0,
          },
          {
            x: "thursday",
            y: dailyMessageCount?.data?.["thursday"] ?? 0,
          },
          {
            x: "friday",
            y: dailyMessageCount?.data?.["friday"] ?? 0,
          },
          {
            x: "saturday",
            y: dailyMessageCount?.data?.["saturday"] ?? 0,
          },
          {
            x: "sunday",
            y: dailyMessageCount?.data?.["sunday"] ?? 0,
          },
        ],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          columnWidth: "60%",
        },
      },
      colors: ["#00E396"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual", "Expected"],
        markers: {
          fillColors: ["#00E396", "#775DD0"],
        },
      },
    },
  };

  useEffect(() => {
    if (user) {
      const data = {
          userId: user.id,
      };

      handleGetDailyMessageCount(data);
    }
  }, [user]);

  return (
    <Container title="Message sent by team">
      <ReactApexChart
        options={state?.options}
        series={state?.series}
        type="bar"
        height={350}
      />
    </Container>
  );
}
