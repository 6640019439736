import { createSlice } from "@reduxjs/toolkit";
import { getAutoReplies, getAutoReplies2, getGreetingMessages } from "./marketingAction";

const initialState = {
  autoReply: null,
  autoReplies: [],
  greetingMessage: [],
  greetingMessages: [],
};

const marketingSlice = createSlice({
  name: "marketing",
  initialState: initialState,
  reducers: {
    getAutoReply: (state, { payload }) => {
      state.autoReply = payload;
    },
    getGreetingMessage: (state, { payload }) => {
      state.greetingMessage = payload;
    },
  },
  extraReducers: {
    [getAutoReplies2.fulfilled]: (state, { payload }) => {
      state.autoReplies = payload;
    },
    [getAutoReplies2.rejected]: (state, { payload }) => {
      state.autoReplies = [];
    },
    [getGreetingMessages.fulfilled]: (state, { payload }) => {
      state.greetingMessages = payload.data;
    },
    [getGreetingMessages.rejected]: (state, { payload }) => {
      state.autoReplies = [];
    },
  },
});

export const { getAutoReply, getGreetingMessage } = marketingSlice.actions;

export default marketingSlice.reducer;
