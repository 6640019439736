import { createSlice } from "@reduxjs/toolkit";
import {
  getCatalogProducts,
  getCatalogOrders,
  getPaymentGateways,
  getCatalogOrder,
  getCatalogOrdersMessage,
  getShippingGateways,
  getCatalogAddresses,
  getCatalogVouchers,
} from "./catalogAction";

const initialState = {
  catalogs: null,
  catalogOrders: null,
  catalogOrder: null,
  catalogOrdersMessage: null,
  paymentGateways: null,
  shippingGateways: null,
  catalogAddresses: null,
  catalogVouchers: null,
};

const catalogSlice = createSlice({
  name: "catalog",
  initialState: initialState,
  extraReducers: {
    [getCatalogProducts.fulfilled]: (state, { payload }) => {
      state.catalogs = payload;
    },
    [getCatalogProducts.rejected]: (state) => {
      state.catalogs = null;
    },
    [getCatalogOrders.fulfilled]: (state, { payload }) => {
      state.catalogOrders = payload;
    },
    [getCatalogOrders.rejected]: (state) => {
      state.catalogOrders = null;
    },
    [getCatalogOrder.fulfilled]: (state, { payload }) => {
      state.catalogOrder = payload;
    },
    [getCatalogOrder.rejected]: (state) => {
      state.catalogOrder = null;
    },
    [getCatalogOrdersMessage.fulfilled]: (state, { payload }) => {
      state.catalogOrdersMessage = payload;
    },
    [getCatalogOrdersMessage.rejected]: (state) => {
      state.catalogOrdersMessage = null;
    },
    [getPaymentGateways.fulfilled]: (state, { payload }) => {
      state.paymentGateways = payload;
    },
    [getPaymentGateways.rejected]: (state) => {
      state.paymentGateways = null;
    },
    [getShippingGateways.fulfilled]: (state, { payload }) => {
      state.shippingGateways = payload;
    },
    [getShippingGateways.rejected]: (state) => {
      state.shippingGateways = null;
    },
    [getCatalogAddresses.fulfilled]: (state, { payload }) => {
      state.catalogAddresses = payload;
    },
    [getCatalogAddresses.rejected]: (state) => {
      state.catalogAddresses = null;
    },
    [getCatalogVouchers.fulfilled]: (state, { payload }) => {
      state.catalogVouchers = payload;
    },
    [getCatalogVouchers.rejected]: (state) => {
      state.catalogVouchers = null;
    },
  },
  reducers:{
    resetCatalogs:(state)=>{
      state.catalogs = null
    }
  }
});

export const {resetCatalogs} = catalogSlice.actions;

export default catalogSlice.reducer;
