import { Box } from "@mui/material";
import React, { useState } from "react";

export default function TeamInboxPage() {
  const [showIframe, setShowIframe] = useState(true); // Set to true to show iframe by default

  return (
    <>
      {showIframe && ( // If showIframe is true, show the iframe
        <Box sx={{ width: "100%", height: "800px" }}>
          <iframe
            src="https://team.realm.chat"
            width="100%"
            height="100%"
            frameBorder="0"
            title="Team Realm Chat"
          ></iframe>
        </Box>
      )}
    </>
  );
}
