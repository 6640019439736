import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import { unsubscribe } from "../../../../store/plan/planAction";
import palette from "../../../../theme/palette";
import CustomModal from "../../../../components/modal/CustomModal";
import FormContainer from "../../../../components/containers/FormContainer";

export default function Unsubscribe() {
  const dispatch = useDispatch();

  const { plan } = useSelector((state) => state.user);
  const { devices } = useSelector((state) => state.device);

  const [isOpenUnsubscribeModal, setIsOpenUnsubscribeModal] = useState(false);

  const handleUnsubscribe = async () => {
    toast.loading("Please wait...");

    await dispatch(unsubscribe({})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        window.location.reload();
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  return (
    <>
      <Container minHeight="256px" title="Plan">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            fontSize={28}
            fontWeight="600"
            sx={{ color: palette.primary.main }}
          >
            {plan?.name}
          </Typography>
          <Typography my={2} fontSize={16} color="#7B7B7B" fontWeight="500">
            Expired : {plan?.expired_at}
          </Typography>
          <BasicButton
            title="Unsubscribe Plan"
            sx={{
              background: "#F15A28",
              color: "#ff",
              ":hover": {
                backgroundColor: "#F15A28",
                opacity: 0.75,
              },
            }}
            onClick={() => setIsOpenUnsubscribeModal(!isOpenUnsubscribeModal)}
          />
          <Box
            sx={{
              display: "flex",
              mt: 2,
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background:
                    "linear-gradient(173deg, rgba(147,223,147,1) 0%, rgba(33,194,193,1) 100%)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"mdi:user"}
                  color="#fff"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Device
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {plan?.total_device} / {plan?.device}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", m: 1 }}>
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background:
                    "linear-gradient(173deg, rgba(147,223,147,1) 0%, rgba(33,194,193,1) 100%)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"mdi:customer-service"}
                  color="#fff"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Customer Service
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                {plan?.total_cs} / {plan?.customer_service}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      <CustomModal open={isOpenUnsubscribeModal}>
        <FormContainer title="Area you sure unsubscribe this plan?">
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              onClick={() => setIsOpenUnsubscribeModal(!isOpenUnsubscribeModal)}
            >
              Cancel
            </Button>
            <BasicButton
              title="Submit"
              type="submit"
              onClick={() => handleUnsubscribe()}
            />
          </Stack>
        </FormContainer>
      </CustomModal>
    </>
  );
}
