import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from 'react-hot-toast';
import { verifyRegister } from "../../store/user/userAction";

export default function VerifySection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { user } = useSelector((state) => state.user);

  const handleVerifyRegister = async () => {
    toast.loading("Please wait...");

    const data = {
      token: searchParams.get("token"),
    };

    await dispatch(verifyRegister(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        navigate("/auth/signin");
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (searchParams.get("token")) {
      handleVerifyRegister();
    }
  }, [searchParams.get("token")]);

  return (
    <>
      <Box
        sx={{
          padding: { xs: "16px", md: "24px" },
          width: "100%",
          maxWidth: "480px",
          background: "#FFFFFF",
          borderRadius: "16px",
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ margin: "0 0 8px 0" }}>
            <Box
              component="img"
              src="/images/realm-logo.svg"
              sx={{ height: "48px" }}
            />
          </Box>
          <Typography
            variant="h4"
            sx={{ fontWeight: "700", color: "#131313", textAlign: "center" }}
          >
            Verify Register
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: "16px",
              fontWeight: "400",
              color: "#7B7B7B",
              textAlign: "center",
            }}
          >
            Verify your registration with ease and continue to use our
          </Typography>
        </Box>
      </Box>
    </>
  );
}
