import ContainerWithSomeButton from "../../../../../components/containers/ContainerWithSomeButton";
import Connect from "../../Connect";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import BasicButton from "../../../../../components/button/BasicButton";

export default function ConnectInstagram() {
    // const dispatch = useDispatch()

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // const [typeToken, setTypeToken] = useState(null);
    // const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        // Load the JavaScript SDK asynchronously
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");

        // Facebook Login with JavaScript SDK

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: "342618604307492",
                autoLogAppEvents: false,
                xfbml: true,
                cookie: false,
                status: false,
                version: "v19.0",
            });

            // // Add this line
            // window.FB.getLoginStatus(function(response) {
            //     statusChangeCallback(response);
            // });
        };
    }, []);

    
    const lists = [
        'Admin access for Facebook Business Manager',
        'Instagram Business / Instagram Professional',
        'Verified Facebook Business Manager Account'
    ]

    function clickBack() {
        navigate('/dashboard/channels')
    }

    function launchFacebookLogin() {
        const configId = "293297583751724";
        const clientId = "342618604307492";
        const redirectUri = "https://app.komers.io/dashboard/channels";
        const scope = "instagram_basic,instagram_manage_comments,pages_show_list,pages_read_engagement,instagram_manage_messages,catalog_management,pages_messaging";
        const extras = encodeURIComponent(JSON.stringify({ setup: { channel: "IG_API_ONBOARDING" } }));
    
        const url = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${clientId}&display=page&extras=${extras}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}&config_id=${configId}`;
    
        window.location.href = url;
    }
    

    // useEffect(() => {
    //     if (accessToken) {
    //         formik.handleSubmit()
    //     }
    // }, [accessToken])

    useEffect(() => {
        window?.FB?.logout(function (response) {
            console.log("FB logout:", response);
        });
    }, [])

    return (
        <ContainerWithSomeButton>
            <Connect
                icon="instagram.png"
                title="Instagram"
                isBackBtn={true}
                clickBack={clickBack}
            />

            <Box
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    },
                    marginRight: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    }
                }}
                mb={4}
            >
                <Typography
                    fontSize={16}
                    color={'#7B7B7B'}
                    fontWeight={600}
                    mt={4}
                    mb={3}
                >
                    What will you need:
                </Typography>

                {lists.map((item, i) => (
                    <Box
                        key={i}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        mb={1}
                    >
                        <Box
                            sx={{
                                height: 7,
                                width: 7,
                                borderRadius: 500,
                                background: '#64D88B'
                            }}
                            mr={1}
                        />
                        <Typography
                            fontWeight={400}
                            fontSize={14}
                            color={'#7B7B7B'}
                            textAlign="start"
                        >
                            {item}
                        </Typography>
                    </Box>
                ))}

                <Typography
                    fontSize={16}
                    fontWeight={600}
                    my={4}
                >
                    How to connect?
                </Typography>

                <BasicButton
                    title="Login With Instagram"
                    disabled={loading}
                    onClick={launchFacebookLogin}
                />
            </Box>
        </ContainerWithSomeButton>
    )
}