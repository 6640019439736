//component
import { Box } from "@mui/material";
import React, { useState } from "react";
import Scrollbar from "../../../components/Scrollbar";
import ContainerWithSomeButton from "../../../components/containers/ContainerWithSomeButton";
import CustomerServiceList from "./List";
import AddCustomerService from "./add-customer-service";
import EditCustomerService from "./edit-customer-service";

export default function CustomerService() {
  const [addCustomerService, setAddCustomerService] = useState(false);
  const [editCustomerService, setEditCustomerService] = useState(false);

  return (
    <>
      {addCustomerService ? (
        <AddCustomerService
          setAddCustomerService={() =>
            setAddCustomerService(!addCustomerService)
          }
        />
      ) : editCustomerService ? (
        <EditCustomerService
          setAddCustomerService={() =>
            setEditCustomerService(!editCustomerService)
          }
        />
      ) : (
        <ContainerWithSomeButton
          title="Customer Service"
          button1
          button1Title="Add Customer Service"
          button1OnClick={() => setAddCustomerService(true)}
        >
          <Box mb={3} />
          <Scrollbar>
            <CustomerServiceList
              setEditCustomerService={() =>
                setEditCustomerService(!editCustomerService)
              }
            />
          </Scrollbar>
        </ContainerWithSomeButton>
      )}
    </>
  );
}
