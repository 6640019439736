import {
  Button as Paper,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../components/button/BasicButton";
import FormContainer from "../../../components/containers/FormContainer";
import {
  getCloudFile,
  uploadCloudFile,
} from "../../../store/cloudFile/cloudFileAction";
import palette from "../../../theme/palette";

export default function Upload({ onCancle }) {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadCloudFile = async () => {
    const data = new FormData();

    data.append("file", file);

    toast.loading("Please wait...");

    await dispatch(uploadCloudFile(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });

    onCancle();
    handleGetCloudFile();
  };

  const handleGetCloudFile = async () => {
    setIsLoading(true);

    const data = {
      params: {
        type: null,
        search: null,
        page: 1,
      },
    };

    await dispatch(getCloudFile(data));

    setIsLoading(false);
  };

  return (
    <FormContainer title="Upload File">
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Paper
                component="label"
                variant="contained"
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                }}
              >
                <Typography fontSize={{ xs: 12, sm: 14 }}>
                  Choose File
                </Typography>
                <input
                  hidden
                  accept="*"
                  multiple
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </Paper>
              <Typography fontSize={{ xs: 12, sm: 14 }} sx={{ mx: 2 }}>
                {file ? file.name : null}
              </Typography>
            </InputAdornment>
          ),
        }}
        disabled
        variant="outlined"
        size="small"
      />
      <Box mb={3} />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Paper onClick={onCancle}>Cancel</Paper>
        <BasicButton
          disabled={isLoading}
          title="Submit"
          onClick={() => handleUploadCloudFile()}
        />
      </Stack>
    </FormContainer>
  );
}
