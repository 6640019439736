import { Box, CardMedia, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import formatWppMarkdown from "whatsapp-markdown";
import Iconify from "../Iconify";

function MessageGenerateToHtml({
  message
}) {
  return <p
    className="chat-message"
    style={{ whiteSpace: "pre-line", paddingLeft: '16px', paddingRight: '16px' }}
    dangerouslySetInnerHTML={{ __html: message }}
  ></p>
}

function HeaderDocument({ detail }) {
  const header = detail?.components?.find(val => val?.type == 'HEADER')

  return (
    <>
      {
        header?.example?.header_handle?.length > 0 &&
        header?.format == 'DOCUMENT' &&
        <Box sx={{ mb: 1, px: 1 }}>
          <Box
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#cdf0bb",
              width: "100%",
              borderRadius: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Iconify
                icon="bxs:file-pdf"
                height="32px"
                width="32px"
                color="#FFFF"
              />
              <Typography
                fontSize="14px"
                color="rgba(55, 55, 55, 0.7)"
                sx={{ ml: 1 }}
              >
                file.pdf
              </Typography>
            </Box>
            <Iconify
              icon="el:download"
              height="28px"
              width="28px"
              color="#3b82f6"
            />
          </Box>
        </Box>
      }
    </>
  )
}

function HeaderImage({ detail }) {
  const header = detail?.components?.find(val => val?.type == 'HEADER')

  return (
    <>
      {header?.example?.header_handle?.length > 0 &&
        header?.format == 'IMAGE' &&
        <Box sx={{ mb: 1, px: 1, }}>
          <CardMedia
            component="img"
            height="140px"
            width="100%"
            image={header.example.header_handle[0]}
            loading="lazy"
            sx={{ objectFit: "cover", borderRadius: "8px" }}
          />
        </Box>
      }
    </>
  )
}

function HeaderText({ detail }) {
  const header = detail?.components?.find(val => val?.type == 'HEADER')

  return (
    <>
      {
        header?.format == 'TEXT' &&
        <Box sx={{ mb: 1, px: 2, }}>
          <Typography
            style={{
              fontWeight: '700'
            }}
          >
            {header.text}
          </Typography>
        </Box>
      }
    </>
  )
}

function RenderHeaderMessage({
  detail
}) {
  const header = detail?.components?.find(val => val?.type == 'HEADER')
  return (
    <>
      <HeaderDocument detail={detail} />
      <HeaderImage detail={detail} />
      <HeaderText detail={detail} />
    </>
  )
}

function RenderFooter({ detail }) {
  const footer = detail?.components?.find((val) => val?.type === "FOOTER");
  return (
    <>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 2 }}
      >
        <Box>
          <Typography
            fontSize="14px"
            color="rgba(55, 55, 55, 0.7)"
          >
            {footer?.text ?? 'Powered by realm.chat'}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            fontSize="8px"
            mr={1}
            color="rgba(55, 55, 55, 0.7)"
          >
            {new Date().getHours() +
              ":" +
              new Date().getMinutes()}
          </Typography>
          <img src="/static/icons/double_check.svg" />
        </Box>
      </Box>
    </>
  )
}

function RenderIconOnButtons({ icon }) {
  return (
    <Iconify
      icon={icon}
      sx={{ mr: 1 }}
    />
  )
}

function RenderButtonMessage({ detail }) {
  const button = detail?.components?.find((val) => val?.type === "BUTTONS")

  return (
    <>
      {button?.buttons?.length > 0 && button?.buttons.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{ mt: 1, display: "flex" }}
            style={{
              borderTop: '1px solid rgba(0, 0, 0, 0.2)'
            }}
          >
            <Box
              sx={{
                borderRadius: "6px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(231, 255, 219, 1)",
                px: 2,
                pt: 1,
                pb: 0.5,
                mx: 0.5,
                fontSize: 16,
                width: "100%",
                textAlign: "center",
                color: "#1FA2FF",
                minHeight: "36px",
                cursor: 'pointer'
              }}
            >
              {item.type == 'QUICK_REPLY' &&
                <RenderIconOnButtons
                  icon="ic:baseline-reply"
                />
              }
              {item.type == 'URL' &&
                <RenderIconOnButtons
                  icon="ant-design:link-outlined"
                />
              }
              {item.type == 'COPY_CODE' &&
                <RenderIconOnButtons
                  icon="ant-design:copy-outlined"
                />
              }
              {item.type == 'PHONE_NUMBER' &&
                <RenderIconOnButtons
                  icon="ant-design:phone-outlined"
                />
              }

              {item.text}
              {/* {single?.formListsOther?.buttonText ?? "Choose"} */}
            </Box>
          </Box>
        )
      })}
    </>
  )
}

function RenderBodyMessage({
  detail,
  message
}) {
  return (
    <>
      <RenderHeaderMessage detail={detail} />

      <MessageGenerateToHtml message={message} />

      <RenderFooter detail={detail} />

      <RenderButtonMessage detail={detail} />
    </>
  )
}

function TxtWithButton({ single }) {
  return (
    <Box>
      {/* button */}
      {/* text with add button */}
      {single.formButtons.length === 1 &&
        <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
          <Box
            sx={{
              borderRadius: "6px",
              display: "block",
              backgroundColor: "rgba(231, 255, 219, 1)",
              px: 2,
              pt: 1,
              pb: 0.5,
              mx: 0.5,
              fontSize: 16,
              width: "100%",
              textAlign: "center",
              color: "#1FA2FF",
              minHeight: "36px",
            }}
          >
            {single.formButtons[0]?.button_title}
          </Box>
        </Box>
      }

      {single.formButtons.length === 2 &&
        <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
          <Box
            sx={{
              borderRadius: "6px",
              display: "block",
              backgroundColor: "rgba(231, 255, 219, 1)",
              px: 2,
              pt: 1,
              pb: 0.5,
              mx: 0.5,
              fontSize: 16,
              width: "50%",
              textAlign: "center",
              color: "#1FA2FF",
              minHeight: "36px",
            }}
          >
            {single?.formButtons[0].button_title}
          </Box>
          <Box
            sx={{
              borderRadius: "6px",
              display: "block",
              backgroundColor: "rgba(231, 255, 219, 1)",
              px: 2,
              pt: 1,
              pb: 0.5,
              mx: 0.5,
              fontSize: 16,
              width: "50%",
              textAlign: "center",
              color: "#1FA2FF",
              minHeight: "36px",
            }}
          >
            {single?.formButtons[1].button_title}
          </Box>
        </Box>
      }
      {single.formButtons.length === 3 &&
        <>
          <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
            <Box
              sx={{
                borderRadius: "6px",
                display: "block",
                backgroundColor: "rgba(231, 255, 219, 1)",
                px: 2,
                pt: 1,
                pb: 0.5,
                mx: 0.5,
                fontSize: 16,
                width: "50%",
                textAlign: "center",
                color: "#1FA2FF",
                minHeight: "36px",
              }}
            >
              {single?.formButtons[0].button_title}
            </Box>
            <Box
              sx={{
                borderRadius: "6px",
                display: "block",
                backgroundColor: "rgba(231, 255, 219, 1)",
                px: 2,
                pt: 1,
                pb: 0.5,
                mx: 0.5,
                fontSize: 16,
                width: "50%",
                textAlign: "center",
                color: "#1FA2FF",
                minHeight: "36px",
              }}
            >
              {single?.formButtons[1].button_title}
            </Box>
          </Box>
          <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
            <Box
              sx={{
                borderRadius: "6px",
                display: "block",
                backgroundColor: "rgba(231, 255, 219, 1)",
                px: 2,
                pt: 0,
                pb: 0.5,
                mx: 0.5,
                fontSize: 16,
                width: "100%",
                textAlign: "center",
                color: "#1FA2FF",
                minHeight: "36px",
              }}
            >
              {single?.formButtons[2].button_title}
            </Box>
          </Box>
        </>
      }
    </Box>
  )
}

function CustomTemplates({ single }) {
  return (
    <>
      {single.formTemplates.length > 0 && single.formTemplates.map((form, index) => {
        return (
          <Box
            sx={{ mt: 1, display: "flex", mx: -0.5 }}
            key={index}
          >
            {form.type == 'add-button' &&
              <Box
                sx={{
                  borderRadius: "6px",
                  display: "block",
                  backgroundColor: "rgba(231, 255, 219, 1)",
                  px: 2,
                  pt: 1,
                  pb: 0.5,
                  mx: 0.5,
                  fontSize: 16,
                  width: "100%",
                  textAlign: "center",
                  color: "#1FA2FF",
                  minHeight: "36px",
                }}
              >
                {form.displayTxt}
              </Box>
            }
            {form.type == 'add-website-button' &&
              <Box
                sx={{
                  borderRadius: "6px",
                  backgroundColor: "rgba(231, 255, 219, 1)",
                  px: 2,
                  pt: 1,
                  pb: 0.5,
                  mx: 0.5,
                  fontSize: 16,
                  width: "100%",
                  color: "#1FA2FF",
                  minHeight: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon="fluent:share-20-filled"
                  color="#1FA2FF"
                  sx={{ mr: 0.5 }}
                />
                {form.displayTxt}
              </Box>
            }
            {form.type == 'add-call-button' &&
              <Box
                sx={{
                  borderRadius: "6px",
                  backgroundColor: "rgba(231, 255, 219, 1)",
                  px: 2,
                  pt: 1,
                  pb: 0.5,
                  mx: 0.5,
                  fontSize: 16,
                  width: "100%",
                  color: "#1FA2FF",
                  minHeight: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon="fluent:call-24-filled"
                  color="#1FA2FF"
                  sx={{ mr: 0.5 }}
                />
                {form.displayTxt}
              </Box>
            }
          </Box>
        )
      })}
    </>
  )
}

function MessageTypeLists({
  single,
  setIsListModalOpen,
  isListModalOpen
}) {
  return (
    <>
      {single.formList.length > 0 &&
        <Box
          sx={{
            display: "flex",
            mx: -0.5,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(231, 255, 219, 1)",
              px: 2,
              pt: 1,
              pb: 0.5,
              mx: 0.5,
              fontSize: 16,
              width: "100%",
              textAlign: "center",
              color: "#1FA2FF",
              minHeight: "36px",
            }}
            onClick={() => {
              setIsListModalOpen(!isListModalOpen)
            }}
          // onClick={() => {
          //   setListModal({
          //     formLists: single?.formLists,
          //     formListsButton: single?.formListsButton,
          //     formListsOther: single?.formListsOther,
          //   });
          //   setIsListModalOpen(!isListModalOpen);
          // }}
          >
            <Iconify
              icon="ant-design:unordered-list-outlined"
              sx={{ mr: 1 }}
            />
            {single?.buttonText?.length > 0 ? single.buttonText : "Choose"}
          </Box>
        </Box>}
    </>
  )
}

function ListsModal({
  isListModalOpen,
  setIsListModalOpen,
  single
}) {
  return (
    <>
      <Box
        className={isListModalOpen ? "list-show" : null}
        sx={{
          position: "absolute",
          bottom: "-1000px",
          left: 0,
          zIndex: 100,
          right: 0,
          width: "100%",
          pb: 4,
          backgroundColor: "rgba(231, 255, 219, 1)",
        }}
      >
        <Box
          sx={{
            borderRadius: "6px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "rgba(231, 255, 219, 1)",
            px: 2,
            pt: 1,
            pb: 0.5,
            mx: 0.5,
            fontSize: 16,
            width: "100%",
            textAlign: "center",
            color: "#1FA2FF",
          }}
        >
          <Box sx={{ width: "12px" }}></Box>
          <Box>{single.buttonText.length > 0 ? single.buttonText : "Choose"}</Box>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              // setListModal(null);
              setIsListModalOpen(!isListModalOpen);
            }}
          >
            <Iconify icon="eva:close-outline" sx={{ mt: 1 }} />
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "rgba(231, 255, 219, 1)",
            px: 2,
            pt: 1,
            pb: 0.5,
            mx: 0.5,
            width: "100%",
            textAlign: "center",
            color: "#1FA2FF",
          }}
        >
          {single.formList.length > 0 && single.formList.map((form, index) => {
            return (
              <Box sx={{ pb: 1 }} key={index}>
                <Box sx={{ pb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography fontSize={14}>{form.list}</Typography>
                  </Box>
                  <Box
                    sx={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  ></Box>
                </Box>

                {/* list child */}
                {form.childList.length > 0 && form.childList.map((childForm, childIndex) => {
                  return (
                    <>
                      <Box
                        sx={{ pb: 1, cursor: "pointer" }}
                        key={childIndex}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            pb: 1,
                          }}
                        >
                          <Typography
                            fontSize={18}
                            sx={{ mb: -0.5 }}
                          >
                            {childForm.title}
                          </Typography>
                          <Typography fontSize={14} color="#60b8f7">
                            {childForm.desc}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            borderBottom:
                              "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        ></Box>
                      </Box>
                    </>
                  )
                })}
              </Box>
            )
          })}
        </Box>
      </Box>
    </>
  )
}

export const DeviceWabaFrame = ({
  detail,
  message,
  single,
  isDisableComponent = false,
  children,
  modalElement,
  minScrollChat = 430
}) => {

  const [isListModalOpen, setIsListModalOpen] = React.useState(false)

  useEffect(() => {
    formatWppMarkdown(".chat-message");
  }, [message]);

  const minScrollHeight = minScrollChat

  const middleMobile = useMediaQuery('(min-width:440px)');
  const miniMobile = useMediaQuery('(min-width:350px)')

  const totalButtons = detail?.components?.find(item=>item?.type == 'BUTTONS')
  const header = detail?.components?.find(val => val?.type == 'HEADER')

  const calculationHeight = useMemo(()=>{
    if(message){
      if(totalButtons?.buttons?.length > 0 && header?.example?.header_handle?.length > 0){
        return message.length + (totalButtons.buttons.length * 10) + 20
      }else if(totalButtons?.buttons?.length > 0){
        return message.length + (totalButtons.buttons.length * 10)
      }else if(header?.example?.header_handle?.length > 0){
        return message.length + 20
      }
      return message.length
    }
    return 0
  }, [
    detail,
    totalButtons,
    header,
    message
  ])

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box>
        <DeviceFrameset
          device="iPhone X"
          color="black"
          zoom={middleMobile ? '0.75' : miniMobile ? '0.60' : '0.50'}
        >
          <Box>
            <Box
              sx={{
                backgroundColor: "#008069",
                height: "72px",
                position: "absolute",
                zIndex: 100,
                width: "100%",
              }}
            >
              <Box
                component="img"
                src="/images/statusbar.svg"
                sx={{ pt: "2px", width: "100%" }}
              />
              <Box
                component="img"
                src="/images/nav-wa.svg"
                sx={{ mt: "0px", width: "100%" }}
              />
            </Box>

            <Box
              sx={{
                backgroundImage: `url(${"/images/bg-wa.svg"})`,
                display: "flex",
                justifyContent: "end",
              }}
              style={{
                // height: calculationHeight < minScrollHeight ? '860px' : '740px',
                // position: calculationHeight < minScrollHeight ? 'static' : 'absolute',
                // overflowY: calculationHeight < minScrollHeight ? 'hidden' : 'auto',
                height: '815px',
                // position: 'absolute',
                overflowY: 'auto',
                paddingTop: '80px',
                bottom: 0,
              }}
            >
              <Box
                style={{
                  // margin: calculationHeight < minScrollHeight ? '' : '0 12px 0 0',
                  // bottom: calculationHeight < minScrollHeight ? 32 : 0,
                  // right: 12,
                  // position: calculationHeight < minScrollHeight ? "absolute" : 'static',
                  marginRight: '12px',
                  width: "calc(100% - 100px)",
                }}
              >
                <Box style={{
                  // paddingBottom: calculationHeight < minScrollHeight ? '0' : '32px'
                  paddingBottom: '32px'
                }}>
                  <Box>
                    <Box sx={{ mt: 1, display: "flex", mx: -0.5 }}>
                      <Box
                        sx={{
                          borderRadius: "6px",
                          display: "block",
                          backgroundColor: "rgba(231, 255, 219, 1)",
                          // px: 2,
                          pt: 1,
                          pb: 0.5,
                          mx: 0.5,
                          fontSize: 16,
                          width: "100%",
                          minHeight: "36px",
                        }}
                      >
                        {/* title in list type */}
                        {/* {single.titleInListType &&
                          <Box mb={0.25}>
                            <Typography
                              fontSize="18px"
                              color="#3b4a54"
                              fontWeight={700}
                            >
                              {single.titleInListType}
                            </Typography>
                          </Box>
                        } */}
                        {!isDisableComponent &&
                          <RenderBodyMessage
                            detail={detail}
                            message={message}
                          />
                        }
                        {children}
                      </Box>
                    </Box>
                  </Box>

                  {/* <TxtWithButton single={single} /> */}

                  {/* text with custom template */}
                  {/* <CustomTemplates single={single} /> */}

                  {/* message type = list */}
                  {/* <MessageTypeLists
                    single={single}
                    isListModalOpen={isListModalOpen}
                    setIsListModalOpen={setIsListModalOpen}
                  /> */}
                </Box>
              </Box>

              {/* modal */}
              {modalElement}
              {/* <ListsModal
                  isListModalOpen={isListModalOpen}
                  setIsListModalOpen={setIsListModalOpen}
                  single={single}
                /> */}
            </Box>
          </Box>
        </DeviceFrameset>
      </Box>
    </Box>
  );
};
