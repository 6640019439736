import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getAllTopup = createAsyncThunk(
  "topup/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/topup/list", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const requestTopup = createAsyncThunk(
  "topup/request",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/topup/request", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
