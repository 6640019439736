import { Icon } from '@iconify/react';
import { Box, Button, Typography } from '@mui/material';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { GeolocateControl, Map, Marker } from 'react-map-gl';
import palette from '../../theme/palette';
// import './mapbox-gl-geocoder.css';

export default function MapLocation({
    mapRef,
    coordinateValue,
    setCoordinateValue,
    onResetCoordinate,
    setOnResetCoordinate,
    prevCoordinate,
    setLoadOpenMap
}) {
    const TOKEN_MAP = "pk.eyJ1IjoiaGFzc2FuZnVhZDk2IiwiYSI6ImNscW52dzFjZjNrbjkybG5weTlzeWE1YjkifQ.Js_MYMEj0uJFQMDOsajJhw"
    const mapStyle = 'mapbox://styles/hassanfuad96/clqo067kz00t401qr55kr7q6r'

    useEffect(() => {
        if (!prevCoordinate?.latitude) {
            navigator.geolocation.getCurrentPosition((pos) => {
                setCoordinateValue({
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    zoom: 14,
                });
                toast.dismiss()
                setOnResetCoordinate(false)
                setLoadOpenMap(false)
            }, () => { }, { enableHighAccuracy: true })
        }
    }, [])

    function clickCoordinateValue(e) {
        setCoordinateValue({
            longitude: e.lngLat.lng,
            latitude: e.lngLat.lat,
            zoom: 14
        })
        setOnResetCoordinate(true)
    }

    function clickCurrentLocationOnGPS(e) {
        navigator.geolocation.getCurrentPosition((pos) => {
            setCoordinateValue({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: 14,
            });
        }, () => { }, { enableHighAccuracy: true })
        setOnResetCoordinate(true)
    }

    function tryOpenMap() {
        toast.dismiss()
        toast.loading('Open GPS for location, please check your GPS and allow browser to access location')
        navigator.geolocation.getCurrentPosition((pos) => {
            setCoordinateValue({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: 14,
            });
            toast.dismiss()
        }, () => { toast.dismiss() }, { enableHighAccuracy: true })
    }

    function resetCoordinate() {
        toast.dismiss()
        toast.loading('Reset your location. please wait...')
        setLoadOpenMap(true)
        if (prevCoordinate?.latitude) {
            setCoordinateValue({
                latitude: prevCoordinate.latitude,
                longitude: prevCoordinate.longitude,
                zoom: 14,
            });
            setTimeout(() => {
                toast.dismiss()
                setOnResetCoordinate(false)
                setLoadOpenMap(false)
            }, 3000)
            return
        }
        navigator.geolocation.getCurrentPosition((pos) => {
            setCoordinateValue({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: 14,
            });
            toast.dismiss()
            setOnResetCoordinate(false)
            setLoadOpenMap(false)
        }, () => { toast.dismiss() }, { enableHighAccuracy: true })
    }

    return (
        <>
            {coordinateValue?.latitude ?
                <>
                    <Map
                        ref={mapRef}
                        mapLib={import('mapbox-gl')}
                        mapboxAccessToken={TOKEN_MAP}
                        initialViewState={coordinateValue}
                        style={{ width: '100%', height: '300px', borderRadius: '10px' }}
                        mapStyle={mapStyle}
                        onClick={clickCoordinateValue}
                    >
                        <GeolocateControl
                            positionOptions={{ enableHighAccuracy: true }}
                            trackUserLocation={true}
                            onTrackUserLocationStart={clickCurrentLocationOnGPS}
                            showUserLocation={true}
                            position='top-right'
                        />
                        <Marker
                            longitude={coordinateValue.longitude}
                            latitude={coordinateValue.latitude}
                        >
                        </Marker>
                    </Map>

                    <Box
                        display="flex"
                        justifyContent="center"
                        mt={2}
                    >
                        <Button
                            style={{
                                marginTop: '4px',
                            }}
                            color="error"
                            onClick={resetCoordinate}
                            disabled={!onResetCoordinate}
                            sx={{
                                background: onResetCoordinate ? '#FF4842' : 'transparent',
                                color: "#fdfdfd",
                                borderRadius: "12px",
                            }}
                            variant={onResetCoordinate ? 'contained' : undefined}
                        >
                            <Typography
                                fontSize={{ xs: 12, sm: 14 }}
                            >
                                Reset Coordinate
                            </Typography>
                        </Button>
                    </Box>
                </>
                :
                <Box
                    width="100%"
                    height="200px"
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    bgcolor="#F6F7FF"
                    borderRadius="8px"
                >
                    <Icon
                        icon="line-md:my-location-off-loop"
                        width={40}
                    />
                    <Button
                        style={{
                            marginTop: '4px'
                        }}
                        onClick={tryOpenMap}
                    >
                        <Typography
                            fontSize={{ xs: 12, sm: 14 }}
                            fontWeight={500}
                        >
                            No Location. Try again
                        </Typography>
                    </Button>
                </Box>
            }
        </>
    )
}