import React from "react";
import CustomModal from "../../modal/CustomModal";
import SubmitFormContainer from "../../containers/SubmitFormContainer";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  addLinkedDevice,
  addWabaCatalog,
  getDevices,
} from "../../../store/device/deviceAction";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ComboBox from "../../select/ComboBox";
import { toggleModalInstagram } from "../../../store/device/deviceSlice";
import BasicInput from "../../input/BasicInput";

export default function ModalInstagram() {
  const dispatch = useDispatch();
  const { device_key, modalInstagram } = useSelector(
    (state) => state.device
  );

  const formik = useFormik({
    initialValues: {
      device_key: device_key,
      catalog_id: "",
    },
    onSubmit: async (value, { resetForm }) => {
      const formData = {
        ...value,
        device_key: device_key,
      };
      toast.dismiss()
      toast.loading('Please wait...')
      const result = await dispatch(addWabaCatalog(formData))
      if (result.type === 'waba-catalog/add/rejected') {
        toast.dismiss()
        toast.error(result.payload.message)
      } else {
        toast.dismiss()
        toast.success(result.payload.message)
        const body = {
          params: {
            platform: "instagram",
          },
        };
        dispatch(getDevices(body));
      }
      resetForm()
      dispatch(toggleModalInstagram(false));
      // await toast.promise(dispatch(addWabaCatalog(formData)), {
      //   loading: "Please wait...",
      //   success: (res) => {
      //     resetForm();
      //     const body = {
      //       params: {
      //         platform: "instagram",
      //       },
      //     };
      //     dispatch(getDevices(body));
      //     dispatch(toggleModalInstagram(false));
      //     return res.payload.message;
      //   },
      //   error: (error) => "Error, try again later",
      // });
    },
  });
  return (
    <CustomModal open={modalInstagram}>
      <SubmitFormContainer
        onSubmit={formik.handleSubmit}
        title="Add Catalog ID"
        onCancel={() => dispatch(toggleModalInstagram(false))}
      >
        <Box height={"auto"}>
          <BasicInput
            fullWidth
            disableMargin
            id="catalog-id"
            placeholder="Type your Catalog ID ..."
            {...formik.getFieldProps("catalog_id")}
          />
        </Box>
      </SubmitFormContainer>
    </CustomModal>
  );
}
