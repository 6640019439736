import { useEffect, useMemo, useState } from "react"
import { Grid } from "@mui/material"
import Custom from "./category/marketing/custom/Custom"
import ProductMessage from "./category/marketing/product-message/ProductMessage"
import { DeviceWabaFrame } from "../../../../../components/containers/DeviceWabaFrame"
import formatWppMarkdown from "whatsapp-markdown"
import HeaderComponent from "./device-frame-body/header"
import BodyComponent from "./device-frame-body/body"
import FooterComponent from "./device-frame-body/footer"
import ButtonsComponent from "./device-frame-body/buttons"
import ListsModal from "./device-frame-body/modal-lists"

export default function EditTemplate({
    currentCategory,
    radioInputValue,
    detail,
    setDetail,
    headerValue,
    setHeaderValue,
    contentAddButton,
    setContentAddButton,
    textHeader,
    setTextHeader,
    totalVariableHeader,
    setTotalVariableHeader
}) {
    // header media
    const [headerMedia, setHeaderMedia] = useState({
        type: 'media',
        extension: 'image'
    })
    // body
    const [bodyValue, setBodyValue] = useState('')
    const [totalVariables, setTotalVariables] = useState([])
    // footer
    const [footerValue, setFooterValue] = useState('')

    const [isListModalOpen, setIsListModalOpen] = useState(false)

    const isCustomFromMarketing = currentCategory?.category == 'Marketing' && radioInputValue?.value == 'custom'
    const isProductMessageFromMarketing = currentCategory?.category == 'Marketing' && radioInputValue?.value == 'product_messages'

    const bodyVariableRegex = /{{\d+}}/g
    const stringRegex = /\s/g

    useEffect(() => {
        formatWppMarkdown("#chat-message");
    }, [detail])

    useEffect(()=>{
        return ()=>setDetail({})
    }, [])

    function clickOptions() {
        setIsListModalOpen(!isListModalOpen)
    }

    // const miniMobile = useMediaQuery('(max-width:415px)')

    const totalButton = useMemo(() => {
        let newData = []
        if (detail?.buttons?.length > 0) {
            detail.buttons.forEach(item => {
                item.actions.forEach(act => {
                    newData.push(act)
                })
            })
        }
        return newData
    }, [detail])

    return (
        <Grid
            container
            spacing={4}
        // sx={{
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     flexWrap: 'wrap',
        // }}
        >
            {/* content form with category */}
            <Grid
                item
                xs={12}
                md={7.5}
            >
                {/* marketing content category */}
                {isCustomFromMarketing &&
                    <Custom
                        contentAddButton={contentAddButton}
                        setContentAddButton={setContentAddButton}
                        headerValue={headerValue}
                        setHeaderValue={setHeaderValue}
                        textHeader={textHeader}
                        setTextHeader={setTextHeader}
                        totalVariableHeader={totalVariableHeader}
                        setTotalVariableHeader={setTotalVariableHeader}
                        headerMedia={headerMedia}
                        setHeaderMedia={setHeaderMedia}
                        bodyValue={bodyValue}
                        setBodyValue={setBodyValue}
                        totalVariables={totalVariables}
                        setTotalVariables={setTotalVariables}
                        footerValue={footerValue}
                        setFooterValue={setFooterValue}
                        detail={detail}
                        setDetail={setDetail}
                        bodyVariableRegex={bodyVariableRegex}
                        stringRegex={stringRegex}
                    />
                }
                {isProductMessageFromMarketing &&
                    <ProductMessage />
                }
            </Grid>

            {/* content view */}
            <Grid
                // sx={{
                //     marginLeft: miniMobile ? -10 : 0,
                //     zIndex: 22
                // }}
                item xs={12} md={4.4}
            >
                <DeviceWabaFrame
                    message={detail?.body?.text ?? ''}
                    isDisableComponent={true}
                    modalElement={
                        <ListsModal
                            isListModalOpen={isListModalOpen}
                            setIsListModalOpen={setIsListModalOpen}
                        >
                            <ButtonsComponent
                                detail={detail}
                                clickOptions={clickOptions}
                                isViewsOnLists={true}
                            />
                        </ListsModal>
                    }
                    // minScrollChat={totalButton.length < 3 ? 700 - (detail?.footer?.text?.length ?? 0) - (detail?.header_text?.variable?.length ?? 30) - (detail?.header_media?.type ? 100 : 0) : detail?.header_media?.type ? 520 - 60 : detail?.header_text?.variable ? 520 - detail.header_text.variable.length : 520}
                >
                    <HeaderComponent
                        detail={detail}
                        headerValue={headerValue}
                        headerMedia={headerMedia}
                    />
                    <BodyComponent
                        detail={detail}
                    />
                    <FooterComponent detail={detail} />
                    <ButtonsComponent
                        detail={detail}
                        clickOptions={clickOptions}
                    />
                </DeviceWabaFrame>
            </Grid>
        </Grid>
    )
}