import { Box, Card, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import BasicButton from "../button/BasicButton";
import TextButton from "../button/TextButton";

export default function SubmitFormContainer({ children, title, width, onCancel, onClickSubmit, onSubmit }) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: 2.5,
        alignItems: "center",
        height: "100%",
        width: width,
        backgroundColor: palette.secondary.main,
      }}
    >
      <Box display="flex" justifyContent="start" mb={3}>
        <Typography fontSize={{ xs: 20, sm: 24 }} fontWeight="700" color="#131313">
          {title}
        </Typography>
      </Box>
      <form onSubmit={onSubmit}>
        {children}
        <Box mb={3} />
        <Box sx={{ display: "flex", gap: 1, justifyContent: "end" }}>
          <TextButton title="Cancel" onClick={onCancel} />
          <BasicButton type="submit" title="Submit" onClick={onClickSubmit} />
        </Box>
      </form>
    </Card>
  );
}
