import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Iconify from "../components/Iconify";
import Page from "../components/Page";
import palette from "../theme/palette";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          {/* <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography> */}
          <Typography variant="h3" paragraph>
            Oopss.. this page is not available.
          </Typography>
          <Box
            component="img"
            src="/static/illustrations/404.svg"
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            variant={"contained"}
            sx={{
              background: palette.gradients.custom,
              borderRadius: "12px",
              color: "#fdfdfd",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Iconify
              icon="bx:medal"
              sx={{
                width: 24,
                height: 24,
                ml: 1,
              }}
            />
            <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
              Upgrade Package Now!
            </Typography>
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
