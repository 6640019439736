import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";
import axios from "axios";

export const getDevices = createAsyncThunk(
  "get-devices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("device", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListDevicesForCombobox = createAsyncThunk(
  "get-list-devices-for-combobox",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("device", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


export const getListCategory = async () => {
  const response = await axios.get("https://api.country.realm.chat/categories.php");
  return response.data.categories;
}

export const getDeviceProfile = async (device_key) => {
  const response = await clientApi.post("device/profile", {
    device_key: device_key
  });
  return response.data;
}


export const addLinkedDevice = createAsyncThunk(
  "linked-device/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/linked-device", data);
      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addWabaCatalog = createAsyncThunk(
  "waba-catalog/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/save-catalog", data);
      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// export const getDeviceProfile = createAsyncThunk(
//   "device/get-device-profile",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await clientApi.post("device/profile", data);
//       if (response.data.result) {
//         return response.data;
//       } else {
//         return rejectWithValue(response.data);
//       }
//     } catch (err) {
//       return rejectWithValue(err);
//     }
//   }
// );

export const deviceUpdate = createAsyncThunk(
  "device/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/update", data);
      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);


export const getIsBusiness = createAsyncThunk(
  "device/is-business",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`device/is-business/${data}`);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addDevice = createAsyncThunk(
  "device/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const unlinkDevice = createAsyncThunk(
  "device/unlink",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/unlink-device", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logoutDevice = createAsyncThunk(
  "device/logout",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/device/logout", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  "device/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("device/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStatusDevice = createAsyncThunk(
  "status-account",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/status-account", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStatusDeviceClient = createAsyncThunk(
  "status-account/client",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/device/status", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getQrDevice = createAsyncThunk(
  "device/get-qr",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/device/get-qr", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkIsBusiness = createAsyncThunk(
  "device/is-business",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get("/device/is-business", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOTPOfficialId = createAsyncThunk(
  "device/get-official-otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/device/get-official-otp", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
