import {
  Button,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import Iconify from "../../../../components/Iconify";
import {
  getContacts,
  importContactFromExcel,
} from "../../../../store/contact/contactAction";

import palette from "../../../../theme/palette";

export default function FromFile({ onCancel }) {
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleImportContactFromExcel = async () => {
    setIsLoading(true);
    toast.loading("Please wait...");

    const data = new FormData();

    data.append("file", file);

    await dispatch(importContactFromExcel(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        onCancel();
        handleGetContacts();
      } else {
        toast.dismiss();
        toast.success(res.payload.message);
      }

      setIsLoading(false);
    });
  };

  const handleGetContacts = async () => {
    const data = {
      params: {
        search: null,
      },
    };

    await dispatch(getContacts(data));
  };

  return (
    <FormContainer title="Import from Excel File">
      <TextField
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button
                component="label"
                variant="contained"
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                }}
              >
                <Typography fontSize={{ xs: 12, sm: 14 }}>
                  Choose File
                </Typography>
                <input
                  hidden
                  accept=".xlsx, .xls, .csv"
                  multiple
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </Button>
              <Typography fontSize={{ xs: 12, sm: 14 }} sx={{ mx: 2 }}>
                {file ? file.name : null}
              </Typography>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        size="small"
      />
      <Box
        component={Link}
        href="/contact/example import contact.xlsx"
        underline="none"
        alignItems="center"
        gap={1}
        mt={2}
        display="flex"
        sx={{ cursor: "pointer", color: "#29c4be" }}
      >
        <Iconify icon="ant-design:download-outlined" />
        <Typography fontSize={13} color="#29c4be">
          Download template here
        </Typography>
      </Box>
      <Box mb={3} />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          disabled={isLoading}
          title="Submit"
          onClick={() => handleImportContactFromExcel()}
        />
      </Stack>
    </FormContainer>
  );
}
