import React, { useEffect, useState } from "react";
import {
  Table,
  Paper,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  TableBody,
  Stack,
} from "@mui/material";
import palette from "../../../../theme/palette";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAutoReply,
  getAutoReplies,
  getAutoReplies2,
} from "../../../../store/marketing/marketingAction";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import { getAutoReply } from "../../../../store/marketing/marketingSlice";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const tableCell = [
  "Reply Id",
  "Keyword",
  "Reply",
  "Pattern Matching",
  "Action",
];

export default function ListMessage(props) {
  const dispatch = useDispatch();

  const { autoReplies } = useSelector((state) => state.marketing);

  const [isLoading, setIsLoading] = useState(false);

  const IconList = (data) => {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonTooltip
          title="Edit"
          handleClick={() => {
            dispatch(getAutoReply(data.item));
            props.setEditNewKeyword();
          }}
          icon="akar-icons:edit"
        />
        <ButtonTooltip
          title="Delete"
          handleClick={() => {
            handleDeleteAutoReply({ id: `${data.item.id}` });
          }}
          icon="fluent:delete-24-regular"
          color="#F15A28"
        />
      </Stack>
    );
  };

  const handleGetAutoReplies2 = async (data) => {
    setIsLoading(true);

    await dispatch(getAutoReplies2(data));

    setIsLoading(false);
  };

  const handleDeleteAutoReply = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteAutoReply(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        const data = {
          page: props.page,
          device_key: props.device?.device_key ?? null
        };

        handleGetAutoReplies2(data);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    const data = {
      page: props.page,
      device_key: props.device?.device_key ?? null
    };

    handleGetAutoReplies2(data);
  }, [props.page, props.device]);

  return (
    <>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: palette.gradients.custom,
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => {
                return (
                  <TableCell align="left" key={index}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography color="#FDFDFD">{item}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : autoReplies?.data?.length ? (
              autoReplies?.data?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">
                    {JSON.stringify(item?.keywords)?.length > 48
                      ? JSON.stringify(item?.keywords)?.substr(0, 48) + "..."
                      : JSON.stringify(item?.keywords)?.substr(0, 48)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "400px" }}>
                    {" "}
                    {item?.message?.length > 48
                      ? item?.message?.substr(0, 48) + "..."
                      : item?.message?.substr(0, 48)}
                  </TableCell>
                  <TableCell align="left">
                    {item?.is_equal === false ? "Message Contain" : "Exact Match"}
                  </TableCell>
                  <TableCell align="left">
                    <IconList item={item} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(props.page) > 1) {
                props.setPage(parseInt(props.page) - 1);
              }
            }}
            disabled={parseInt(props.page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (parseInt(props.page) < autoReplies?.pagination?.last_page) {
                props.setPage(parseInt(props.page) + 1);
              }
            }}
            disabled={parseInt(props.page) >= autoReplies?.pagination?.last_page}
          />
        </Box>
      </Box>
    </>
  );
}
