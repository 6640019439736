import { Button } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getContacts,
  importContactFromDevice,
} from "../../../../store/contact/contactAction";
import { getDevices } from "../../../../store/device/deviceAction";

export default function FromDevice({ onCancel }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      device: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        device_key: value.device,
      };

      await dispatch(importContactFromDevice(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
          onCancel();
          resetForm();
          handleGetContacts();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  const handleGetContacts = async () => {
    const data = {
      params: {
        search: null,
      },
    };

    await dispatch(getContacts(data));
  };

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba",
      },
    };

    await dispatch(getDevices(body));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  return (
    <FormContainer title="Import Contact from Device">
      <Box sx={{ position: "relative", height: "68px" }}>
        <Box
          sx={{ position: "fixed", width: "calc(100% - 40px)", zIndex: "1400" }}
        >
          <ComboBox
            fullWidth
            disableMargin
            title="Channel"
            placeholder="Please select channel"
            options={devices}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => {
              formik.setFieldValue("device", value.device_key);
            }}
          />
        </Box>
      </Box>
      <Box mb={3} />
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          disabled={isLoading}
          title="Submit"
          onClick={formik.handleSubmit}
        />
      </Stack>
    </FormContainer>
  );
}
