import { Box, Typography } from "@mui/material";
import ComboBox from "../../../../components/select/ComboBox";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import { DataGrid } from "@mui/x-data-grid";
import BasicButton from "../../../../components/button/BasicButton";

export default function TableLists({
    currentDevices,
    wabaDevice,
    changeDevice,
    loadingFetch,
    clickFetch,
    total,
    setStatusPage,
    page,
    rows,
    columns,
    setPage,
    loadWabaTemplate,
    last_page
}) {
    return (
        <>
            <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%" }}>
                    <ComboBox
                        fullWidth
                        disableMargin
                        placeholder="Please select channel"
                        options={currentDevices}
                        value={wabaDevice}
                        getOptionLabel={(option) => option?.name}
                        onChange={changeDevice}
                    >

                    </ComboBox>
                </Box>
                <Box sx={{ ml: 2 }}>
                    <ButtonWithIcon
                        title="Fetch"
                        icon="mdi:widget-timeline-variant"
                        disabled={loadingFetch || !wabaDevice}
                        onClick={clickFetch}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography fontSize={16} fontWeight="600" sx={{ mb: -0.5, ml: 1 }}>
                        Templates (Total {total})
                    </Typography>
                </Box>
                <ButtonWithIcon
                    title="Create template"
                    icon="formkit:add"
                    disabled={wabaDevice?.device_key ? false : true}
                    onClick={() => setStatusPage({
                        status: 'CREATE-TEMPLATE',
                        title: 'New Message Template'
                    })}
                />
            </Box>
            <Box sx={{ mb: 2 }} />
            <Box sx={{ mb: 2, height: "1080px", width: "100%" }}>
                <DataGrid
                    hideFooter
                    getRowId={(row) => row?.id}
                    getRowHeight={() => 100}
                    pageSize={10}
                    page={page - 1}
                    rows={rows}
                    columns={columns}
                />
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <Box sx={{ mx: 1 }}>
                    <BasicButton
                        title="<"
                        onClick={() => {
                            if (parseInt(page) > 1) {
                                setPage(parseInt(page) - 1);
                                loadWabaTemplate(wabaDevice?.device_key, page - 1)
                            }
                        }}
                        disabled={parseInt(page) === 1}
                    />
                </Box>
                <Box sx={{ mx: 1 }}>
                    <BasicButton
                        title=">"
                        onClick={() => {
                            if (parseInt(page) < last_page) {
                                setPage(parseInt(page) + 1);
                                loadWabaTemplate(wabaDevice?.device_key, page + 1)
                            }
                        }}
                        disabled={parseInt(page) >= last_page}
                    />
                </Box>
            </Box>
        </>
    )
}