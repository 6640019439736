//component
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import BasicInput from "../../../../components/input/BasicInput";
import { requestTopup } from "../../../../store/topup/topupAction";
import List from "./List";

export default function Credits() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      amount: 0,
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        amount: value.amount,
      };

      await dispatch(requestTopup(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          window.location.href = res.payload.data?.url_direct;
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  return (
    <>
      <ContainerWithSomeButton title="Recharge Credits">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography
                fontSize={20}
                fontWeight="700"
                sx={{ color: "#4ED44E" }}
              >
                Credits:
              </Typography>
              <Typography fontSize={24} fontWeight="700">
                {user?.currency} {parseFloat(user?.wallet).toFixed(2)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{ mb: 2, display: "flex", alignItems: "center", gap: 2 }}
              >
                <Typography fontSize={16} fontWeight="700">
                  {user?.currency}
                </Typography>
                <BasicInput
                  type="number"
                  title=""
                  {...formik.getFieldProps("amount")}
                  sx={{ width: "120px" }}
                />
              </Box>
              <BasicButton
                title="Recharge Now"
                type="submit"
                disabled={isLoading}
                onClick={formik.handleSubmit}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mb={3} />
        <Scrollbar>
          <List />
        </Scrollbar>
      </ContainerWithSomeButton>
    </>
  );
}
