import { useDispatch, useSelector } from "react-redux";
import Connect from "../Connect";
import NoAccount from "../NoAccount";
import { Box, Typography } from "@mui/material";
import ModalInstagram from "../../../../components/DeviceItem/sections/ModalInstagram";
import { useNavigate } from "react-router-dom";
import DeviceChannel from "../device-channel/DeviceChannel";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { addDevice } from "../../../../store/device/deviceAction";
import { useEffect } from "react";
import { resetDevice, resetStatusDevice, updateInstagramDevice } from "../../../../store/device/deviceSlice";
import { setRedirectPath } from "../../../../store/channel/channelSlice";

export default function Instagram({
    loading
}) {
    const { devices } = useSelector((state) => state.device)

    const hash = window.location.hash
    const queryResultConnect = '#access_token'

    const access_token = hash?.replace(`${queryResultConnect}=`, '')
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            platform: 'instagram',
        },
        onSubmit: async (value, { resetForm }) => {
            let data = null
            const current_access_token = access_token?.split('&data_access_expiration_time=')[0]

            dispatch(resetDevice())
            dispatch(resetStatusDevice())

            if (current_access_token) {
                data = {
                    platform: value.platform,
                    access_token: current_access_token
                }
            }

            dispatch(addDevice(data)).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();
                    dispatch(updateInstagramDevice(true))
                } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                    dispatch(updateInstagramDevice(true))
                }
            });
            navigate('/dashboard/channels', { replace: true })
            dispatch(setRedirectPath('instagram'))
        }
    })

    useEffect(() => {
        if (hash?.includes(queryResultConnect)) {
            formik.handleSubmit()
        }
    }, [hash])

    const navigate = useNavigate()

    function clickConnect() {
        navigate('/dashboard/channels/connect-instagram')
    }

    return (
        <>
            <ModalInstagram />
            <Connect
                icon="instagram.png"
                title="Instagram"
                textBtn="Connect"
                clickConnect={clickConnect}
            />

            {!loading && devices?.length > 0 ?
                <Box
                    p={2}
                >
                    <Typography
                        fontSize={{ xs: 16, sm: 16, md: 16 }}
                        fontWeight={600}
                        color={"#131313"}
                        mt={3}
                        mb={2}
                    >
                        Accounts
                    </Typography>

                    {devices.map((item, i) => {
                        return (
                            <DeviceChannel
                                key={i}
                                item={item}
                                icon="instagram.png"
                                platform="instagram"
                            />
                        )
                    })}
                </Box> :
                <>
                    {!loading ?
                        <NoAccount
                            desc={`Click "Connect" to sync your first Instagram account`}
                        /> :
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: 400
                            }}
                        >
                            <Box
                                component="img"
                                width={40}
                                height={40}
                                src="/images/loading.svg"
                            />
                        </Box>
                    }
                </>
            }
        </>
    )
}