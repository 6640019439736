import { Button, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getContacts,
  importContactFromGroup,
} from "../../../../store/contact/contactAction";
import { getDevices } from "../../../../store/device/deviceAction";
import { getGroups } from "../../../../store/group/groupAction";

export default function FromWhatsappGroup({ onCancel }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);
  const { groups } = useSelector((state) => state.group);

  const [isLoading, setIsLoading] = useState(false);

  const [device, setDevice] = useState(null);

  const formik = useFormik({
    initialValues: {
      device: "",
      target: "",
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");
      setIsLoading(true);

      const data = {
        device_key: value.device,
        target: value.target,
      };

      await dispatch(importContactFromGroup(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
          onCancel();
          resetForm();
          handleGetContacts();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  const handleGetContacts = async () => {
    const data = {
      params: {
        search: null,
      },
    };

    await dispatch(getContacts(data));
  };

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetGroups = async (data) => {
    await dispatch(getGroups(data));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (device) {
      handleGetGroups({ accountid: device.device_key });
    }
  }, [device]);

  const tabletSize = useMediaQuery('(min-width:680px)')

  return (
    <Box
    style={{
      height: tabletSize ? 'auto' : '90vh'
    }}
    >
      <FormContainer title="Import Contact Whatsapp">
        <Box mb={2}>
          <Box sx={{ position: "relative", height: "68px" }}>
            <Box
              sx={{
                position: "fixed",
                width: "calc(100% - 40px)",
                zIndex: "1500",
              }}
            >
              <ComboBox
                fullWidth
                disableMargin
                title="Channel"
                placeholder="Please select channel"
                options={devices}
                getOptionLabel={(option) => option?.name}
                onChange={(e, value) => {
                  formik.setFieldValue("device", value.device_key);
                  setDevice(value);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={2}>
          <Box sx={{ position: "relative", height: "68px" }}>
            <Box
              sx={{
                position: "fixed",
                width: "calc(100% - 40px)",
                zIndex: "1400",
              }}
            >
              <ComboBox
                fullWidth
                disableMargin
                title="Group"
                placeholder="Please select group"
                options={groups}
                getOptionLabel={(option) => option?.name}
                sx={{ zIndex: "1300" }}
                onChange={(e, value) => {
                  formik.setFieldValue("target", value.id);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={3} />
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onCancel}>Cancel</Button>
          <BasicButton
            disabled={isLoading}
            title="Submit"
            onClick={formik.handleSubmit}
          />
        </Stack>
      </FormContainer>
    </Box>
  );
}
