import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import BasicButton from "../../../../components/button/BasicButton";
import Container from "../../../../components/containers/Container";
import CustomModal from "../../../../components/modal/CustomModal";
import {
  deleteCatalogOrderMessage,
  getCatalogOrdersMessage,
} from "../../../../store/catalog/catalogAction";
import palette from "../../../../theme/palette";
import Form from "./Form";
import UpdateForm from "./UpdateForm";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const tableCell = ["No", "Device Key", "Type", "Message", "Action"];

export default function OrderMessage() {
  const dispatch = useDispatch();

  const { catalogOrdersMessage } = useSelector((state) => state.catalog);

  const [isLoading, setIsLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [page, setPage] = useState(1);

  const [detail, setDetail] = useState(null);

  const _openAddModal = () => setOpenAddModal(!openAddModal);
  const _onCloseAddModal = () => setOpenAddModal(!openAddModal);

  const _openUpdateModal = () => setOpenUpdateModal(!openUpdateModal);
  const _onCloseUpdateModal = () => setOpenUpdateModal(!openUpdateModal);

  const handleGetCatalogOrdersMessage = async () => {
    setIsLoading(true);

    const data = {
      params: {
        page: page,
      },
    };

    await dispatch(getCatalogOrdersMessage(data));

    setIsLoading(false);
  };

  const handleDeleteCatalogOrderMessage = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteCatalogOrderMessage(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message)

        handleGetCatalogOrdersMessage();
      } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });
  };

  useEffect(() => {
    handleGetCatalogOrdersMessage();
  }, [page]);

  return (
    <Container
      withButton
      buttonTitle="Add Message"
      title="Order Message"
      buttonIcon="carbon:add-alt"
      onClick={_openAddModal}
    >
      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <Table aria-label="simple table">
            <TableHead
              style={{
                background: palette.gradients.custom,
              }}
            >
              <TableRow>
                {tableCell.map((item, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography color="#FDFDFD">{item}</Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={tableCell.length} align="center">
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  </TableCell>
                </TableRow>
              ) : catalogOrdersMessage?.data?.length ? (
                catalogOrdersMessage?.data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {page === 1
                        ? index + 1
                        : catalogOrdersMessage?.paginations?.per_page * 10 -
                        9 +
                        index}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.device_key}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.type}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      {item?.message?.length >= 12
                        ? `${item?.message?.substring(0, 24)}...`
                        : item?.message}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                      <Stack direction="row" spacing={1}>
                        <ButtonTooltip
                          title="Edit"
                          handleClick={() => {
                            setDetail(item);
                            _openUpdateModal();
                          }}
                          icon="akar-icons:edit"
                        />
                        <ButtonTooltip
                          title="Delete"
                          handleClick={() => {
                            handleDeleteCatalogOrderMessage({
                              message_id: item.message_id,
                            });
                          }}
                          icon="fluent:delete-24-regular"
                          color="#F15A28"
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={tableCell.length}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (
                parseInt(page) < catalogOrdersMessage?.paginations?.last_page
              ) {
                setPage(parseInt(page) + 1);
              }
            }}
            disabled={
              parseInt(page) >= catalogOrdersMessage?.paginations?.last_page
            }
          />
        </Box>
      </Box>

      <CustomModal open={openAddModal}>
        <Form onCancel={_onCloseAddModal} />
      </CustomModal>
      <CustomModal open={openUpdateModal}>
        <UpdateForm onCancel={_onCloseUpdateModal} detail={detail} />
      </CustomModal>
    </Container>
  );
}
