import { Navigate, useRoutes } from "react-router-dom";
import AuthRoute from "./components/route/AuthRoute";
import ProtectedRoute from "./components/route/ProtectedRoute";
import AuthLayout from "./layouts/auth";
import DashboardLayout from "./layouts/dashboard";
import CloudStorage from "./pages/CloudStorage";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Integration from "./pages/Integration";
import Logout from "./pages/Logout";
import Marketing from "./pages/Marketing";
import Page404 from "./pages/Page404";
import Plan from "./pages/Plan";
import Report from "./pages/Report";
import ResetPassword from "./pages/ResetPassword";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import CustomerService from "./pages/CustomerService";
import TeamInboxPage from "./pages/TeamInbox";
import TemplateMessage from "./pages/TemplateMessage";
import WhatsappShop from "./pages/WhatsappShop";
import Verify from "./pages/Verify";
import Account from "./pages/Account";
import DeviceItemUpdate from "./components/DeviceItem/pages/DeviceItemUpdate";
import Channel from "./pages/Channel";
import ConnectChannel from "./pages/ConnectChannels";
import TeamInbox from "./pages/CustomerService";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: "/dashboard",
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [
        {path: "channels", element: <Channel/>},
        {path: 'channels/:connect_channel', element: <ConnectChannel/>},
        {
          path: "app",
          element: <Dashboard />,
        },
        { path: "app/device-update/:device_key", element: <DeviceItemUpdate /> },
        { path: "contacts", element: <Contact /> },
        { path: "report", element: <Report /> },
        { path: "cloud-storage", element: <CloudStorage /> },
        { path: "whatsapp-shop", element: <WhatsappShop /> },
        { path: "marketings", element: <Marketing /> },
        { path: "template-message", element: <TemplateMessage /> },
        { path: "customer-service", element: <CustomerService /> },
        { path: "team-inbox", element: <TeamInboxPage /> },
        { path: "integration", element: <Integration /> },
        { path: "plan", element: <Plan /> },
        { path: "account", element: <Account /> },
        { path: "logout", element: <Logout /> },
      ],
    },
    {
      path: "/auth",
      element: <Navigate to="/auth/signin" replace />,
    },
    {
      path: "/auth",
      element: <AuthRoute element={<AuthLayout />} />,
      children: [
        { path: "signup", element: <SignUp /> },
        { path: "signin", element: <SignIn /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "verify", element: <Verify /> },
      ],
    },

    {
      path: "/404",
      element: <Page404 />,
    },

    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
