import { createSlice } from "@reduxjs/toolkit";
import { getAllTopup } from "./topupAction";

const initialState = {
  topup: null,
  topups: [],
};

const topupSlice = createSlice({
  name: "topup",
  initialState: initialState,
  reducers: {
    gettopup: (state, { payload }) => {
      state.topup = payload;
    },
  },
  extraReducers: {
    [getAllTopup.fulfilled]: (state, { payload }) => {
      state.topups = payload;
    },
    [getAllTopup.rejected]: (state, { payload }) => {
      state.topups = [];
    },
  },
});

export const { gettopup } = topupSlice.actions;

export default topupSlice.reducer;
