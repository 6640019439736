import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomSelect from "../../../components/select/CustomSelect";

function AppDropDown({ justTitle, title }) {
  return (
    <Box
      bgcolor="transparent"
      alignItems="center"
      py={1}
      mb={2}
      borderRadius={1}
      display="flex"
      width="100%"
    >
      <Typography color="#7B7B7B" fontWeight="400" fontSize="20px">
        {title}
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      {/* {justTitle ? null : (
        <CustomSelect width={{ xs: 128, sm: 177 }} label="Last 7 days" />
      )} */}
    </Box>
  );
}

export default AppDropDown;
