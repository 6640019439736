import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import BasicInput from "../../../../components/input/BasicInput";
import { createCustomerService } from "../../../../store/customer-service/customerServiceAction";

export default function AddCustomerService(props) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      const body = Object.assign(value, {
        phone: `${value?.phone}}`,
      });

      await dispatch(createCustomerService(body)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          props.setAddCustomerService();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    },
  });

  return (
    <SaveCancelContainer
      title="Add New Customer Service"
      buttonCancelTitle="Cancel"
      buttonSaveTitle="Submit"
      onCancel={() => props.setAddCustomerService()}
      onSave={formik.handleSubmit}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Name"
            placeholder="Enter name"
            {...formik.getFieldProps("name")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Username"
            placeholder="Enter username"
            value={formik.values.username}
            onChange={(e) => {
              formik.setFieldValue(
                "username",
                e.target.value?.replace(/\s/g, "")
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Email"
            placeholder="Enter email"
            {...formik.getFieldProps("email")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            type="number"
            title="Phone"
            placeholder="Enter phone"
            {...formik.getFieldProps("phone")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Password"
            placeholder="Enter password"
            {...formik.getFieldProps("password")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <BasicInput
            fullWidth
            disableMargin
            title="Password Confirmation"
            placeholder="Enter password confirmation"
            {...formik.getFieldProps("password_confirmation")}
          />
        </Grid>
      </Grid>

      <Box mb={16} />
    </SaveCancelContainer>
  );
}
