import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getCountryCodes = createAsyncThunk(
  "country/codes",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api.country.realm.chat/region.php",
        data
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRegions = createAsyncThunk(
  "country/region",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api.country.realm.chat/region.php",
        data
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getStates = createAsyncThunk(
  "country/state",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api.country.realm.chat/state.php"
      );

      const raw = Object.entries(response.data).find(
        (e) => e[0] === data.region
      );

      if (raw) {
        return raw;
      } else {
        return [];
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDistricts = createAsyncThunk(
  "country/district",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://api.country.realm.chat/district.php"
      );

      const raw = Object.entries(response.data).find(
        (e) => e[0] === data.state
      );

      if (raw) {
        return raw;
      } else {
        return [];
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
