import { createSlice } from "@reduxjs/toolkit";
import { getCloudFile } from "./cloudFileAction";

const initialState = {
  cloudFiles: [],
  storageInfo: [],
};

const cloudFileSlice = createSlice({
  name: "cloudFile",
  initialState: initialState,
  extraReducers: {
    [getCloudFile.fulfilled]: (state, { payload }) => {
      state.cloudFiles = payload;
      state.storageInfo = payload.storage_info;
    },
  },
});

export const { resetCloudFiles } = cloudFileSlice.actions;

export default cloudFileSlice.reducer;
