import { createSlice } from "@reduxjs/toolkit";
import { getGroupMembers, getGroups } from "./groupAction";

const initialState = {
  groups: [],
  groupMembers: [],
};

const groupSlice = createSlice({
  name: "group",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [getGroups.fulfilled]: (state, { payload }) => {
      state.groups = payload ?? [];
    },
    [getGroups.rejected]: (state) => {
      state.groups = [];
    },
    [getGroupMembers.fulfilled]: (state, { payload }) => {
      state.groupMembers = payload?.data;
    },
    [getGroupMembers.rejected]: (state) => {
      state.groupMembers = [];
    },
  },
});

export const { resetGroup } = groupSlice.actions;

export default groupSlice.reducer;