import { Autocomplete, Button, TextField, Typography, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import {
  addContactManual,
  getContacts,
} from "../../../../store/contact/contactAction";
import palette from "../../../../theme/palette";

const tags = ["Shopee", "Facebook", "Instagram", "Web"];

export default function FormAddContact({ onCancel }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      keyword: tags[1],
      facebook: "",
      instagram: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        name: value.name,
        phone: value.phone,
        email: value.email,
        keyword: value.keyword,
        facebook: value.facebook,
        instagram: value.instagram,
      };

      await dispatch(addContactManual(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          onCancel();
          resetForm();
          handleGetContacts();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }

        setIsLoading(false);
      });
    },
  });

  const handleGetContacts = async () => {
    const data = {
      params: {
        search: null,
      },
    };

    await dispatch(getContacts(data));
  };

  const desktopSize = useMediaQuery('(min-width:1680px)')

  return (
    <Box
    style={{
      height: desktopSize ? 'auto' : '90vh'
    }}
    >
      <FormContainer title="Add Contact">
        <BasicInput title="Name" {...formik.getFieldProps("name")} />
        <BasicInput title="Phone" {...formik.getFieldProps("phone")} />
        <BasicInput title="Email" {...formik.getFieldProps("email")} />
        <Typography fontWeight="700" mb={0.5}>
          Tags
        </Typography>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={tags}
          getOptionLabel={(option) => option}
          defaultValue={[tags[1]]}
          filterSelectedOptions
          ChipProps={{
            style: {
              backgroundColor: palette.primary.main,
              color: palette.secondary.main,
            },
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
          onChange={(e, value) =>
            formik.setFieldValue("keyword", value.toString())
          }
        />
        <Box mb={3} />
        <BasicInput title="Facebook Link" {...formik.getFieldProps("facebook")} />
        <BasicInput
          title="Instagram Link"
          {...formik.getFieldProps("instagram")}
        />
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onCancel}>Cancel</Button>
          <BasicButton
            title="Save"
            type="submit"
            disabled={isLoading}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </FormContainer>
    </Box>
  );
}
