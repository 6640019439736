import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import { getDevices } from "../../../../store/device/deviceAction";
import {
  getIntegrationWebhooks,
  updateIntegrationWebhook,
} from "../../../../store/integration/integrationAction";

const statusOptions = [
  { name: "Active", key: true },
  { name: "Inactive", key: false },
];

export default function Form({ onCancel }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      device_key: null,
      status: null,
      url: "",
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

      toast.loading("Please wait...");

      const data = {
        device_key: value.device_key?.device_key,
        url: value.url,
        status: value.status?.key,
      };

      await dispatch(updateIntegrationWebhook(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
      handleGetIntegrationWebhooks();
    },
  });

  const handleGetDevices = async () => {
    await dispatch(getDevices());
  };

  const handleGetIntegrationWebhooks = async () => {
    await dispatch(getIntegrationWebhooks({}));
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  return (
    <FormContainer title="Add New Webhook">
      <ComboBox
        fullWidth
        disableMargin
        title="Channel"
        placeholder="Please select channel"
        options={devices}
        getOptionLabel={(option) => option?.name}
        value={formik.values.device_key}
        onChange={(e, value) => {
          formik.setFieldValue("device_key", value);
        }}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        title="URL"
        placeholder="Type url in here ..."
        {...formik.getFieldProps("url")}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Status"
        placeholder="Please select status"
        options={statusOptions}
        getOptionLabel={(option) => option?.name}
        value={formik.values.status}
        onChange={(e, value) => {
          formik.setFieldValue("status", value);
        }}
      />
      <Box mb={2} />
      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          title="Save"
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
    </FormContainer>
  );
}
