import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import ButtonWithIcon from "../button/ButtonWithIcon";
import Iconify from "../Iconify";

export default function Container({
  children,
  title,
  width,
  withButton,
  buttonTitle,
  buttonDisabled,
  minHeight,
  onClick,
  buttonIcon,
  iconClose,
  onClose,
}) {
  return (
    <Card
      sx={{
        borderRadius: "10px",
        padding: 2.5,
        alignItems: "center",
        minHeight: minHeight,
        height: "100%",
        width: width,
        backgroundColor: palette.secondary.main,
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          fontSize={{ xs: 20, sm: 24 }}
          fontWeight="700"
          color="#131313"
        >
          {title}
        </Typography>
        {withButton && (
          <ButtonWithIcon
            title={buttonTitle}
            icon={buttonIcon}
            onClick={onClick}
            disabled={buttonDisabled}
          />
        )}
        {iconClose && (
          <IconButton onClick={onClose}>
            <Iconify icon="ci:close-big" />
          </IconButton>
        )}
      </Box>
      <Box mb={3} />
      {children}
    </Card>
  );
}
