import { Box, Button } from "@mui/material";
import Iconify from "../../../../../../../../../components/Iconify";
import VariableContent from "../../../../../VariableContent";

export default function HeaderText({
    addVariableHeader,
    textHeader,
    totalVariableHeader,
    changeHeaderContent
}) {
    return (
        <>
            <Box
                sx={{
                    justifyContent: 'flex-end',
                    width: '100%',
                    display: 'flex'
                }}
                mb={1}
            >
                <Button
                    variant="text"
                    sx={{
                        fontSize: {
                            xs: 12,
                            sm: 14,
                            md: 14
                        },
                        fontWeight: 500
                    }}
                    onClick={addVariableHeader}
                >
                    <Iconify icon="ic:round-plus" sx={{
                        marginRight: 0.5
                    }} /> Add variable
                </Button>
            </Box>

            {
                textHeader?.value?.includes('{{1}}') &&
                <VariableContent
                    title="Example for header content"
                    desc="To help us review your content, provide examples of variables or media in the header. Do not include customer information. Meta's hosted cloud API reviews templates and variable parameters to protect the security and integrity of our service."
                    totalVariables={totalVariableHeader}
                    changeInput={changeHeaderContent}
                />
            }
        </>
    )
}