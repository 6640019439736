import React, { useEffect, useState } from "react";
import {
  Table,
  Paper,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  TableBody,
  Stack,
} from "@mui/material";
import palette from "../../../../theme/palette";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGreetingMessage,
  getGreetingMessages,
} from "../../../../store/marketing/marketingAction";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import { getGreetingMessage } from "../../../../store/marketing/marketingSlice";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

const tableCell = [
  "Greeting Id",
  "Keyword",
  "Reply",
  "Pattern Matching",
  "Action",
];

export default function GreetingList(props) {
  const dispatch = useDispatch();

  const { greetingMessages } = useSelector((state) => state.marketing);

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const IconList = (data) => {
    return (
      <Stack direction="row" spacing={1}>
        <ButtonTooltip
          title="Edit"
          handleClick={() => {
            dispatch(getGreetingMessage(data.item));
            props.setEditGreetingMessage();
          }}
          icon="akar-icons:edit"
        />
        <ButtonTooltip
          title="Delete"
          handleClick={() => {
            handleDeleteGreetingMessage({ id: `${data.item?.id}` });
          }}
          icon="fluent:delete-24-regular"
          color="#F15A28"
        />
      </Stack>
    );
  };

  const handleGetGreetingMessages = async (data) => {
    setIsLoading(true);

    await dispatch(getGreetingMessages(data));

    setIsLoading(false);
  };

  const handleDeleteGreetingMessage = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteGreetingMessage(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message)
        const data = {
          page: page,
        };

        handleGetGreetingMessages(data);
      } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });
  };

  useEffect(() => {
    const data = {
      page: page,
    };

    handleGetGreetingMessages(data);
  }, [page]);

  return (
    <>
      <TableContainer component={Paper} style={{ backgroundColor: "#FDFDFD" }}>
        <Table aria-label="simple table">
          <TableHead
            style={{
              background: palette.gradients.custom,
            }}
          >
            <TableRow>
              {tableCell.map((item, index) => {
                return (
                  <TableCell align="left" key={index}>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent={"space-between"}
                    >
                      <Typography color="#FDFDFD">{item}</Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box
                    component="img"
                    src="/images/loading.svg"
                    alt="loading"
                    height="48px"
                    sx={{ mx: "auto" }}
                  />
                </TableCell>
              </TableRow>
            ) : greetingMessages?.length ? (
              greetingMessages?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item?.id}</TableCell>
                  <TableCell align="left">
                    {JSON.stringify(item?.keyword)}
                  </TableCell>
                  <TableCell align="left" sx={{ width: "400px" }}>
                    {JSON.stringify(item?.answer)}
                  </TableCell>
                  <TableCell align="left">
                    {item?.equals === "0" ? "Message Container" : "Exact Match"}
                  </TableCell>
                  <TableCell align="left">
                    <IconList item={item} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  Data Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box width={"100%"} display="flex" justifyContent="center" mt={2}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              if (parseInt(page) < greetingMessages?.last_page) {
                setPage(parseInt(page) + 1);
              }
            }}
            disabled={parseInt(page) >= greetingMessages?.last_page}
          />
        </Box>
      </Box>
    </>
  );
}
