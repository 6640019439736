import { Box, Button, Typography } from "@mui/material";
import Iconify from "../Iconify";
import RadioInputCategory from "./RadioInputCategory";

export default function InputCategory({
    icon,
    category,
    desc,
    inputs,
    clickCategory,
    currentCategory,
    radioInputValue,
    clickInput
}) {
    return (
        <Button
            style={{
                justifyContent: 'start',
            }}
            sx={{
                backgroundColor: currentCategory?.category == category ? '#ECF3FF' : '#F2F3F5',
                padding: '1rem',
                borderRadius: 1,
                width: '100%',
                marginBottom: 1
            }}
            onClick={clickCategory}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {/* main */}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    {/* icon */}
                    <Box
                        sx={{
                            width: {
                                xs: 50,
                                sm: 60,
                                md: 60,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                height: '45px',
                                width: '45px',
                                backgroundColor: '#F5F6F7',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                maxHeight: {
                                    xs: '40px',
                                    sm: '45px',
                                    md: '45px'
                                },
                                maxWidth: {
                                    xs: '40px',
                                    sm: '45px',
                                    md: '45px'
                                }
                            }}
                            mr={{
                                xs: 1,
                                sm: 2,
                                md: 2
                            }}
                            borderRadius={500}
                        >
                            <Iconify
                                icon={icon}
                                sx={{
                                    fontSize: {
                                        xs: '1.2rem',
                                        sm: '1.5rem',
                                        md: '1.5rem'
                                    },
                                    color: '#222',
                                    // backgroundColor: '#F6F7FF',
                                }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Typography
                            fontSize={{
                                xs: 14,
                                sm: 16
                            }}
                            textAlign="start"
                            fontWeight={700}
                            color="#131313"
                        >
                            {category}
                        </Typography>
                        <Typography
                            fontSize={{
                                xs: 12,
                                sm: 14
                            }}
                            fontWeight={500}
                            color="#131313"
                            textAlign="start"
                        >
                            {desc}
                        </Typography>
                    </Box>
                </Box>

                {/* input radio button */}
                {currentCategory?.category == category &&
                    <Box
                        sx={{
                            display: 'flex'
                        }}
                        ml={1}
                        mt={1}
                    >
                        <RadioInputCategory
                            inputs={inputs}
                            radioInputValue={radioInputValue}
                            clickInput={clickInput}
                        />
                    </Box>
                }
            </Box>
        </Button>
    )
}