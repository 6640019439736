import { createSlice } from "@reduxjs/toolkit";
import {
  getDistricts,
  getStates,
  getRegions,
  getCountryCodes,
} from "./countryAction";

const initialState = {
  regions: [],
  states: [],
  districts: [],
  countryCodes: [],
};

const countrySlice = createSlice({
  name: "country",
  initialState: initialState,
  reducers: {
    resetStates: (state) => {
      state.states = [];
    },
    resetDistricts: (state) => {
      state.districts = [];
    },
  },
  extraReducers: {
    [getCountryCodes.fulfilled]: (state, { payload }) => {
      state.countryCodes = payload;
    },
    [getCountryCodes.rejected]: (state) => {
      state.countryCodes = [];
    },
    [getRegions.fulfilled]: (state, { payload }) => {
      state.regions = payload;
    },
    [getRegions.rejected]: (state) => {
      state.regions = [];
    },
    [getStates.fulfilled]: (state, { payload }) => {
      state.states = payload[1];
    },
    [getStates.rejected]: (state) => {
      state.states = [];
    },
    [getDistricts.fulfilled]: (state, { payload }) => {
      state.districts = payload[1];
    },
    [getDistricts.rejected]: (state) => {
      state.districts = [];
    },
  },
});

export const { resetStates, resetDistricts } = countrySlice.actions;

export default countrySlice.reducer;
