import { Button, Checkbox, Grid, IconButton, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { DeviceFrame } from "../../../../components/containers/DeviceFrame";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { getDevices } from "../../../../store/device/deviceAction";
import { Box } from "@mui/system";
import ComboBox from "../../../../components/select/ComboBox";
import BasicInput from "../../../../components/input/BasicInput";
import { motion } from "framer-motion";
import formatWppMarkdown from "whatsapp-markdown";
import toast from "react-hot-toast";
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import CustomModal from "../../../../components/modal/CustomModal";
import Upload from "./Upload";
import SaveCancelContainer from "../../../../components/containers/SaveCancelContainer";
import { createMessageTemplate } from "../../../../store/template/templateAction";

const messageTypeData = [
  { name: "Text", key: "text" },
  // { name: "Text With Button", key: "button" },
  // { name: "Custom Templates", key: "template" },
  // { name: "Lists", key: "list" },
];

export default function AddTemplate(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [typeMessage, setTypeMessage] = useState(null);

  const [formButtons, setFormButtons] = useState([]);

  const [formLists, setFormLists] = useState([]);
  const [formListsButton, setFormListsButton] = useState([]);
  const [formListsTitle, setFormListsTitle] = useState(null);
  const [formListsButtonText, setFormListsButtonText] = useState(null);

  const [formTemplates, setFormTemplates] = useState([]);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  // button type
  let handleChangeFormButtons = (index, name, value) => {
    let newFormValues = [...formButtons];
    newFormValues[index][name] = value;
    setFormButtons(newFormValues);
  };

  let addFormButtons = () => {
    setFormButtons([...formButtons, { button_reply: "", button_title: "" }]);
  };

  let removeFormButtons = (index) => {
    let newFormValues = [...formButtons];
    newFormValues.splice(index, 1);
    setFormButtons(newFormValues);
  };

  // form type
  let handleChangeFormLists = (index, name, value) => {
    let newFormValues = [...formLists];
    newFormValues[index][name] = value;
    setFormLists(newFormValues);
  };

  let addFormLists = () => {
    setFormLists([
      ...formLists,
      {
        list: "",
        index: formLists.length ? formLists[formLists.length - 1].index + 1 : 0,
      },
    ]);
  };

  let removeFormLists = (index) => {
    let newFormValues = [...formLists];
    newFormValues.splice(index, 1);
    setFormLists(newFormValues);
  };

  // form type
  let handleChangeFormListsButton = (index, name, value) => {
    let newFormValues = [...formListsButton];

    let newValue = newFormValues.find((e) => e.index === index);
    newValue[name] = value;

    setFormListsButton(newFormValues);
  };

  let addFormListsButton = (listIndex) => {
    setFormListsButton([
      ...formListsButton,
      {
        title: "",
        description: "",
        row_reply: "",
        listIndex: listIndex,
        index: formListsButton.length
          ? formListsButton[formListsButton.length - 1].index + 1
          : 0,
      },
    ]);
  };

  let removeFormListsButton = (index) => {
    let newFormValues = [...formListsButton];
    newFormValues.filter((e) => e.index !== index);
    newFormValues.splice(newFormValues.length - 1, 1);
    setFormListsButton(newFormValues);
  };

  // template type
  let handleChangeFormTemplates = (index, name, value) => {
    let newFormValues = [...formTemplates];
    newFormValues[index][name] = value;
    setFormTemplates(newFormValues);
  };

  let addFormTemplates = (data) => {
    setFormTemplates([
      ...formTemplates,
      { type: data, displayText: "", action: "" },
    ]);
  };

  let removeFormTemplates = (index) => {
    let newFormValues = [...formTemplates];
    newFormValues.splice(index, 1);
    setFormTemplates(newFormValues);
  };

  const formik = useFormik({
    initialValues: {
      templateName: "",
      message: "",
      type: "",
      fileSelected: "",
      isVoice: false,
      footer: "",
      allowCopy: false,
    },
    onSubmit: async (value, { resetForm }) => {
      toast.loading("Please wait...");

      let data = null;

      data = {
        type: value.type,
        message: value.message,
        fileUrl: null,
        footer: value.footer,
        isVoice: value.isVoice,
      };

      if (typeMessage?.key === "button") {
        data.type = "button";
        data.buttons = formButtons?.length ? formButtons : null;
      } else if (typeMessage?.key === "list") {
        let listRaw = [];

        formLists.map((item, index) => {
          let listButtonRaw = [];

          formListsButton.map((item) => {
            if (item.listIndex === index) {
              listButtonRaw.push({
                row_reply: item.row_reply,
                title: item.title,
                description: item.description,
              });
            }
          });

          listRaw.push({
            title: item.list,
            rows: listButtonRaw,
          });
        });

        data.type = "list";
        data.listSections = listRaw?.length ? listRaw : null;
        data.title = value.title;
        data.buttonText = value.buttonText;
      } else if (typeMessage?.key === "template") {
        let templateRaw = [];

        formTemplates.map((item, index) => {
          if (item.type === "quick-reply") {
            templateRaw.push({
              index: index + 1,
              quickReplyButton: {
                displayText: item.displayText,
                button_reply: item.action,
              },
            });
          } else if (item.type === "url") {
            templateRaw.push({
              index: index + 1,
              urlButton: {
                displayText: item.displayText,
                url: item.action,
              },
            });
          } else if (item.type === "call") {
            templateRaw.push({
              index: index + 1,
              callButton: {
                displayText: item.displayText,
                phoneNumber: item.action,
              },
            });
          }
        });

        data.type = "template";
        data.templates = templateRaw?.length ? templateRaw : null;
      }

      data.fileUrl = value.fileSelected
        ? `https://new-client.realm.chat/cloud_storage/${value.fileSelected.file_url}`
        : null;

      const dataJSON = {
        userId: user.id,
        templateName: value.templateName,
        type: value.type,
        allowCopy: value.allowCopy,
        messageTemplate: data,
      };

      await dispatch(createMessageTemplate(dataJSON)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message);

          resetForm();

          setTypeMessage(null);
          setFormButtons([]);
          setFormLists([]);
          setFormListsButton([]);
          setFormTemplates([]);
          setFormListsTitle(null);
          setFormListsButtonText(null);

          props.setAddTemplate();
        } else {
          toast.dismiss();
          toast.error(res.payload.message);
        }
      });
    },
  });

  const handleGetDevices = async () => {
    await dispatch(getDevices());
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    formatWppMarkdown("#chat-message");
  }, [formik.values]);

  return (
    <SaveCancelContainer
      title="Add Message Templates"
      buttonCancelTitle="Close"
      buttonSaveTitle="Submit"
      buttonDisabled={!formik.values.message.length}
      onCancel={() => props.setAddTemplate()}
      onSave={formik.handleSubmit}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} minHeight="540px">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <BasicInput
                  fullWidth
                  disableMargin
                  title="Template Name"
                  placeholder="Type message in here ..."
                  {...formik.getFieldProps("templateName")}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <ComboBox
                  fullWidth
                  disableMargin
                  options={messageTypeData ?? []}
                  getOptionLabel={(option) => option?.name}
                  title="Message Type"
                  placeholder={"Choose type message"}
                  value={typeMessage}
                  onChange={(e, value) => {
                    formik.setFieldValue("type", value.key);

                    setFormButtons([]);
                    setFormLists([]);
                    setFormListsButton([]);
                    setFormTemplates([]);
                    formik.setFieldValue("title", "");
                    formik.setFieldValue("buttonText", "");
                    formik.setFieldValue("message", "");

                    setTypeMessage(value);
                  }}
                />
              </Grid>

              {typeMessage ? (
                <Grid item xs={12} sm={12}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    {typeMessage?.key === "list" ? (
                      <Box mb={2}>
                        <BasicInput
                          fullWidth
                          disableMargin
                          placeholder="Title"
                          value={formik.values.title}
                          onChange={(e) => {
                            formik.setFieldValue("title", e.target.value);
                            setFormListsTitle(e.target.value);
                          }}
                        />
                      </Box>
                    ) : null}
                    <Box mb={2}>
                      <BasicInput
                        fullWidth
                        disableMargin
                        multiline
                        rows={5}
                        placeholder="Type message in here ..."
                        {...formik.getFieldProps("message")}
                      />
                    </Box>
                  </motion.div>
                </Grid>
              ) : null}

              {typeMessage?.key === "button" ? (
                <>
                  {formButtons.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12} lg={5}>
                          <BasicInput
                            disableMargin
                            fullWidth
                            name="button_title"
                            title="Button Tittle"
                            placeholder="i.e Yes"
                            value={item.button_title || ""}
                            onChange={(e) =>
                              handleChangeFormButtons(
                                index,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <BasicInput
                            disableMargin
                            fullWidth
                            multiline
                            rows={5}
                            name="button_reply"
                            title="Button Response"
                            placeholder="{{Reply _ID}} or response text"
                            value={item.button_reply || ""}
                            onChange={(e) =>
                              handleChangeFormButtons(
                                index,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={1}>
                          <Box
                            mb={3.3}
                            sx={{ display: { xs: "none", lg: "block" } }}
                          />
                          <IconButton onClick={() => removeFormButtons(index)}>
                            <Iconify
                              icon="ant-design:close-square-outlined"
                              color="#F15A28"
                              height="32px"
                              width="32px"
                            />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    );
                  })}

                  {formButtons.length < 3 ? (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        sx={{
                          background: "#FFFFFF",
                          borderRadius: "8px",
                          color: "#7B7B7B",
                          width: "100%",
                          height: "40px",
                          boxShadow: "none",
                          border: "1px solid #CBC8C8",
                        }}
                        startIcon={
                          <Iconify
                            icon="fluent:add-12-filled"
                            color="#7B7B7B"
                            height="16px"
                            width="16px"
                          />
                        }
                        onClick={() => {
                          addFormButtons();
                        }}
                      >
                        <Typography fontSize={{ xs: 12, sm: 14 }}>
                          Add Button
                        </Typography>
                      </Button>
                    </Grid>
                  ) : null}
                </>
              ) : typeMessage?.key === "list" ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <BasicInput
                      disableMargin
                      fullWidth
                      title="Button Text"
                      placeholder="Click Here"
                      value={formik.values.buttonText}
                      onChange={(e) => {
                        formik.setFieldValue("buttonText", e.target.value);
                        setFormListsButtonText(e.target.value);
                      }}
                    />
                  </Grid>
                  {formLists.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12} lg={11}>
                          <BasicInput
                            disableMargin
                            fullWidth
                            name="list"
                            title="List Title"
                            placeholder="List Title"
                            value={item.list || ""}
                            onChange={(e) =>
                              handleChangeFormLists(
                                index,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={1}>
                          <Box
                            mb={3.3}
                            sx={{ display: { xs: "none", lg: "block" } }}
                          />
                          <IconButton onClick={() => removeFormLists(index)}>
                            <Iconify
                              icon="ant-design:close-square-outlined"
                              color="#F15A28"
                              height="32px"
                              width="32px"
                            />
                          </IconButton>
                        </Grid>
                        {formListsButton
                          .filter((e) => e.listIndex === item.index)
                          .map((item, i) => {
                            return (
                              <Fragment key={i}>
                                <Grid item xs={12} lg={4}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    name="title"
                                    title="Create Tittle"
                                    placeholder="i.e Yes"
                                    value={item.title || ""}
                                    onChange={(e) =>
                                      handleChangeFormListsButton(
                                        item.index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    multiline
                                    rows={5}
                                    name="description"
                                    title="Description ( Optional )"
                                    placeholder="Select description here"
                                    value={item.description || ""}
                                    onChange={(e) =>
                                      handleChangeFormListsButton(
                                        item.index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                  <BasicInput
                                    disableMargin
                                    fullWidth
                                    multiline
                                    rows={5}
                                    name="row_reply"
                                    title="Response"
                                    placeholder="{{Reply _ID}} or response text"
                                    value={item.row_reply || ""}
                                    onChange={(e) =>
                                      handleChangeFormListsButton(
                                        item.index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </Grid>
                              </Fragment>
                            );
                          })}
                        <Grid item xs={12} lg={6}></Grid>
                        <Grid item xs={12} lg={6}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ justifyContent: "flex-end" }}
                          >
                            {formListsButton.length ? (
                              <Grid item xs={6}>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: "8px",
                                    color: "#7B7B7B",
                                    width: "100%",
                                    height: "40px",
                                    boxShadow: "none",
                                    border: "1px solid #CBC8C8",
                                  }}
                                  onClick={() => removeFormListsButton(index)}
                                >
                                  <Typography fontSize={{ xs: 12, sm: 14 }}>
                                    Delete
                                  </Typography>
                                </Button>
                              </Grid>
                            ) : null}

                            <Grid item xs={6}>
                              <Button
                                variant="outlined"
                                sx={{
                                  background: "#FFFFFF",
                                  borderRadius: "8px",
                                  color: "#7B7B7B",
                                  width: "100%",
                                  height: "40px",
                                  boxShadow: "none",
                                  border: "1px solid #CBC8C8",
                                }}
                                startIcon={
                                  <Iconify
                                    icon="fluent:add-12-filled"
                                    color="#7B7B7B"
                                    height="16px"
                                    width="16px"
                                  />
                                }
                                onClick={() => {
                                  addFormListsButton(index);
                                }}
                              >
                                <Typography fontSize={{ xs: 12, sm: 14 }}>
                                  Add Button
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Fragment>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      sx={{
                        background: "#FFFFFF",
                        borderRadius: "8px",
                        color: "#7B7B7B",
                        width: "100%",
                        height: "40px",
                        boxShadow: "none",
                        border: "1px solid #CBC8C8",
                      }}
                      startIcon={
                        <Iconify
                          icon="fluent:add-12-filled"
                          color="#7B7B7B"
                          height="16px"
                          width="16px"
                        />
                      }
                      onClick={() => {
                        addFormLists();
                      }}
                    >
                      <Typography fontSize={{ xs: 12, sm: 14 }}>
                        Add List
                      </Typography>
                    </Button>
                  </Grid>
                </>
              ) : typeMessage?.key === "template" ? (
                <>
                  {formTemplates.map((item, index) => {
                    return (
                      <Fragment key={index}>
                        <Grid item xs={12} lg={5}>
                          <BasicInput
                            disableMargin
                            fullWidth
                            name="displayText"
                            title="Display Text"
                            placeholder="i.e Yes"
                            value={item.displayText || ""}
                            onChange={(e) =>
                              handleChangeFormTemplates(
                                index,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <BasicInput
                            disableMargin
                            fullWidth
                            multiline
                            rows={5}
                            name="action"
                            title={`${
                              item.type === "quick-reply"
                                ? "Reply"
                                : item.type === "url"
                                ? "Website"
                                : item.type === "call"
                                ? "Phone Number"
                                : null
                            }`}
                            placeholder={`${
                              item.type === "quick-reply"
                                ? "{{Reply _ID}} or response text"
                                : item.type === "url"
                                ? "https://realm.chat"
                                : item.type === "call"
                                ? "Input number"
                                : null
                            }`}
                            value={item.action || ""}
                            onChange={(e) =>
                              handleChangeFormTemplates(
                                index,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={1}>
                          <Box
                            mb={3.3}
                            sx={{ display: { xs: "none", lg: "block" } }}
                          />
                          <IconButton
                            onClick={() => removeFormTemplates(index)}
                          >
                            <Iconify
                              icon="ant-design:close-square-outlined"
                              color="#F15A28"
                              height="32px"
                              width="32px"
                            />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    );
                  })}

                  {formTemplates.length < 3 ? (
                    <>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "8px",
                            color: "#7B7B7B",
                            width: "100%",
                            height: "40px",
                            boxShadow: "none",
                            border: "1px solid #CBC8C8",
                          }}
                          startIcon={
                            <Iconify
                              icon="fluent:add-12-filled"
                              color="#7B7B7B"
                              height="16px"
                              width="16px"
                            />
                          }
                          onClick={() => {
                            addFormTemplates("quick-reply");
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            Add Button
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "8px",
                            color: "#7B7B7B",
                            width: "100%",
                            height: "40px",
                            boxShadow: "none",
                            border: "1px solid #CBC8C8",
                          }}
                          startIcon={
                            <Iconify
                              icon="fluent:add-12-filled"
                              color="#7B7B7B"
                              height="16px"
                              width="16px"
                            />
                          }
                          onClick={() => {
                            addFormTemplates("url");
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            Add Website Button
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          sx={{
                            background: "#FFFFFF",
                            borderRadius: "8px",
                            color: "#7B7B7B",
                            width: "100%",
                            height: "40px",
                            boxShadow: "none",
                            border: "1px solid #CBC8C8",
                          }}
                          startIcon={
                            <Iconify
                              icon="fluent:add-12-filled"
                              color="#7B7B7B"
                              height="16px"
                              width="16px"
                            />
                          }
                          onClick={() => {
                            addFormTemplates("call");
                          }}
                        >
                          <Typography fontSize={{ xs: 12, sm: 14 }}>
                            Add Call Button
                          </Typography>
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </>
              ) : null}
              {formik.values.type !== "list" ? (
                <Grid item xs={12} sm={12}>
                  <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                    Attachment (Optional)
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <BasicButton
                      title="Choose File"
                      onClick={() => setOpenUploadModal(true)}
                    />
                    <Typography color="#131313" sx={{ ml: 2 }}>
                      {formik?.values?.fileSelected?.file_name}
                    </Typography>
                  </Box>
                </Grid>
              ) : null}
              {formik.values.fileSelected?.file_type === "audio" ? (
                <Grid item xs={12} sm={12}>
                  <BasicInput
                    type="checkbox"
                    title="Send as Voice Note"
                    sx={{ width: "24px" }}
                    onChange={() => {
                      formik.setFieldValue(
                        "isVoice",
                        !formik.values.isVoice ? true : false
                      );
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12}>
                <Typography fontSize={16} fontWeight="600" sx={{ mb: 1 }}>
                  Public
                </Typography>
                <Checkbox
                  type="checkbox"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 }, p: 0 }}
                  onChange={() => {
                    formik.setFieldValue(
                      "allowCopy",
                      !formik.values.allowCopy ? true : false
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <DeviceFrame
            single={{
              formButtons: formButtons ?? [],
              formTemplates: formTemplates ?? [],
              formLists: formLists ?? [],
              formListsButton: formListsButton ?? [],
              formListsOther: {
                title: formListsTitle,
                buttonText: formListsButtonText,
              },
              fileSelected: formik.values.fileSelected,
              isVoice: formik.values.isVoice,
            }}
            setFooter={(value) => formik.setFieldValue("footer", value)}
          >
            <p id="chat-message" style={{ whiteSpace: "pre-line" }}>
              {formik.values.message.split("\n").join("<br />")}
            </p>
          </DeviceFrame>
        </Grid>
      </Grid>
      <CustomModal
        handleClose={() => setOpenUploadModal(false)}
        open={openUploadModal}
      >
        <Upload
          onCancle={() => setOpenUploadModal(false)}
          onSelect={(value) => {
            formik.setFieldValue("fileSelected", value);
          }}
          value={formik.values?.fileSelected}
        />
      </CustomModal>
    </SaveCancelContainer>
  );
}
