import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import BasicButton from "../../../../components/button/BasicButton";
import FormContainer from "../../../../components/containers/FormContainer";
import BasicInput from "../../../../components/input/BasicInput";
import ComboBox from "../../../../components/select/ComboBox";
import {
  getCatalogOrdersMessage,
  updateCatalogOrderMessage,
} from "../../../../store/catalog/catalogAction";
import { getDevices } from "../../../../store/device/deviceAction";

const messageTypes = [
  { name: "Cart", key: "cart" },
  { name: "COD", key: "cod" },
  { name: "Success", key: "success" },
  { name: "Fail", key: "fail" },
  { name: "Follow Up Abandon Cart", key: "reminder_cart" },
];

const variables = [
  { name: "Order ID", key: "{ORDER_ID}" },
  { name: "Item Count", key: "{ITEM_COUNT}" },
  { name: "Price", key: "{PRICE}" },
  { name: "Item", key: "{ITEM}" },
  { name: "Name", key: "{NAME}" },
];

export default function UpdateForm({ onCancel, detail }) {
  const dispatch = useDispatch();

  const { devices } = useSelector((state) => state.device);

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      device: null,
      type: messageTypes?.find((e) => e.key === detail?.type_id),
      message: detail?.message,
    },
    onSubmit: async (value, { resetForm }) => {
      setIsLoading(true);

     toast.loading("Please wait...");

      const data = {
        device_key: value.device?.device_key,
        type: value.type?.key,
        message: value.message,
      };

      await dispatch(updateCatalogOrderMessage(data)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          toast.dismiss();
          toast.success(res.payload.message)
        } else {
          toast.dismiss();
          toast.error(res.payload.message)
        }
      });

      setIsLoading(false);
      onCancel();

      resetForm();
      handleGetCatalogOrdersMessage();
    },
  });

  const handleClickVariable = (key) => {
    const messageInputEl = document.getElementById("message-input");

    const cursorPosition = messageInputEl.selectionStart;

    const _message =
      formik.values.message.slice(0, cursorPosition) +
      key +
      formik.values.message.slice(cursorPosition);

    formik.setFieldValue("message", _message);

    messageInputEl.selectionStart = cursorPosition;
    messageInputEl.selectionEnd = cursorPosition;
    messageInputEl.focus();
  };

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba,instagram",
      },
    };

    await dispatch(getDevices(body));
  };

  const handleGetCatalogOrdersMessage = async () => {
    await dispatch(getCatalogOrdersMessage());
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (devices) {
      const _devices = devices;

      formik.setFieldValue(
        "device",
        _devices?.find((e) => e.device_key === detail?.device_key)
      );
    }
  }, [devices]);

  return (
    <FormContainer title="Edit Message">
      <ComboBox
        fullWidth
        disableMargin
        title="Channel"
        placeholder="Please select channel"
        options={devices}
        getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
        value={formik.values.device}
        onChange={(e, value) => {
          formik.setFieldValue("device", value);
        }}
      />
      <Box mb={2} />
      <ComboBox
        fullWidth
        disableMargin
        title="Message Type"
        placeholder="Please select message type"
        options={messageTypes}
        getOptionLabel={(option) => option?.name}
        value={formik.values.type}
        onChange={(e, value) => {
          formik.setFieldValue("type", value);
        }}
      />
      <Box mb={2} />
      <BasicInput
        fullWidth
        disableMargin
        multiline
        rows={5}
        id="message-input"
        placeholder="Type message in here ..."
        {...formik.getFieldProps("message")}
      />
      <Box mb={2} />
      <Typography fontWeight="700" mb={0.5}>
        Add Variables :
      </Typography>
      <Stack direction="row" spacing={1}>
        {variables.map((item, index) => {
          return (
            <Button
              variant="outlined"
              key={index}
              onClick={(e) => handleClickVariable(item.key)}
            >
              {item.name}
            </Button>
          );
        })}
      </Stack>
      <Box mb={2} />
      <Stack
        direction="row"
        spacing={1}
        display="flex"
        justifyContent="end"
        alignItems="center"
      >
        <Button onClick={onCancel}>Cancel</Button>
        <BasicButton
          title="Save"
          type="submit"
          disabled={isLoading}
          onClick={formik.handleSubmit}
        />
      </Stack>
      <Box mb={2} />
    </FormContainer>
  );
}
