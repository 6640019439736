import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { setIsHoverSidebar } from "../../store/template/templateSlice";
import navConfig from "./NavConfig";
import Iconify from "../../components/Iconify";
import { useDispatch } from "react-redux";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const getImage = (name, type) => (
  <Box
    component="img"
    sx={{
      height: 22,
      width: 22,
    }}
    alt={name}
    src={`/icons/sidebar/${name}`}
  />
);

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const DRAWER_WIDTH = 70;
const DRAWER_WIDTH_HOVER = 280;
// const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const HoverDrawer = styled(Drawer)(({ theme }) => ({
  "&:hover": {
    "& .MuiDrawer-paper": {
      // width: DRAWER_WIDTH_HOVER,
      width: DRAWER_WIDTH,
    },
    "& .hover-text": {
      opacity: 1,
    },
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isDesktop = useResponsive("up", "lg");

  const handleHoverEnter = () => {
    dispatch(setIsHoverSidebar(true));
  };

  const handleHoverExit = () => {
    dispatch(setIsHoverSidebar(false));
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const accountConfig = [
    {
      title: "Account",
      path: "/dashboard/account",
      // icon: getIcon("charm:person"),
      icon: getImage("/outline/Account.svg"),
      iconActive: getImage("/outline/Account_Active.svg"),
    },
    {
      title: "Logout",
      path: "/dashboard/logout",
      icon: getImage("/outline/Arrow_Right.svg"),
      iconActive: getImage("/outline/Arrow_Right_Active.svg"),
    },
  ];

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        backgroundColor: "#FDFDFD",
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: "inline-flex",
          justifyContent: "center",
        }}
      >
        <Logo />
      </Box>
      <NavSection navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
      {/* <UpgradeAccount /> */}
      <Box mt={7}>
        <NavSection navConfig={accountConfig} />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH_HOVER },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <HoverDrawer
          open
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverExit}
          variant="persistent"
          sx={{
            width: DRAWER_WIDTH,
          }}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              border: "none",
              transition: "width 0.3s ease-in-out",
            },
          }}
        >
          {renderContent}
        </HoverDrawer>
      )}
    </RootStyle>
  );
}
