//component
import styled from "@emotion/styled";
import {
  Badge,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import Container from "../../../../components/containers/Container";
import EmptyBox from "../../../../components/EmptyBox";
import Iconify from "../../../../components/Iconify";
import Scrollbar from "../../../../components/Scrollbar";
import socket from "../../../../services/socket";
import { cancelScheduleMessage } from "../../../../store/marketing/marketingAction";
import {
  exportReport,
  getReportScheduleMessage,
  getReportScheduleMessageStatus,
} from "../../../../store/report/reportAction";
import {
  resetReportMessageStatus,
  setReportBulkMessageStatus,
} from "../../../../store/report/reportSlice";
import palette from "../../../../theme/palette";
import { IconListDetailResponsive } from "./Config";
import dayjs from "dayjs";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const tableCellList = ["Bulk ID", "Date", "From", "Status", "Action"];

const tableCellDetail = ["No", "Phone Number", "Status", "Time"];

const DetailItem = ({ item, index, detail }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("schedule-update", (res) => {
      if (res.id === detail && res.number === item.number) {
        dispatch(setReportBulkMessageStatus(res));
      }
    });
  }, []);

  return (
    <TableRow key={index}>
      <TableCell align="left">{index + 1}</TableCell>
      <TableCell align="left">{item.number}</TableCell>
      <TableCell align="left">
        {item.status === "Error" ? (
          <Tooltip title={`${item.error ?? "Error"}`}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  fontWeight: 700,
                  color: "#F15A28",
                  mr: 0.5,
                }}
              >
                {item.status}
              </Box>
              <Iconify
                icon={"material-symbols:info-outline-rounded"}
                color="#F15A28"
                width={12}
                height={12}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Tooltip>
        ) : (
          item.status
        )}
      </TableCell>
      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
        {moment(parseInt(`${item.timestamp}` + "000")).format("HH:mm:ss")}
      </TableCell>
    </TableRow>
  );
};

export default function ScheduleMessage() {
  const dispatch = useDispatch();

  const { plan } = useSelector((state) => state.user);
  const { scheduleMessageAll, scheduleMessageStatusAll, isDetail } =
    useSelector((state) => state.report);

  const [isLoading, setIsLoading] = useState(false);

  const [search, setSearch] = useState(null);
  const [detail, setDetail] = useState(null);

  const buttonGroupDetail = [
    {
      title: "Export",
      icon: null,
      prev: () => handleExportReport(),
    },
  ];

  const handleGetReportsScheduleMessage = async (data) => {
    setIsLoading(true);

    await dispatch(getReportScheduleMessage(data));

    setIsLoading(false);
  };

  const handleGetScheduleMessageStatus = async (item) => {
    setIsLoading(true);

    await dispatch(getReportScheduleMessageStatus({ scheduleId: item }));

    setIsLoading(false);
  };

  const handleCancelScheduleMessage = async (data) => {
    toast.loading("Please wait...");

    await dispatch(cancelScheduleMessage(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message)
      } else {
        toast.dismiss();
        toast.error(res.payload.message)
      }
    });
  };

  const handleExportReport = async () => {
    toast.loading("Please wait...");

    const dataJSON = {
      id: detail,
    };

    await dispatch(exportReport(dataJSON)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success("Export Report Successfully ...");
      } else {
        toast.dismiss();
        toast.error("Export Report Failed ...");
      }

      const url = window.URL.createObjectURL(new Blob([res.payload]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Komers Report WhatsApp Schedule.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  useEffect(() => {
    const data = {
      params: {
        search: search,
      },
    };

    handleGetReportsScheduleMessage(data);
  }, [search]);

  useEffect(() => {
    if (detail) {
      handleGetScheduleMessageStatus(detail);
    }
  }, [detail]);

  useEffect(() => {
    return () => {
      dispatch(resetReportMessageStatus());
    };
  }, []);

  useEffect(() => {
    setDetail(null);
  }, [isDetail]);

  // var gmtDateStr = ;
  // var gmtDate = new Date(gmtDateStr + " GMT");
  // var localDateStr = gmtDate.toLocaleString();
  // console.log(localDateStr);

  if (plan?.is_schedulemessage === "1") {
    if (detail) {
      return (
        <Card
          style={{
            backgroundColor: "#FDFDFD",
            padding: "16px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
            pl={2}
            pr={1}
            alignItems="center"
          >
            <Box>
              <Typography
                fontSize={{ xs: 20, sm: 24 }}
                fontWeight="700"
                color="#131313"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                {detail}
              </Typography>
              <Typography
                fontSize={{ xs: 20, sm: 24 }}
                fontWeight="700"
                color="#131313"
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {detail.substring(0, 12)} ...
              </Typography>
            </Box>
            <IconListDetailResponsive
              onClickExportIcon={() => handleExportReport()}
            />
            <Stack
              spacing={1}
              display={{ xs: "none", sm: "flex", md: "flex" }}
              direction="row"
            >
              {buttonGroupDetail.map((item, index) => {
                return (
                  <Box display="flex" alignItems="center" key={index}>
                    <ButtonWithIcon
                      title={item.title}
                      icon={item.icon}
                      onClick={item.prev}
                    />
                  </Box>
                );
              })}
            </Stack>
          </Box>
          <Box
            sx={{
              mb: 2,
              background:
                "linear-gradient(154.66deg, rgba(147, 223, 147, 0.1) 5.17%, rgba(33, 194, 193, 0.1) 68.62%)",
              borderRadius: "12px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"uil:comment-alt-message"}
                  color="#7B7B7B"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Total Message
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {scheduleMessageStatusAll.length}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"material-symbols:check"}
                  color="#7B7B7B"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Sent
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {" "}
                  {
                    scheduleMessageStatusAll.filter((e) => e.status === "Sent")
                      .length
                  }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"material-symbols:done-all"}
                  color="#7B7B7B"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Delivered
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {
                    scheduleMessageStatusAll.filter(
                      (e) => e.status === "Delivered"
                    ).length
                  }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"material-symbols:done-all"}
                  color="#21C2C1"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Read
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {
                    scheduleMessageStatusAll.filter((e) => e.status === "Read")
                      .length
                  }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"ph:arrow-clockwise-bold"}
                  color="#7B7B7B"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Replied
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {
                    scheduleMessageStatusAll.filter(
                      (e) => e.status === "Replied"
                    ).length
                  }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                p: 2,
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "48px",
                  width: "48px",
                  background: "#fff",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Iconify
                  icon={"mdi:clock-time-four-outline"}
                  color="#7B7B7B"
                  width={36}
                  height={36}
                />
              </Box>
              <Box>
                <Typography fontSize="14px" fontWeight="700" color="#7B7B7B">
                  Error
                </Typography>
                <Typography fontSize="24px" fontWeight="700" color="#00B909">
                  {
                    scheduleMessageStatusAll.filter((e) => e.status === "Error")
                      .length
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
          {scheduleMessageStatusAll ? (
            <Scrollbar>
              <TableContainer
                component={Paper}
                style={{ backgroundColor: "#FDFDFD" }}
              >
                <Table aria-label="simple table">
                  <TableHead
                    style={{
                      background: palette.gradients.custom,
                    }}
                  >
                    <TableRow>
                      {tableCellDetail.map((item, index) => {
                        return (
                          <TableCell align="left" key={index}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography color="#FDFDFD">{item}</Typography>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Box
                            component="img"
                            src="/images/loading.svg"
                            alt="loading"
                            height="48px"
                            sx={{ mx: "auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : scheduleMessageStatusAll.length ? (
                      scheduleMessageStatusAll.map((item, index) => {
                        return (
                          <DetailItem
                            item={item}
                            index={index}
                            detail={detail}
                            key={index}
                          />
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          Data Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : (
            <EmptyBox
              title1="No WhatsApp Schedule Database "
              title2="Generate now at"
            />
          )}
        </Card>
      );
    } else {
      return (
        <Card
          style={{
            backgroundColor: "#FDFDFD",
            padding: "16px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb={1}
            pl={2}
            pr={1}
            alignItems="center"
          >
            <Box>
              <Typography
                fontSize={{ xs: 20, sm: 24 }}
                fontWeight="700"
                color="#131313"
              >
                WhatsApp Schedule
              </Typography>
            </Box>
          </Box>
          <FormControl
            fullWidth
            sx={{ marginTop: 2, marginBottom: 2 }}
            onChange={(e) => setSearch(e.target.value)}
          >
            <OutlinedInput
              style={{ height: "40px" }}
              id="outlined-adornment-amount"
              placeholder="Search contact"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={"heroicons:magnifying-glass"}
                    color="#7B7B7B"
                    width={24}
                    height={24}
                  />
                </InputAdornment>
              }
            />
          </FormControl>
          {scheduleMessageAll ? (
            <Scrollbar>
              <TableContainer
                component={Paper}
                style={{ backgroundColor: "#FDFDFD" }}
              >
                <Table aria-label="simple table">
                  <TableHead
                    style={{
                      background: palette.gradients.custom,
                    }}
                  >
                    <TableRow>
                      {tableCellList.map((item, index) => {
                        return (
                          <TableCell align="left" key={index}>
                            <Box
                              alignItems="center"
                              display="flex"
                              justifyContent={"space-between"}
                            >
                              <Typography color="#FDFDFD">{item}</Typography>
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Box
                            component="img"
                            src="/images/loading.svg"
                            alt="loading"
                            height="48px"
                            sx={{ mx: "auto" }}
                          />
                        </TableCell>
                      </TableRow>
                    ) : scheduleMessageAll.length ? (
                      scheduleMessageAll.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="left">{item.schedule_id}</TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {(() => {
    const gmtDateStr = item.created_at;
    const splitDate = gmtDateStr?.split(' ')
    const getNormalDate = `${splitDate[0]} ${splitDate[1]}`
    const gmtDate = new Date(getNormalDate + " GMT");
    const localDateStr = gmtDate.toLocaleString();
    //console.log(localDateStr); // This logs the converted date to the browser's console.
    return localDateStr; // Return the converted date for display.
  })()}
                          </TableCell>
                          <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                            {item.device_info ? item.device_info?.phone : "-"}
                          </TableCell>
                          <TableCell align="left">
                            <Box
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              {item.status === "Stopped" ? (
                                <Badge color="error" badgeContent={item.status}>
                                  <></>
                                </Badge>
                              ) : item.status === "Success" ? (
                                <Badge
                                  color="primary"
                                  badgeContent={item.status}
                                >
                                  <></>
                                </Badge>
                              ) : (
                                <Badge
                                  color="warning"
                                  badgeContent={item.status}
                                >
                                  <></>
                                </Badge>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Box sx={{ mr: 1 }}>
                              <ButtonWithIcon
                                title="View"
                                icon="mdi:card-search-outline"
                                onClick={() => setDetail(item.schedule_id)}
                              />
                            </Box>
                            {item.status === "Pending" ||
                            item.status === "Processing" ? (
                              <ButtonWithIcon
                                title="Stop"
                                sx={{ background: "#F15A28" }}
                                onClick={() =>
                                  handleCancelScheduleMessage({
                                    scheduleId: item.schedule_id,
                                  })
                                }
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          Data Not Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          ) : (
            <EmptyBox title1="No WhatsApp Broadcast Database" />
          )}
        </Card>
      );
    }
  } else {
    return (
      <Container title="WhatsApp Schedule Report">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
