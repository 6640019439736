import { Box, Typography } from "@mui/material";
import BasicInput from "../../../../../../../../../components/input/BasicInput";
import TemplateSection from "../../../../../TemplateSection";
import BodyTools from "./BodyTools";
import VariableContent from "../../../../../VariableContent";

export default function Body({
    bodyValue,
    changeBody,
    miniMobile,
    clickB,
    clickI,
    addVariable,
    totalVariables,
    changeBodyContent
}) {
    return (
        <TemplateSection
            title="Body"
            desc={`Enter the text for your message in the language you've selected.`}
        >
            <BasicInput
                fullWidth
                disableMargin
                multiline
                rows={5}
                value={bodyValue}
                placeholder="Type message in here ..."
                onChange={changeBody}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: miniMobile ? 'column' : 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                mt={1}
                mb={1}
            >
                <Typography
                    fontSize={12}
                >
                    Characters: {bodyValue.length}/1024
                </Typography>
                {/* short cut */}
                <BodyTools
                    clickB={clickB}
                    clickI={clickI}
                    addVariable={addVariable}
                />
            </Box>

            {/* samples for body content */}
            {totalVariables.length > 0 &&
                <VariableContent
                    title="Samples for body content"
                    desc="To help us review your message template, please add an example for each variable in your body text. Do not use real customer information. Cloud API hosted by Meta reviews templates and variable parameters to protect the security and integrity of our services."
                    totalVariables={totalVariables}
                    changeInput={changeBodyContent}
                    label="Body"
                />
            }
        </TemplateSection>
    )
}