import {
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import { useSelector } from "react-redux";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import palette from "../../../../theme/palette";
import Container from "../../../../components/containers/Container";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";


const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));


export default function APIDocumentation() {
  const { apiKey } = useSelector((state) => state.user);

  const { plan } = useSelector((state) => state.user);

  if (plan?.is_apiaccess === "1") {
    return (
      <div>
        <ContainerWithSomeButton title="API Documentation">
          <Box sx={{ mb: 2 }}>
            <Typography fontWeight="700" mb={0.5} alignItems="center">
              API Key
            </Typography>
            <Box
              sx={{
                bgcolor: "#F6F7FF",
                alignItems: "center",
                p: 2,
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize="16px" color="#0D4AFD" sx>
                {apiKey}
              </Typography>
              <ButtonTooltip
                title="Copy"
                handleClick={() => {
                  navigator.clipboard.writeText(`${apiKey}`);

                  toast.success("Copy Link Succesfully");
                }}
                icon="cil:copy"
                color="#0D4AFD"
              />
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography fontWeight="700" mb={0.5} alignItems="center">
              API URL
            </Typography>
            <Box
              sx={{
                bgcolor: "#F6F7FF",
                alignItems: "center",
                p: 2,
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize="16px" color="#0D4AFD" sx>
                https://connect.realm.chat/
              </Typography>
              <ButtonTooltip
                title="Copy"
                handleClick={() => {
                  navigator.clipboard.writeText(`https://connect.realm.chat/`);

                  toast.success("Copy Link Succesfully");
                }}
                icon="cil:copy"
                color="#0D4AFD"
              />
            </Box>
          </Box>
          <Box>
            <Typography fontWeight="700" mb={0.5} alignItems="center">
              API Documentation
            </Typography>
            <Box sx={{ mb: 2 }}>
              <ButtonWithIcon
                title="Open"
                icon="material-symbols:link"
                onClick={() => window.open("https://realmchat.readme.io/")}
              />
            </Box>
            {/* <iframe
            src="https://realmchat.readme.io/reference/getting-started-with-your-api-1"
            width="100%"
            height="600px"
            frameborder="0"
          ></iframe> */}
          </Box>
        </ContainerWithSomeButton>
      </div>
    );
  } else {
    return (
      <Container title="API Documentation">
        <Grid container spacing={4}>
          <Grid item xs={12} minHeight="540px">
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Oopss.. this page is not available.
              </Typography>
              <Box
                component="img"
                src="/static/illustrations/404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                variant={"contained"}
                sx={{
                  background: palette.gradients.custom,
                  borderRadius: "12px",
                  color: "#fdfdfd",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Iconify
                  icon="bx:medal"
                  sx={{
                    width: 24,
                    height: 24,
                    ml: 1,
                  }}
                />
                <Typography ml={{ xs: 0, sm: 1 }} fontSize={{ xs: 18, sm: 22 }}>
                  Upgrade Package Now!
                </Typography>
              </Button>
            </ContentStyle>
          </Grid>
        </Grid>
      </Container>
    );
  }
}



