import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const getContacts = createAsyncThunk(
  "contacts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("contact", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addContactManual = createAsyncThunk(
  "contact/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact/add", data);

      console.log(response.data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const importContactFromExcel = createAsyncThunk(
  "contact/import/excel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact/import/excel", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const importContactFromDevice = createAsyncThunk(
  "contact/import/device",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact/import/device", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const importContactFromGroup = createAsyncThunk(
  "contact/import/group",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact/import/group", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const exportContactToExcel = createAsyncThunk(
  "contact/export/excel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi({
        method: "POST",
        url: "contact/export/excel",
        responseType: "blob",
      });

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteContact = createAsyncThunk(
  "contact/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getProfilePictureContact = createAsyncThunk(
  "/profile-pictures",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/profile-pictures", data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getContactScrappings = createAsyncThunk(
  "contact-scrapping",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("contact-scrapping", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const addContactScrapping = createAsyncThunk(
  "contact-scrapping/add",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact-scrapping/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const stopContactScrapping = createAsyncThunk(
  "contact-scrapping/stop",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("contact-scrapping/stop", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateContact = createAsyncThunk(
  'contact/update',
  async (data, {rejectWithValue})=>{
    const result = await clientApi.post('contact/update', data)

    if(result?.status !== 200){
      return rejectWithValue(result)
    }
    return result
  }
)