import { useDispatch } from "react-redux";
import ContainerWithSomeButton from "../../../../../components/containers/ContainerWithSomeButton";
import Connect from "../../Connect";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Box } from "@mui/material";
import BasicInput from "../../../../../components/input/BasicInput";
import BasicButton from "../../../../../components/button/BasicButton";
import { useFormik } from "formik";
import { addDevice } from "../../../../../store/device/deviceAction";
import toast from "react-hot-toast";

export default function ConnectEmail() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            username: '',
        },
        onSubmit: async (value, { resetForm }) => {
            setLoading(true)

            const data = {
                platform: 'email',
                name: value.name ?? null,
                username: value.username ?? null,
            }

            dispatch(addDevice(data)).then((res) => {
                if (res.meta.requestStatus === "fulfilled") {
                    toast.dismiss();
                    toast.success(res.payload.message);

                    resetForm();
                    navigate('/dashboard/channels')
                } else {
                    toast.dismiss();
                    toast.error(res.payload.message);
                }

                setLoading(false);
            });
        }
    })

    function clickBack() {
        navigate('/dashboard/channels')
    }

    return (
        <ContainerWithSomeButton>
            <Connect
                icon="email.png"
                title="Email"
                isBackBtn={true}
                clickBack={clickBack}
            />

            <Box
                mt={4}
                mb={2}
                sx={{
                    marginLeft: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    },
                    marginRight: {
                        xs: 0,
                        sm: 3.5,
                        md: 3.5
                    }
                }}
            >
                <BasicInput
                    title="Name"
                    fullWidth
                    disableMargin
                    id="name"
                    placeholder="Type name in here ..."
                    value={formik.values.name}
                    {...formik.getFieldProps("name")}
                />
                <Box mb={2} />
                <BasicInput
                    title="Username"
                    fullWidth
                    disableMargin
                    id="username"
                    placeholder="Type username in here ..."
                    value={formik.values.username}
                    {...formik.getFieldProps("username")}
                />
                <Box mb={2} />

                <Box mt={6} mb={4}>
                    <BasicButton
                        type="submit"
                        title="Create"
                        disabled={loading}
                        onClick={formik.handleSubmit}
                    />
                </Box>
            </Box>
        </ContainerWithSomeButton>
    )
}