import { MenuItem, Stack } from "@mui/material"
import ButtonMenu from "../../../../../components/button/ButtonMenu"

export default function MenuTelegram({
    item,
    handleUnlinkDevice,
    handleAddTelegramLink,
    handleDeleteDevice
}) {
    return (
        <Stack direction="row">
            <ButtonMenu
                sx={{
                    background: 'transparent'
                }}
            >
                <MenuItem
                    sx={{
                        fontSize: 14,
                    }}
                    onClick={() => {
                        if (item.linked) {
                            handleUnlinkDevice()
                        } else {
                            handleAddTelegramLink()
                        }
                    }}
                >
                    {item.linked ? 'Unlink' : 'Add Link'}
                </MenuItem>

                <MenuItem
                    sx={{
                        fontSize: 14,
                        color: '#F15A28'
                    }}
                    onClick={handleDeleteDevice}
                >
                    Delete Account
                </MenuItem>
            </ButtonMenu>
        </Stack>
    )
}