import React from "react";
import CustomModal from "../../modal/CustomModal";
import SubmitFormContainer from "../../containers/SubmitFormContainer";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  addLinkedDevice,
  getDevices,
} from "../../../store/device/deviceAction";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ComboBox from "../../select/ComboBox";
import { toggleModalTelegram } from "../../../store/device/deviceSlice";

export default function ModalTelegram() {
  const dispatch = useDispatch();
  const { device_key, modalTelegram, listDevices } = useSelector(
    (state) => state.device
  );

  const formik = useFormik({
    initialValues: {
      device_key: device_key,
      linked_device_key: "",
    },
    onSubmit: async (value, { resetForm }) => {
      const formData = {
        ...value,
        device_key: device_key,
      };
      await toast.promise(dispatch(addLinkedDevice(formData)), {
        loading: "Please wait...",
        success: (res) => {
          resetForm();
          const body = {
            params: {
              platform: "telegram",
            },
          };
          dispatch(getDevices(body));
          dispatch(toggleModalTelegram(false));
          return res.payload.message;
        },
        error: (error) => "Error, try again later",
      });
    },
  });
  return (
    <CustomModal open={modalTelegram}>
      <SubmitFormContainer
        onSubmit={formik.handleSubmit}
        title="Add Device"
        onCancel={() => dispatch(toggleModalTelegram(false))}
      >
        <Box height={200}>
          <ComboBox
            fullWidth
            disableMargin
            title="Channel"
            placeholder="Please select channel"
            options={listDevices}
            getOptionLabel={(option) => option?.name}
            value={formik.values.type}
            onChange={(e, value) => {
              formik.setFieldValue("linked_device_key", value.device_key);
            }}
          />
        </Box>
      </SubmitFormContainer>
    </CustomModal>
  );
}
