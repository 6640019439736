import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Iconify from "../../../components/Iconify";
import Scrollbar from "../../../components/Scrollbar";
import InputSearch from "../../../components/input/InputSearch";
import {
  deleteMessageTemplate,
  getTemplates,
} from "../../../store/template/templateAction";
import { getTemplate } from "../../../store/template/templateSlice";
import palette from "../../../theme/palette";
import ButtonTooltip from "../../../components/button/ButtonTooltip";

const tableCell = ["No", "Type", "Campaign Name", "Action"];

export default function List({ setEditTemplate }) {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { templates } = useSelector((state) => state.template);

  const [isLoading, setIsLoading] = useState(false);

  const handleGetTemplates = async () => {
    setIsLoading(true);

    await dispatch(getTemplates({}));

    setIsLoading(false);
  };

  const handleDeleteTemplate = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteMessageTemplate(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        const data = {
          userId: user?.id,
        };

        handleGetTemplates({});
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  useEffect(() => {
    const data = {
      userId: user?.id,
    };

    handleGetTemplates(data);
  }, []);

  return (
    <div>
      <InputSearch />
      <Box mb={3} />
      <Scrollbar>
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          <Table aria-label="simple table">
            <TableHead
              style={{
                background: palette.gradients.custom,
              }}
            >
              <TableRow>
                {tableCell.map((item, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography color="#FDFDFD">{item}</Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Box
                      component="img"
                      src="/images/loading.svg"
                      alt="loading"
                      height="48px"
                      sx={{ mx: "auto" }}
                    />
                  </TableCell>
                </TableRow>
              ) : templates?.data?.length ? (
                templates?.data?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="left">{item.type}</TableCell>
                    <TableCell align="left">{item.template_name}</TableCell>
                    <TableCell align="left">
                      <Stack direction="row" spacing={1}>
                        <ButtonTooltip
                          title="Edit"
                          handleClick={() => {
                            dispatch(getTemplate(item));
                            setEditTemplate();
                          }}
                          icon="akar-icons:edit"
                        />
                        <ButtonTooltip
                          title="Delete"
                          handleClick={() => {
                            handleDeleteTemplate({ id: item.id });
                          }}
                          icon="fluent:delete-24-regular"
                          color="#F15A28"
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    Data Not Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </div>
  );
}
