import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridRowEditStopReasons, GridRowModes, useGridApiRef } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';
import Iconify from "../../../../components/Iconify";
import BasicButton from "../../../../components/button/BasicButton";
import ButtonWithIcon from "../../../../components/button/ButtonWithIcon";
import ContainerWithSomeButton from "../../../../components/containers/ContainerWithSomeButton";
import FormContainer from "../../../../components/containers/FormContainer";
import CustomModal from "../../../../components/modal/CustomModal";
import ComboBox from "../../../../components/select/ComboBox";
import {
  deleteCatalogProduct,
  duplicateCatalogProducts,
  fetchCatalogProducts,
  getCatalogProducts,
  updateQuantityProducts,
} from "../../../../store/catalog/catalogAction";
import { getDevices } from "../../../../store/device/deviceAction";
import UpdateForm from "./UpdateForm";
import UpdateAttribute from "./UpdateAttribute";
import { resetCatalogs } from "../../../../store/catalog/catalogSlice";
import ButtonTooltip from "../../../../components/button/ButtonTooltip";

function FormModalDuplicate({
  onClose,
  onSubmit,
  onChange,
  targetDevice,
  targetDevices,
  isDuplicateLoading
}) {
  return (
    <FormContainer title="Select Target Device">
      <Box
        sx={{
          minHeight: 150
        }}
      >
        <ComboBox
          fullWidth
          disableMargin
          placeholder="Please select the target device"
          options={targetDevices}
          getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
          value={targetDevice}
          onChange={onChange}
        />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: "flex", justifyContent: "end" }}
        mt={2}
      >
        <Button onClick={onClose}>
          Cancel
        </Button>
        <BasicButton
          title="Duplicate"
          type="submit"
          disabled={targetDevice && !isDuplicateLoading ? false : true}
          onClick={onSubmit}
        />
      </Stack>
    </FormContainer>
  )
}

export default function ProductList() {
  const dispatch = useDispatch();

  // const { devices } = useSelector((state) => state.device);
  const { plan } = useSelector((state) => state.user)
  const { catalogs } = useSelector((state) => state.catalog);

  const [devices, setDevices] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [productsSelected, setProductsSelected] = useState([]);

  const [catalogProducts, setCatalogProducts] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const [openModalDuplicate, setOpenModalDuplicate] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [openAttribute, setOpenAttribute] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isOnlyRealm, setIsOnlyRealm] = useState(false);
  const [page, setPage] = useState(1);

  const [targetDevice, setTargetDevice] = useState(null)
  const [device, setDevice] = useState(null);
  const [detail, setDetail] = useState(null);

  const [currrentQty, setCurrentQty] = useState(null)
  const [inputQtyValue, setInputQtyValue] = useState({})

  const apiRef = useRef()

  // attribute variant
  const storageAttributeName = 'attribute-name'
  const storageAttributeName2 = 'attribute-name-2'
  const colors = ["Black", "White", "Blue", "Green", "Red", "Yellow", "Orange", "Purple", "Pink", "Brown", "Grey", "Gold", "Silver", "Multi"]
  const sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', 'Free Size', 'Custom Size', '1-2 Years', '3-4 Years', '5-6 Years', '7-8 Years', '9-10 Years', '11-12 Years']
  const shoe_sizes = ['35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45']
  const dataAttribute = [
    {
      name: 'Color',
      value: 'color',
      attributeValue: [],
      menu: colors,
    },
    {
      name: 'Size',
      value: 'size',
      attributeValue: [],
      menu: sizes,
    },
    {
      name: 'Shoe Size',
      value: 'shoe_size',
      attributeValue: [],
      menu: shoe_sizes,
    },
  ]

  const planIsCustomAttribute = useMemo(() => {
    if (plan?.custom_attribute === '1') {
      return true
    }
    return false
  }, [plan])

  const defaultAttribute = useMemo(() => {
    if (planIsCustomAttribute) {
      return !localStorage.getItem(storageAttributeName) ? [dataAttribute[0]] : [JSON.parse(localStorage.getItem(storageAttributeName))[0]]
    }
    return [dataAttribute[0]]
  }, [planIsCustomAttribute])
  const [dataAttributeName, setDataAttributeName] = useState(
    planIsCustomAttribute ?
      JSON.parse(localStorage.getItem(storageAttributeName)) :
      [
        {
          name: 'Color',
          value: 'color',
          attributeValue: [],
          menu: colors,
        },
        {
          name: 'Size',
          value: 'size',
          attributeValue: [],
          menu: sizes,
        },
        {
          name: 'Shoe Size',
          value: 'shoe_size',
          attributeValue: [],
          menu: shoe_sizes,
        },
      ]
  )
  const [dataAttributeName2, setDataAttributeName2] = useState(
    planIsCustomAttribute ?
      JSON.parse(localStorage.getItem(storageAttributeName2)) :
      [
        {
          name: 'Color',
          value: 'color',
          attributeValue: [],
          menu: colors,
        },
        {
          name: 'Size',
          value: 'size',
          attributeValue: [],
          menu: sizes,
        },
        {
          name: 'Shoe Size',
          value: 'shoe_size',
          attributeValue: [],
          menu: shoe_sizes,
        },
      ]
  )
  const [triggerUpdateAttr, setTriggerUpdateAttr] = useState(false)

  const regexNumber = /^\d*$/

  // update local storage attribute name
  useEffect(() => {
    if (planIsCustomAttribute) {
      const getLocalAttributeName = localStorage.getItem(storageAttributeName)
      if (!getLocalAttributeName) {
        localStorage.setItem(storageAttributeName, JSON.stringify(dataAttribute))
      } else {
        localStorage.setItem(storageAttributeName, JSON.stringify(dataAttributeName))
      }
    }
  }, [planIsCustomAttribute, dataAttributeName, triggerUpdateAttr])

  useEffect(() => {
    if (planIsCustomAttribute) {
      setDataAttributeName(JSON.parse(localStorage.getItem(storageAttributeName)))
    }
  }, [planIsCustomAttribute, localStorage.getItem(storageAttributeName), triggerUpdateAttr])

  // update local storage attribute name 2
  useEffect(() => {
    if (planIsCustomAttribute) {
      const getLocalAttributeName2 = localStorage.getItem(storageAttributeName2)
      if (!getLocalAttributeName2) {
        localStorage.setItem(storageAttributeName2, JSON.stringify(dataAttribute))
      } else {
        localStorage.setItem(storageAttributeName2, JSON.stringify(dataAttributeName2))
      }
    }
  }, [planIsCustomAttribute, dataAttributeName2, triggerUpdateAttr])

  useEffect(() => {
    if (planIsCustomAttribute) {
      setDataAttributeName2(JSON.parse(localStorage.getItem(storageAttributeName2)))
    }
  }, [planIsCustomAttribute, localStorage.getItem(storageAttributeName2), triggerUpdateAttr])

  // update quantity only
  async function onRowEditStop(params) {
    // const { row } = params
    // const product_id = params.id
    // const quantity = row.quantity.length === 0 ? '0' : row.quantity
    // const quantity = row.quantity
    const quantity = params.value
    toast.dismiss()
    toast.loading("Please wait...");
    const data = {
      product_id: params.id,
      quantity: quantity
    };

    await dispatch(updateQuantityProducts(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);
        handleGetCatalogProducts()
        setInputQtyValue({})
        setCurrentQty(null)
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
        handleGetCatalogProducts()
        setInputQtyValue({})
        setCurrentQty(null)
      }
    });
  }

  const newValidateQty = useCallback(() => {
    const hasError = inputQtyValue?.value?.length === 0
    if (!regexNumber.test(inputQtyValue?.value)) {
      toast.dismiss()
      toast.error('Quantity must be a number')
      const reset = inputQtyValue?.value?.replaceAll(/\D/g, '')
      if (currrentQty !== reset && reset?.length !== 0) {
        setInputQtyValue({
          id: inputQtyValue?.id,
          value: reset
        })
        onRowEditStop({
          id: inputQtyValue?.id,
          value: reset
        })
      }
      return
    }
    if (hasError) {
      toast.dismiss()
      toast.error('Quantity cannot be empty')
      return
    }
    onRowEditStop(inputQtyValue)
  }, [inputQtyValue])

  // function validateQty(params) {
  //   const { row } = params
  //   const hasError = row.quantity.length === 0
  //   const newProps = { ...params }
  //   if (!regexNumber.test(row.quantity)) {
  //     toast.dismiss()
  //     toast.error('Quantity must be a number')
  //     newProps.row.quantity = row.quantity.replaceAll(/\D/g, '')
  //     if (currrentQty !== newProps.row.quantity && newProps.row.quantity.length !== 0) {
  //       onRowEditStop(newProps)
  //     }
  //     return { ...newProps }
  //   }
  //   if (hasError) {
  //     toast.dismiss()
  //     toast.error('Quantity cannot be empty')
  //     return { ...newProps }
  //   }
  //   onRowEditStop(newProps)
  //   return { ...newProps }
  // }

  const headGroupMembers = [
    {
      field: "name",
      headerName: "Name",
      width: 280,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box display="flex" sx={{ whiteSpace: "nowrap" }}>
            <Box
              component="img"
              src={row?.image}
              sx={{
                width: "80px",
                height: "80px",
                objectFit: "center",
              }}
            />
            <Box
              ml={2}
              display="flex"
              justifyContent="space-between"
              sx={{ maxWidth: "160px", overflow: "hidden" }}
            >
              <Typography ml={2} fontSize={14} fontWeight="600">
                {row?.name}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 160,
      renderCell: (params) => {
        const { row } = params;

        return row?.type === 1 ? "Physical" : row?.type ? "Digital" : null;
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 160,
      type: 'number',
      editable: true,
      // preProcessEditCellProps: (params) => {
      //   const { props } = params
      //   setInputQtyValue({
      //     id: params.id,
      //     value: props.value
      //   })
      //   return { ...params.props };
      // },
      renderEditCell: (params) => {
        return <input
          type="number"
          value={inputQtyValue?.value}
          onChange={(e) => setInputQtyValue({
            id: params.id,
            // value: params.value
            value: e.target.value
          })}
          style={{
            outline: 'none',
            border: 'none',
            height: '100%',
            width: '100%',
            padding: '0 10px'
          }}
          onBlur={(e) => {
            apiRef.current.api?.setRowMode(params.id, 'view')
            setInputQtyValue({
              id: params.id,
              value: e.target.value
            })
            if (params.value.toString() !== e.target.value?.toString()) {
              newValidateQty()
            }
          }}
          autoFocus
        />
      },
      renderCell: (params) => {
        const { row } = params;
        apiRef.current = params

        return <Typography
          fontSize={14}
          color={row?.have_attribute ? '#999' : '#212B36'}
        // color={'#212B36'}
        >
          {row?.quantity ?? "-"}
          {/* <Iconify
            icon="tabler:pencil"
            sx={{
              color: row?.have_attribute ? '#aaa' : '#212B36',
              marginLeft: 1
            }}
          /> */}
        </Typography>
      },
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 160,
      renderCell: (params) => {
        const { row } = params;

        return row?.weight !== 0 ? row?.weight : "-";
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 160,
      renderCell: (params) => {
        const { row } = params;

        return `${row?.price} ${row?.currency}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params) => {
        const { row } = params;

        return row?.status;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 160,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Stack direction="row" spacing={1}>
            <ButtonTooltip
              title="Edit Product"
              handleClick={() => {
                setDetail(row);
                _openModal();
              }}
              icon="akar-icons:edit"
            />
            <ButtonTooltip
              title="Edit Product Attributes"
              handleClick={() => {
                if (
                  planIsCustomAttribute &&
                  !localStorage.getItem(storageAttributeName) ||
                  !localStorage.getItem(storageAttributeName2)
                ) {
                  localStorage.removeItem(storageAttributeName)
                  localStorage.removeItem(storageAttributeName2)
                }
                if (planIsCustomAttribute) {
                  setTriggerUpdateAttr(true)
                  setTimeout(() => {
                    setDetail(row)
                    _openAttribute()
                    setTriggerUpdateAttr(false)
                  }, 0);
                } else {
                  setDetail(row)
                  _openAttribute()
                }
              }}
              icon="fluent:text-bullet-list-square-edit-24-regular"
              color="#8D6E63"
            />
            <ButtonTooltip
              title="Delete"
              handleClick={() => {
                setDetail(row);
                setOpenDeleteModal(!openDeleteModal);
              }}
              icon="fluent:delete-24-regular"
              color="#F15A28"
            />
          </Stack>
        );
      },
    },
  ];

  const _openModal = () => setOpenModal(!openModal);
  const _openAttribute = () => setOpenAttribute(!openAttribute)
  const _openModalDuplicate = () => setOpenModalDuplicate(!openModalDuplicate)
  const _onCloseModal = () => setOpenModal(!openModal);
  const _onCloseAttribute = () => setOpenAttribute(!openAttribute)
  const _onCloseModalDuplicate = () => setOpenModalDuplicate(!openModalDuplicate)

  const handleGetDevices = async () => {
    const body = {
      params: {
        platform: "whatsapp,waba,instagram",
      },
    };
    const result = await dispatch(getDevices(body))
    if (result.type == 'get-devices/fulfilled' && result?.payload?.data?.length > 0) {
      setDevices(result.payload.data)
    } else {
      toast.dismiss()
      toast.error(result?.payload?.message ?? 'Device not found')
      setDevices([])
    }
  };

  const handleGetCatalogProducts = async () => {
    setIsLoading(true);
    const data = {
      params: {
        page: 'all',
        device_key: device?.device_key,
      },
    };

    const result = await dispatch(getCatalogProducts(data));
    if (result.type == 'catalog/product/fulfilled') {
      toast.dismiss()
    } else {
      toast.dismiss()
      toast.error(result?.payload?.message)
    }

    setIsLoading(false);
  };

  const handleFetchCatalogProducts = async () => {
    setIsFetchLoading(true);
    setIsSelectAll(false)
    setProductsSelected([])

    toast.loading("Please wait...");

    const data = {
      device_key: device.device_key,
    };

    await dispatch(fetchCatalogProducts(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        handleGetCatalogProducts();
        setPage(1)
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });

    setIsFetchLoading(false);
  };

  const handleDuplicateCatalogProducts = async () => {
    setIsDuplicateLoading(true);

    toast.dismiss()
    toast.loading("Please wait...");

    const data = {
      device_key: targetDevice.device_key,
      ids: productsSelected,
      all_product: isSelectAll,
      country_code: targetDevice?.country_code ?? "MY",
    };

    await dispatch(duplicateCatalogProducts(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        setOpenModalDuplicate(false)
        setTimeout(() => {
          setPage(1);
          setCatalogProducts([]);
          setIsSelectAll(false);
          handleGetCatalogProducts();
        }, 1000);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });

    // setDevice(null);
    setIsDuplicateLoading(false);
  };

  const handleDeleteCatalogProduct = async (data) => {
    toast.loading("Please wait...");

    await dispatch(deleteCatalogProduct(data)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.dismiss();
        toast.success(res.payload.message);

        handleGetCatalogProducts();
        setDetail(null);
        setOpenDeleteModal(!openDeleteModal);
        setIsOnlyRealm(false);
      } else {
        toast.dismiss();
        toast.error(res.payload.message);
      }
    });
  };

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids;

    setProductsSelected(selectedRowsData);
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  useEffect(() => {
    if (devices?.length) {
      setDevice(devices[0]);
    }
  }, [devices]);

  // useEffect(() => {
  //   if (page && device) {
  //     handleGetCatalogProducts();
  //   }
  // }, [page, device]);

  useEffect(() => {
    if (device) {
      handleGetCatalogProducts();
    }
    return () => dispatch(resetCatalogs())
  }, [device]);

  useEffect(() => {
    if (catalogs?.data?.length) {
      // const _catalogs = Array.from(
      //   new Set([...catalogProducts, ...catalogs?.data].map((item) => item.id)),
      //   (id) =>
      //     [...catalogProducts, ...catalogs?.data].find((item) => item.id === id)
      // );

      setCatalogProducts(catalogs.data);
    } else {
      setCatalogProducts([]);
    }
  }, [catalogs?.data]);

  const styleEditProduct = {
    position: "absolute",
    top: "50%",
    left: "50%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    width: { xs: "calc(100% - 24px)", sm: '80%', md: 880 },
    bgcolor: "transparent",
    overflow: "auto",  // Add overflow: "auto" to enable scrolling
  };

  function selectAllProducts(checked) {
    if (checked) {
      setProductsSelected(catalogProducts.map(item => item.id))
    } else {
      setProductsSelected([])
    }
  }

  const total_page = useMemo(() => {
    const total = Math.floor(catalogProducts.length / 10)
    return total
  }, [catalogProducts])

  // form modal duplicate
  const targetDevices = useMemo(() => {
    if (devices.length === 0) {
      return []
    }

    const newDevices = devices.filter(dev => dev.device_key !== device?.device_key)
    return newDevices
  }, [devices, device])

  useEffect(() => {
    setTargetDevice(targetDevices[0])
  }, [targetDevices])

  function changeTargetDevice(e, value) {
    setTargetDevice(value)
  }

  return (
    <ContainerWithSomeButton title="Product List" button1title="Channel">
      <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%" }}>
          <ComboBox
            fullWidth
            disableMargin
            placeholder="Please select channel"
            options={devices}
            getOptionLabel={(option) => (option?.name ? `${option.name} - ${option.phone}` : '')}
            value={device}
            onChange={(e, value) => {
              setCatalogProducts([]);
              setDevice(value);
              setPage(1);
              setIsSelectAll(false)
            }}
          />
        </Box>
        <Box sx={{ ml: 2 }}>
          <ButtonWithIcon
            title="Fetch"
            icon="mdi:widget-timeline-variant"
            disabled={isFetchLoading || !device}
            onClick={() => handleFetchCatalogProducts()}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            type="checkbox"
            sx={{ "& .MuiSvgIcon-root": { fontSize: 32 }, p: 0 }}
            checked={isSelectAll}
            onChange={() => {
              selectAllProducts(!isSelectAll)
              setIsSelectAll(!isSelectAll);
            }}
            disabled={catalogProducts.length === 0}
          />
          <Typography fontSize={16} fontWeight="600" sx={{ mb: -0.5, ml: 1 }}>
            {/* All Product ( Total {catalogs?.pagination?.total} ) */}
            All Product ( Total {catalogProducts.length} )
          </Typography>
        </Box>
        <Box>
          <ButtonWithIcon
            title="Duplicate"
            icon="zondicons:duplicate"
            disabled={
              isDuplicateLoading ||
              !device ||
              !(productsSelected?.length || isSelectAll)
            }
            // onClick={() => handleDuplicateCatalogProducts()}
            onClick={_openModalDuplicate}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 2 }} />
      <Box sx={{ mb: 2, height: "1080px", width: "100%" }}>
        <DataGrid
          checkboxSelection
          editMode="row"
          rows={catalogProducts ?? []}
          columns={headGroupMembers}
          getRowId={(row) => row?.id}
          getRowHeight={() => 100}
          pageSize={10}
          page={page - 1}
          selectionModel={productsSelected}
          isCellEditable={(params) => {
            if (params.row?.have_attribute) {
              return false
            }
            return true
          }}
          onSelectionModelChange={(ids) => {
            onRowsSelectionHandler(ids);
            if (ids.length < catalogProducts.length && isSelectAll) {
              setIsSelectAll(false)
            } else if (ids.length > 0 && ids.length === catalogProducts.length && !isSelectAll) {
              setIsSelectAll(true)
            }
          }}
          onRowEditStart={(params) => {
            setCurrentQty(params.row.quantity)
            setInputQtyValue({
              value: params.row.quantity,
              id: params.id
            })
          }}
          onRowEditStop={(params) => {
            if (params.row.quantity.toString() !== inputQtyValue?.value?.toString()) {
              newValidateQty()
            }
          }}
          hideFooter
        />
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title="<"
            onClick={() => {
              if (parseInt(page) > 1) {
                setPage(parseInt(page) - 1);
              }
            }}
            disabled={parseInt(page) === 1}
          />
        </Box>
        <Box sx={{ mx: 1 }}>
          <BasicButton
            title=">"
            onClick={() => {
              // if (parseInt(page) < catalogs?.pagination?.last_page) {
              //   setPage(parseInt(page) + 1);
              // }
              if (parseInt(page) <= total_page) {
                setPage(parseInt(page) + 1);
              }
            }}
            // disabled={parseInt(page) >= catalogs?.pagination?.last_page}
            disabled={parseInt(page) > total_page}
          />
        </Box>
      </Box>
      <CustomModal
        open={openModal}
      >
        <UpdateForm
          onCancel={_onCloseModal}
          detail={detail}
          getData={() => handleGetCatalogProducts()}
          resetCatalogs={() => setCatalogProducts([])}
        />
      </CustomModal>
      <CustomModal
        open={openAttribute}
        style={styleEditProduct}
      >
        <UpdateAttribute
          dataAttribute={dataAttribute}
          defaultAttribute={defaultAttribute}
          dataAttributeName={dataAttributeName}
          dataAttributeName2={dataAttributeName2}
          setDataAttributeName={setDataAttributeName}
          setDataAttributeName2={setDataAttributeName2}
          planIsCustomAttribute={planIsCustomAttribute}
          detail={detail}
          onCancel={() => {
            if (
              !localStorage.getItem(storageAttributeName) ||
              !localStorage.getItem(storageAttributeName2)
            ) {
              window.location.reload()
              return
            }
            _onCloseAttribute()
          }}
          getData={() => handleGetCatalogProducts()}
          resetCatalogs={() => setCatalogProducts([])}
        />
      </CustomModal>
      <CustomModal
        open={openModalDuplicate}
      >
        <FormModalDuplicate
          onClose={_onCloseModalDuplicate}
          targetDevices={targetDevices}
          targetDevice={targetDevice}
          onChange={changeTargetDevice}
          onSubmit={handleDuplicateCatalogProducts}
          isDuplicateLoading={isDuplicateLoading}
        />
      </CustomModal>
      <CustomModal open={openDeleteModal}>
        <FormContainer title="Area you sure delete product on Realm only?">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              type="checkbox"
              checked={isOnlyRealm}
              onChange={() => {
                setIsOnlyRealm(!isOnlyRealm);
              }}
            />
            <Typography variant="body2" sx={{ fontWeight: "400" }}>
              Delete on Realm and WhatsApp Catalog
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Box>
              <Button onClick={() => setOpenDeleteModal(!openDeleteModal)}>
                Cancel
              </Button>
              <BasicButton
                title="Delete"
                type="submit"
                onClick={() =>
                  handleDeleteCatalogProduct({
                    product_id: detail?.id,
                    delete_from_wa: isOnlyRealm,
                  })
                }
              />
            </Box>
          </Stack>
        </FormContainer>
      </CustomModal>
    </ContainerWithSomeButton>
  );
}
