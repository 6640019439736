import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, clientApi } from "../../services/api";

export const createMessageTemplate = createAsyncThunk(
  "message-template/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/template/add", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateMessageTemplate = createAsyncThunk(
  "template/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/template/update", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getTemplates = createAsyncThunk(
  "template",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/template", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMessageTemplate = createAsyncThunk(
  "template/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/template/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPublicTemplates = createAsyncThunk(
  "message-template/public",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("/template/library", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMessageTemplate = createAsyncThunk(
  "message-template/detail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/message-template-get", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const copyMessageTemplate = createAsyncThunk(
  "template/copy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("/template/copy", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
