import { createSlice } from "@reduxjs/toolkit";
import { getDeviceProfile, getDevices, getListCategory, getListDevicesForCombobox } from "./deviceAction";

const initialState = {
  devices: [],
  listDevices: [],
  statusDevices: [],
  modalTelegram: false,
  modalUpdateProfile: false,
  modalWaba: false,
  modalInstagram: false,
  device_key: "",
  deviceProfile:{},
  categories:[],
  updateInstagramDevice: false
};

const deviceSlice = createSlice({
  name: "device",
  initialState: initialState,
  reducers: {
    updateInstagramDevice: (state, {payload})=>{
      state.updateInstagramDevice = payload
    },
    resetDevice: (state) => {
      state.devices = initialState.devices;
    },
    toggleModalTelegram: (state, { payload }) => {
      state.modalTelegram = payload;
    },
    toggleModalWaba: (state, { payload }) => {
      state.modalWaba = payload;
    },
    toggleModalInstagram: (state, { payload }) => {
      state.modalInstagram = payload;
    },
    toggleModalUpdateProfile: (state, { payload }) => {
      state.modalUpdateProfile = payload;
    },
    setDeviceKey: (state, { payload }) => {
      state.device_key = payload;
    },
    addStatusDevice: (state, { payload }) => {
      const raw = JSON.parse(JSON.stringify(state.statusDevices));

      raw.push({
        accountid: payload?.accountid,
        status: payload?.status,
        isFarming: payload?.isFarming,
      });

      state.statusDevices = raw;
    },
    updateStatusDevice: (state, { payload }) => {
      const raw = JSON.parse(JSON.stringify(state.statusDevices));

      const dataOne = raw.find((e) => e.accountid === payload?.accountid);
      const dataAll = raw.filter((e) => e.accountid !== payload?.accountid);

      if (dataOne) {
        dataOne.status = payload ? payload.status : null;

        dataAll.push(dataOne);
      }

      state.statusDevices = dataAll;
    },
    deleteStatusDevice: (state, { payload }) => {
      const raw = JSON.parse(JSON.stringify(state.statusDevices));

      const data = raw.filter((e) => e.accountid !== payload?.accountid);

      state.statusDevices = data;
    },
    resetStatusDevice: (state) => {
      state.statusDevices = initialState.statusDevices;
    },
  },
  extraReducers: {
    [getDevices.fulfilled]: (state, { payload }) => {
      state.devices = payload?.data;
    },
    [getListDevicesForCombobox.fulfilled]: (state, { payload }) => {
      state.listDevices = payload?.data;
    },
    [getDeviceProfile.fulfilled]: (state, { payload }) => {
      state.deviceProfile = payload?.data;
    },
    [getListCategory.fulfilled]: (state, { payload }) => {
      state.categories = payload?.data.categories;
    },
  },
});

export const {
  resetDevice,
  addStatusDevice,
  updateStatusDevice,
  deleteStatusDevice,
  resetStatusDevice,
  setDeviceKey,
  toggleModalTelegram,
  toggleModalWaba,
  toggleModalInstagram,
  toggleModalUpdateProfile,
  updateInstagramDevice
} = deviceSlice.actions;

export default deviceSlice.reducer;
