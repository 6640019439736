import { Box, Typography } from '@mui/material'
import React, { Fragment } from 'react'

export default function FormGroup({ title, subTitle = null, children }) {
    return (
        <Box marginBottom={"40px"}>
            <Box marginBottom={"12px"}>
                <Typography
                    fontSize={{ xs: 12, sm: 16 }}
                    fontWeight="600"
                    marginBottom={subTitle && "4px"}
                    color="#7B7B7B"
                >
                    {title}
                </Typography>
                {
                    subTitle && (
                        <Typography
                            fontSize={{ xs: 10, sm: 14 }}
                            fontWeight="400"
                            color="#7B7B7B"
                            marginBottom={"20px"}
                        >
                            {subTitle}
                        </Typography>
                    )
                }

            </Box>

            {children}
        </Box>
    )
}
