import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";

export const getGroups = createAsyncThunk(
  "groups",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/get-all-groups", data);

      if (response?.data?.length) {
        return response?.data;
      } else {
        return rejectWithValue(null);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getGroupMembers = createAsyncThunk(
  "groups/all-members",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/get-all-group-members", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
