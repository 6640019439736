import { createSlice } from "@reduxjs/toolkit"
import { getLanguages } from "./languageAction"

const initialState = {
    languages: []
}

const languageSlice = createSlice({
    name: 'language',
    initialState,
    extraReducers: {
        [getLanguages.fulfilled]: (state, {payload})=>{
            state.languages = payload
        }
    }
})

export const {} = languageSlice.actions

export default languageSlice.reducer