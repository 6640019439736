import { MenuItem, Stack, Switch, Typography } from "@mui/material";
import ButtonMenu from "../../../../../components/button/ButtonMenu";

export default function MenuFacebook({
    status,
    navigate,
    item,
    handleDeleteDevice
}) {
    return <Stack direction="row">
        <ButtonMenu
            sx={{
                background: 'transparent'
            }}
        >
            <MenuItem
                    sx={{
                        fontSize: 14,
                        color: '#F15A28'
                    }}
                    onClick={handleDeleteDevice}
                >
                    Delete Account
                </MenuItem>
        </ButtonMenu>
    </Stack>
}