import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import Iconify from "../../../../components/Iconify";

export default function WarningOption({
    icon = 'ph:warning',
    desc = 'This box must be checked to add this button.',
    label,
    checked,
    showCheckedBox = true,
    onChange
}) {
    return (
        <>
            {showCheckedBox &&
                <FormGroup
                    sx={{
                        marginTop: 2
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="info"
                                checked={checked}
                            />
                        } label={
                            <Typography
                                fontSize={{
                                    xs: 12,
                                    sm: 14,
                                    md: 14,
                                }}
                                color="#000"
                            >
                                {label}
                            </Typography>
                        }
                        onChange={onChange}
                    />
                </FormGroup>
            }
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0.5,
                    backgroundColor: '#fadcdc'
                }}
                mt={2}
                p={1}
            >
                <Iconify
                    icon={icon}
                    sx={{
                        marginRight: 1,
                        color: '#e61e1e'
                    }}
                />
                <Typography
                    fontSize={{
                        xs: 12,
                        sm: 14,
                        md: 14
                    }}
                    color="#000"
                    fontWeight={400}
                >
                    {desc}
                </Typography>
            </Box>
        </>
    )
}