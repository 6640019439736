import React from "react";
import { Button as Paper, Typography } from "@mui/material";
import palette from "../../theme/palette";

export default function BasicButton({ title, disabled, onClick, fullWidth, children, type, ...props }) {
  return (
    <Paper
      fullWidth={fullWidth}
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      type={type}
      sx={
        disabled
          ? {
              background: palette.secondary.dark,
              borderRadius: "12px",
              color: "#fdfdfd",
            }
          : {
              background: palette.gradients.custom,
              borderRadius: "12px",
              color: "#fdfdfd",
            }
      }
      {...props}
    >
      {title && <Typography fontSize={{ xs: 12, sm: 14 }}>{title}</Typography>}
      {children}
    </Paper>
  );
}
