import { createAsyncThunk } from "@reduxjs/toolkit";
import { clientApi } from "../../services/api";

export const getPlans = createAsyncThunk(
  "subscribe/plans",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.get("subscribe/plans", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const subscribe = createAsyncThunk(
  "subscribe/store",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("subscribe/store", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const unsubscribe = createAsyncThunk(
  "subscribe/delete",
  async (data, { rejectWithValue }) => {
    try {
      const response = await clientApi.post("subscribe/delete", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
