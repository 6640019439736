import { Box, Typography } from "@mui/material";

export default function FooterComponent({ detail }) {
    return (
        <Box
            mb={1}
            mt={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2 }}
        >
            <Box>
                <Typography
                    fontSize="14px"
                    color="rgba(55, 55, 55, 0.7)"
                >
                    {detail?.footer?.text ?? 'Powered by realm.chat'}
                </Typography>
            </Box>
            <Box display="flex">
                <Typography
                    fontSize="8px"
                    mr={1}
                    color="rgba(55, 55, 55, 0.7)"
                >
                    {new Date().getHours() +
                        ":" +
                        new Date().getMinutes()}
                </Typography>
                <img src="/static/icons/double_check.svg" />
            </Box>
        </Box>
    )
}