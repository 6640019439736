import { Box, Typography } from "@mui/material";
import palette from "../../../../theme/palette";

export default function TemplateSection({
    title,
    desc,
    isOptional,
    children
}) {
    return (
        <Box
            mt={4}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Typography
                    fontSize={16}
                    fontWeight="700"
                    mr={isOptional ? 1 : 0}
                >
                    {title}
                </Typography>
                {isOptional &&
                    <Typography
                        fontSize={16}
                        fontWeight="700"
                    >
                        (Optional)
                    </Typography>
                }
            </Box>
            <Typography
                fontSize={14}
                fontWeight="400"
                color={palette.secondary.dark}
                mt={1}
                mb={2}
            >
                {desc}
            </Typography>
            {children}
        </Box>
    )
}