import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getLanguages = createAsyncThunk(
    'languages', 
    async (data, {rejectWithValue})=>{
        try {
            const result = await axios.get('https://api.country.realm.chat/languages.php', data)

            return result.data
        } catch (error) {
            return rejectWithValue(error)            
        }
    }
)