import { createSlice } from "@reduxjs/toolkit";
import { getCustomerServices } from "./customerServiceAction";

const initialState = {
  customerService: null,
  customerServices: [],
};

const customerServiceSlice = createSlice({
  name: "customer-service",
  initialState: initialState,
  reducers: {
    getCustomerService: (state, { payload }) => {
      state.customerService = payload;
    },
  },
  extraReducers: {
    [getCustomerServices.fulfilled]: (state, { payload }) => {
      state.customerServices = payload;
    },
    [getCustomerServices.rejected]: (state, { payload }) => {
      state.customerServices = [];
    },
  },
});

export const { getCustomerService } = customerServiceSlice.actions;

export default customerServiceSlice.reducer;
