import { createAsyncThunk } from "@reduxjs/toolkit";
import { connectApi } from "../../services/api";

export const getIntegrationWebhooks = createAsyncThunk(
  "integration/webhook",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();

      data.headers = {
        Authorization: `Bearer ${user.authToken}`,
      };

      const response = await connectApi.get("/integration/webhook", data);

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateIntegrationWebhook = createAsyncThunk(
  "integration/webhook/update",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      };

      const response = await connectApi.post(
        "integration/webhook/update",
        data,
        config
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteIntegrationWebhook = createAsyncThunk(
  "integration/webhook/delete",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { user } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${user.authToken}`,
        },
      };

      const response = await connectApi.post(
        "integration/webhook/delete",
        data,
        config
      );

      if (response.data.result) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
