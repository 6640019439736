import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function AuthRoute({ element }) {
  const navigate = useNavigate();
  const { login } = useSelector((state) => state.user);

  useEffect(() => {
    if (login === true) {
      navigate("/");
    }
  }, [login]);

  if (login === false) {
    return element;
  } else {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="/images/loading.svg" alt="loading" height="40" />
      </div>
    );
  }
}
