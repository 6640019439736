import { Box } from "@mui/material";
import React from "react";

export default function BoxImage({ src, alt }) {
  return (
    <Box
          sx={{
            mr: 4,
            padding: 2,
            height: '20%',
            border: `1px solid #E4E4E4`,
            borderRadius: 1,
          }}
        >
    <Box
      width={120}
      height={40}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      margin={0}
      sx={{
        width: ["50px", "80px", "120px"], // Responsive values for width
        height: ["20px", "30px", "40px"], // Responsive values for height
      }}
    >
      <img src={src} alt={alt} style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'cover',
        }} />
    </Box>
        </Box>
  );
}
