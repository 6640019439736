import { MenuItem, Stack, Switch, Typography } from "@mui/material";
import ButtonMenu from "../../../../../components/button/ButtonMenu";

export default function MenuCloudAPICatalogSetting({
    status,
    navigate,
    item,
    handleUpdateCatalogSetting
}) {
    return <Stack direction="row">
        <ButtonMenu
            sx={{
                background: 'transparent'
            }}
        >
            <MenuItem
                sx={{
                    fontSize: 14,
                }}
                onClick={() => navigate('/dashboard/app/device-update/' + item?.device_key)}
            >
                Update Profile
            </MenuItem>
            <MenuItem
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 0,
                }}
            >
                <Typography>Cart</Typography>
                <Switch
                    checked={item?.catalog_setting?.is_cart_enabled}
                    onChange={handleUpdateCatalogSetting}
                />
            </MenuItem>
            <MenuItem
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 0,
                }}
            >
                <Typography>Catalog</Typography>
                <Switch
                    checked={
                        item?.catalog_setting?.is_catalog_visible
                    }
                    onChange={handleUpdateCatalogSetting}
                />
            </MenuItem>
        </ButtonMenu>
    </Stack>
}