import RenderHeaderMedia from "./RenderHeaderMedia";
import RenderHeaderText from "./RenderHeaderText";

export default function HeaderComponent({
    detail,
    headerValue,
    headerMedia
}) {
    return (
        <>
            {headerValue?.value == 'text' &&
                detail?.header_text &&
                <RenderHeaderText detail={detail} />
            }
            {
                headerValue?.value == 'media' &&
                <RenderHeaderMedia
                    icon={
                        headerMedia?.extension == 'image' ?
                            'bi:image' :
                            headerMedia?.extension == 'video' ?
                                'lets-icons:video-fill' :
                                'solar:document-text-bold'
                    }
                />
            }
        </>
    )
}